import React, { useState, useEffect, useCallback } from 'react';
import { useImmer } from 'use-immer';
import { useSystemContext } from '../../Context/SystemContext';
import { useParams, useHistory } from 'react-router-dom';

import Helmet from 'react-helmet';

import ComandanteAPI from '../../utils/ComandanteAPI';

import debounce from 'lodash.debounce';

import MainLayout from "../../Layouts/Main";

import { Tabs, Select, Spin, Form, Input } from 'antd';
import { SearchOutlined } from '@ant-design/icons'

import './Project.css';

import ArchivedTask from './Draggable/ArchivedTask';
import ViewTaskDrawer from '../Task/Drawers/ViewTaskDrawer';

import Error from '../Error/Error';

const Archived = () => {
    const { 
        currentUser,
        membersDataset,
        systemVariables
    } = useSystemContext();

    const roleOptions = (systemVariables && systemVariables.user && systemVariables.user.roleOptions ? systemVariables.user.roleOptions : null);

    const history = useHistory();

    const { productId, projectId, taskId } = useParams();

    const [searchArchivedTasksForm] = Form.useForm();

    const [productDataset, setProductDataset] = useState(null);
    const [projectDataset, setProjectDataset] = useState(null);
    const [tasksByStageIdDataset, updateTasksByStageIdDataset] = useImmer(null);
    const [tasksSortBy, setTasksSortBy] = useState('custom');
    const [archivedTasks, updateArchivedTasks] = useImmer(null);

    const [taskIdForViewTaskDrawer, setTaskIdForViewTaskDrawer] = useState(null);

    const [pageError, setPageError] = useState(null);

    const fetchProduct = useCallback(() => {
        ComandanteAPI.HttpGetRequest('products/' + productId + '/get', (err, res) => {
            if(err) {
                setPageError(<Error status={ (err.status ? err.status : null) } />);
                return;
            }

            if(!res || !res.data) {
                setPageError(<Error status={ 404 } />);
                return;
            }

            setProductDataset((res && res.data) || null);
        });
    }, [productId]);

    const fetchProject = useCallback(() => {
        ComandanteAPI.HttpGetRequest('products/' + productId + '/projects/' + projectId + '/get', (err, res) => {
            if(err) {
                setPageError(<Error status={ (err.status ? err.status : null) } />);
                return;
            }

            if(!res || !res.data) {
                setPageError(<Error status={ 404 } />);
                return;
            }

            setProjectDataset((res && res.data) || null);
        });
    }, [productId, projectId]);

    const fetchTasksByStageId = useCallback((sortBy = null) => {
        ComandanteAPI.HttpGetRequest('products/' + productId + '/projects/' + projectId + '/tasks-by-stage-id' + (sortBy && sortBy !== 'custom' ? '?sort='+sortBy : ''), (err, res) => {
            if(err) {
                setPageError(<Error status={ (err.status ? err.status : null) } />);
                return;
            }

            if(!res || !res.data) {
                setPageError(<Error status={ 404 } />);
                return;
            }

            updateTasksByStageIdDataset(tasksByStageIdDataset => (res && res.data) || null);
        });
    }, [productId, projectId, updateTasksByStageIdDataset]);

    const fetchArchivedTasks = useCallback((filter = null) => {
        ComandanteAPI.HttpGetRequest('products/' + productId + '/projects/' + projectId + '/tasks/archived' + (filter ? '?filter='+filter : ''), (err, res) => {
            updateArchivedTasks(archivedTasks => (res && res.data) || null);
        });
    }, [productId, projectId, updateArchivedTasks]);

    const debounced_fetchArchivedTasks = debounce(value => {
        fetchArchivedTasks(value);
    }, 300);

    useEffect(() => {
        if(window.innerWidth <= 575) {
            history.push('/products/' + productId + '/projects/' + projectId + '/list');
        }
    })

    useEffect(() => {
        fetchProduct();
        fetchProject();
        fetchArchivedTasks(null);
    }, [fetchProduct, fetchProject, fetchArchivedTasks]);

    const onTabChange = (activeKey) => {
        history.push('/products/' + productId + '/projects/' + projectId + '/' + activeKey)
    }   

    if(pageError) return pageError;

    return (
        (projectDataset && projectDataset.project) ? (
            <>
                <Helmet>
                    <title>{ (projectDataset.project.name ? projectDataset.project.name : 'Project') + ' | Projects | ' + (productDataset && productDataset.product.name ? productDataset.product.name : 'Product') + ' | Products | ' + process.env.REACT_APP_META_TITLE }</title>
                </Helmet>

                <MainLayout>
                    <>
                        <div className="project--section-header">
                            <div className="float-right">
                                <Form form={ searchArchivedTasksForm } layout="inline">
                                    <Form.Item className="ml-4" name="search">
                                        <Input 
                                            className="curved-input"
                                            style={{ zIndex: 999, maxWidth: '360px' }}
                                            prefix={ <SearchOutlined /> }
                                            placeholder="Search archived tasks..."
                                            onChange={ (event) => {
                                                event.persist(); // Signal to React not to nullify the event object

                                                debounced_fetchArchivedTasks(event.target.value);
                                            }}
                                        />
                                    </Form.Item>
                                </Form>
                            </div>

                            <div>
                                <h1 className="d-inline-block">{ (projectDataset.project.name ? projectDataset.project.name : 'Project') }</h1>

                                <Tabs className="project--header-tabs d-inline-block" defaultActiveKey="board" onChange={ onTabChange }>
                                    <Tabs.TabPane tab="Board" key="board"></Tabs.TabPane>
                                    <Tabs.TabPane tab="List" key="list"></Tabs.TabPane>
                                    <Tabs.TabPane tab="Archived" key="archived"></Tabs.TabPane>
                                    <Tabs.TabPane tab="Report" key="report"></Tabs.TabPane>
                                </Tabs>
                            </div>
                        </div>

                        <div className="project--section-body pt-4 pb-4" style={{ overflowX: 'scroll' }}>
                            {
                                !(currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Observer', 'Contributor', 'Editor'].includes(roleOptions[currentUser.role+''])) && (
                                    <>
                                        <div className="stages-C list">
                                            <div className="stage-W ml-4" style={{ maxWidth: '650px' }}> 
                                                <div className="stage-column mt-4" style={{ background: '#4662EA0D' }}>
                                                    <div className="stage-label mb-2" style={{ color: '#4662EA' }}>Archived</div>

                                                    {
                                                        archivedTasks && archivedTasks.data && Object.keys(archivedTasks.data).length > 0 && Object.values(archivedTasks.data).map((task, index) => {
                                                            return (
                                                                <ArchivedTask
                                                                    key={ task._id }
                                                                    view="list"
                                                                    index={ index }
                                                                    _id={ task._id }
                                                                    organizationId={ currentUser && currentUser.organizationId }
                                                                    productId={ productId }
                                                                    projectId={ projectId }
                                                                    linkTo={ '/products/' + productId + '/projects/' + projectId + '/tasks/' + task._id } 
                                                                    product={ productDataset && productDataset.product ? productDataset.product.name : '' }
                                                                    project={ projectDataset && projectDataset.project ? projectDataset.project.name : '' }
                                                                    title={ task.title }
                                                                    attachments={ task.attachments ? task.attachments : [] }
                                                                    assignee={ task.assignedTo && membersDataset && membersDataset.data && membersDataset.data[task.assignedTo] ? membersDataset.data[task.assignedTo].firstName + ' ' + membersDataset.data[task.assignedTo].lastName : null }
                                                                    priority={ task.priority }
                                                                    dueDate={ task.dueDate } 
                                                                    status={ task.status }
                                                                    setTaskIdForViewTaskDrawer={ setTaskIdForViewTaskDrawer }
                                                                />
                                                            );
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )
                            }
                        </div>

                        { 
                            (taskIdForViewTaskDrawer) && (<ViewTaskDrawer
                                key={ 'viewTaskDrawer--taskId-' + taskIdForViewTaskDrawer }
                                onClose={ () => { 
                                    setTaskIdForViewTaskDrawer(null); 
                                    if(taskId) history.push('/products/' + productId + '/projects/' + projectId);
                                } }
                                productId={ productId }
                                projectId={ projectId }
                                taskId={ taskIdForViewTaskDrawer }
                                refreshTasks={ fetchTasksByStageId }
                                stageValue={ null }
                            />)
                        }
                    </>
                </MainLayout>
            </>
        ) : (
            <div className="aligner-xy" style={{ height: '100vh' }}><Spin /></div>
        )
    )
}

export default Archived;