import React, { useState, useEffect, useCallback } from 'react';
import { useImmer } from "use-immer";
import { useSystemContext } from '../../Context/SystemContext';

import ComandanteAPI from '../../utils/ComandanteAPI';
import { Link } from 'react-router-dom';

import Helmet from 'react-helmet';

import moment from 'moment';

import MainLayout from '../../Layouts/Main';

import CustomIcon from '../../UIElements/CustomIcon/CustomIcon';

import { Row, Col, Collapse, Spin } from 'antd';

import './Home.css';

import avatarPlaceholder from '../../assets/images/placeholder-avatar.png';

import ViewTaskDrawer from '../Task/Drawers/ViewTaskDrawer';

const Home = () => {
    const { 
        currentUser, 
        systemVariables,
        productsDataset,
        projectsDataset,
        membersDataset
    } = useSystemContext();

    const priorityLevelOptions = (systemVariables && systemVariables.task && systemVariables.task.priorityLevelOptions ? systemVariables.task.priorityLevelOptions : null);

    const [myTasksDueSoonDataset, updateMyTasksDueSoonDataset] = useImmer(null);
    const [myTasksRunningLateDataset, updateMyTasksRunningLateDataset] = useImmer(null);

    const [taskForViewTaskDrawer, setTaskForViewTaskDrawer] = useState(null);

    const getMyTasksDueSoon = useCallback(async () => {
        ComandanteAPI.HttpGetRequest('my-tasks/due-soon?paginated=false', (err, res) => {
            updateMyTasksDueSoonDataset(myTasksDueSoonDataset => (res && res.data) || null);
        });
    }, [updateMyTasksDueSoonDataset]);

    const getMyTasksRunningLate = useCallback(async () => {
        ComandanteAPI.HttpGetRequest('my-tasks/running-late?paginated=false', (err, res) => {
            updateMyTasksRunningLateDataset(myTasksRunningLateDataset => (res && res.data) || null);
        });
    }, [updateMyTasksRunningLateDataset]);

    useEffect(() => {
        getMyTasksDueSoon();
        getMyTasksRunningLate();
	}, [getMyTasksDueSoon, getMyTasksRunningLate]);
    
    return (
        (currentUser && systemVariables) ? (
            <>
                <Helmet>
                    <title>Home | { process.env.REACT_APP_META_TITLE }</title>
                </Helmet>

                <MainLayout>
                    <div style={{ maxWidth: '612px' }}>
                        <Collapse className="home--collapsing-cards m-xl-5" defaultActiveKey={ ['tasks-due-soon', 'tasks-running-late', 'latest-notifications', 'to-do-list'] }>
                            <Collapse.Panel key="tasks-due-soon" header={ <><CustomIcon className="d-xs-none" iconKey="tasks" color="#8C8C8C" style={{ width: '16px', height: '18px', margin: '0 2px -3px 0' }} /> Tasks due soon</> }>
                                <div className="content">
                                    {
                                        myTasksDueSoonDataset && myTasksDueSoonDataset.data && Object.keys(myTasksDueSoonDataset.data).length > 0 ? (
                                            Object.values(myTasksDueSoonDataset.data).map(task => {
                                                return (
                                                    <Row key={ task._id }>
                                                        <Col span={ 24 }>
                                                            <div key={ task._id } className="task hover-pointer" onClick={ () => { setTaskForViewTaskDrawer({ ...task }) } }>
                                                                <div className="title">{ task.title ? task.title : 'N/A' }</div>

                                                                <div className="info-C">
                                                                    <div className="breadcrumbs">
                                                                        { 
                                                                            productsDataset && productsDataset.data && productsDataset.data[task.productId+''] ? (
                                                                                <Link to={ '/products/' + productsDataset.data[task.productId+'']._id }>{ productsDataset.data[task.productId+''].name }</Link>
                                                                            ) : (
                                                                                'Product'
                                                                            )
                                                                        }

                                                                        <span className="ml-2 mr-2">&gt;</span>

                                                                        {
                                                                            productsDataset && productsDataset.data && productsDataset.data[task.productId+''] && projectsDataset && projectsDataset.data && projectsDataset.data[task.projectId+''] ? (
                                                                                <Link to={ '/products/' + productsDataset.data[task.productId+'']._id + '/projects/' + projectsDataset.data[task.projectId+'']._id }>{ projectsDataset.data[task.projectId+''].name }</Link>
                                                                            ) : (
                                                                                'Project'
                                                                            )
                                                                        }
                                                                    </div>

                                                                    <div className="R1">
                                                                        <div className={ 'priority d-inline-block float-right' +  (task.priority && priorityLevelOptions && priorityLevelOptions[task.priority] ? ' ' + priorityLevelOptions[task.priority].toLowerCase() : '')}>
                                                                            {
                                                                                (task.priority) ? (
                                                                                    (priorityLevelOptions && priorityLevelOptions[task.priority]) ? (
                                                                                        priorityLevelOptions[task.priority]
                                                                                    ) : (
                                                                                        task.priority
                                                                                    )
                                                                                ) : (
                                                                                    'None'
                                                                                )
                                                                            }
                                                                        </div>

                                                                        <div className="vertical-y">
                                                                            <div className={ 'assignee' + (task.assignedTo ? '' : ' empty') }>
                                                                                {
                                                                                    (task.assignedTo) ? (
                                                                                        <img className="mr-1 mb-0" src={ avatarPlaceholder } alt="Avatar" />
                                                                                    ) : (
                                                                                        <CustomIcon iconKey="profile" color="#C0C0C3" style={{ margin: '0 4px -2px 0' }} /> 
                                                                                    )
                                                                                }

                                                                                { task.assignedTo && membersDataset && membersDataset.data && membersDataset.data[task.assignedTo] ? membersDataset.data[task.assignedTo].firstName + ' ' + membersDataset.data[task.assignedTo].lastName : 'No assignee' }
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="R2 clearfix mt-3">
                                                                        {
                                                                            task.dueDate && (
                                                                                <div className="float-right" style={{ fontSize: '12px' }}>
                                                                                    <CustomIcon iconKey="date-time" color='#6246EA' style={{ margin: '0 2px -2px 0' }} /> { 'Due ' + moment(task.dueDate).fromNow() }
                                                                                </div>
                                                                            )   
                                                                        }

                                                                        {
                                                                            task.activities && task.activities.length > 0 &&  (
                                                                                <span className="ml-1 mr-1">
                                                                                    <CustomIcon iconKey="comments" color="#6246EA" /> { task.activities.length }
                                                                                </span>
                                                                            ) 
                                                                        }

                                                                        {
                                                                            task.dependencies && task.dependencies.length > 0 && (
                                                                                <span className="ml-1 mr-1">
                                                                                    <CustomIcon iconKey="dependencies" color="#6246EA" /> { task.dependencies.length }
                                                                                </span>
                                                                            ) 
                                                                        }

                                                                        {
                                                                            task.checklist && task.checklist.length > 0 && (
                                                                                <span className="ml-1 mr-1">
                                                                                    <CustomIcon iconKey="checklist" color="#6246EA" style={{ height: '13px', margin: '0 0 -1px 0' }} /> { task.checklist.length }
                                                                                </span>
                                                                            ) 
                                                                        }

                                                                        {
                                                                            task.attachments && task.attachments.length > 0 && (
                                                                                <span className="ml-1 mr-1">
                                                                                    <CustomIcon iconKey="attachment-horizontal" color="#6246EA" /> { task.attachments.length }
                                                                                </span>
                                                                            ) 
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>  
                                                        </Col>
                                                    </Row>
                                                );
                                            })
                                        ) : (
                                            <div className="text-center p-3" style={{ background: '#F8F8FB', border: '1px solid #DFE0E1', borderRadius: '3px', color: '#8C8C8C' }}>
                                                <div className="title">You have no tasks due today.</div>
                                            </div>                            
                                        )
                                    }
                                </div>
                            </Collapse.Panel>

                            <Collapse.Panel key="tasks-running-late" header={ <><CustomIcon className="d-xs-none" iconKey="tasks" color="#8C8C8C" style={{ width: '16px', height: '18px', margin: '0 2px -3px 0' }} /> Tasks running late</> }>
                                <div className="content">
                                    {
                                        myTasksRunningLateDataset && myTasksRunningLateDataset.data && Object.keys(myTasksRunningLateDataset.data).length > 0 ? (
                                            Object.values(myTasksRunningLateDataset.data).map(task => {
                                                return (
                                                    <div key={ task._id } className="task hover-pointer" onClick={ () => { setTaskForViewTaskDrawer({ ...task }) } }>
                                                        <div className="title">{ task.title ? task.title : 'N/A' }</div>

                                                        <div className="info-C">
                                                            <div className="breadcrumbs">
                                                                { 
                                                                    productsDataset && productsDataset.data && productsDataset.data[task.productId+''] ? (
                                                                        <Link to={ '/products/' + productsDataset.data[task.productId+'']._id }>{ productsDataset.data[task.productId+''].name }</Link>
                                                                    ) : (
                                                                        'Product'
                                                                    )
                                                                }

                                                                <span className="ml-2 mr-2">&gt;</span>

                                                                {
                                                                    productsDataset && productsDataset.data && productsDataset.data[task.productId+''] && projectsDataset && projectsDataset.data && projectsDataset.data[task.projectId+''] ? (
                                                                        <Link to={ '/products/' + productsDataset.data[task.productId+'']._id + '/projects/' + projectsDataset.data[task.projectId+'']._id }>{ projectsDataset.data[task.projectId+''].name }</Link>
                                                                    ) : (
                                                                        'Project'
                                                                    )
                                                                }
                                                            </div>

                                                            <div className="R1">
                                                                <div className={ 'priority d-inline-block float-right' +  (task.priority && priorityLevelOptions && priorityLevelOptions[task.priority] ? ' ' + priorityLevelOptions[task.priority].toLowerCase() : '')}>
                                                                    {
                                                                        (task.priority) ? (
                                                                            (priorityLevelOptions && priorityLevelOptions[task.priority]) ? (
                                                                                priorityLevelOptions[task.priority]
                                                                            ) : (
                                                                                task.priority
                                                                            )
                                                                        ) : (
                                                                            'None'
                                                                        )
                                                                    }
                                                                </div>

                                                                <div className="vertical-y">
                                                                    <div className={ 'assignee' + (task.assignedTo ? '' : ' empty') }>
                                                                        {
                                                                            (task.assignedTo) ? (
                                                                                <img className="mr-1 mb-0" src={ avatarPlaceholder } alt="Avatar" />
                                                                            ) : (
                                                                                <CustomIcon iconKey="profile" color="#C0C0C3" style={{ margin: '0 4px -2px 0' }} /> 
                                                                            )
                                                                        }

                                                                        { task.assignedTo && membersDataset && membersDataset.data && membersDataset.data[task.assignedTo] ? membersDataset.data[task.assignedTo].firstName + ' ' + membersDataset.data[task.assignedTo].lastName : 'No assignee' }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="R2 clearfix mt-3">
                                                                {
                                                                    task.dueDate && (
                                                                        <div className="float-right" style={{ fontSize: '12px', color: '#E45858' }}>
                                                                            <CustomIcon iconKey="date-time" color='#E45858' style={{ margin: '0 2px -2px 0' }} /> { 'Due by ' + moment(task.dueDate).fromNow(true) }
                                                                        </div>
                                                                    )   
                                                                }

                                                                {
                                                                    task.activities && task.activities.length > 0 &&  (
                                                                        <span className="ml-1 mr-1">
                                                                            <CustomIcon iconKey="comments" color="#6246EA" /> { task.activities.length }
                                                                        </span>
                                                                    ) 
                                                                }

                                                                {
                                                                    task.dependencies && task.dependencies.length > 0 && (
                                                                        <span className="ml-1 mr-1">
                                                                            <CustomIcon iconKey="dependencies" color="#6246EA" /> { task.dependencies.length }
                                                                        </span>
                                                                    ) 
                                                                }

                                                                {
                                                                    task.checklist && task.checklist.length > 0 && (
                                                                        <span className="ml-1 mr-1">
                                                                            <CustomIcon iconKey="checklist" color="#6246EA" style={{ height: '13px', margin: '0 0 -1px 0' }} /> { task.checklist.length }
                                                                        </span>
                                                                    ) 
                                                                }

                                                                {
                                                                    task.attachments && task.attachments.length > 0 && (
                                                                        <span className="ml-1 mr-1">
                                                                            <CustomIcon iconKey="attachment-horizontal" color="#6246EA" /> { task.attachments.length }
                                                                        </span>
                                                                    ) 
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>  
                                                );
                                            })
                                        ) : (
                                            <div className="text-center p-3" style={{ background: '#F8F8FB', border: '1px solid #DFE0E1', borderRadius: '3px', color: '#8C8C8C' }}>
                                                <div className="title">You have no tasks due today.</div>
                                            </div>                            
                                        )
                                    }
                                </div>
                            </Collapse.Panel>

                            { /*
                            <Collapse.Panel key="latest-notifications" header={ <><CustomIcon iconKey="notifications" color="#8C8C8C" style={{ width: '16px', height: '18px', margin: '0 2px -3px 0' }} /> Latest notifications</> }>
                                <div className="content empty text-center">
                                    You have no notifications.
                                </div>
                            </Collapse.Panel>

                            <Collapse.Panel key="to-do-list" header={ <><CustomIcon iconKey="my-tasks" color="#8C8C8C" style={{ width: '16px', height: '18px', margin: '0 2px -3px 0' }} /> To-do list</> }>
                                <div className="content empty text-center">
                                    You have no entry in your to-do list.
                                </div>
                            </Collapse.Panel>
                            */ }
                        </Collapse>
                    </div>

                    { 
                        (taskForViewTaskDrawer && taskForViewTaskDrawer._id && taskForViewTaskDrawer.productId && taskForViewTaskDrawer.projectId) && (<ViewTaskDrawer
                            key={ 'viewTaskDrawer--taskId-' + taskForViewTaskDrawer._id }
                            onClose={ () => { setTaskForViewTaskDrawer(null); } }
                            productId={ taskForViewTaskDrawer.productId }
                            projectId={ taskForViewTaskDrawer.projectId }
                            taskId={ taskForViewTaskDrawer._id }
                            refreshTasks={ () => {} }
                            stageValue={ null }
                        />)
                    }
                </MainLayout>
            </>
        ) : (
            <div className="aligner-xy" style={{ height: '100vh' }}><Spin /></div>
        )
    )
}

export default Home;
