import React, { useEffect, useState } from 'react';

import { useHistory } from 'react-router-dom';

import Header from './Header/Header';
import Sidebar from './Sidebar/Sidebar';

import { Layout } from 'antd';

import './Main.css';

const Main = (props) => {
    const history = useHistory();
    
    const [sidebarCollapsed, setSidebarCollapsed] = useState(false);

    const toggleSidebar = () => {
        setSidebarCollapsed(!sidebarCollapsed);
    }

    useEffect(() => {
        setSidebarCollapsed(true);
    }, []);

    return (
        <Layout style={{ background: '#FFFFFF', minHeight:'100vh', height: '100%' }}>
            <Header toggleSidebar={ toggleSidebar } />

            <Layout style={{ background: '#FCFBFF', marginTop: '48px' }}>
                <Sidebar collapsed={ sidebarCollapsed } />

                <Layout.Content className="content--main" style={{ background: '#FCFBFF', minHeight: 280 }}>
                    { props.children }
                </Layout.Content>
            </Layout>
        </Layout>
    );
}

export default Main;
