import React, { useEffect, useCallback } from 'react';
import { useImmer } from "use-immer";
import { useSystemContext } from '../../Context/SystemContext';

import debounce from 'lodash.debounce';

import ComandanteAPI from '../../utils/ComandanteAPI';

import { Input, Button, Table, Modal, message, Form } from 'antd';
import avatarPlaceholder from '../../assets/images/placeholder-avatar.png';
import removeIcon from '../../assets/images/icon-remove.png';
import './Team.css';

const Members = ({ teamId, setVisible, refreshTeams, ...restProps }) => {
    const {
        systemVariables
    } = useSystemContext();

    const roleOptions = (systemVariables && systemVariables.user && systemVariables.user.roleOptions ? systemVariables.user.roleOptions : null);

    const [teamMembersDataset, updateTeamMembersDataset] = useImmer(null);
    const [membersDataset, updateMembersDataset] = useImmer(null);
    const [membersToAdd, updateMembersToAdd] = useImmer(null);

    const fetchTeamMembers = useCallback((sort = null) => {
        ComandanteAPI.HttpGetRequest('teams/' + teamId + '/members' + (sort ? '?sort='+sort : ''), (err, res) => {
            updateTeamMembersDataset(teamMembersDataset => (res && res.data) || null);
        });
    }, [teamId, updateTeamMembersDataset]);

    const fetchMembers = useCallback((searchQuery) => {
        ComandanteAPI.HttpGetRequest('members?paginated=false' + (searchQuery ? '&search=' + searchQuery : ''), (err, res) => {
            updateMembersDataset(membersDataset => (res && res.data) || null);
        });
    }, [updateMembersDataset])

    const debounced_fetchMembers = debounce(value => {
        fetchMembers(value);
    }, 300);

    useEffect(() => {
        fetchTeamMembers();
        fetchMembers();
    }, [fetchTeamMembers, fetchMembers]);

    useEffect(() => {
        updateMembersToAdd(membersToAdd => {
            let nMembersToAdd = {};

            if(teamMembersDataset && teamMembersDataset.data && Object.values(teamMembersDataset.data).length > 0) {
                for(let member of Object.values(teamMembersDataset.data)) {
                    nMembersToAdd[member._id+''] = member;
                }
            }

            return nMembersToAdd;
        });
    }, [teamMembersDataset, updateMembersToAdd]);

    const handleManageTeamMembersModalOk = () => {
        ComandanteAPI.HttpPostRequest('teams/' + teamId + '/members/manage', { memberIds: (membersToAdd ? Object.keys(membersToAdd) : []) }, (err, res) => {
            if(err || !res) {
                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.status === 200) {
                setVisible(false);
                fetchTeamMembers();
                refreshTeams();
                return;
            }
        });
    }

    const handleManageTeamMembersModalUndo = () => {
        updateMembersToAdd(membersToAdd => {
            let nMembersToAdd = {};

            if(teamMembersDataset && teamMembersDataset.data && Object.values(teamMembersDataset.data).length > 0) {
                for(let member of Object.values(teamMembersDataset.data)) {
                    nMembersToAdd[member._id+''] = member;
                }    
            }
            return nMembersToAdd;
        });
    }

    const handleManageTeamMembersModalCancel = () => { setVisible(false) };

    const membersQueryColumns = [
        {
            width: 32,
            responsive: ['lg', 'xl'],
            render: (text, record) => <img src={ avatarPlaceholder } alt="Avatar" height={ 20 } />
        },
        {
            title: 'User',
            render: (text, record) => <span>{ record.firstName + ' ' + record.lastName }</span>,
        },
        {
            title: 'Role',
            width: 180,
            responsive: ['lg', 'xl'],
            render: (text, record) => <span>{ (roleOptions && roleOptions[record.role+''] ? roleOptions[record.role+''] : 'N/A') }</span>,
        },
        {
            title: 'Email',
            width: 200,
            responsive: ['xl'],
            render: (text, record) => <span>{ record.email }</span>,
        },
        {
            width: 60,
            render: (text, record) => {
                return (
                    (!membersToAdd || !membersToAdd[record._id]) && (
                        <Button 
                            className="btn-white mt-1" 
                            style={{ height: '25px', lineHeight: '25px' }}
                            onClick={ 
                                () => {
                                    updateMembersToAdd(membersToAdd => {
                                        if(!membersToAdd) return { [record._id+'']: record };
                                        membersToAdd[record._id+''] = record;
                                    });
                                } 
                            }
                        >
                            Add
                        </Button>
                    )
                );
            }
        }
    ];

    const membersToAddColumns = [
        {
            width: 32,
            responsive: ['lg', 'xl'],
            render: (text, record) => <img src={ avatarPlaceholder } alt="Avatar" height={ 20 } />
        },
        {
            title: 'User',
            render: (text, record) => <span>{ record.firstName + ' ' + record.lastName }</span>,
        },
        {
            title: 'Role',
            width: 180,
            responsive: ['lg', 'xl'],
            render: (text, record) => <span>{ (roleOptions && roleOptions[record.role+''] ? roleOptions[record.role+''] : 'N/A') }</span>,
        },
        {
            title: 'Email',
            width: 200,
            responsive: ['xl'],
            render: (text, record) => <span>{ record.email }</span>,
        },
        {
            width: 60,
            render: (text, record) => {
                return (
                    <Button 
                        className="btn-nothing mt-1" 
                        style={{ height: '25px', lineHeight: '25px' }}
                        onClick={ 
                            () => {
                                updateMembersToAdd(membersToAdd => {
                                    if(membersToAdd && membersToAdd[record._id+'']) delete membersToAdd[record._id+''];
                                });
                            }
                        }
                    >
                        <img className="icon" src={ removeIcon } alt="Icon" style={{ height: '16px' }} />
                    </Button>
                );
            }
        }
    ];

    return (
        <Modal title={ null } footer={ null } visible={ true } onCancel={ handleManageTeamMembersModalCancel } width={ 991 }>
            <div className="modal-header-W" style={{ padding: '30px 30px' }}>
                <div className="text-left">Manage Team Members</div>
            </div>

            <div className="modal-body-W">
                <div className="R1" style={{ padding: '15px 30px' }}>
                    <div className="float-right d-xs-none d-sm-none" style={{ marginTop: '42px' }}>
                        <Button className="btn-white" onClick={ () => { handleManageTeamMembersModalUndo() } }>Undo All</Button>
                        <Button className="btn-purple ml-4" onClick={ () => { handleManageTeamMembersModalOk() } }>Save Changes</Button>
                    </div>

                    <Form className="d-xs-none" layout="vertical">
                        <Form.Item label="Search by individual">
                            <Input 
                                style={{ maxWidth: '280px' }}
                                onChange={ (event) => {
                                    event.persist(); // Signal to React not to nullify the event object

                                    debounced_fetchMembers(event.target.value);
                                }}
                            />
                        </Form.Item>
                    </Form>

                    <div className="table-section-header d-xs-none mt-3">Individiuals Found</div>

                    <Table 
                        rowKey="_id"
                        rowClassName={ (record, index) => ((membersToAdd && membersToAdd[record._id+'']) ? 'd-none' : '') }
                        className="members-to-add-T mt-2"
                        columns={ membersQueryColumns }
                        dataSource={ membersDataset && membersDataset.data ? Object.values(membersDataset.data) : [] }
                        pagination={ false }
                    />
                </div>

                <div className="R2" style={{ background: '#FFFFFF', padding: '15px 30px' }}>
                    <div className="table-section-header mt-3">Individiuals Added</div>
                    <Table
                        rowKey="_id"
                        className="members-added-T mt-2"
                        columns={ membersToAddColumns }
                        dataSource={ membersToAdd ? Object.values(membersToAdd) : [] }
                        pagination={ false }
                    />

                    <div className="text-right d-md-none d-lg-none d-xl-none mt-3">
                        <Button className="btn-purple ml-4" onClick={ () => { handleManageTeamMembersModalOk() } }>Save Changes</Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default Members;