import React, { useState, useEffect, useCallback } from 'react';
import { useImmer } from "use-immer";
import { useSystemContext } from '../../Context/SystemContext';

import Helmet from 'react-helmet';

import moment from 'moment';

import ComandanteAPI from '../../utils/ComandanteAPI';
import ComandanteEditor from '../../utils/ComandanteEditor/ComandanteEditor';

import MainLayout from '../../Layouts/Main';

import CustomIcon from '../../UIElements/CustomIcon/CustomIcon';

import { Skeleton, Avatar, Tabs, Button, Pagination } from 'antd';
import { Link, useHistory } from 'react-router-dom';

import './Notifications.css';

import avatarPlaceholder from '../../assets/images/placeholder-avatar.png';

import ViewTaskDrawer from '../Task/Drawers/ViewTaskDrawer';

const Notifications = (props) => {
    const history = useHistory();

    const { 
        systemVariables,
        fetchNotificationsCount
    } = useSystemContext();

    const notificationTypeOptions = (systemVariables && systemVariables.notification && systemVariables.notification.typeOptions ? systemVariables.notification.typeOptions : null);

    const [activeTab, setActiveTab] = useState('/active');

    const [usersDataset, updateUsersDataset] = useImmer(null);

    const [notificationsDataset, updateNotificationsDataset] = useImmer(null);
    const [archivedNotificationsDataset, updateArchivedNotificationsDataset] = useImmer(null);

    const [notificationForViewTaskDrawer, setNotificationForViewTaskDrawer] = useState(null);

    const getUsers = useCallback(async () => {
        ComandanteAPI.HttpGetRequest('members?paginated=false', (err, res) => {
            updateUsersDataset(usersDataset => ((res && res.data) || []));
        });
    }, [updateUsersDataset]);

    useEffect(() => {
        getUsers();
	}, [getUsers]);

    const getNotifications = useCallback(async (page = 1) => {
        ComandanteAPI.HttpGetRequest('notifications?paginated=true&page=' + page, (err, res) => {
            updateNotificationsDataset(notificationsDataset => (res && res.data) || []);
        });
    }, [updateNotificationsDataset]);

    const getArchivedNotifications = useCallback(async (page = 1) => {
        ComandanteAPI.HttpGetRequest('notifications/archived?paginated=true&page=' + page, (err, res) => {
            updateArchivedNotificationsDataset(archivedNotificationsDataset => (res && res.data) || []);
        });
    }, [updateArchivedNotificationsDataset]);

    useEffect(() => {
        getNotifications();
        getArchivedNotifications();
    }, [getNotifications, getArchivedNotifications]);

    const onNotificationClick = (notificationId) => {
        if(!notificationId) return;

        let notification = (notificationsDataset && notificationsDataset.data && notificationsDataset.data[notificationId+'']);
        if(!notification) notification = (archivedNotificationsDataset && archivedNotificationsDataset.data && archivedNotificationsDataset.data[notificationId+'']);
        if(!notification) return;

        if(notification.taskId) {
            setNotificationForViewTaskDrawer(notification);
            return;
        }

        if(notification.projectId) {
            history.push('/products/' + notification.productId + '/projects/' + notification.projectId);
            return;
        }

        if(notification.productId) {
            history.push('/products/' + notification.productId);
            return;
        }
    }

    const archiveNotification = (notificationId) => {
        ComandanteAPI.HttpPostRequest('notifications/' + notificationId + '/archive', {}, (err, res) => {
            getNotifications();
            getArchivedNotifications();
            fetchNotificationsCount();
        });
    }

    const unarchiveNotification = (notificationId) => {
        ComandanteAPI.HttpPostRequest('notifications/' + notificationId + '/unarchive', {}, (err, res) => {
            getNotifications();
            getArchivedNotifications();
            fetchNotificationsCount();
        });
    }

    const archiveAllNotifications = () => {
        ComandanteAPI.HttpPostRequest('notifications/archive-all', {}, (err, res) => {
            getNotifications();
            getArchivedNotifications();
            fetchNotificationsCount();
        });
    }

    return (
        <>
            <Helmet>
                <title>Notifications | { process.env.REACT_APP_META_TITLE }</title>
            </Helmet>

            <MainLayout>
                <div className="notifications--section-header">
                    <h1>Notifications</h1>
                </div>

                <div className="notifications--section-body">
                    <Tabs defaultActiveKey={ activeTab } onChange={ (activeKey) => { setActiveTab(activeKey) } }>
                        <Tabs.TabPane tab="Active" key="/active">
                            {
                                (notificationsDataset && notificationsDataset.data && Object.keys(notificationsDataset.data).length > 0) && (
                                    <div className="text-right" style={{ maxWidth: '612px' }}>
                                        <Button className="btn-nothing" style={{ color: '#6246EA' }} onClick={ () => { archiveAllNotifications() } }>
                                            <CustomIcon iconKey="archive" color="#6246EA" style={{ margin: '0 4px -3px 0' }} />Archive All
                                        </Button>
                                    </div>
                                )
                            }

                            
                            {
                                (notificationsDataset) ? (
                                    (notificationsDataset.data && Object.keys(notificationsDataset.data).length > 0) ? (
                                        <>
                                            {
                                                (Object.values(notificationsDataset.data).map(notification => (
                                                    <div key={ notification._id } className="notification hover-pointer mt-2 pt-2">
                                                        <div className="header pl-4 pr-4">
                                                            <div className="float-right">
                                                                <span className="date-time">{ moment(notification.createdAt).fromNow() }</span>
                                                                <Button className="btn-archive btn-nothing d-inline pt-0" onClick={ () => { archiveNotification(notification._id+'') } }>
                                                                    <CustomIcon iconKey="archive" color="#6246EA" style={{ margin: '0 4px -3px 0' }} />
                                                                    <span style={{ color: '#6246EA' }}>Archive</span>
                                                                </Button>
                                                            </div>

                                                            <div className="label">
                                                                <CustomIcon iconKey="comments" color="#C0C0C3" style={{ width: '20px', margin: '0 4px -2px 0' }} />
                                                                <span style={{ color: '#C0C0C3' }}>ACTIVITY</span>
                                                            </div>
                                                        </div>

                                                        <div className="content mt-1 pl-4 pr-4" onClick={ () => { onNotificationClick(notification._id); } }>
                                                            <div>
                                                                { (notification.notifierUserId && usersDataset && usersDataset.data && usersDataset.data[notification.notifierUserId+'']) && <Avatar className="pp mr-2" shape="square" size={ 20 } src={ (usersDataset.data[notification.notifierUserId+''].profilePhoto ? process.env.REACT_APP_COMANDANTE_API_ADDRESS + usersDataset.data[notification.notifierUserId+''].profilePhoto.path.substring(7) : avatarPlaceholder) } alt={ 'Avatar | ' + usersDataset.data[notification.notifierUserId+''].firstName + ' ' + usersDataset.data[notification.notifierUserId+''].lastName } height={ 20 } /> }
                                                                <span className="notifier">{ (notification.notifierUserId && usersDataset && usersDataset.data && usersDataset.data[notification.notifierUserId+''] ? usersDataset.data[notification.notifierUserId+''].username : 'A user') }</span> { notification.message }
                                                            </div>

                                                            {
                                                                (notification.reference) && (
                                                                    (notificationTypeOptions && notificationTypeOptions[notification.type] && ['Comment', 'Mention'].includes(notificationTypeOptions[notification.type])) ? (
                                                                        <ComandanteEditor
                                                                            key={ 'comandanteEditor--notification-' + notification._id }
                                                                            editorKey={ 'comandanteEditor--notification-' + notification._id }
                                                                            initialValue={ notification.reference ? notification.reference : '' }
                                                                            className="comandante-editor"
                                                                            readOnly={ true }
                                                                        />
                                                                    ) : (
                                                                        (notification.reference ? notification.reference : '')
                                                                    )
                                                                )
                                                            }
                                                        </div>

                                                        <div className="footer mt-4 pt-3 pb-3 pl-4 pr-4">
                                                            <div className="breadcrumbs">
                                                                {
                                                                    (notification.productId && notification.productName) && (
                                                                        <>
                                                                            <Link to={ '/products/' + notification.productId }>{ notification.productName }</Link>

                                                                            {
                                                                                (notification.projectId && notification.projectName) && (
                                                                                    <>
                                                                                        <span className="ml-2 mr-2">&gt;</span>

                                                                                        <Link to={ '/products/' + notification.productId + '/projects/' + notification.projectId }>{ notification.projectName }</Link>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </>
                                                                    )
                                                                }
                                                            </div>

                                                            { /* <div className="mt-2">Prepare a new backend with the incoming features from R&amp;D</div> */ }
                                                        </div>
                                                    </div>
                                                )))
                                            }

                                            { 
                                                notificationsDataset && notificationsDataset.pagination && (
                                                    <div className="text-right mt-3 pb-5" style={{ maxWidth:'612px' }}>
                                                        <Pagination
                                                            current={ notificationsDataset.pagination.current }
                                                            pageSize={ notificationsDataset.pagination.pageSize }
                                                            total={ notificationsDataset.pagination.total }
                                                            onChange={
                                                                (page, pageSize) => {
                                                                    getNotifications(page);
                                                                }
                                                            }
                                                        />
                                                    </div>
                                                ) 
                                            }
                                        </>
                                    ) : (
                                        <div style={{ maxWidth: '612px' }}>
                                            <div className="text-center p-4" style={{ background: '#F8F8FB', border: '1px solid #DFE0E1', borderRadius: '3px', color: '#8C8C8C' }}>
                                                <div className="title">You don't have any notifications.</div>
                                            </div>                            
                                        </div> 
                                    )
                                ) : (
                                    <Skeleton active />
                                )
                            }
                        </Tabs.TabPane>
                    
                        <Tabs.TabPane tab="Archived" key="/archived">
                            {
                                (archivedNotificationsDataset) ? (
                                    (archivedNotificationsDataset.data && Object.keys(archivedNotificationsDataset.data).length > 0) ? (
                                        <>
                                            {
                                                (Object.values(archivedNotificationsDataset.data).map(notification => (
                                                    <div key={ notification._id } className="notification hover-pointer mt-2 pt-2">
                                                        <div className="header pl-4 pr-4">
                                                            <div className="float-right">
                                                                <span className="date-time">{ moment(notification.createdAt).fromNow() }</span>
                                                                <Button className="btn-archive btn-nothing d-inline pt-0" onClick={ () => { unarchiveNotification(notification._id+'') } }>
                                                                    <CustomIcon iconKey="archive" color="#6246EA" style={{ margin: '0 4px -3px 0' }} />
                                                                    <span style={{ color: '#6246EA' }}>Unarchive</span>
                                                                </Button>
                                                            </div>

                                                            <div className="label">
                                                                <CustomIcon iconKey="comments" color="#C0C0C3" style={{ width: '20px', margin: '0 4px -2px 0' }} />
                                                                <span style={{ color: '#C0C0C3' }}>ACTIVITY</span>
                                                            </div>
                                                        </div>

                                                        <div className="content mt-1 pl-4 pr-4" onClick={ () => { onNotificationClick(notification._id); } }>
                                                            <div>
                                                            { (notification.notifierUserId && usersDataset && usersDataset.data && usersDataset.data[notification.notifierUserId+'']) && <Avatar className="pp mr-2" shape="square" size={ 20 } src={ (usersDataset.data[notification.notifierUserId+''].profilePhoto ? process.env.REACT_APP_COMANDANTE_API_ADDRESS + usersDataset.data[notification.notifierUserId+''].profilePhoto.path.substring(7) : avatarPlaceholder) } alt={ 'Avatar | ' + usersDataset.data[notification.notifierUserId+''].firstName + ' ' + usersDataset.data[notification.notifierUserId+''].lastName } height={ 20 } /> }
                                                                <span className="notifier">{ (notification.notifierUserId && usersDataset && usersDataset.data && usersDataset.data[notification.notifierUserId+''] ? usersDataset.data[notification.notifierUserId+''].username : 'A user') }</span> { notification.message }
                                                            </div>

                                                            {
                                                                (notification.reference) && (
                                                                    (notificationTypeOptions && notificationTypeOptions[notification.type] && ['Comment', 'Mention'].includes(notificationTypeOptions[notification.type])) ? (
                                                                        <ComandanteEditor
                                                                            key={ 'comandanteEditor--notification-' + notification._id }
                                                                            editorKey={ 'comandanteEditor--notification-' + notification._id }
                                                                            initialValue={ notification.reference ? notification.reference : '' }
                                                                            className="comandante-editor"
                                                                            readOnly={ true }
                                                                        />
                                                                    ) : (
                                                                        (notification.reference ? notification.reference : '')
                                                                    )
                                                                )
                                                            }
                                                        </div>

                                                        <div className="footer mt-4 pt-3 pb-3 pl-4 pr-4">
                                                            <div className="breadcrumbs">
                                                                {
                                                                    (notification.productId && notification.productName) && (
                                                                        <>
                                                                            <Link to={ '/products/' + notification.productId }>{ notification.productName }</Link>

                                                                            {
                                                                                (notification.projectId && notification.projectName) && (
                                                                                    <>
                                                                                        <span className="ml-2 mr-2">&gt;</span>

                                                                                        <Link to={ '/products/' + notification.productId + '/projects/' + notification.projectId }>{ notification.projectName }</Link>
                                                                                    </>
                                                                                )
                                                                            }
                                                                        </>
                                                                    )
                                                                }
                                                            </div>

                                                            { /* <div className="mt-2">Prepare a new backend with the incoming features from R&amp;D</div> */ }
                                                        </div>
                                                    </div>
                                                )))
                                            }

                                            { 
                                                archivedNotificationsDataset && archivedNotificationsDataset.pagination && (
                                                    <div className="text-right mt-3 pb-5" style={{ maxWidth:'612px' }}>
                                                        <Pagination
                                                            current={ archivedNotificationsDataset.pagination.current }
                                                            pageSize={ archivedNotificationsDataset.pagination.pageSize }
                                                            total={ archivedNotificationsDataset.pagination.total }
                                                            onChange={
                                                                (page, pageSize) => {
                                                                    getArchivedNotifications(page);
                                                                }
                                                            }
                                                        />
                                                    </div>
                                                ) 
                                            }
                                        </>
                                    ) : (
                                        <div style={{ maxWidth: '612px' }}>
                                            <div className="text-center p-4" style={{ background: '#F8F8FB', border: '1px solid #DFE0E1', borderRadius: '3px', color: '#8C8C8C' }}>
                                                <div className="title">You don't have any archived notifications.</div>
                                            </div>                            
                                        </div> 
                                    )
                                ) : (
                                    <Skeleton active />
                                )
                            }                           
                        </Tabs.TabPane>
                    </Tabs>
                </div>

                { 
                    (notificationForViewTaskDrawer && notificationForViewTaskDrawer.productId && notificationForViewTaskDrawer.projectId && notificationForViewTaskDrawer.taskId) && (<ViewTaskDrawer
                        key={ 'viewTaskDrawer--taskId-' + notificationForViewTaskDrawer.taskId }
                        onClose={ () => { setNotificationForViewTaskDrawer(null); } }
                        productId={ notificationForViewTaskDrawer.productId }
                        projectId={ notificationForViewTaskDrawer.projectId }
                        taskId={ notificationForViewTaskDrawer.taskId }
                        refreshTasks={ () => {} }
                        stageValue={ null }
                    />)
                }
            </MainLayout>
        </>
    )
}

export default Notifications;