import React, { useState, useEffect, useCallback } from 'react';
import { useImmer } from 'use-immer';
import { Link } from 'react-router-dom';
import { useSystemContext } from '../../../Context/SystemContext';

import ComandanteAPI from '../../../utils/ComandanteAPI';

import debounce from 'lodash.debounce';

import ComandanteEditor from '../../../utils/ComandanteEditor/ComandanteEditor';
import { convertToRaw } from 'draft-js';

import { CopyToClipboard } from 'react-copy-to-clipboard';

import CustomIcon from '../../../UIElements/CustomIcon/CustomIcon';

import { Row, Col, Drawer, Collapse, Form, Input, Select, DatePicker, Checkbox, Upload, Progress, Button, Dropdown, Menu, Typography, Spin, Modal, message } from 'antd';
import { InboxOutlined, SearchOutlined, FileOutlined, RollbackOutlined, ClockCircleOutlined, CheckCircleOutlined } from '@ant-design/icons';

import './TaskDrawer.css';
import 'draft-js/dist/Draft.css';

import avatarPlaceholder from '../../../assets/images/placeholder-avatar.png';

import moment from 'moment';

const ViewTaskDrawer = ({ productId, projectId, taskId, onClose, refreshTasks, stageValue }) => {
    const {
        currentUser, fetchCurrentUser,
        systemVariables
    } = useSystemContext();

    const roleOptions = (systemVariables && systemVariables.user && systemVariables.user.roleOptions ? systemVariables.user.roleOptions : null);
    const priorityLevelOptions = (systemVariables && systemVariables.task && systemVariables.task.priorityLevelOptions ? systemVariables.task.priorityLevelOptions : null);
    const taskStatusOptions = (systemVariables && systemVariables.task && systemVariables.task.statusOptions ? systemVariables.task.statusOptions : null);
    const activityTypeOptions = (systemVariables && systemVariables.activity && systemVariables.activity.activityTypeOptions ? systemVariables.activity.activityTypeOptions : null);

    const imageMimeTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/tiff'];

    const [productDataset, updateProductDataset] = useImmer(null);
    const [projectDataset, updateProjectDataset] = useImmer(null);
    const [projectMembersDataset, updateProjectMembersDataset] = useImmer(null);
    const [tasksDataset, updateTasksDataset] = useImmer(null);
    const [taskDataset, updateTaskDataset] = useImmer(null);
    const [logsDataset, updateLogsDataset] = useImmer(null);
    const [activitiesDataset, updateActivitiesDataset] = useImmer(null);
    const [repliesDataset, updateRepliesDataset] = useImmer(null);

    const [descriptionEditorState, setDescriptionEditorState] = useState(null);

    const [activityEditorState, setActivityEditorState] = useState(null);
    const [clearActivityEditor, setClearActivityEditor] = useState(0);

    const [replyEditorState, setReplyEditorState] = useState(null);
    const [clearReplyEditor, setClearReplyEditor] = useState(0);

    // const [activityEditorRefs, updateActivityEditorRefs] = useImmer(null);
    
    const [taskDetailsVisible, setTaskDetailsVisible] = useState(false);
    const [repliesActiveKey, setRepliesActiveKey] = useState(null);

    const [editTaskForm] = Form.useForm();
    const [editTaskErrors, setEditTaskErrors] = useState(null);

    const [checklistIdsByFieldIndex, updateChecklistIdsByFieldIndex] = useImmer(null);
    const [checkboxValuesByFieldIndex, updateCheckboxValuesByFieldIndex] = useImmer(null);
    const [assigneesByFieldIndex, updateAssigneesByFieldIndex] = useImmer(null);
    const [dueDatesByFieldIndex, updateDueDatesByFieldIndex] = useImmer(null);

    const [dependenciesSearchBarVisible, setDependenciesSearchBarVisible] = useState(false);
    const [dependenciesSearchResultsDataset, updateDependenciesSearchResultsDataset] = useImmer(null);

    const [uploadModalVisible, setUploadModalVisible] = useState(false);
    const [archiveTaskModalVisible, setArchiveTaskModalVisible] = useState(false);
    const [unarchiveTaskModalVisible, setUnarchiveTaskModalVisible] = useState(false);
    const [stageForUnarchiveModel, setStageForUnarchiveModel] = useState(null);
    const [deleteTaskModalVisible, setDeleteTaskModalVisible] = useState(false);

    const [deleteAttachmentModalVisible, setDeleteAttachmentModalVisible] = useState(false);
    const [attachmentIdToBeDeleted, setAttachmentIdToBeDeleted] = useState(null);

    const [commentToBeDeleted, setCommentToBeDeleted] = useState(null);
    const [deleteCommentModalVisible, setDeleteCommentModalVisible] = useState(false);

    const [replyToBeDeleted, setReplyToBeDeleted] = useState(null);
    const [commentIdOfReplyToBeDeleted, setCommentIdOfReplyToBeDeleted] = useState(null);
    const [deleteReplyModalVisible, setDeleteReplyModalVisible] = useState(false);

    const [imageToView, setImageToView] = useState(false);

    let checklistEntryRefs = {};
    const setChecklistEntryRefs = (index, ref) => {
        checklistEntryRefs[''+index] = ref;
    }

    const fetchProduct = useCallback(() => {
        if(productId) {
            ComandanteAPI.HttpGetRequest('products/' + productId + '/get', (err, res) => {
                updateProductDataset(productDataset => (res && res.data) || null);
            });
        }
    }, [productId, updateProductDataset]);
    
    const fetchProject = useCallback(() => {
        if(productId && projectId) {
            ComandanteAPI.HttpGetRequest('products/' + productId + '/projects/' + projectId + '/get', (err, res) => {
                updateProjectDataset(projectDataset => (res && res.data) || null);
            });
        }
    }, [productId, projectId, updateProjectDataset]);

    const fetchProjectMembers = useCallback(() => {
        if(productId && projectId) {
            ComandanteAPI.HttpGetRequest('products/' + productId + '/projects/' + projectId + '/members', (err, res) => {
                updateProjectMembersDataset(projectMembersDataset => (res && res.data) || []);
            });
        }
    }, [productId, projectId, updateProjectMembersDataset]);

    const fetchTask = useCallback(() => {
        if(productId && projectId && taskId) {
            ComandanteAPI.HttpGetRequest('products/' + productId + '/projects/' + projectId + '/tasks/' + taskId + '/get', (err, res) => {
                updateTaskDataset(taskDataset => (res && res.data) || null);
            });
        }
    }, [productId, projectId, taskId, updateTaskDataset]);

    const fetchDependencies = useCallback(async (searchQuery) => {
        if(productId && projectId && taskId) {
            ComandanteAPI.HttpGetRequest('products/' + productId + '/projects/' + projectId + '/tasks/' + taskId + '/dependencies', (err, res) => {
                updateTasksDataset(tasksDataset => (res && res.data) || null);
            });
        }
    }, [productId, projectId, taskId, updateTasksDataset]);

    const fetchDependenciesSearchResults = useCallback(async (searchQuery) => {
        if(productId && projectId && taskId) {
            ComandanteAPI.HttpGetRequest('products/' + productId + '/projects/' + projectId + '/tasks/' + taskId + '/dependencies-search-results?limit=4&search=' + (searchQuery ? searchQuery : ''), (err, res) => {
                updateDependenciesSearchResultsDataset(dependenciesSearchResultsDataset => (res && res.data) || null);
            });
        }
    }, [productId, projectId, taskId, updateDependenciesSearchResultsDataset]);

    const debounced_fetchDependenciesSearchResults = debounce(value => {
        fetchDependenciesSearchResults(value);
    }, 300);

    const fetchLogs = useCallback((page = 1) => {
        if(productId && projectId && taskId) {
            ComandanteAPI.HttpGetRequest('products/' + productId + '/projects/' + projectId + '/tasks/' + taskId + '/logs?page=' + page, (err, res) => {
                updateLogsDataset(logsDataset => {
                    if(!logsDataset) return (res && res.data) || null;

                    if(logsDataset && logsDataset.data) {
                        if(res && res.data) {
                            return {
                                ...res.data,
                                data: { ...logsDataset.data, ...res.data.data }
                            }
                        }
                    }
                });
            });
        }
    }, [productId, projectId, taskId, updateLogsDataset]);

    const fetchActivities = useCallback((page = 1) => {
        if(productId && projectId && taskId) {
            ComandanteAPI.HttpGetRequest('products/' + productId + '/projects/' + projectId + '/tasks/' + taskId + '/activities?hideLogs=true&page=' + page, (err, res) => {
                updateActivitiesDataset(activitiesDataset => {
                    if(!activitiesDataset) return (res && res.data) || null;

                    if(activitiesDataset && activitiesDataset.data) {
                        if(res && res.data) {
                            return {
                                ...res.data,
                                data: { ...activitiesDataset.data, ...res.data.data }
                            }
                        }
                    }
                });
            });
        }
    }, [productId, projectId, taskId, updateActivitiesDataset]);

    const refreshActivities = useCallback((page = 1) => {
        ComandanteAPI.HttpGetRequest('products/' + productId + '/projects/' + projectId + '/tasks/' + taskId + '/activities?hideLogs=true&page=' + page, (err, res) => {
            updateActivitiesDataset(activitiesDataset => (res && res.data) || null);
        });
    }, [productId, projectId, taskId, updateActivitiesDataset]);

    const fetchReplies = useCallback((activityId) => {
        if(productId && projectId && taskId && activityId) {
            ComandanteAPI.HttpGetRequest('products/' + productId + '/projects/' + projectId + '/tasks/' + taskId + '/activities/' + activityId + '/replies', (err, res) => {
                updateRepliesDataset(repliesDataset => (res && res.data) || null);
            });
        }
    }, [productId, projectId, taskId, updateRepliesDataset]);

    useEffect(() => {
        fetchProduct();
        fetchProject();
        fetchProjectMembers();
        fetchDependencies();
        fetchTask();
        fetchLogs();
        fetchActivities();
    }, [fetchProduct, fetchProject, fetchProjectMembers, fetchTask, fetchDependencies, fetchLogs, fetchActivities]);

    useEffect(() => {
        let nChecklistItems = [];
        let nChecklistIdsByFieldIndex = {};
        let nCheckboxValuesByFieldIndex = {};
        let nAssigneesByFieldIndex = {};
        let nDueDatesByFieldIndex = {};

        if(taskDataset && taskDataset.task && taskDataset.task.checklist) {
            if(Array.isArray(taskDataset.task.checklist) && taskDataset.task.checklist.length > 0) {
                for(let [index, checklistItem] of taskDataset.task.checklist.entries()) {
                    nChecklistItems.push(checklistItem.title);
                    nChecklistIdsByFieldIndex[index] = checklistItem._id;
                    nCheckboxValuesByFieldIndex[index] = checklistItem.checked;
                    nAssigneesByFieldIndex[index] = checklistItem.assignedTo;
                    nDueDatesByFieldIndex[index] = checklistItem.dueDate;
                }
            }
        }

        updateChecklistIdsByFieldIndex(checklistIdsByFieldIndex => nChecklistIdsByFieldIndex);
        updateCheckboxValuesByFieldIndex(checkboxValuesByFieldIndex => nCheckboxValuesByFieldIndex);
        updateAssigneesByFieldIndex(assigneesByFieldIndex => nAssigneesByFieldIndex);
        updateDueDatesByFieldIndex(dueDatesByFieldIndex => nDueDatesByFieldIndex);

        // if(editTaskForm && editTaskForm.__INTERNAL__ && editTaskForm.__INTERNAL__.name) {
            editTaskForm.setFieldsValue({
                stage: ((taskDataset && taskDataset.task && taskDataset.task.stage && { value: taskDataset.task.stage }) || { value: false }),
                dueDate: ((taskDataset && taskDataset.task && taskDataset.task.dueDate && moment(taskDataset.task.dueDate)) || null),
                priority: ((taskDataset && taskDataset.task && taskDataset.task.priority && { value: taskDataset.task.priority+'' }) || { value: false }),
                assignedTo: ((taskDataset && taskDataset.task && taskDataset.task.assignedTo && { value: taskDataset.task.assignedTo }) || { value: false }),
                title: ((taskDataset && taskDataset.task && taskDataset.task.title) || null),
                description: ((taskDataset && taskDataset.task && taskDataset.task.description) || null),
                checklist: nChecklistItems,
            });
        // }
    }, [editTaskForm, taskDataset, updateCheckboxValuesByFieldIndex, updateAssigneesByFieldIndex, updateDueDatesByFieldIndex]);

    useEffect(() => {
        if(stageValue) {
            // if(editTaskForm && editTaskForm.__INTERNAL__ && editTaskForm.__INTERNAL__.name) {
                editTaskForm.setFieldsValue({
                    stage: { value: stageValue },
                });
            // }
        }
    }, [stageValue]);

    const _descriptionEditorStateCallback = (descriptionEditorState) => {
        setDescriptionEditorState(descriptionEditorState);
    }

    const onEditTaskFinish = (values) => {
        let createTaskValues = {
            ...values,
            description: JSON.stringify(convertToRaw(descriptionEditorState.getCurrentContent())),
            checklistIdsByFieldIndex,
            checkboxValuesByFieldIndex,
            assigneesByFieldIndex,
            dueDatesByFieldIndex
        }

        ComandanteAPI.HttpPostRequest('products/' + productId + '/projects/' + projectId + '/tasks/' + taskId + '/edit', createTaskValues, (err, res) => {  
            if(err && err.data && err.data.errors) {
                let formErrors = [];
                for(let formError of err.data.errors) {
                    if(formError.param && formError.msg) formErrors[formError.param] = formError.msg;
                }

                setEditTaskErrors(formErrors);
            }

            if(res && res.data && res.data.result === true) {
                fetchTask();
                fetchDependencies();
                refreshTasks();
                fetchLogs();
                fetchActivities();
            }
        });
    }

    const manageDependencies = (dependantTaskIds) => {
        ComandanteAPI.HttpPostRequest('products/' + productId + '/projects/' + projectId + '/tasks/' + taskId + '/manage-dependencies', { dependantTaskIds: (dependantTaskIds && Array.isArray(dependantTaskIds) && dependantTaskIds.length > 0 ? dependantTaskIds : []) }, (err, res) => {  
            if(err && err.data && err.data.errors) {
                message.error('Error updating the dependencies.')
            }

            if(res && res.data && res.data.result === true) {
                fetchTask();
                fetchDependencies();
                refreshTasks();
            }
        });
    }

    const _activityEditorStateCallback = (activityEditorState) => {
        setActivityEditorState(activityEditorState);
    }

    const _replyEditorStateCallback = (replyEditorState) => {
        setReplyEditorState(replyEditorState);
    }

    const onFollowTask = () => {
        ComandanteAPI.HttpPostRequest('products/' + productId + '/projects/' + projectId + '/tasks/' + taskId + '/follow', {}, (err, res) => {  
            if(res && res.data && res.data.result === true) {
                fetchCurrentUser();
            }
        });
    }

    const markAsCompleted = () => {
        ComandanteAPI.HttpPostRequest('products/' + productId + '/projects/' + projectId + '/tasks/' + taskId + '/mark-as-completed', {}, (err, res) => {  
            if(res && res.data && res.data.result === true) {
                fetchTask();
            }
        });
    }

    const handleDeleteTaskModalCancel = () => { setDeleteTaskModalVisible(false) }
    const handleDeleteTaskModalOk = () => {
        ComandanteAPI.HttpPostRequest('products/' + productId + '/projects/' + projectId + '/tasks/' + taskId + '/delete', {}, (err, res) => {
            if(err || !res) {    
                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.data && res.data.result === true) {
                window.location.reload();
            }
        });
    }

    const handleArchiveTaskModalCancel = () => { setArchiveTaskModalVisible(false) }
    const handleArchiveTaskModalOk = () => {
        ComandanteAPI.HttpPostRequest('products/' + productId + '/projects/' + projectId + '/tasks/' + taskId + '/archive', {}, (err, res) => {
            if(err || !res) {    
                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.data && res.data.result === true) {
                window.location.reload();
            }
        });
    }

    const handleUnarchiveTaskModalCancel = () => { setUnarchiveTaskModalVisible(false) }
    const handleUnarchiveTaskModalOk = () => {
        if(!stageForUnarchiveModel) return;

        ComandanteAPI.HttpPostRequest('products/' + productId + '/projects/' + projectId + '/tasks/' + taskId + '/unarchive', { stage: stageForUnarchiveModel }, (err, res) => {
            if(err || !res) {    
                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.data && res.data.result === true) {
                window.location.reload();
            }
        });
    }

    const handleDeleteAttachmentModalCancel = () => { setDeleteAttachmentModalVisible(false) }
    const handleDeleteAttachmentModalOk = () => {
        onDeleteAttachment(attachmentIdToBeDeleted);
    }

    const onMakeComment = () => {        
        if(activityEditorState.getCurrentContent().getPlainText().trim()) {
            ComandanteAPI.HttpPostRequest('products/' + productId + '/projects/' + projectId + '/tasks/' + taskId + '/activities/add', { body: JSON.stringify(convertToRaw(activityEditorState.getCurrentContent())) }, (err, res) => {  
                if(res && res.data && res.data.result === true) {
                    fetchActivities();

                    setClearActivityEditor(prev => prev + 1);
                }
            });
        }
    }

    const onReplyComment = (activityId) => {
        /*
        if(activityEditorRefs && activityEditorRefs[activityId] && activityEditorRefs[activityId].current) {
            if(activityEditorRefs[activityId].current.getEditorState().getCurrentContent().getPlainText().trim()) {
                ComandanteAPI.HttpPostRequest('products/' + productId + '/projects/' + projectId + '/tasks/' + taskId + '/activities/add', { replyTo: activityId, body: JSON.stringify(convertToRaw(activityEditorRefs[activityId].current.getEditorState().getCurrentContent())) }, (err, res) => {  
                    if(res && res.data && res.data.result === true) {
                        fetchActivities();
                        fetchReplies(activityId);

                        setClearReplyEditor(prev => prev + 1);
                    }
                });
            }
        }
        */

        if(replyEditorState.getCurrentContent().getPlainText().trim()) {
            ComandanteAPI.HttpPostRequest('products/' + productId + '/projects/' + projectId + '/tasks/' + taskId + '/activities/add', { replyTo: activityId, body: JSON.stringify(convertToRaw(replyEditorState.getCurrentContent())) }, (err, res) => {  
                if(res && res.data && res.data.result === true) {
                    fetchActivities();
                    fetchReplies(activityId);

                    setClearReplyEditor(prev => prev + 1);
                }
            });
        }
    }

    const handleDeleteCommentModalCancel = () => { setDeleteCommentModalVisible(false) }
    const handleDeleteCommentModalOk = () => {
        ComandanteAPI.HttpPostRequest('products/' + productId + '/projects/' + projectId + '/tasks/' + taskId + '/activities/' + commentToBeDeleted + '/delete', {}, (err, res) => {  
            if(res && res.data && res.data.result === true) {
                refreshActivities();

                setDeleteCommentModalVisible(false);
            }
        });
    }

    const handleDeleteReplyModalCancel = () => { setDeleteReplyModalVisible(false) }
    const handleDeleteReplyModalOk = () => {
        ComandanteAPI.HttpPostRequest('products/' + productId + '/projects/' + projectId + '/tasks/' + taskId + '/activities/' + replyToBeDeleted + '/delete', {}, (err, res) => {  
            if(res && res.data && res.data.result === true) {
                refreshActivities();
                fetchReplies(commentIdOfReplyToBeDeleted);

                setDeleteReplyModalVisible(false);
            }
        });
    }

    const viewFileHandler = async (fileName, type, originalFileName) => {
        ComandanteAPI.FileReader('products/' + productId + '/projects/' + projectId + '/tasks/' + taskId + '/attachments/' + fileName, type, originalFileName, (err, res) => {
            if(err && err.status) {
                if(err.status === 404) message.error('File not found!');
            }
        });
    };

    const onDeleteAttachment = (attachmentId) => {
        ComandanteAPI.HttpPostRequest('products/' + productId + '/projects/' + projectId + '/tasks/' + taskId + '/attachments/' + attachmentId + '/delete', {}, (err, res) => {
            if(err && err.status) {
                if(err.status === 404) message.error('File not found!');
            }
            
            if(res && res.data && res.data.result === true) {
                setImageToView(null);
                setAttachmentIdToBeDeleted(null);
                setDeleteAttachmentModalVisible(false);
                fetchTask();
                refreshTasks();
            }
        });
    }

    return (
        (productDataset && productDataset.product && projectDataset && projectDataset.project && taskDataset && taskDataset.task) ? (
            <>
                <Drawer
                    width={ 720 }
                    visible={ true }
                    mask={ false }
                    maskClosable={ false }
                    closable={ false }
                    onClose={ onClose }
                >
                    <div className="task-view-H">
                        <div className="float-right" style={{ margin: '-8px 0 0 0' }}>
                            <CopyToClipboard text={ process.env.REACT_APP_URL + '/products/' + productId + '/projects/' + projectId + '/tasks/' + taskId } onCopy={ () => {} }>
                                <Button className="btn-nothing d-xs-none d-inline" style={{ fontSize: '12px', color: '#6246EA' }}>Copy link</Button>
                            </CopyToClipboard>

                            {
                                taskDataset && taskDataset.task && (
                                    currentUser && currentUser.followedTaskIds && Array.isArray(currentUser.followedTaskIds) && currentUser.followedTaskIds.includes(taskDataset.task._id+'') ? (
                                        <Button className="btn-white d-xs-none d-inline mr-3" onClick={ () => { onFollowTask() } }>Unfollow</Button>
                                    ) : (
                                        <Button className="btn-white d-xs-none d-inline mr-3" onClick={ () => { onFollowTask() } }>Follow</Button>
                                    )
                                )
                            }

                            {
                                ((currentUser && taskDataset && taskDataset.task && taskDataset.task.createdBy && taskDataset.task.createdBy+'' === currentUser._id+'') || (currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Comandante', 'Product Owner', 'Project Manager'].includes(roleOptions[currentUser.role+'']))) && (
                                    <Dropdown 
                                        trigger={ ['click'] }
                                        placement="bottomLeft"
                                        overlay={
                                            <Menu style={{ fontWeight: 500, width: '220px' }}>
                                                <Menu.Item key="1" onClick={ () => { markAsCompleted() } }>
                                                    <div className="hover-pointer">
                                                        {
                                                            taskDataset && taskDataset.task && taskDataset.task.isCompleted ? (
                                                                <><ClockCircleOutlined className="mr-1" style={{ fontSize: '16px' }} /> Mark as ongoing</>
                                                            ) : (
                                                                <><CheckCircleOutlined className="mr-1" style={{ fontSize: '16px' }} /> Mark as completed</>
                                                            )
                                                        }
                                                    </div>
                                                </Menu.Item>
                                                
                                                {
                                                    (taskDataset && taskDataset.task && taskStatusOptions && taskStatusOptions[taskDataset.task.status] && taskStatusOptions[taskDataset.task.status] === 'Active') ? (
                                                        <Menu.Item key="2" onClick={ () => { setArchiveTaskModalVisible(true) } }>
                                                            <div className="hover-pointer">
                                                                <CustomIcon iconKey="archive" style={{ width: '15px', margin: '0 4px -4px 0' }} /> Archive
                                                            </div>
                                                        </Menu.Item>
                                                    ) : (taskDataset && taskDataset.task && taskStatusOptions && taskStatusOptions[taskDataset.task.status] && taskStatusOptions[taskDataset.task.status] === 'Inactive') ? (
                                                        <Menu.Item key="2" onClick={ () => { setUnarchiveTaskModalVisible(true) } }>
                                                            <div className="hover-pointer">
                                                                <RollbackOutlined style={{ margin: '0 4px -4px 0', fontSize: '14px' }} /> Unarchive
                                                            </div>
                                                        </Menu.Item>
                                                    ) : (
                                                        null
                                                    )

                                                }

                                                <Menu.Item key="3" onClick={ () => { setDeleteTaskModalVisible(true) } }>
                                                    <div className="hover-pointer">
                                                        <CustomIcon iconKey="delete" color="#E45858" style={{ width: '12px', margin: '0 4px -2px 2px' }} /> Delete
                                                    </div>
                                                </Menu.Item>
                                            </Menu>
                                        }
                                    >
                                        <span className="hover-pointer">
                                            <CustomIcon iconKey="options-vertical" style={{ height: '18px', margin: '0 12px -2px 12px' }} />
                                        </span>
                                    </Dropdown>
                                )
                            }

                            <span className="hover-pointer" onClick={ () => { onClose() } }>
                                <CustomIcon iconKey="close" style={{ height: '16px', margin: '0 0 -1px 12px' }} />
                            </span>
                        </div>

                        <Link to={ '/products/' + productId }>{ productDataset.product.name }</Link>
                        <span className="ml-2 mr-2">&gt;</span>
                        <Link to={ '/products/' + productId + '/projects/' + projectId }>{ projectDataset.project.name }</Link>
                    </div>

                    <div className="task-view-B">
                        <Form className="form--task-drawer" form={ editTaskForm } initialValues={{}} name="editTask" onFinish={ onEditTaskFinish } layout="vertical" hideRequiredMark={ true }>
                            <div className="form-R1" style={{ padding: '20px 30px 40px 30px' }}>
                                <Row gutter={ 8 }>
                                    <Col xs={ 12 } sm={ 12 } md={ 6 } lg={ 6 } xl={ 6 }>
                                        <Form.Item
                                            name="stage"
                                            label={ <><CustomIcon iconKey="piechart" color="#8C8C8C" className="mr-1" /> STAGE</> }
                                            validateStatus={ editTaskErrors && editTaskErrors.stage ? "error" : null }
                                            help={ editTaskErrors && editTaskErrors.stage ? editTaskErrors.stage : null }
                                        >
                                            <Select 
                                                labelInValue
                                                placeholder="Select stage"
                                                onChange={ () => { editTaskForm.submit() } }
                                                disabled={ (currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Observer'].includes(roleOptions[currentUser.role+''])) }
                                            >
                                                {
                                                    projectDataset.project.stages && Array.isArray(projectDataset.project.stages) && projectDataset.project.stages.length > 0 && projectDataset.project.stages.map((stage) => {
                                                        return (
                                                            <Select.Option
                                                                key={ 'projectStage_'+stage._id }
                                                                value={ stage._id }
                                                                disabled={ (currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Observer', 'Contributor', 'Editor', 'Project Manager'].includes(roleOptions[currentUser.role+''])) && stage.locked ? true : false }
                                                            >
                                                                { stage.label }
                                                            </Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col xs={ 12 } sm={ 12 } md={ 6 } lg={ 6 } xl={ 6 }>
                                        <Form.Item
                                            name="dueDate"
                                            label={ <><CustomIcon iconKey="date-time" color="#8C8C8C" className="mr-1"/> DUE DATE</> }
                                            validateStatus={ editTaskErrors && editTaskErrors.dueDate ? "error" : null }
                                        >
                                            <DatePicker 
                                                onChange={ () => { editTaskForm.submit() } } 
                                                style={{ width: '100%', height: '32px' }}
                                                disabled={ (currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Observer'].includes(roleOptions[currentUser.role+''])) }
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={ 12 } sm={ 12 } md={ 6 } lg={ 6 } xl={ 6 }>
                                        <Form.Item
                                            name="priority"
                                            label={ <><CustomIcon iconKey="flag" color="#8C8C8C" className="mr-1"/> PRIORITY</> }
                                            validateStatus={ editTaskErrors && editTaskErrors.priority ? "error" : null }
                                        >
                                            <Select
                                                labelInValue
                                                placeholder="Select priority"
                                                onChange={ () => { editTaskForm.submit() } }
                                                disabled={ (currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Observer'].includes(roleOptions[currentUser.role+''])) }
                                            >
                                                {
                                                    priorityLevelOptions && Object.keys(priorityLevelOptions).map((pKey) => {
                                                        return <Select.Option key={ 'priorityLevel_'+pKey } value={ pKey+''}>{ priorityLevelOptions[pKey+''] }</Select.Option>
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col xs={ 12 } sm={ 12 } md={ 6 } lg={ 6 } xl={ 6 }>
                                        <Form.Item
                                            name="assignedTo"
                                            label={ <><CustomIcon iconKey="user" color="#8C8C8C" className="mr-1"/> ASSIGNEE</> }
                                            validateStatus={ editTaskErrors && editTaskErrors.assignedTo ? "error" : null }
                                        >
                                            <Select 
                                                allowClear={ true }
                                                labelInValue 
                                                placeholder="Select assignee" 
                                                onChange={ () => { editTaskForm.submit() } }
                                                disabled={ (currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Observer'].includes(roleOptions[currentUser.role+''])) }
                                            >
                                                {
                                                    projectMembersDataset && projectMembersDataset.data && Object.values(projectMembersDataset.data).length > 0 && Object.values(projectMembersDataset.data).map((member) => {
                                                        return <Select.Option key={ member._id } value={ member._id }>{ member.firstName + ' ' + member.lastName }</Select.Option>
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>

                            {
                                (taskDetailsVisible) && (
                                    <div className="form-R1-2 d-xs-none d-sm-none" style={{ padding: '20px 30px 40px 30px' }}>
                                        <Row gutter={ 16 }>
                                            <Col span={ 8 }>
                                                <label><CustomIcon iconKey="taskCreated" color="#8C8C8C" className="mr-1" style={{ margin: '0 0 -2 0' }} /> CREATED</label>
                                                <p>
                                                    By { (taskDataset && taskDataset.task && taskDataset.task.createdBy && projectMembersDataset && projectMembersDataset.data && projectMembersDataset.data[taskDataset.task.createdBy] && projectMembersDataset.data[taskDataset.task.createdBy].firstName + ' ' + projectMembersDataset.data[taskDataset.task.createdBy].lastName) || 'N/A' } <br />
                                                    { (taskDataset && taskDataset.task && taskDataset.task.createdAt && moment(taskDataset.task.createdAt).format('dddd, MMMM Do YYYY')) }
                                                </p>
                                            </Col>

                                            <Col span={ 8 }>
                                                <label><CustomIcon iconKey="taskUpdated" color="#8C8C8C" className="mr-1" style={{ margin: '0 0 -2 0' }} /> LAST UPDATED</label>
                                                <p>
                                                    By { (taskDataset && taskDataset.task && taskDataset.task.lastUpdatedBy && projectMembersDataset && projectMembersDataset.data && projectMembersDataset.data[taskDataset.task.lastUpdatedBy] && projectMembersDataset.data[taskDataset.task.lastUpdatedBy].firstName + ' ' + projectMembersDataset.data[taskDataset.task.lastUpdatedBy].lastName) || 'N/A' } <br />
                                                    { (taskDataset && taskDataset.task && taskDataset.task.updatedAt && moment(taskDataset.task.updatedAt).format('dddd, MMMM Do YYYY')) }
                                                </p>
                                            </Col>

                                            <Col span={ 8 }>
                                                <label><CustomIcon iconKey="id" color="#8C8C8C" className="mr-1" style={{ margin: '0 0 -2 0' }} /> ID</label>
                                                <p> { (taskDataset && taskDataset.task && taskDataset.task._id) } </p>
                                            </Col>
                                        </Row>
                                    </div>
                                )
                            }

                            <div className="form-R2" style={{ padding: '20px 30px 20px 30px' }}>
                                <div onClick={ () => { setTaskDetailsVisible(!taskDetailsVisible) } } className="details-button d-xs-none d-sm-none">{ (taskDetailsVisible ? 'Close Details' : 'Details') }</div>
                                
                                <Form.Item
                                    className={ 'task-drawer-custom-input' + (editTaskErrors && editTaskErrors.title ? ' error' : '' ) + (((currentUser && taskDataset && taskDataset.task && taskDataset.task.createdBy && taskDataset.task.createdBy+'' === currentUser._id+'') || (currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Comandante', 'Product Owner', 'Project Manager'].includes(roleOptions[currentUser.role+'']))) ? '' : ' disabled') }
                                    name="title"
                                    label="Title"
                                    validateStatus={ editTaskErrors && editTaskErrors.title ? "error" : null }
                                    help={ editTaskErrors && editTaskErrors.title ? editTaskErrors.title : null }
                                >
                                    <Input
                                        className="title"
                                        onBlur={
                                            () => {
                                                if(taskDataset && taskDataset.task) {
                                                    if(editTaskForm.getFieldValue("title")+'' !== taskDataset.task.title+'') {
                                                        editTaskForm.submit();
                                                    }
                                                }
                                            }
                                        }
                                        disabled={ ((currentUser && taskDataset && taskDataset.task && taskDataset.task.createdBy && taskDataset.task.createdBy+'' === currentUser._id+'') || (currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Comandante', 'Product Owner', 'Project Manager'].includes(roleOptions[currentUser.role+'']))) ? false : true }
                                    />
                                </Form.Item>

                                <Form.Item
                                    className={ 'task-drawer-custom-input comandante-rte-wrapper' + (((currentUser && taskDataset && taskDataset.task && taskDataset.task.createdBy && taskDataset.task.createdBy+'' === currentUser._id+'') || (currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Comandante', 'Product Owner', 'Project Manager'].includes(roleOptions[currentUser.role+'']))) ? '' : ' disabled') }
                                    name="description"
                                    label="Description"
                                    validateStatus={ editTaskErrors && editTaskErrors.description ? "error" : null }
                                    help={ editTaskErrors && editTaskErrors.description ? editTaskErrors.description : null }
                                >
                                    <ComandanteEditor
                                        key={ 'comandanteEditor--description' }
                                        editorKey={ 'comandanteEditor--description' }
                                        className={ 'comandante-editor' + (editTaskErrors && editTaskErrors.title ? ' error' : '' ) + (((currentUser && taskDataset && taskDataset.task && taskDataset.task.createdBy && taskDataset.task.createdBy+'' === currentUser._id+'') || (currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Comandante', 'Product Owner', 'Project Manager'].includes(roleOptions[currentUser.role+'']))) ? '' : ' disabled') }
                                        productId={ productId }
                                        projectId={ projectId }
                                        setEditorState={ _descriptionEditorStateCallback }
                                        initialValue={ (taskDataset && taskDataset.task && taskDataset.task.description) || '' }
                                        onBlur={
                                            () => {
                                                editTaskForm.submit();
                                            }
                                        }
                                        disabled={ ((currentUser && taskDataset && taskDataset.task && taskDataset.task.createdBy && taskDataset.task.createdBy+'' === currentUser._id+'') || (currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Comandante', 'Product Owner', 'Project Manager'].includes(roleOptions[currentUser.role+'']))) ? false : true }
                                    />
                                </Form.Item>

                                <div className="checklist-wrapper d-xs-none" style={{ margin: '50px 0 0 0' }}>
                                    <div className="mt-5" style={{ fontSize: '20px', color: '#595959' }}>
                                        <CustomIcon iconKey="checklist" className="mr-1" style={{ margin: '0 0 0 0' }} />
                                        Checklist
                                    </div>
                                    
                                    <Form.List name="checklist">
                                        { (fields, { add, remove }) => {
                                            return (
                                                <>
                                                    <Form.Item className="m-0">
                                                        {
                                                            !(currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Observer'].includes(roleOptions[currentUser.role+''])) && (
                                                                <Button
                                                                    className="btn-nothing d-block mt-1 p-0"
                                                                    onClick={ 
                                                                        () => {
                                                                            add();

                                                                            updateAssigneesByFieldIndex(assigneesByFieldIndex => {
                                                                                let nAssigneesArr = (assigneesByFieldIndex ? Object.values(assigneesByFieldIndex) : []);
                                                                                nAssigneesArr.push(null);

                                                                                let nAssigneesObj = {};
                                                                                for(let [i, assignee] of nAssigneesArr.entries()) {
                                                                                    nAssigneesObj[''+i] = assignee;
                                                                                }
                                                                                                                                    
                                                                                return nAssigneesObj;
                                                                            });

                                                                            updateDueDatesByFieldIndex(dueDatesByFieldIndex => {
                                                                                let nDueDatesArr = (dueDatesByFieldIndex ? Object.values(dueDatesByFieldIndex) : []);
                                                                                nDueDatesArr.push(null);

                                                                                let nDueDatesObj = {};
                                                                                for(let [i, dueDate] of nDueDatesArr.entries()) {
                                                                                    nDueDatesObj[''+i] = dueDate;
                                                                                }
                                                                                                                                    
                                                                                return nDueDatesObj;
                                                                            });

                                                                            updateCheckboxValuesByFieldIndex(checkboxValuesByFieldIndex => {
                                                                                let nChecboxesArr = (checkboxValuesByFieldIndex ? Object.values(checkboxValuesByFieldIndex) : []);
                                                                                nChecboxesArr.push(false);

                                                                                let nChecboxesObj = {};
                                                                                for(let [i, checked] of nChecboxesArr.entries()) {
                                                                                    nChecboxesObj[''+i] = checked;
                                                                                }
                                                                                                                                    
                                                                                return nChecboxesObj;
                                                                            });
                                                                        }
                                                                    }
                                                                    style={{ color: '#6246EA' }}
                                                                >
                                                                    <CustomIcon iconKey="add" color="#6246EA" className="mr-1" style={{ height: '12px' }} /> Create an entry
                                                                </Button>
                                                            )
                                                        }
                                                        
                                                        { fields.map((field, fieldIndex) => (
                                                            <Form.Item key={ 'checkListItem_'+fieldIndex } className="checklist-entry mt-2">
                                                                <Checkbox 
                                                                    defaultChecked={ checkboxValuesByFieldIndex && checkboxValuesByFieldIndex[fieldIndex] ? true : false }
                                                                    style={{ float: 'left', margin: '6px 0 0 0' }}
                                                                    onChange={
                                                                        () => {
                                                                            updateCheckboxValuesByFieldIndex(checkboxValuesByFieldIndex => {
                                                                                if(!checkboxValuesByFieldIndex) return { [''+fieldIndex]: true };

                                                                                if(!checkboxValuesByFieldIndex[fieldIndex]) {
                                                                                    checkboxValuesByFieldIndex[''+fieldIndex] = true;
                                                                                } else {
                                                                                    checkboxValuesByFieldIndex[''+fieldIndex] = !checkboxValuesByFieldIndex[''+fieldIndex];
                                                                                }
                                                                            });

                                                                            editTaskForm.submit();
                                                                        } 
                                                                    }
                                                                    disabled={ (currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Observer'].includes(roleOptions[currentUser.role+''])) }
                                                                />

                                                                <Button 
                                                                    className="btn-nothing p-0"
                                                                    style={{ float: 'right' }}
                                                                    onClick={ 
                                                                        () => { 
                                                                            remove(field.name);

                                                                            updateDueDatesByFieldIndex(dueDatesByFieldIndex => {
                                                                                if(dueDatesByFieldIndex[fieldIndex]) delete dueDatesByFieldIndex[fieldIndex];
                                                                            });

                                                                            updateAssigneesByFieldIndex(assigneesByFieldIndex => {
                                                                                if(assigneesByFieldIndex[fieldIndex]) delete assigneesByFieldIndex[fieldIndex];
                                                                            });

                                                                            updateCheckboxValuesByFieldIndex(checkboxValuesByFieldIndex => {
                                                                                if(checkboxValuesByFieldIndex[fieldIndex]) delete checkboxValuesByFieldIndex[fieldIndex];
                                                                            });

                                                                            editTaskForm.submit();
                                                                        }
                                                                    }
                                                                    disabled={ (currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Observer'].includes(roleOptions[currentUser.role+''])) }
                                                                >
                                                                    <CustomIcon iconKey="delete" color="#E45858" style={{ height: '14px', margin: '8px 0 8px 0' }} />
                                                                </Button>

                                                                <Row gutter={ 12 } style={{ width: 'calc(100% - 36px)' }}>
                                                                    <Col span={ 12 }>
                                                                        <Form.Item { ...field } className="task-drawer-custom-input m-0">
                                                                            <Input.TextArea
                                                                                key={ fieldIndex }
                                                                                placeholder={ 'Type here...' }
                                                                                className={ (checkboxValuesByFieldIndex && checkboxValuesByFieldIndex[fieldIndex] ? 'checklist-item done' : 'checklist-item to-do') + (editTaskForm.getFieldValue('checklist') && editTaskForm.getFieldValue('checklist')[fieldIndex] && editTaskForm.getFieldValue('checklist')[fieldIndex].length > 0 ? ' no-border' : '') }
                                                                                style={{ border: '1px solid #DFE0E1', padding: '4px 4px 4px 4px' }}
                                                                                ref={ (ref) => { setChecklistEntryRefs(fieldIndex, ref) } }
                                                                                onBlur={
                                                                                    () => {
                                                                                        if(editTaskForm.getFieldValue('checklist') && editTaskForm.getFieldValue('checklist')[fieldIndex]) {
                                                                                            if(editTaskForm.getFieldValue('checklist') !== '' && editTaskForm.getFieldValue('checklist').length > 0) {
                                                                                                editTaskForm.submit();       
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                                autoSize
                                                                                disabled={ (currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Observer'].includes(roleOptions[currentUser.role+''])) }
                                                                            />
                                                                        </Form.Item>
                                                                    </Col>

                                                                    <Col span={ 6 }>
                                                                        <Select
                                                                            labelInValue
                                                                            placeholder="Select assignee"
                                                                            showArrow={ false }
                                                                            defaultValue={ (assigneesByFieldIndex && assigneesByFieldIndex[fieldIndex]) ? { value: assigneesByFieldIndex[fieldIndex] } : null }
                                                                            onChange={
                                                                                (selected) => {
                                                                                    if(selected && selected.value) {
                                                                                        updateAssigneesByFieldIndex(assigneesByFieldIndex => {
                                                                                            if(!assigneesByFieldIndex) return { [''+fieldIndex]: selected.value };
                                                                                            assigneesByFieldIndex[''+fieldIndex] = selected.value+'';
                                                                                        });

                                                                                        editTaskForm.submit();
                                                                                    }
                                                                                }
                                                                            }
                                                                            disabled={ (currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Observer'].includes(roleOptions[currentUser.role+''])) }
                                                                        >
                                                                            {
                                                                                projectMembersDataset && projectMembersDataset.data && Object.values(projectMembersDataset.data).length > 0 && Object.values(projectMembersDataset.data).map(member => {
                                                                                    return <Select.Option key={ 'projectMember_'+member._id } value={ member._id }>{ member.firstName + ' ' + member.lastName }</Select.Option>
                                                                                })
                                                                            }
                                                                        </Select>
                                                                    </Col>

                                                                    <Col span={ 6 }>
                                                                        <DatePicker
                                                                            placeholder="Select date"
                                                                            style={{ height: '32px', lineHeight: '32px' }}
                                                                            defaultValue={ dueDatesByFieldIndex && dueDatesByFieldIndex[fieldIndex] && moment(dueDatesByFieldIndex[fieldIndex]) }
                                                                            onChange={
                                                                                (selected) => {
                                                                                    updateDueDatesByFieldIndex(dueDatesByFieldIndex => {
                                                                                        if(!dueDatesByFieldIndex) return { [''+fieldIndex]: selected };
                                                                                        dueDatesByFieldIndex[''+fieldIndex] = selected;
                                                                                    })

                                                                                    editTaskForm.submit();
                                                                                }
                                                                            }
                                                                            disabled={ (currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Observer'].includes(roleOptions[currentUser.role+''])) }
                                                                        />
                                                                    </Col>
                                                                </Row>
                                                            </Form.Item>
                                                        ))}
                                                    </Form.Item>
                                                </>
                                            )}
                                        }   
                                    </Form.List>
                                </div>

                                <div className="dependencies-wrapper d-xs-none" style={{ margin: '10px 0 0 0' }}>
                                    <div className="mt-5" style={{ fontSize: '20px', color: '#595959' }}>
                                        <CustomIcon iconKey="dependencies" className="mr-1" style={{ margin: '0 0 5px 0' }} />
                                        Dependencies
                                    </div>

                                    {
                                        !(currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Observer'].includes(roleOptions[currentUser.role+''])) && (
                                            <Button className="btn-nothing pl-0 pr-0" style={{ color: '#6246EA' }} onClick={ () => { setDependenciesSearchBarVisible(true); } }>
                                                <CustomIcon iconKey="add" color="#6246EA" className="mr-1" style={{ margin: '0 0 -2px 0' }} /> Create a dependency
                                            </Button>
                                        )
                                    }

                                    { dependenciesSearchBarVisible &&
                                        <div className={ 'dependencies--search-container mt-2 mb-2' }>
                                            <Input 
                                                style={{ zIndex: 999, maxWidth: '280px' }}
                                                prefix={ <SearchOutlined /> }
                                                placeholder="Search by task id or title"
                                                onChange={ (event) => {
                                                    event.persist(); // Signal to React not to nullify the event object

                                                    debounced_fetchDependenciesSearchResults(event.target.value);
                                                }}
                                            />

                                            <Button
                                                className="btn-nothing pl-0 pr-0"
                                                onClick={ () => {
                                                    setDependenciesSearchBarVisible(false);
                                                    updateDependenciesSearchResultsDataset(dependenciesSearchResultsDataset => null)
                                                } }>
                                                <CustomIcon iconKey="close" color="#E45858" className="ml-4" style={{ height: '12px' }} />
                                            </Button>

                                            <div className="dependencies--results mt-4 mb-5">
                                                <div style={{ color: '#6C6D72' }}>
                                                    { dependenciesSearchResultsDataset && dependenciesSearchResultsDataset.data && Object.values(dependenciesSearchResultsDataset.data).length > 0 ? Object.values(dependenciesSearchResultsDataset.data).length : 0 } results found
                                                </div>

                                                {
                                                    dependenciesSearchResultsDataset && dependenciesSearchResultsDataset.data && Object.keys(dependenciesSearchResultsDataset.data).length > 0 && Object.values(dependenciesSearchResultsDataset.data).map((task) => {
                                                        return (
                                                            <div key={ 'task_'+task._id } className="dependency-W mt-2 ml-5 pt-3 pb-3 pl-4 pr-4">
                                                                <div className="float-right">
                                                                    <Button className="btn-nothing pl-2 pr-2" style={{ height: '30px', margin: '-4px -6px 0 0' }} onClick={
                                                                        () => {
                                                                            manageDependencies([ ...taskDataset.task.dependantTaskIds, task._id+'' ]);

                                                                            setDependenciesSearchBarVisible(false);
                                                                            updateDependenciesSearchResultsDataset(dependenciesSearchResultsDataset => null);
                                                                        }
                                                                    }>
                                                                        <CustomIcon iconKey="add" color="#6246EA" className="mr-1" style={{ height: '14px', margin: '0 0 2px 0' }} />
                                                                    </Button>
                                                                </div>

                                                                <div className="breadcrumbs">
                                                                    { productDataset.product.name }
                                                                    <span className="ml-2 mr-2">&gt;</span>
                                                                    { projectDataset.project.name }
                                                                </div>

                                                                <div className="title mt-2">
                                                                    { task.title }
                                                                </div>

                                                                <div className="mt-2" style={{ color: '#595959' }}>
                                                                    <div className={ 'assignee d-inline-block mr-4' + (task.assignedTo ? '' : ' empty') } style={{ fontSize: '12px', lineHeight: '18px' }}>
                                                                        {
                                                                            (task.assignedTo) ? (
                                                                                <img className="mr-1 mb-0" style={{ height: '16px' }} src={ avatarPlaceholder } alt="Avatar" />
                                                                            ) : (
                                                                                <CustomIcon iconKey="profile" color="#C0C0C3" style={{ margin: '0 4px -2px 0' }} /> 
                                                                            )
                                                                        }
                                                                        { task.assignedTo && projectMembersDataset && projectMembersDataset.data && projectMembersDataset.data[task.assignedTo] ? projectMembersDataset.data[task.assignedTo].firstName + ' ' + projectMembersDataset.data[task.assignedTo].lastName : 'No assignee'}
                                                                    </div>

                                                                    {
                                                                        (projectDataset.project.stages && Array.isArray(projectDataset.project.stages) && projectDataset.project.stages.length > 0) && (
                                                                            <div className={ 'd-inline-block mr-4' } style={{ fontSize: '12px', lineHeight: '18px', color: ((projectDataset.project.stages.find(stage => (stage._id && stage._id+'' === task.stage+''))).color) }}>
                                                                                {
                                                                                    (projectDataset.project.stages.find(stage => (stage._id && stage._id+'' === task.stage+''))).label
                                                                                }
                                                                            </div>
                                                                        )
                                                                    }

                                                                    <div className={ 'priority d-inline-block mr-4' +  (task.priority && priorityLevelOptions && priorityLevelOptions[task.priority] ? ' ' + priorityLevelOptions[task.priority].toLowerCase() : '')}>
                                                                        {
                                                                            (task.priority) ? (
                                                                                (priorityLevelOptions && priorityLevelOptions[task.priority]) ? (
                                                                                    priorityLevelOptions[task.priority]
                                                                                ) : (
                                                                                    task.priority
                                                                                )
                                                                            ) : (
                                                                                'None'
                                                                            )
                                                                        }
                                                                    </div>

                                                                    { task.dueDate && 
                                                                        <div className={ 'dueDate d-inline-block mr-4' } style={{ fontSize: '12px' }}>
                                                                            <CustomIcon iconKey="date-time" color="#6246EA" style={{ width: '18px', margin: '0 0 -2px 0' }} /> { moment(task.dueDate).calendar() }
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    }

                                    <div className="dependencies mt-5">
                                        {
                                            (taskDataset && taskDataset.task && taskDataset.task.dependantTaskIds && Array.isArray(taskDataset.task.dependantTaskIds) && taskDataset.task.dependantTaskIds.length > 0) && taskDataset.task.dependantTaskIds.map((taskId, index) => {
                                                return (
                                                    (tasksDataset && tasksDataset.data && tasksDataset.data[taskId+'']) && (
                                                        <div key={ 'dependency_'+index } className="dependency-W mt-2 pt-3 pb-3 pl-4 pr-4">
                                                            <div className="float-right">
                                                                <Button 
                                                                    className="btn-nothing pl-2 pr-2" 
                                                                    style={{ height: '30px', margin: '-4px -6px 0 0' }} 
                                                                    onClick={
                                                                        () => {
                                                                            let nDependencies = [ ...taskDataset.task.dependantTaskIds ];
                                                                            const index = nDependencies.indexOf(taskId+'')
                                                                            nDependencies.splice(index, 1);
                                                                            manageDependencies([ ...nDependencies ]);

                                                                            setDependenciesSearchBarVisible(false);
                                                                            updateDependenciesSearchResultsDataset(dependenciesSearchResultsDataset => null)
                                                                        }
                                                                    }
                                                                    disabled={ (currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Observer'].includes(roleOptions[currentUser.role+''])) }
                                                                >
                                                                    <CustomIcon iconKey="delete" color="#E45858" className="mr-1" style={{ height: '14px', margin: '0 0 2px 0' }} />
                                                                </Button>
                                                            </div>

                                                            <div className="breadcrumbs">
                                                                { productDataset.product.name }
                                                                <span className="ml-2 mr-2">&gt;</span>
                                                                { projectDataset.project.name }
                                                            </div>

                                                            <div className="title mt-2">
                                                                { tasksDataset.data[taskId+''].title }
                                                            </div>

                                                            <div className="mt-2" style={{ color: '#595959' }}>
                                                                <div className={ 'assignee d-inline-block mr-4' + (tasksDataset.data[taskId+''].assignedTo ? '' : ' empty') } style={{ fontSize: '12px', lineHeight: '18px' }}>
                                                                    {
                                                                        (tasksDataset.data[taskId+''].assignedTo) ? (
                                                                            <img className="mr-1 mb-0" style={{ height: '16px' }} src={ avatarPlaceholder } alt="Avatar" />
                                                                        ) : (
                                                                            <CustomIcon iconKey="profile" color="#C0C0C3" style={{ margin: '0 4px -2px 0' }} /> 
                                                                        )
                                                                    }
                                                                    { tasksDataset.data[taskId+''].assignedTo && projectMembersDataset && projectMembersDataset.data && projectMembersDataset.data[tasksDataset.data[taskId+''].assignedTo] ? projectMembersDataset.data[tasksDataset.data[taskId+''].assignedTo].firstName + ' ' + projectMembersDataset.data[tasksDataset.data[taskId+''].assignedTo].lastName : 'No assignee'}
                                                                </div>

                                                                {
                                                                    (tasksDataset.data[taskId+''] && projectDataset.project.stages && Array.isArray(projectDataset.project.stages) && projectDataset.project.stages.length > 0) && (
                                                                        <div className={ 'd-inline-block mr-4' } style={{ fontSize: '12px', lineHeight: '18px', color: ((projectDataset.project.stages.find(stage => (stage._id && stage._id+'' === tasksDataset.data[taskId+''].stage+''))).color) }}>
                                                                            {
                                                                                (projectDataset.project.stages.find(stage => (stage._id && stage._id+'' === tasksDataset.data[taskId+''].stage+''))).label
                                                                            }
                                                                        </div>
                                                                    )
                                                                }

                                                                <div className={ 'priority d-inline-block mr-4' +  (tasksDataset.data[taskId+''].priority && priorityLevelOptions && priorityLevelOptions[tasksDataset.data[taskId+''].priority] ? ' ' + priorityLevelOptions[tasksDataset.data[taskId+''].priority].toLowerCase() : '')}>
                                                                    {
                                                                        (tasksDataset.data[taskId+''].priority) ? (
                                                                            (priorityLevelOptions && priorityLevelOptions[tasksDataset.data[taskId+''].priority]) ? (
                                                                                priorityLevelOptions[tasksDataset.data[taskId+''].priority]
                                                                            ) : (
                                                                                tasksDataset.data[taskId+''].priority
                                                                            )
                                                                        ) : (
                                                                            'None'
                                                                        )
                                                                    }
                                                                </div>

                                                                { tasksDataset.data[taskId+''].dueDate && 
                                                                    <div className={ 'dueDate d-inline-block mr-4' } style={{ fontSize: '12px' }}>
                                                                        <CustomIcon iconKey="date-time" color="#6246EA" style={{ width: '18px', margin: '0 0 -2px 0' }} /> { moment(tasksDataset.data[taskId+''].dueDate).calendar() }
                                                                    </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                );
                                            })
                                        }
                                    </div>
                                </div>

                                <div style={{ margin: '50px 0 0 0' }}>
                                    <div className="mt-5" style={{ fontSize: '20px', color: '#595959' }}>
                                        <CustomIcon iconKey="attachment-horizontal" className="mr-1" style={{ width: '21px', height: '18px', margin: '0 0 -2px 0' }} />
                                        Attachments
                                    </div>
                                        
                                    {
                                        !(currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Observer'].includes(roleOptions[currentUser.role+''])) && (
                                            <Button className="btn-nothing pl-0 pr-0" style={{ color: '#6246EA' }} onClick={ () => { setUploadModalVisible(true) } }>
                                                <CustomIcon iconKey="upload" color="#6246EA" className="mr-1" style={{ margin: '0 0 -2px 0' }} /> Upload file(s)
                                            </Button>
                                        )
                                    }

                                    <div className="attachments card-view pb-4">
                                        <Row gutter={ 8 }>
                                            {
                                                (taskDataset && taskDataset.task && taskDataset.task.attachments && Array.isArray(taskDataset.task.attachments) && taskDataset.task.attachments.length > 0 ) && (
                                                    taskDataset.task.attachments.map((attachment) => {
                                                        return (
                                                            <Col key={ attachment._id } xs={ 12 } sm={ 12 } md={ 8 } lg={ 8 } xl={ 6 }>
                                                                <div
                                                                    className="attachment-C hover-pointer pb-4" 
                                                                    onClick={ () => {
                                                                        if(imageMimeTypes.includes(attachment.type)) {
                                                                            setImageToView(attachment);
                                                                        } else {
                                                                            viewFileHandler(attachment.fileName, attachment.type, attachment.originalFileName);
                                                                        }
                                                                    }}
                                                                >
                                                                    <div className="img-wrapper">
                                                                        { 
                                                                            (imageMimeTypes.includes(attachment.type)) ? (
                                                                                <img className="attachment" src={ process.env.REACT_APP_COMANDANTE_API_ADDRESS + attachment.filePath.substring(7) } alt="Attachment" />
                                                                            ) : (
                                                                                <div className="text-center" style={{ background: '#F8F8FB', height: '92px' }}>
                                                                                    <FileOutlined className="mt-4" style={{ fontSize: '48px', color: '#6246EA' }} />
                                                                                </div>
                                                                            )
                                                                        }

                                                                        <img className="uploaded-by" src={ (attachment.uploadedBy && projectMembersDataset && projectMembersDataset.data && projectMembersDataset.data[attachment.uploadedBy+''] && projectMembersDataset.data[attachment.uploadedBy+''].profilePhoto ? process.env.REACT_APP_COMANDANTE_API_ADDRESS + projectMembersDataset.data[attachment.uploadedBy+''].profilePhoto.path.substring(7) : avatarPlaceholder) } alt="Avatar" />
                                                                    </div>

                                                                    <div className="info-C mt-5 pl-2 pr-2">
                                                                        <Typography.Text className="file-name" ellipsis={ true }>{ attachment.originalFileName }</Typography.Text>
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        );
                                                    })
                                                )
                                            }
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </Form>

                        { 
                            (logsDataset && logsDataset.data && Object.keys(logsDataset.data).length > 0) && (
                                <div className="logs-W">
                                    <div style={{ padding: '20px 30px 20px 30px' }}>
                                        <div className="mt-5" style={{ fontSize: '20px', color: '#595959' }}>
                                            <CustomIcon iconKey="checklist" className="mr-1" style={{ margin: '0 0 0 0' }} />
                                            Logs
                                        </div>

                                        {
                                            Object.values(logsDataset.data).map((log, index) => {
                                                return (
                                                    <div key={ log._id+'' }>
                                                        <div className="activity log mt-5">
                                                            <div className="datetime float-right">
                                                                { log.createdAt ? moment(log.createdAt).calendar() : null }
                                                            </div>
                                                            
                                                            { log.body ? log.body : null }
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }

                                        {   
                                            (logsDataset && logsDataset.pagination && logsDataset.pagination.current && logsDataset.pagination.pageSize && logsDataset.pagination.total && ((logsDataset.pagination.current * logsDataset.pagination.pageSize) < logsDataset.pagination.total)) && (
                                                <div className="text-center mt-3">
                                                    <Button
                                                        className="btn-nothing"
                                                        style={{ color: '#6246EA' }}
                                                        onClick={ () => { fetchLogs(logsDataset.pagination.current + 1) } }
                                                    >
                                                        Load more...
                                                    </Button>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            )
                        }

                        <div className="activities-W">
                            <div style={{ padding: '20px 30px 20px 30px' }}>
                                <div className="mt-5" style={{ fontSize: '20px', color: '#595959' }}>
                                    <CustomIcon iconKey="comments" className="mr-1" style={{ width: '24px', height: '22px', margin: '0 0 -5px 0' }} />
                                    Activities
                                </div>

                                <div className="mt-2" style={{ fontSize: '14px', color: '#595959' }}>
                                    <span style={{ fontSize: '16px' }}>
                                        { activitiesDataset && activitiesDataset.pagination && activitiesDataset.pagination.total ? activitiesDataset.pagination.total : '0' } Comment(s)
                                    </span>
                                </div>

                                { 
                                    (activitiesDataset && activitiesDataset.data && Array.isArray(Object.values(activitiesDataset.data)) && Object.values(activitiesDataset.data).length > 0) ? (
                                        Object.values(activitiesDataset.data).map((activity, index) => {
                                            return (
                                                <div key={ activity._id+'' }>
                                                    {
                                                        (
                                                            (activity.type && activityTypeOptions && activityTypeOptions[activity.type] && activityTypeOptions[activity.type] === 'Comment') ? (
                                                                <div className="activity comment mt-4">
                                                                    <img className="avatar mr-4" src={ avatarPlaceholder } alt="Avatar" />

                                                                    <div className="content-W">
                                                                        <div className="mt-4 pl-4 pr-4">
                                                                            <div className="datetime float-right">
                                                                                { activity.createdAt ? moment(activity.createdAt).calendar() : null }
                                                                            </div>

                                                                            <div className="sender">{ projectMembersDataset && projectMembersDataset.data && projectMembersDataset.data[activity.userId] ? projectMembersDataset.data[activity.userId].firstName + ' ' + projectMembersDataset.data[activity.userId].lastName : 'Member' }</div>
                                                                        </div>

                                                                        <ComandanteEditor
                                                                            key={ 'comandanteEditor--activity-' + activity._id }
                                                                            editorKey={ 'comandanteEditor--activity-' + activity._id }
                                                                            className="comandante-editor"
                                                                            initialValue={ activity.body ? activity.body+'' : '' }
                                                                            readOnly={ true }
                                                                        />

                                                                        {
                                                                            !(currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Observer'].includes(roleOptions[currentUser.role+''])) && (
                                                                                <div className="actions mt-1 pl-4 pr-4">
                                                                                    <Button className="btn-nothing p-0" style={{ color: '#6246EA' }} onClick={ () => { setRepliesActiveKey(activity._id); fetchReplies(activity._id); } }>
                                                                                        <CustomIcon iconKey="reply" color="#6246EA" className="mr-1" style={{ margin: '0 0 -2px 0' }} /> Reply
                                                                                    </Button>

                                                                                    {
                                                                                        (currentUser.role === 99 || activity.userId+'' === currentUser._id+'') && (
                                                                                            <Button className="btn-nothing ml-4 p-0" style={{ color: '#6246EA' }} onClick={ () => { setCommentToBeDeleted(activity._id); setDeleteCommentModalVisible(true); } }>
                                                                                                <CustomIcon iconKey="delete" color="#6246EA" className="mr-1" style={{ width: '10px', margin: '0 0 -4px 0' }} /> Delete
                                                                                            </Button>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            )
                                                                        }

                                                                        <Collapse className="replies collapsing-replies mt-2" ghost={ true } activeKey={ repliesActiveKey }>
                                                                            <Collapse.Panel
                                                                                key={ activity._id }
                                                                                header={ 
                                                                                    (activity._id === repliesActiveKey) ? (
                                                                                        <Button
                                                                                            className="d-block btn-expand btn-nothing"
                                                                                            onClick={ () => { setRepliesActiveKey(null) } }
                                                                                        >
                                                                                            Collapse
                                                                                        </Button>
                                                                                    ) : (
                                                                                        (activity.numberOfReplies && activity.numberOfReplies > 0) ? (
                                                                                            <Button 
                                                                                                className="d-block btn-expand btn-nothing" 
                                                                                                onClick={ 
                                                                                                    () => { 
                                                                                                        setRepliesActiveKey(activity._id);
                                                                                                        fetchReplies(activity._id);
                                                                                                    } 
                                                                                                }
                                                                                            >
                                                                                                { activity.numberOfReplies + (activity.numberOfReplies === 1 ? ' Reply' : ' Replies') }    
                                                                                            </Button>
                                                                                        ) : (
                                                                                            null
                                                                                        )
                                                                                    )
                                                                                }
                                                                                showArrow={ false }
                                                                            >
                                                                                {
                                                                                    (activity._id === repliesActiveKey) && (
                                                                                        <div className="replies-W">
                                                                                            {
                                                                                                (repliesDataset && repliesDataset.data && Object.values(repliesDataset.data).map((reply) => {
                                                                                                    return (
                                                                                                        <div key={ reply._id } className="reply mt-4">
                                                                                                            <div className="content-W pb-3">
                                                                                                                <div className="mt-4 pl-4 pr-4">
                                                                                                                    <img className="avatar float-left mr-2" src={ avatarPlaceholder } alt="Avatar" />
                                                                                                                    <div className="datetime float-right">
                                                                                                                        { reply.createdAt ? moment(reply.createdAt).calendar() : null }
                                                                                                                    </div>

                                                                                                                    <div className="sender">{ projectMembersDataset && projectMembersDataset.data && projectMembersDataset.data[reply.userId] ? projectMembersDataset.data[reply.userId].firstName + ' ' + projectMembersDataset.data[reply.userId].lastName : 'Member' }</div>
                                                                                                                </div>

                                                                                                                <ComandanteEditor
                                                                                                                    key={ 'comandanteEditor--activity-' + activity._id + '--reply-' + reply._id }
                                                                                                                    editorKey={ 'comandanteEditor--activity-' + activity._id + '--reply-' + reply._id }
                                                                                                                    initialValue={ reply.body ? reply.body : '' }
                                                                                                                    className="comandante-editor"
                                                                                                                    readOnly={ true }
                                                                                                                />

                                                                                                                {
                                                                                                                    !(currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Observer'].includes(roleOptions[currentUser.role+''])) && (
                                                                                                                        <div className="actions mt-1 pl-4 pr-4">
                                                                                                                            {
                                                                                                                                (currentUser.role === 99 || activity.userId+'' === currentUser._id+'') && (
                                                                                                                                    <Button className="btn-nothing p-0" style={{ color: '#6246EA' }} onClick={ () => { setCommentIdOfReplyToBeDeleted(reply.replyTo+''); setReplyToBeDeleted(reply._id+''); setDeleteReplyModalVisible(true); } }>
                                                                                                                                        <CustomIcon iconKey="delete" color="#6246EA" className="mr-1" style={{ width: '10px', margin: '0 0 -4px 0' }} /> Delete
                                                                                                                                    </Button>
                                                                                                                                )
                                                                                                                            }
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                }
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    );
                                                                                                }))
                                                                                            }
                                                                                        </div>
                                                                                    )
                                                                                }

                                                                                { 
                                                                                    (!(currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Observer'].includes(roleOptions[currentUser.role+''])) && activity._id === repliesActiveKey) && (
                                                                                        <div className="activities-editor-W">
                                                                                            <ComandanteEditor
                                                                                                key={ 'comandanteEditor--activity-' + activity._id + '--reply' }
                                                                                                editorKey={ 'comandanteEditor--activity-' + activity._id + '--reply' }                                                                                    
                                                                                                className="comandante-editor comandante-activities-editor mt-4"
                                                                                                // referenceKey={ activity._id }
                                                                                                // setRef={ _activityEditorRefCallback }
                                                                                                productId={ productId }
                                                                                                projectId={ projectId }
                                                                                                setEditorState={ _replyEditorStateCallback }
                                                                                                clearEditor={ clearReplyEditor }
                                                                                                placeholder="Type your message here..."
                                                                                                initialValue={ '' }
                                                                                            />

                                                                                            <Button className="btn-purple btn-comment" onClick={ () => { onReplyComment(activity._id) } }>Reply</Button>
                                                                                        </div>
                                                                                    )
                                                                                }
                                                                            </Collapse.Panel>
                                                                        </Collapse>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                null
                                                            )
                                                        )
                                                    }
                                                </div>
                                            )
                                        })
                                    ) : (
                                        <div className="text-left mt-3" style={{ color: '#8C8C8C' }}>
                                            There is no comment(s) in this task yet. Be the first one to leave a comment on this!
                                        </div>
                                    )
                                }

                                {   
                                    (activitiesDataset && activitiesDataset.pagination && activitiesDataset.pagination.current && activitiesDataset.pagination.pageSize && activitiesDataset.pagination.total && ((activitiesDataset.pagination.current * activitiesDataset.pagination.pageSize) < activitiesDataset.pagination.total)) ? (
                                        <div className="text-center mt-3">
                                            <Button
                                                className="btn-nothing"
                                                style={{ color: '#6246EA' }}
                                                onClick={ () => { fetchActivities(activitiesDataset.pagination.current + 1) } }
                                            >
                                                Load more...
                                            </Button>
                                        </div>
                                    ) : null
                                }
                            </div>

                            {
                                !(currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Observer'].includes(roleOptions[currentUser.role+''])) && (
                                    <div className="activities-editor-W">
                                        <ComandanteEditor
                                            key={ 'comandanteEditor--comment' }
                                            editorKey={ 'comandanteEditor--comment' }
                                            className="comandante-editor comandante-activities-editor mt-4"
                                            productId={ productId }
                                            projectId={ projectId }
                                            setEditorState={ _activityEditorStateCallback }
                                            clearEditor={ clearActivityEditor }
                                            placeholder="Type your message here..."
                                            initialValue={ '' }
                                        />

                                        <Button className="btn-purple btn-comment" onClick={ onMakeComment }>Comment</Button>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </Drawer>

                {
                    (uploadModalVisible) && (
                        <Modal title={ null } footer={ null } visible={ true } onCancel={ () => { setUploadModalVisible(false); } } width={ 640 }>
                            <div className="modal-body-W" style={{ padding: '45px 45px' }}>
                                <h2 className="mb-5">Upload Files</h2>
                                
                                <Upload.Dragger 
                                    action={ process.env.REACT_APP_COMANDANTE_API_ADDRESS + 'products/' + productId + '/projects/' + projectId + '/tasks/' + taskId + '/upload' }
                                    headers={ (localStorage.getItem('token') ? { 'Authorization': localStorage.getItem('token') + '' } : {}) }
                                    multiple={ true }
                                    defaultFileList={ [] }
                                    // fileList={ [] }
                                    progress={{ strokeWidth: 2, showInfo: false }}
                                    showUploadList={ true }
                                    onChange={ 
                                        ({ file, fileList, event }) => {
                                            if(fileList && Array.isArray(fileList) && fileList.length > 0) {
                                                let uploadCompleted = true;
                                                for(let fileListItem of fileList) {
                                                    if(!fileListItem.percent || fileListItem.percent < 100 || !fileListItem.status || fileListItem.status !== 'done') {
                                                        uploadCompleted = false;
                                                        break;
                                                    }
                                                }

                                                if(uploadCompleted) {
                                                    setUploadModalVisible(false);
                                                    refreshTasks();
                                                    fetchTask();
                                                }
                                            }
                                        }
                                    }
                                    style={{ background: '#FFFFF', border: '2px dashed #D1D1E9', padding: '15px 30px 15px 30px' }}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined style={{ fontSize: '72px', color: '#D1D1E9' }} />
                                    </p>

                                    <p style={{ fontSize: '13px', color: '#8C8C8C' }}>Drag &amp; Drop your files here.</p>
                                    <p className="mt-2" style={{ fontSize: '12px', color: '#8C8C8C' }}>OR</p>

                                    <Button className="btn-white mt-4">Browse Files</Button>
                                </Upload.Dragger>
                            </div>
                        </Modal>
                    )
                }

                {
                    (imageToView) && (
                        <div className="image-gallery-W">
                            <Modal className="image-gallery-modal" title={ null } footer={ null } visible={ true } onCancel={ () => { setImageToView(null) } } width={ '100%' } height={ '100%' } closable={ false } style={{ top: '0', maxWidth: 'unset' }} zIndex={ 9990 }>
                                <div className="image-gallery-modal-header p-5">
                                    <div className="float-right mt-2 mr-4">
                                        <Button className="btn-nothing ml-4 mr-4 pt-0 pb-0 pl-0 pr-0" style={{ color: '#FFFFFE' }} onClick={ () => { setDeleteAttachmentModalVisible(true); setAttachmentIdToBeDeleted(imageToView._id); } }>
                                            <CustomIcon iconKey="delete" color="#FFFFFE" style={{ height: '15px', margin: '0 4px -2px 0' }} /> Delete
                                        </Button>

                                        <Button className="btn-nothing ml-4 mr-4 pt-0 pb-0 pl-0 pr-0" style={{ color: '#FFFFFE' }} onClick={ () => { viewFileHandler(imageToView.fileName, imageToView.type, imageToView.originalFileName) } }>
                                            <CustomIcon iconKey="download" color="#FFFFFE" style={{ margin: '0 4px -2px 0' }} /> Download
                                        </Button>

                                        <Button className="btn-nothing ml-4 mr-4 pt-0 pb-0 pl-0 pr-0" onClick={ () => { setImageToView(null) } }>
                                            <CustomIcon iconKey="close" color="#FFFFFE" style={{ margin: '0 0 -1px 0' }} />
                                        </Button>
                                    </div>

                                    <div className="file-name">{ imageToView.originalFileName ? imageToView.originalFileName.toUpperCase() : 'IMAGE FILE' }</div>
                                    <div className="file-type mt-2">
                                        Format: <b>{ (imageToView.type ? imageToView.type.toUpperCase() : 'N/A') }</b>
                                        <span className="ml-2 mr-2" />
                                        Uploaded by: <b>{ (imageToView.uploadedBy && projectMembersDataset && projectMembersDataset.data && projectMembersDataset.data[imageToView.uploadedBy] ? projectMembersDataset.data[imageToView.uploadedBy].firstName + ' ' + projectMembersDataset.data[imageToView.uploadedBy].lastName  : imageToView.uploadedBy) }</b>
                                    </div>
                                </div>

                                <div className="image-gallery-modal-body text-center">
                                    <div className="aligner-xy" style={{ height: '100%' }}>
                                        <img className="attachment" src={ process.env.REACT_APP_COMANDANTE_API_ADDRESS + imageToView.filePath.substring(7) } alt="Attachment" />
                                    </div>
                                </div>
                            </Modal>
                        </div>
                    )
                }

                {
                    (archiveTaskModalVisible) && (
                        <Modal className="confirmation-modal" title={ null } footer={ null } visible={ true } onCancel={ handleArchiveTaskModalCancel } width={ 520 }>
                            <div className="confirmation-modal-header-W">
                                
                            </div>

                            <div className="confirmation-modal-W">
                                <div className="header">
                                    Archive Task
                                </div>

                                <div className="body">
                                    { 'Are you sure you want to archive this task?' }

                                    <div className="text-right mt-4">
                                        <Button className="btn-white mr-2" onClick={ handleArchiveTaskModalCancel }>Cancel</Button>
                                        <Button className="btn-red" onClick={ handleArchiveTaskModalOk }>Archive</Button>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    )
                }

                {
                    (unarchiveTaskModalVisible) && (
                        <Modal className="confirmation-modal" title={ null } footer={ null } visible={ true } onCancel={ handleUnarchiveTaskModalCancel } width={ 520 }>
                            <div className="confirmation-modal-header-W">
                                
                            </div>

                            <div className="confirmation-modal-W">
                                <div className="header">
                                    Unarchive Task
                                </div>

                                <div className="body">
                                    { 'Please select the stage that you would like to recover the task into:' }

                                    <Select
                                        labelInValue
                                        className="viewTaskDrawer--unarchive-stage-select-box d-block mt-4"
                                        placeholder="Select stage"
                                        onSelect={ (selected) => { setStageForUnarchiveModel((selected && selected.value) || null) } }
                                    >
                                        {
                                            projectDataset.project.stages && Array.isArray(projectDataset.project.stages) && projectDataset.project.stages.length > 0 && projectDataset.project.stages.map((stage) => {
                                                return <Select.Option key={ 'projectStage_'+stage._id } value={ stage._id }>{ stage.label }</Select.Option>
                                            })
                                        }
                                    </Select>

                                    <div className="text-right mt-4">
                                        <Button className="btn-white mr-2" onClick={ handleUnarchiveTaskModalCancel }>Cancel</Button>
                                        <Button className="btn-purple" onClick={ handleUnarchiveTaskModalOk }>Unarchive</Button>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    )
                }

                <Modal className="confirmation-modal" title={ null } footer={ null } visible={ deleteTaskModalVisible } onCancel={ handleDeleteTaskModalCancel } width={ 520 }>
                    <div className="confirmation-modal-header-W">
                        
                    </div>

                    <div className="confirmation-modal-W">
                        <div className="header">
                            Delete Task
                        </div>

                        <div className="body">
                            { 'Are you sure you want to delete this task ?' }

                            <div className="text-right mt-4">
                                <Button className="btn-white mr-2" onClick={ handleDeleteTaskModalCancel }>Cancel</Button>
                                <Button className="btn-red" onClick={ handleDeleteTaskModalOk }>Delete</Button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal className="confirmation-modal" title={ null } footer={ null } visible={ deleteAttachmentModalVisible } onCancel={ handleDeleteAttachmentModalCancel } width={ 520 } zIndex={ 9999 }>
                    <div className="confirmation-modal-header-W">
                        
                    </div>

                    <div className="confirmation-modal-W">
                        <div className="header">
                            Delete Attachment
                        </div>

                        <div className="body">
                            { 'Are you sure you want to delete this attachment ?' }

                            <div className="text-right mt-4">
                                <Button className="btn-white mr-2" onClick={ handleDeleteAttachmentModalCancel }>Cancel</Button>
                                <Button className="btn-red" onClick={ handleDeleteAttachmentModalOk }>Delete</Button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal className="confirmation-modal" title={ null } footer={ null } visible={ deleteCommentModalVisible } onCancel={ handleDeleteCommentModalCancel } width={ 520 }>
                    <div className="confirmation-modal-header-W"></div>

                    <div className="confirmation-modal-W deletion-modal">
                        <div className="header">
                            Delete Comment
                        </div>

                        <div className="body">
                            Are you sure you want to delete this comment?

                            <div className="text-right mt-4">
                                <Button className="btn-white mr-2" onClick={ handleDeleteCommentModalCancel }>Cancel</Button>
                                <Button className="btn-red float-right" onClick={ handleDeleteCommentModalOk }>Permanently Delete</Button>
                            </div>
                        </div>
                    </div>
                </Modal>

                <Modal className="confirmation-modal" title={ null } footer={ null } visible={ deleteReplyModalVisible } onCancel={ handleDeleteReplyModalCancel } width={ 520 }>
                    <div className="confirmation-modal-header-W"></div>

                    <div className="confirmation-modal-W deletion-modal">
                        <div className="header">
                            Delete Reply
                        </div>

                        <div className="body">
                            Are you sure you want to delete this reply?

                            <div className="text-right mt-4">
                                <Button className="btn-white mr-2" onClick={ handleDeleteReplyModalCancel }>Cancel</Button>
                                <Button className="btn-red float-right" onClick={ handleDeleteReplyModalOk }>Permanently Delete</Button>
                            </div>
                        </div>
                    </div>
                </Modal>
            </>
        ) : (
            <div className="aligner-xy" style={{ height: '100vh' }}><Spin /></div>
        )
    )
}

export default ViewTaskDrawer;