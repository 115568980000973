import React, { useState, useEffect, useCallback } from 'react';
import { useImmer } from "use-immer";
import { useSystemContext } from '../../Context/SystemContext';

import ComandanteAPI from '../../utils/ComandanteAPI';

import Helmet from 'react-helmet';
import debounce from 'lodash.debounce';

import MainLayout from '../../Layouts/Main';

import ProductIcon, { ProductIconsSet } from '../../UIElements/ProductIcon/ProductIcon';
import ProjectIcon from '../../UIElements/ProjectIcon/ProjectIcon';
import CustomIcon from '../../UIElements/CustomIcon/CustomIcon';
import { ColorSet } from '../../UIElements/ColorPicker/ColorPicker';

import { Dropdown, Menu, Form, Input, Button, Row, Col, Table, Collapse, Pagination, Empty, Spin } from 'antd';
import { SearchOutlined, CaretRightOutlined } from '@ant-design/icons';

import AddTeamModal from './AddTeamModal';
import EditTeamModal from './EditTeamModal';
import DeleteTeamModal from './DeleteTeamModal';
import ManageTeamMembersModal from './ManageTeamMembersModal';
import ManageProductsModal from './ManageProductsModal';

import './Team.css';

import avatarPlaceholder from '../../assets/images/placeholder-avatar.png';

const Team = () => {
    const { 
        currentUser,
        systemVariables,
        productsDataset,
        projectsGroupedByProductIdDataset
    } = useSystemContext();

    const roleOptions = (systemVariables && systemVariables.user && systemVariables.user.roleOptions ? systemVariables.user.roleOptions : null);

    const [pageQuery, setPageQuery] = useState(null);
    const [searchQuery, setSearchQuery] = useState(null);
    const [teamsDataset, updateTeamsDataset] = useImmer(null);
    const [membersDataset, updateMembersDataset] = useImmer(null);

    const [addTeamModalVisible, setAddTeamModalVisible] = useState(false);
    const [initialValueForEditTeamModal, setInitialValueForEditTeamModal] = useState(null);
    const [teamIdForTeamToBeEdited, setTeamIdForTeamToBeEdited] = useState(null);
    const [teamIdForTeamToBeDeleted, setTeamIdForTeamToBeDeleted] = useState(null);

    const [selectedTeamForManageTeamMembersModal, setSelectedTeamForManageTeamMembersModal] = useState(null);
    const [manageTeamMembersModalVisible, setManageTeamMembersModalVisible] = useState(false);

    const [selectedTeamForManageProductsModal, setSelectedTeamForManageProductsModal] = useState(null);
    const [manageProductsModalVisible, setManageProductsModalVisible] = useState(false);

    const [searchForm] = Form.useForm();

    const getTeams = useCallback(async (page = 1, searchQuery) => {
        ComandanteAPI.HttpGetRequest('teams?paginated=true&page=' + page + (searchQuery ? '&search=' + searchQuery : ''), (err, res) => {
            updateTeamsDataset(teamsDataset => (res && res.data) || null);
        });
    }, [updateTeamsDataset]);

    const fetchMembers = useCallback((searchQuery) => {
        ComandanteAPI.HttpGetRequest('members?paginated=false' + (searchQuery ? '&search=' + searchQuery : ''), (err, res) => {
            updateMembersDataset(membersDataset => (res && res.data) || null);
        });
    }, [updateMembersDataset])

    useEffect(() => {
        getTeams(1, null);
        fetchMembers(null);
    }, [getTeams, fetchMembers]);

    const debounced_getTeams = debounce((page, value) => {
        getTeams(page, value);
    }, 300);

    const membersColumns = [
        {
            title: 'Members',
            rowClassName: 'member',
            render: (text, record) => {
                return (
                    <>
                        <img src={ avatarPlaceholder } alt="Avatar" height={ 18 } /> { record.firstName + ' ' + record.lastName }
                    </>
                )
            },
        },
        {
            title: 'Role',
            rowClassName: 'role',
            width: 180,
            render: (text, record) => (roleOptions && roleOptions[record.role+''] ? roleOptions[record.role+''] : 'N/A'),
        },
    ];
    
    return (
        (currentUser && systemVariables) ? (
            <>
                <Helmet>
                    <title>Team | { process.env.REACT_APP_META_TITLE }</title>
                </Helmet>

                <MainLayout>
                    <div className="team--section-header">
                        <div className="float-right d-xs-none">
                            { /*
                            <div className="d-inline sort-options mt-2">
                                Sort by
                                <Select
                                    labelInValue
                                    defaultValue={{ value: "default" }}
                                    className="d-inline-block select-plain ml-1 pl-1 pr-1"
                                    style={{ minWidth: '80px', color: '#6246EA' }}
                                    suffixIcon={ null }
                                >
                                    <Select.Option value="default">Default</Select.Option>
                                </Select>
                            </div>
                            */ }

                            <Form form={ searchForm } layout="vertical" className="d-inline d-xs-none d-sm-none">
                                <Form.Item className="team--search-teams-input d-inline-block" name="search">
                                    <Input 
                                        style={{ zIndex: 999, maxWidth: '292px' }}
                                        prefix={ <SearchOutlined /> }
                                        placeholder="Search Teams"
                                        onChange={ (event) => {
                                            event.persist(); // Signal to React not to nullify the event object

                                            setSearchQuery(event.target.value);
                                            debounced_getTeams(pageQuery, event.target.value);
                                        }}
                                    />
                                </Form.Item>
                            </Form>

                            <Button
                                className="btn-purple d-inline ml-4"
                                onClick={ () => { setAddTeamModalVisible(true) } }
                            >
                                <CustomIcon iconKey="add" color="#FFFFFF" style={{ margin: '0 4px -1px 0' }} /> <span className="d-xs-none">Create a new team</span>
                            </Button>
                        </div>

                        <h1>Team</h1>
                    </div>

                    {
                        (teamsDataset && teamsDataset.data && Object.values(teamsDataset.data) && Array.isArray(Object.values(teamsDataset.data)) && Object.values(teamsDataset.data).length > 0) ? (
                            <div className="teams-W p-4">
                                <Row gutter={ 20 }>
                                    {
                                        Object.values(teamsDataset.data).map((team) => {
                                            return (
                                                <Col key={ team._id+'' } xs={ 24 } sm={ 24 } md={ 24 } lg={ 12 } xl={ 8 } className="mb-5">
                                                    <div className="team">
                                                        <div className="team--products-and-projects">
                                                            <div className="header">
                                                                { team.name ? team.name : 'Team' }

                                                                <div className="float-right">
                                                                    {
                                                                        (team.productIds && Array.isArray(team.productIds) && team.productIds.length > 0) && (
                                                                            <Button className="btn-white" onClick={ () => { setSelectedTeamForManageProductsModal(team._id+''); setManageProductsModalVisible(true); } }><span className="d-xs-none">Manage</span> Products</Button>
                                                                        )
                                                                    }

                                                                    <Dropdown
                                                                        trigger={ ['click'] }
                                                                        overlay={
                                                                            <Menu style={{ fontWeight: 500, width: '220px' }}>
                                                                                <Menu.Item key="0" onClick={ () => { setTeamIdForTeamToBeEdited(team._id+''); setInitialValueForEditTeamModal(team.name) } }>
                                                                                    <div className="hover-pointer">
                                                                                        <CustomIcon iconKey="edit" style={{ width: '12px', margin: '0 4px 0 2px' }} /> Edit Details
                                                                                    </div>
                                                                                </Menu.Item>

                                                                                <Menu.Item key="1" onClick={ () => { setTeamIdForTeamToBeDeleted(team._id+'') } }>
                                                                                    <div className="hover-pointer">
                                                                                        <CustomIcon iconKey="delete" color="#E45858" style={{ width: '10px', margin: '0 4px -4px 2px' }} /> Delete
                                                                                    </div>
                                                                                </Menu.Item>
                                                                            </Menu>
                                                                        }
                                                                    >
                                                                        <Button className="btn-nothing ml-3 pr-1 pl-1">
                                                                            <CustomIcon iconKey="options-vertical" style={{ margin: '0 0 -2px 0' }} />
                                                                        </Button>
                                                                    </Dropdown>
                                                                </div>
                                                            </div>

                                                            {
                                                                (team.productIds && Array.isArray(team.productIds) && team.productIds.length > 0) ? (
                                                                    <>
                                                                        <span className="d-xs-none d-sm-none" style={{ lineHeight: '32px' }}>Team Products &amp; Projects</span>
                                                                        <div className="helper mb-1">PRODUCT</div>

                                                                        <div style={{ overflowY: 'scroll', maxHeight: '120px' }}>
                                                                            {
                                                                                team.productIds.map(productId => {
                                                                                    return (
                                                                                        productsDataset && productsDataset.data && productsDataset.data[productId] && (
                                                                                            <Collapse key={ productId } className="team--collapsing-products mt-0" defaultActiveKey={ [] } expandIcon={ ({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} /> }>
                                                                                                <Collapse.Panel
                                                                                                    key={ productId+'' }
                                                                                                    header={ <><ProductIcon className="ml-4" style={{ margin: '0 4px -2px 0' }} iconKey={ productsDataset.data[productId].iconKey ? productsDataset.data[productId].iconKey : Object.keys(ProductIconsSet())[0] } color={ productsDataset.data[productId].iconColor ? productsDataset.data[productId].iconColor : ColorSet()[4] } /> { productsDataset.data[productId].name }</> }
                                                                                                >
                                                                                                    <div className="projects-C" style={{ padding: '0 0 0 0' }}>
                                                                                                        {
                                                                                                            (projectsGroupedByProductIdDataset && projectsGroupedByProductIdDataset.data && projectsGroupedByProductIdDataset.data[productId] && Array.isArray(projectsGroupedByProductIdDataset.data[productId]) && projectsGroupedByProductIdDataset.data[productId].length > 0 && projectsGroupedByProductIdDataset.data[productId].map(project => {
                                                                                                                return (
                                                                                                                    team.projectIds && Array.isArray(team.projectIds) && team.projectIds.length > 0 && team.projectIds.includes(project._id+'') && (
                                                                                                                        <div className="project" key={ project._id }>
                                                                                                                            <div className="project-col-1">
                                                                                                                                <div className="project-col-1-inner">
                                                                                                                                    <div className="img-W ml-3">
                                                                                                                                        <ProjectIcon color={ project.iconColor ? project.iconColor : null } />
                                                                                                                                    </div>
                                                                                                                                    <div className="info">
                                                                                                                                        <div className="name">{ project.name }</div>
                                                                                                                                    </div>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    )
                                                                                                                )
                                                                                                            }))
                                                                                                        }
                                                                                                    </div>
                                                                                                </Collapse.Panel>
                                                                                            </Collapse>
                                                                                        )
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </>
                                                                ) : (
                                                                    <div className="empty-helper">
                                                                        <div className="primary">This team has no products to work on yet.</div>
                                                                        <div className="secondary mt-2">Would you like to assign products to it?</div>
                                                                        <div className="text-center">
                                                                            <Button className="btn-purple mt-4" onClick={ () => { setSelectedTeamForManageProductsModal(team._id+''); setManageProductsModalVisible(true); } }>Manage Products</Button>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>

                                                        <div className="team--team-members" style={{ overflow: 'auto' }}>
                                                            {
                                                                (team.memberIds && Array.isArray(team.memberIds) && team.memberIds.length > 0) ? (
                                                                    <>
                                                                        <span style={{ lineHeight: '32px' }}>Team Members</span>

                                                                        <div className="float-right">
                                                                            <Button
                                                                                className="btn-white"
                                                                                onClick={ 
                                                                                    () => {
                                                                                        setSelectedTeamForManageTeamMembersModal(team._id+'');
                                                                                        setManageTeamMembersModalVisible(true);
                                                                                    } 
                                                                                }
                                                                            >
                                                                                <span className="d-xs-none">Manage</span> Members
                                                                            </Button>
                                                                        </div>

                                                                        <Table 
                                                                            rowKey="_id"
                                                                            rowClassName={ (record, index) => (team.memberIds && Array.isArray(team.memberIds) && team.memberIds.length > 0 && team.memberIds.includes(record._id) ? '' : 'd-none') }
                                                                            className="members-T members-to-add-T mt-4"
                                                                            columns={ membersColumns }
                                                                            dataSource={ membersDataset && membersDataset.data ? Object.values(membersDataset.data) : [] }
                                                                            pagination={ false }
                                                                        />
                                                                    </>
                                                                ) : (
                                                                    <div className="empty-helper" style={{ marginTop: '16px' }}>
                                                                        <div className="primary">This team has no members yet.</div>
                                                                        <div className="secondary mt-2">Would you like to add members for this team?</div>
                                                                        <div className="text-center">
                                                                            <Button
                                                                                className="btn-purple mt-4"
                                                                                onClick={ 
                                                                                    () => {
                                                                                        setSelectedTeamForManageTeamMembersModal(team._id+'');
                                                                                        setManageTeamMembersModalVisible(true);
                                                                                    } 
                                                                                }
                                                                            >Manage Members</Button>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </Col>
                                            )
                                        })
                                    }
                                </Row>

                                { 
                                    teamsDataset && teamsDataset.pagination && (
                                        <div className="float-right pb-5">
                                            <Pagination
                                                current={ teamsDataset.pagination.current }
                                                pageSize={ teamsDataset.pagination.pageSize }
                                                total={ teamsDataset.pagination.total }
                                                onChange={
                                                    (page, pageSize) => {
                                                        setPageQuery(page);

                                                        debounced_getTeams(page, searchQuery);
                                                    }
                                                }
                                            />
                                        </div>
                                    ) 
                                }
                            </div>
                        ) : (
                            <div className="teams-W p-4">
                                <div className="team text-center" style={{ padding: '60px 40px 60px 40px' }}>
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ margin: '0 auto 0 auto' }} />
                                </div>
                            </div>
                        )
                    }

                    { addTeamModalVisible && <AddTeamModal setVisible={ setAddTeamModalVisible } refreshTeams={ getTeams } /> }
                    { teamIdForTeamToBeEdited && <EditTeamModal teamId={ teamIdForTeamToBeEdited } setTeamId={ setTeamIdForTeamToBeEdited } initialValue={ initialValueForEditTeamModal } refreshTeams={ getTeams } /> }
                    { teamIdForTeamToBeDeleted && <DeleteTeamModal teamId={ teamIdForTeamToBeDeleted } setTeamId={ setTeamIdForTeamToBeDeleted } refreshTeams={ getTeams } /> }
                    { manageTeamMembersModalVisible && <ManageTeamMembersModal setVisible={ setManageTeamMembersModalVisible } refreshTeams={ getTeams } teamId={ selectedTeamForManageTeamMembersModal } /> }
                    { manageProductsModalVisible && <ManageProductsModal setVisible={ setManageProductsModalVisible } refreshTeams={ getTeams } teamId={ selectedTeamForManageProductsModal } /> }
                </MainLayout>
            </>
        ) : (
            <div className="aligner-xy" style={{ height: '100vh' }}><Spin /></div>
        )
    )
}

export default Team;