import React, { useEffect, useCallback } from 'react';

import { useImmer } from "use-immer";

import { useSystemContext } from '../../Context/SystemContext';

import ComandanteAPI from '../../utils/ComandanteAPI';

import ProductIcon, { ProductIconsSet } from '../../UIElements/ProductIcon/ProductIcon';
import ProjectIcon from '../../UIElements/ProjectIcon/ProjectIcon';
import CustomIcon from '../../UIElements/CustomIcon/CustomIcon';
import { ColorSet } from '../../UIElements/ColorPicker/ColorPicker';

import { Modal, Button, Collapse, Empty, message } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';
  
import './Team.css';
import removeIcon from '../../assets/images/icon-remove.png';

const ManageProductsModal = ({ teamId, setVisible, refreshTeams, ...restProps }) => {
    const {
        productsDataset,
        projectsGroupedByProductIdDataset
    } = useSystemContext();


    const [teamDataset, updateTeamDataset] = useImmer(null);

    const [productIds, updateProductIds] = useImmer(null);
    const [productsToAdd, updateProductsToAdd] = useImmer(null);
    const [projectsToAdd, updateProjectsToAdd] = useImmer(null);

    const getTeam = useCallback(() => {
        ComandanteAPI.HttpGetRequest('teams/' + teamId, (err, res) => {
            updateTeamDataset(teamsDataset => (res && res.data) || null);
        });
    }, [teamId, updateTeamDataset]);

    useEffect(() => {
        getTeam();
    }, [getTeam]);

    useEffect(() => {
        if(teamDataset && teamDataset.team) {
            if(teamDataset.team.productIds && Array.isArray(teamDataset.team.productIds) && teamDataset.team.productIds.length > 0) {
                let xProductsToAdd = [];
                for(let productId of teamDataset.team.productIds) {
                    xProductsToAdd.push(productId);
                }
                updateProductsToAdd(productsToAdd => {
                    if(productsToAdd && Array.isArray(productsToAdd) && productsToAdd.length > 0) {
                        return [...productsToAdd, ...xProductsToAdd];
                    } else {
                        return [...xProductsToAdd];
                    }
                });
            }

            if(teamDataset.team.projectIds && Array.isArray(teamDataset.team.projectIds) && teamDataset.team.projectIds.length > 0) {
                let xProjectsToAdd = [];
                for(let projectId of teamDataset.team.projectIds) {
                    xProjectsToAdd.push(projectId);
                }
                updateProjectsToAdd(projectsToAdd => {
                    if(projectsToAdd && Array.isArray(projectsToAdd) && projectsToAdd.length > 0) {
                        return [...projectsToAdd, ...xProjectsToAdd];
                    } else {
                        return [...xProjectsToAdd];
                    }
                });
            }
        }
    }, [teamDataset, updateProductsToAdd, updateProjectsToAdd])

    useEffect(() => {
        if(productsDataset && productsDataset.data && Array.isArray(Object.values(productsDataset.data)) && Object.values(productsDataset.data).length > 0) {
            let xProductIds = [];
            for(let product of Object.values(productsDataset.data)) {
                xProductIds.push(product._id+'');
            }

            updateProductIds(productIds => {
                return [ ...xProductIds ];
            });
        }
    }, [productsDataset, updateProductIds]);

    const handleManageProductsModalCancel = () => { setVisible(false) };
    const handleManageProductsModalNext = () => {
        ComandanteAPI.HttpPostRequest('teams/' + teamId + '/products/manage', { productIds: (productsToAdd && Array.isArray(productsToAdd) && productsToAdd.length > 0 ? [ ...productsToAdd ] : []), projectIds: (projectsToAdd && Array.isArray(projectsToAdd) && projectsToAdd.length > 0 ? [ ...projectsToAdd ] : []) }, (err, res) => {
            if(err || !res) {
                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.status === 200) {
                setVisible(false);
                refreshTeams();
                return;
            }
        });
    }

    return (
        <Modal title={ null } footer={ null } visible={ true } onCancel={ handleManageProductsModalCancel } width={ 640 }>
            { 
                <>
                    <div className="modal-header-W">
                        <div style={{ fontSize: '21px' }}>Which products should this team be in?</div>
                    </div>

                    <div className="modal-body-W" style={{ padding: '30px 30px' }}>
                        <div style={{ fontWeight: 500, fontSize: '12px', color: '#8C8C8C' }}>PRODUCTS FOUND</div>
                        <div className="mt-3" style={{ maxHeight: '190px', overflowY: 'scroll' }}>
                            {
                                (productsDataset && productsDataset.data && Array.isArray(Object.values(productsDataset.data))) ? (
                                    Object.values(productsDataset.data).map(product => {
                                        return (
                                            <Collapse className="manage-products-modal--collapsing-products found mt-0" defaultActiveKey={ [] } expandIcon={ ({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} /> }>
                                                <Collapse.Panel
                                                    key="tasks-due-soon mt-2"
                                                    header={
                                                        <>
                                                            {
                                                                (!productsToAdd || !Array.isArray(productsToAdd) || !productsToAdd.includes(product._id+'')) && (
                                                                    <div className="float-right">
                                                                        <div
                                                                            onClick={
                                                                                (e) => {
                                                                                    e.stopPropagation();
    
                                                                                    updateProductsToAdd(productsToAdd => {
                                                                                        if(!productsToAdd || !Array.isArray(productsToAdd) || productsToAdd.length <= 0) productsToAdd = [];
                                                                                        if(!productsToAdd.includes(product._id)) productsToAdd.push(product._id);
    
                                                                                        return productsToAdd;
                                                                                    });
                                                                                } 
                                                                            }
                                                                        >
                                                                            <CustomIcon
                                                                                className="mr-1"
                                                                                style={{ margin: '0 0 -4px 0', height: '16px' }}
                                                                                iconKey="add-outlined"
                                                                                color="#6246EA"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
    
                                                            <ProductIcon className="" style={{ margin: '0 4px -1px 0' }} iconKey={ product.iconKey ? product.iconKey : Object.keys(ProductIconsSet())[0] } color={ product.iconColor ? product.iconColor : ColorSet()[4] } />
                                                            { product.name }
                                                        </>
                                                    }
                                                >
                                                    <div className="projects-C">
                                                        {
                                                            (projectsGroupedByProductIdDataset && projectsGroupedByProductIdDataset.data && projectsGroupedByProductIdDataset.data[product._id] && Array.isArray(projectsGroupedByProductIdDataset.data[product._id]) && projectsGroupedByProductIdDataset.data[product._id].length > 0 && projectsGroupedByProductIdDataset.data[product._id].map(project => {
                                                                return (
                                                                    <div className="project p-2" key={ project._id }>
                                                                        <div className="project-col-1">
                                                                            <div className="project-col-1-inner">
                                                                                <div className="img-W ml-2">
                                                                                    <ProjectIcon color={ project.iconColor ? project.iconColor : null } />
                                                                                </div>
                                                                                <div className="info">
                                                                                    <div className="name">{ project.name }</div>
                                                                                </div>
    
                                                                                <div className="project-col-2">
                                                                                    {
                                                                                        (!projectsToAdd || !Array.isArray(projectsToAdd) || !projectsToAdd.includes(project._id+'')) && (
                                                                                            <span
                                                                                                className="icon ml-2 mr-2"
                                                                                                onClick={
                                                                                                    (e) => {
                                                                                                        e.stopPropagation();
    
                                                                                                        updateProductsToAdd(productsToAdd => {
                                                                                                            if(!productsToAdd || !Array.isArray(productsToAdd) || productsToAdd.length <= 0) productsToAdd = [];
                                                                                                            if(!productsToAdd.includes(product._id)) productsToAdd.push(product._id);
                            
                                                                                                            return productsToAdd;
                                                                                                        });
    
                                                                                                        updateProjectsToAdd(projectsToAdd => {
                                                                                                            if(!projectsToAdd || !Array.isArray(projectsToAdd) || projectsToAdd.length <= 0) projectsToAdd = [];
                                                                                                            if(!projectsToAdd.includes(project._id)) projectsToAdd.push(project._id);
                            
                                                                                                            return projectsToAdd;
                                                                                                        });
                                                                                                    } 
                                                                                                }
                                                                                            >
                                                                                                <CustomIcon className="mr-1" iconKey="add-outlined" color="#6246EA" style={{ margin: '0 0 -4px 0', height: '16px' }} />
                                                                                            </span>
                                                                                        )
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }))
                                                        }
                                                    </div>
                                                </Collapse.Panel>
                                            </Collapse>
                                        )
                                    })
                                ) : (
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                )
                            }
                        </div>
                    </div>

                    <div style={{ background: '#FFFFFE', boxShadow: 'inset 0px 1px 0px #DFE0E1', padding: '30px 30px 30px 30px' }}>
                        <div style={{ fontWeight: 500, fontSize: '12px', color: '#8C8C8C' }}>TEAM'S PRODUCTS</div>
                        <div className="mt-3" style={{ maxHeight: '190px', overflowY: 'scroll' }}>
                            {
                                (productsToAdd && Array.isArray(productsToAdd) && productsToAdd.length > 0) ? (
                                    productsToAdd.map(productId => {
                                        return (
                                            productsDataset && productsDataset.data && productsDataset.data[productId] && (
                                                <Collapse className="manage-products-modal--collapsing-products added mt-0" defaultActiveKey={ productIds } expandIcon={ ({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} /> }>
                                                    <Collapse.Panel
                                                        key={ productId+'' }
                                                        header={ 
                                                            <>
                                                                <div className="float-right">
                                                                    <img 
                                                                        className="mr-2" 
                                                                        style={{ height: '16px' }}
                                                                        src={ removeIcon } alt="Icon" 
                                                                        onClick={
                                                                            () => {
                                                                                updateProductsToAdd(productsToAdd => {
                                                                                    if(productsToAdd && Array.isArray(productsToAdd) && productsToAdd.length > 0) {
                                                                                        let filteredItems = productsToAdd.filter(item => item !== productId+'');
                                                                                        return filteredItems;
                                                                                    }
                                                                                })

                                                                                updateProjectsToAdd(projectsToAdd => {
                                                                                    if(projectsToAdd && Array.isArray(projectsToAdd) && projectsToAdd.length > 0) {
                                                                                        let filteredItems = projectsToAdd.filter(item => (projectsGroupedByProductIdDataset && projectsGroupedByProductIdDataset.data && projectsGroupedByProductIdDataset.data[productId+''] && Array.isArray(projectsGroupedByProductIdDataset.data[productId+'']) && projectsGroupedByProductIdDataset.data[productId+''].length > 0 && projectsGroupedByProductIdDataset.data[productId+''].includes(item)));
                                                                                        return filteredItems;
                                                                                    }
                                                                                })
                                                                            }
                                                                        }
                                                                    />
                                                                </div>

                                                                <ProductIcon className="" style={{ margin: '0 4px -1px 0' }} iconKey={ productsDataset.data[productId].iconKey ? productsDataset.data[productId].iconKey : Object.keys(ProductIconsSet())[0] } color={ productsDataset.data[productId].iconColor ? productsDataset.data[productId].iconColor : ColorSet()[4] } /> 
                                                                { productsDataset.data[productId].name }
                                                            </> 
                                                        }
                                                    >
                                                        <div className="projects-C">
                                                            {
                                                                (projectsGroupedByProductIdDataset && projectsGroupedByProductIdDataset.data && projectsGroupedByProductIdDataset.data[productId] && Array.isArray(projectsGroupedByProductIdDataset.data[productId]) && projectsGroupedByProductIdDataset.data[productId].length > 0 && projectsGroupedByProductIdDataset.data[productId].map(project => {
                                                                    return (
                                                                        projectsToAdd && Array.isArray(projectsToAdd) && projectsToAdd.length > 0 && projectsToAdd.includes(project._id+'') && (
                                                                            <div className="project p-2" key={ project._id }>
                                                                                <div className="project-col-1">
                                                                                    <div className="project-col-1-inner">
                                                                                        <div className="img-W ml-2">
                                                                                            <ProjectIcon color={ project.iconColor ? project.iconColor : null } />
                                                                                        </div>
                                                                                        <div className="info">
                                                                                            <div className="name">{ project.name }</div>
                                                                                        </div>
                                                                                        <span className="icon ml-2 mr-2">
                                                                                            <div className="float-right">
                                                                                                <img 
                                                                                                    className="mr-2" 
                                                                                                    style={{ height: '16px' }}
                                                                                                    src={ removeIcon } alt="Icon" 
                                                                                                    onClick={
                                                                                                        () => {
                                                                                                            updateProjectsToAdd(projectsToAdd => {
                                                                                                                if(projectsToAdd && Array.isArray(projectsToAdd) && projectsToAdd.length > 0) {
                                                                                                                    let filteredItems = projectsToAdd.filter(item => item !== project._id+'');
                                                                                                                    return filteredItems;
                                                                                                                }
                                                                                                            })
                                                                                                        }
                                                                                                    }
                                                                                                />
                                                                                            </div>
                                                                                        </span>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    )
                                                                }))
                                                            }
                                                        </div>
                                                    </Collapse.Panel>
                                                </Collapse>
                                            )
                                        )
                                    })
                                ) : (
                                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                )
                            }
                        </div>
                    </div>
                    
                    <div className="modal-footer-W" style={{ background: '#FFFFFE' }}>
                        <div className="text-right">
                            <Button className="btn-purple" onClick={ () => { handleManageProductsModalNext() } }>Done</Button>
                        </div>
                    </div>
                </>
            }
        </Modal>
    )
}

export default ManageProductsModal;