import React from 'react';

import moment from 'moment';

import CustomIcon from '../UIElements/CustomIcon/CustomIcon';

const CustomDateFormat = ({ displayCalendarIcon, date }) => {
    let today = moment();
    let yesterday = moment().subtract(1, 'day');
    let tomorrow = moment().add(1, 'day');

    let color = '#6246EA';
    if(moment(date)) {
        if(moment(date).isSame(today, 'day')) color = '#E45858';
        if(moment(date).isSame(yesterday, 'day')) color = '#E88225';
        if(moment(date).isSame(tomorrow, 'day')) color = '#E88225';
    }

    return (
        <>
            { displayCalendarIcon ? <CustomIcon iconKey="date-time" color={ color } style={{ margin: '0 0 -2px 0' }} />  : null }

            <span style={{ fontSize: '12px', color: color+'' }}>
                {
                    moment(date).calendar(null, {
                        lastDay : ' [Yesterday]',
                        sameDay : ' [Today]',
                        nextDay : ' [Tomorrow]',
                        lastWeek : ' [Last] dddd',
                        nextWeek : ' [Next] dddd',
                        sameElse : ' DD/MM'
                    })
                }
            </span>
        </>
    );
}

export default CustomDateFormat;