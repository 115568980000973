import React, { useState, useEffect, useCallback } from 'react';
import { useImmer } from 'use-immer';
import { useSystemContext } from '../../Context/SystemContext';
import { useParams, useHistory } from 'react-router-dom';

import Helmet from 'react-helmet';

import ComandanteAPI from '../../utils/ComandanteAPI';

import { DragDropContext } from "react-beautiful-dnd";

import MainLayout from "../../Layouts/Main";

import { ColorSet } from '../../UIElements/ColorPicker/ColorPicker';
import CustomIcon from '../../UIElements/CustomIcon/CustomIcon';

import { Tabs, Select, Button, Spin } from 'antd';
import { LockFilled } from '@ant-design/icons';

import Task from './Draggable/Task';
import Stage from './Droppable/Stage';
import CreateTaskDrawer from '../Task/Drawers/CreateTaskDrawer';
import ViewTaskDrawer from '../Task/Drawers/ViewTaskDrawer';

import './Project.css';

import Error from '../Error/Error';

const Board = () => {
    const { 
        currentUser,
        membersDataset,
        systemVariables
    } = useSystemContext();

    const roleOptions = (systemVariables && systemVariables.user && systemVariables.user.roleOptions ? systemVariables.user.roleOptions : null);

    const { productId, projectId, taskId } = useParams();
    const history = useHistory();

    const [productDataset, setProductDataset] = useState(null);
    const [projectDataset, setProjectDataset] = useState(null);
    const [tasksByStageIdDataset, updateTasksByStageIdDataset] = useImmer(null);
    const [tasksSortBy, setTasksSortBy] = useState('custom');

    const [createTaskDrawerStageId, setCreateTaskDrawerStageId] = useState(false);
    const [createTaskDrawerIsVisible, setCreateTaskDrawerIsVisible] = useState(false);

    const [taskIdForViewTaskDrawer, setTaskIdForViewTaskDrawer] = useState(null);
    const [newStageValueForViewTaskDrawer, setNewStageValueForViewTaskDrawer] = useState(null);

    const [columns, setColumns] = useState(null);

    const [pageError, setPageError] = useState(null);

    const fetchProduct = useCallback(() => {
        ComandanteAPI.HttpGetRequest('products/' + productId + '/get', (err, res) => {
            if(err) {
                setPageError(<Error status={ (err.status ? err.status : null) } />);
                return;
            }

            if(!res || !res.data) {
                setPageError(<Error status={ 404 } />);
                return;
            }

            setProductDataset((res && res.data) || null);
        });
    }, [productId]);

    const fetchProject = useCallback(() => {
        ComandanteAPI.HttpGetRequest('products/' + productId + '/projects/' + projectId + '/get', (err, res) => {
            if(err) {
                setPageError(<Error status={ (err.status ? err.status : null) } />);
                return;
            }

            if(!res || !res.data) {
                setPageError(<Error status={ 404 } />);
                return;
            }

            setProjectDataset((res && res.data) || null);
        });
    }, [productId, projectId]);

    const fetchTasksByStageId = useCallback((sortBy = null) => {
        ComandanteAPI.HttpGetRequest('products/' + productId + '/projects/' + projectId + '/tasks-by-stage-id' + (sortBy && sortBy !== 'custom' ? '?sort='+sortBy : ''), (err, res) => {
            if(err) {
                setPageError(<Error status={ (err.status ? err.status : null) } />);
                return;
            }

            if(!res || !res.data) {
                setPageError(<Error status={ 404 } />);
                return;
            }

            updateTasksByStageIdDataset(tasksByStageIdDataset => (res && res.data) || null);
        });
    }, [productId, projectId, updateTasksByStageIdDataset]);

    useEffect(() => {
        if(window.innerWidth <= 575) {
            history.push('/products/' + productId + '/projects/' + projectId + '/list');
        }
    })

    useEffect(() => {
        fetchProduct();
        fetchProject();
        fetchTasksByStageId(null);
    }, [fetchProduct, fetchProject, fetchTasksByStageId]);

    useEffect(() => {
        fetchTasksByStageId(tasksSortBy);
    }, [fetchTasksByStageId, tasksSortBy]);

    useEffect(() => {
        setCreateTaskDrawerStageId(false);
        setCreateTaskDrawerIsVisible(false);
        setTaskIdForViewTaskDrawer(null);
    }, [productId, projectId]);

    useEffect(() => {
        let xColumns = {};

        if(projectDataset && projectDataset.project && projectDataset.project.stages) {
            if(Array.isArray(projectDataset.project.stages) && projectDataset.project.stages.length > 0) {
                for(let stage of projectDataset.project.stages) {
                    let stageLabel = (stage.label ? stage.label : null);
                    let stageColor = (stage.color ? stage.color : null);

                    xColumns = {
                        ...xColumns,
                        [stage._id]: {
                            name: stageLabel,
                            color: stageColor,
                            items: (tasksByStageIdDataset && tasksByStageIdDataset.data && tasksByStageIdDataset.data[stage._id+''] && Array.isArray(tasksByStageIdDataset.data[stage._id+'']) && tasksByStageIdDataset.data[stage._id+''].length > 0 ? tasksByStageIdDataset.data[stage._id+''] : []),
                            locked: (stage.locked ? true : false)
                        }
                    }
                }
            }
        }

        setColumns(xColumns);
    }, [projectDataset, tasksByStageIdDataset]);

    useEffect(() => {
        if(taskId) {
            setTaskIdForViewTaskDrawer(taskId);
        }
    }, [taskId]);

    const onTabChange = (activeKey) => {
        history.push('/products/' + productId + '/projects/' + projectId + '/' + activeKey)
    }

    const onDragEnd = (result, columns, setColumns) => {
        if (!result.destination) return;
        const { source, destination } = result;

        if (source.droppableId !== destination.droppableId) {
            const sourceColumn = columns[source.droppableId];
            const destColumn = columns[destination.droppableId];
            const sourceItems = [...sourceColumn.items];
            const destItems = [...destColumn.items];
            const [removed] = sourceItems.splice(source.index, 1);
            destItems.splice(destination.index, 0, removed);
    
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...sourceColumn,
                    items: sourceItems
                },
                [destination.droppableId]: {
                    ...destColumn,
                    items: destItems
                }
            });
        } else {
            const column = columns[source.droppableId];
            const copiedItems = [...column.items];
            if(source.index === destination.index) return;
            const [removed] = copiedItems.splice(source.index, 1);
            copiedItems.splice(destination.index, 0, removed);
            
            setColumns({
                ...columns,
                [source.droppableId]: {
                    ...column,
                    items: copiedItems
                }
            });
        }

        ComandanteAPI.HttpPostRequest('products/' + productId + '/projects/' + projectId + '/tasks/' + result.draggableId + '/move', { source: { stageId: source.droppableId, position: source.index }, destination: { stageId: destination.droppableId, position: destination.index } }, (err, res) => {
            if(res && res.data && res.data.result === true) {
                fetchTasksByStageId();
                setNewStageValueForViewTaskDrawer(destination.droppableId+'');
            }
        });
    };

    const openCreateTaskDrawer = (stageId = null) => {
        setCreateTaskDrawerStageId(stageId);
        setCreateTaskDrawerIsVisible(true);
        setTaskIdForViewTaskDrawer(null);
    }

    if(pageError) return pageError;

    return (
        (projectDataset && projectDataset.project) ? (
            <>
                <Helmet>
                    <title>{ (projectDataset.project.name ? projectDataset.project.name : 'Project') + ' | Projects | ' + (productDataset && productDataset.product.name ? productDataset.product.name : 'Product') + ' | Products | ' + process.env.REACT_APP_META_TITLE }</title>
                </Helmet>

                <MainLayout>
                    <>
                        <div className="project--section-header">
                            <div className="float-right">
                                <div className="sort-options">
                                    Sort by 
                                    <Select
                                        labelInValue
                                        defaultValue={{ value: tasksSortBy }}
                                        className="d-inline-block project--sort-tasks-select-box mb-2 ml-1 pl-1 pr-1"
                                        style={{ minWidth: '125px' }}
                                        onChange={ (selected) => {
                                            setTasksSortBy((selected && selected.value) || null);
                                        } }
                                    >
                                        <Select.Option value="custom">Custom</Select.Option>
                                        <Select.Option value="dueDate">Due Date</Select.Option>
                                        <Select.Option value="createdAt">Creation Date</Select.Option>
                                    </Select>
                                </div>
                            </div>

                            <div>
                                <h1 className="d-inline-block">{ (projectDataset.project.name ? projectDataset.project.name : 'Project') }</h1>

                                <Tabs className="project--header-tabs d-inline-block" defaultActiveKey="board" onChange={ onTabChange }>
                                    <Tabs.TabPane tab="Board" key="board"></Tabs.TabPane>
                                    <Tabs.TabPane tab="List" key="list"></Tabs.TabPane>
                                    <Tabs.TabPane tab="Archived" key="archived"></Tabs.TabPane>
                                    <Tabs.TabPane tab="Report" key="report"></Tabs.TabPane>
                                </Tabs>
                            </div>
                        </div>

                        <div className="project--section-body pt-4 pb-4" style={{ margin: '72px 0 0 0' }}>
                            <DragDropContext onDragEnd={ result => onDragEnd(result, columns, setColumns) }>
                                <div className="stages-C board">
                                    {
                                        columns && Object.entries(columns).map(([columnId, column], index) => {
                                            let stageId = columnId;
                                            let stageLabel = (column.name ? column.name : 'Unknown');
                                            let stageColor = (column.color ? column.color : ColorSet()[index % 6]);

                                            return (
                                                <div key={ index } className="stage-W ml-3 mr-3"> 
                                                    <div className="stage-column" style={{ background: stageColor + '0D' }}>
                                                        <div className="float-right">{ column.locked ? <LockFilled style={{ fontSize: '14px' }} /> : null }</div>

                                                        <div className="stage-label" style={{ color: stageColor }}>{ stageLabel ? stageLabel : '' }</div>

                                                        {
                                                            !(currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Observer', 'Contributor'].includes(roleOptions[currentUser.role+''])) && (
                                                                <Button
                                                                    className="stage-action btn-nothing d-block"
                                                                    onClick={ () => { openCreateTaskDrawer(stageId+'') } }
                                                                    disabled={ (currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Observer', 'Contributor', 'Editor', 'Project Manager'].includes(roleOptions[currentUser.role+''])) && column.locked ? true : false }
                                                                >
                                                                    <CustomIcon
                                                                        iconKey="add"
                                                                        color={ (currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Observer', 'Contributor', 'Editor', 'Project Manager'].includes(roleOptions[currentUser.role+''])) && column.locked ? '#8C8C8C' : '#6246EA' }
                                                                        className="mr-1"
                                                                        style={{ height: '10px' }} 
                                                                    /> Create new task
                                                                </Button>
                                                            )
                                                        }

                                                        <Stage stageId={ stageId } view="board" locked={ column.locked ? true : false }>
                                                            {
                                                                column && column.items && Array.isArray(column.items) && column.items.length > 0 && column.items.map((task, index) => {
                                                                    return (
                                                                        <Task
                                                                            key={ task._id }
                                                                            view="board"
                                                                            index={ index }
                                                                            isDragDisabled={ (tasksSortBy && tasksSortBy !== 'custom') }
                                                                            _id={ task._id }
                                                                            organizationId={ currentUser && currentUser.organizationId }
                                                                            productId={ productId }
                                                                            projectId={ projectId }
                                                                            linkTo={ '/products/' + productId + '/projects/' + projectId + '/tasks/' + task._id } 
                                                                            product={ productDataset && productDataset.product ? productDataset.product.name : '' }
                                                                            project={ projectDataset && projectDataset.project ? projectDataset.project.name : '' }
                                                                            title={ task.title }
                                                                            attachments={ task.attachments ? task.attachments : [] }
                                                                            assignee={ task.assignedTo && membersDataset && membersDataset.data && membersDataset.data[task.assignedTo] ? membersDataset.data[task.assignedTo].firstName + ' ' + membersDataset.data[task.assignedTo].lastName : null }
                                                                            priority={ task.priority }
                                                                            dueDate={ task.dueDate } 
                                                                            status={ task.status }
                                                                            stage={ task.stage }
                                                                            setTaskIdForViewTaskDrawer={ setTaskIdForViewTaskDrawer }
                                                                        />
                                                                    );
                                                                })
                                                            }
                                                        </Stage>
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </DragDropContext>
                        </div>

                        {
                            (createTaskDrawerIsVisible) && (<CreateTaskDrawer
                                key={ 'createTaskDrawer' }
                                onClose={ () => { setCreateTaskDrawerIsVisible(false); } }
                                productId={ productId }
                                projectId={ projectId }
                                stageId={ createTaskDrawerStageId }
                                refreshTasks={ fetchTasksByStageId }
                            />)
                        }

                        { 
                            (taskIdForViewTaskDrawer) && (<ViewTaskDrawer
                                key={ 'viewTaskDrawer--taskId-' + taskIdForViewTaskDrawer }
                                onClose={ () => { 
                                    setTaskIdForViewTaskDrawer(null); 
                                    if(taskId) history.push('/products/' + productId + '/projects/' + projectId);
                                } }
                                productId={ productId }
                                projectId={ projectId }
                                taskId={ taskIdForViewTaskDrawer }
                                refreshTasks={ fetchTasksByStageId }
                                stageValue={ newStageValueForViewTaskDrawer ? newStageValueForViewTaskDrawer : null }
                            />)
                        }
                    </>
                </MainLayout>
            </>
        ) : (
            <div className="aligner-xy" style={{ height: '100vh' }}><Spin /></div>
        )
    )
}

export default Board;
