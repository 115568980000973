import { useState, useEffect, useCallback, createContext, useContext } from 'react';

import { useImmer } from "use-immer";

import ComandanteAPI from '../utils/ComandanteAPI';

export const SystemContext = createContext();

export const SystemContextProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [systemVariables, setSystemVariables] = useState(null);

    const [membersDataset, updateMembersDataset] = useImmer(null);
    const [productsDataset, updateProductsDataset] = useImmer(null);
    const [projectsDataset, updateProjectsDataset] = useImmer(null);
    const [projectsGroupedByProductIdDataset, updateProjectsGroupedByProductIdDataset] = useImmer(null);
    const [notificationsCount, setNotificationsCount] = useState(0);

    const fetchCurrentUser = useCallback(async () => {
        if(localStorage.getItem('token')) {
            ComandanteAPI.HttpGetRequest('auth/user', (err, res) => {
                if(err || !res) {
                    localStorage.removeItem('token');
                    window.location.href = '/login';
                    return;
                }
                
                if(res.data) {
                    setCurrentUser(res.data);
                }
            });
        }
    }, []);

	useEffect(() => {
		fetchCurrentUser();
	}, [fetchCurrentUser]);

    const fetchSystemVariables = useCallback(async () => {
        ComandanteAPI.HttpGetRequest('system/variables', (err, res) => {
            if(err || !res) {
                alert('System variables could not be fetched!');
                return;
            }

            if(res.data) {
                setSystemVariables(res.data);
            }
        });
    }, []);

	useEffect(() => {
		if(currentUser) {
			fetchSystemVariables();
		}
	}, [fetchSystemVariables, currentUser]);

    const fetchMembers = useCallback(async () => {
        ComandanteAPI.HttpGetRequest('members?paginated=false', (err, res) => {
            if(res && res.data) updateMembersDataset(membersDataset => res.data);
        });
    }, [updateMembersDataset]);

    useEffect(() => {
        if(currentUser) {
            fetchMembers();
        }
	}, [fetchMembers, currentUser]);
    
    const fetchProducts = useCallback(async () => {
        ComandanteAPI.HttpGetRequest('products?paginated=false', (err, res) => {
            if(res && res.data) updateProductsDataset(productsDataset => res.data);
        });
    }, [updateProductsDataset]);

    useEffect(() => {
        if(currentUser) {
            fetchProducts();
        }
	}, [fetchProducts, currentUser]);

    const fetchProjects = useCallback(async () => {
        ComandanteAPI.HttpGetRequest('projects?paginated=false', (err, res) => {
            if(res && res.data) updateProjectsDataset(projectsDataset => res.data);
        });
    }, [updateProjectsDataset]);

    useEffect(() => {
        if(currentUser) {
            fetchProjects();
        }
	}, [fetchProjects, currentUser]);

    const fetchProjectsGroupedByProductId = useCallback(async () => {
        ComandanteAPI.HttpGetRequest('projects-grouped-by-product-id?paginated=false', (err, res) => {
            if(res && res.data) updateProjectsGroupedByProductIdDataset(projectsGroupedByProductIdDataset => res.data);
        });
    }, [updateProjectsGroupedByProductIdDataset]);

	useEffect(() => {
        if(currentUser) {
            fetchProjectsGroupedByProductId();
        }
	}, [fetchProjectsGroupedByProductId, currentUser]);

    const fetchNotificationsCount = useCallback(async () => {
        ComandanteAPI.HttpGetRequest('notifications/count', (err, res) => {
            setNotificationsCount((res && res.data && res.data.count) || 0);
        });
    }, [setNotificationsCount]);

    useEffect(() => {
        if(currentUser) {
            fetchNotificationsCount();
        }
    }, [fetchNotificationsCount, currentUser]);

    return (
        <SystemContext.Provider value={{ 
            currentUser, fetchCurrentUser,
            systemVariables, 
            membersDataset, updateMembersDataset, fetchMembers,
            productsDataset, updateProductsDataset, fetchProducts, 
            projectsDataset, updateProjectsDataset, fetchProjects,
            projectsGroupedByProductIdDataset, updateProjectsGroupedByProductIdDataset, fetchProjectsGroupedByProductId,
            notificationsCount, setNotificationsCount, fetchNotificationsCount
        }}>
            { children }
        </SystemContext.Provider>
    );
}

export const useSystemContext = () => useContext(SystemContext);