import React from 'react';

import MainLayout from '../../Layouts/Main';

import BadRequest from './assets/images/400.png';
import Unauthorized from './assets/images/401.png';
import Forbidden from './assets/images/403.png';
import FileNotFound from './assets/images/404.png';
import InternalServerError from './assets/images/500.png';

import './Error.css';

const Error = (props) => {
    return (
        <MainLayout>
            <div className="aligner-xy mt-5" style={{ height: '100%' }}>
                <div>
                    {
                        (parseInt(props.status) === 400) ? (
                            <img className="img-error w-100" src={ BadRequest } alt="Bad Request" />
                        ) : (parseInt(props.status) === 401) ? (
                            <img className="img-error w-100" src={ Unauthorized } alt="Unauthorized" />
                        ) : (parseInt(props.status) === 403) ? (
                            <img className="img-error w-100" src={ Forbidden } alt="Forbidden" />
                        ) : (parseInt(props.status) === 404) ? (
                            <img className="img-error w-100" src={ FileNotFound } alt="File Not Found" />
                        ) : (
                            <img className="img-error w-100" src={ InternalServerError } alt="Internal Server Error" />
                        )
                    }

                    <div className="alert alert-purple text-center">
                        Oops!
                    </div>

                    <div className="message text-center">
                        {
                            (parseInt(props.status) === 400) ? (
                                'Bad Request.'
                            ) : (parseInt(props.status) === 401) ? (
                                'You are not authorized to view this page.'
                            ) : (parseInt(props.status) === 403) ? (
                                'You are not authorized to view this page.'
                            ) : (parseInt(props.status) === 404) ? (
                                'We cannot find the page you are looking for.'
                            ) : (
                                'Internal Server Error.'
                            )
                        }
                    </div>
                </div>
            </div>
        </MainLayout>
    );
}

export default Error;