import React, { useState, useEffect } from 'react';
import { useSystemContext } from '../../../Context/SystemContext';

import ComandanteAPI from '../../../utils/ComandanteAPI';

import { Modal, Form, Input, Select, Button, message } from 'antd';

import './EditUserModal.css';

const EditUserModal = ({ setVisible, userId, refreshUsers, showChangeUserPasswordModal, ...restProps }) => {
    const { 
        systemVariables,
    } = useSystemContext();

    const roleOptions = (systemVariables && systemVariables.user && systemVariables.user.roleOptions ? systemVariables.user.roleOptions : null);
    const statusOptions = (systemVariables && systemVariables.user && systemVariables.user.statusOptions ? systemVariables.user.statusOptions : null);

    const [editUserForm] = Form.useForm();
    const [editUserFormErrors, setEditUserFormErrors] = useState([]);

    const handleEditUserModalCancel = () => {
        setVisible(false);
    };

    useEffect(() => {
        if(userId && editUserForm) {
            ComandanteAPI.HttpGetRequest('members/' + userId + '/get', (err, res) => {
                if(err || !res) {
                    message.error('System is currently unavailable. Please try again later.');
                    return;
                }

                let member = (res && res.data && res.data.member);
                if(member) {
                    if(editUserForm && editUserForm.__INTERNAL__ && editUserForm.__INTERNAL__.name) {
                        editUserForm.setFieldsValue({
                            email: ((member && member.email) || null),
                            username: ((member && member.username) || null),
                            firstName: ((member && member.firstName) || null),
                            lastName: ((member && member.lastName) || null),
                            role: ((member && member.role && { value: ''+member.role }) || { value: false }),
                            status: ((member && member.status && { value: ''+member.status }) || { value: false }),
                        });
                    }
                }
            });
        }
    }, [userId, editUserForm]);

    const handleEditUserFormSubmit = (values) => {
        ComandanteAPI.HttpPostRequest('members/' + userId + '/edit', { ...values }, (err, res) => {
            if(err && err.data && err.data.errors) {
                let formErrors = {};
                for(let formError of err.data.errors) {
                    if(formError.param && formError.msg) formErrors[formError.param] = formError.msg;
                }

                if(formErrors && Object.keys(formErrors).length > 0) {
                    setEditUserFormErrors(formErrors);
                    return;
                }
            }

            if(res && res.data && res.data.result === true) {
                refreshUsers();
                
                setVisible(false);
                editUserForm.resetFields();
            }
        });
    }

    return (
        <Modal title={ null } footer={ null } visible={ true } onCancel={ handleEditUserModalCancel } width={ 540 }>
            <div key={ 'editUserModal' } className={ 'editUserModal' }>
                <div className="editUserModal-step1">
                    <div className="editUserModal-header">
                        <h2> Edit Account </h2>
                    </div>

                    <div className="editUserModal-body">
                        <Form form={ editUserForm } name="editUserForm" onFinish={ handleEditUserFormSubmit } layout="vertical" hideRequiredMark={ true }>
                            <Form.Item
                                name="email"
                                label="Email"
                                validateStatus={ editUserFormErrors && editUserFormErrors.email ? "error" : null }
                                help={ editUserFormErrors && editUserFormErrors.email ? editUserFormErrors.email : null }
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="username"
                                label="Username"
                                validateStatus={ editUserFormErrors && editUserFormErrors.username ? "error" : null }
                                help={ editUserFormErrors && editUserFormErrors.username ? editUserFormErrors.username : null }
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="password"
                                label="Password"
                                initialValue="********"
                            >
                                <Input.Password addonAfter={ <a onClick={ () => { handleEditUserModalCancel(); showChangeUserPasswordModal(userId); } }>Change Password</a> } disabled={ true } />
                            </Form.Item>

                            <Form.Item
                                name="firstName"
                                label="First Name"
                                validateStatus={ editUserFormErrors && editUserFormErrors.firstName ? "error" : null }
                                help={ editUserFormErrors && editUserFormErrors.firstName ? editUserFormErrors.firstName : null }
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="lastName"
                                label="Last Name"
                                validateStatus={ editUserFormErrors && editUserFormErrors.lastName ? "error" : null }
                                help={ editUserFormErrors && editUserFormErrors.lastName ? editUserFormErrors.lastName : null }
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                name="role"
                                label="Role"
                                validateStatus={ editUserFormErrors && editUserFormErrors.role ? "error" : null }
                                help={ editUserFormErrors && editUserFormErrors.role ? editUserFormErrors.role : null }
                            >
                                <Select labelInValue>
                                    {
                                        roleOptions && Object.keys(roleOptions).map(pKey => {
                                            return <Select.Option key={ pKey+'' } value={ pKey+'' }>{ roleOptions[pKey] }</Select.Option>;
                                        })
                                    }
                                </Select>
                            </Form.Item>

                            <Form.Item
                                name="status"
                                label="Status"
                                validateStatus={ editUserFormErrors && editUserFormErrors.status ? "error" : null }
                                help={ editUserFormErrors && editUserFormErrors.status ? editUserFormErrors.status : null }
                            >
                                <Select labelInValue>
                                    {
                                        statusOptions && Object.keys(statusOptions).map(pKey => {
                                            return (pKey > 0 && <Select.Option key={ pKey+'' } value={ pKey+'' }>{ statusOptions[pKey] }</Select.Option>);
                                        })
                                    }
                                </Select>
                            </Form.Item>
                        </Form>
                    </div>
                    
                    <div className="editUserModal-footer">
                        <Button className="btn-white" onClick={ () => { handleEditUserModalCancel() } }>Cancel</Button>
                        <Button className="btn-purple float-right" onClick={ () => { editUserForm.submit(); } }>Save</Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default EditUserModal;