import React from 'react';

import Helmet from 'react-helmet';

import ComandanteAPI from '../../utils/ComandanteAPI';

import { Row, Col, Form, Input, Button, message } from 'antd';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';

import './Login.css';

import logo from '../../assets/images/logo-texture.png';
import loginBG from './assets/images/login-BG.png';

const Login = () => {
    const onFinish = values => {
        ComandanteAPI.HttpPostRequest('auth/login', values, (err, res) => {
            if(err || !res) {
                if(err && err.hasOwnProperty('data') && err.data && err.data.hasOwnProperty('errors') && err.data.errors) {
                    Object.values(err.data.errors).map(formErr => {
                        if(formErr.msg) {
                            message.error(formErr.msg);
                            return false;
                        }
                        return true;
                    });

                    return;
                }

                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.data.result === true && res.data.token) {
                localStorage.setItem('token', res.data.token);
                window.location.href = "/";
                return;
            }
        });
    };

    return (
        <>
            <Helmet>
                <title>Login | { process.env.REACT_APP_META_TITLE }</title>
            </Helmet>

            <Row type="flex" gutter={ 0 } style={{ height: '100vh' }}>
                <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ 10 } xl={ 10 } className="col-with-border" style={{ background: '#F8F8FB', borderRight: '1px solid #D1D1E9', height: '100vh' }}>
                    <div className="aligner-x" style={{ height: '100%' }}>
                        <div className="login-W">
                            <img src={ logo } alt="Logo" />

                            <h1>Login</h1>

                            <Form name="login" initialValues={{ remember: true }} onFinish={ onFinish } layout="vertical" hideRequiredMark={ true }>
                                <Form.Item 
                                    name="username"
                                    label="Username"
                                    rules={ [{ required: true, message: 'You must enter your username!' }] }
                                >
                                    <Input />
                                </Form.Item>

                                <Form.Item
                                    name="password"
                                    label="Password"
                                    rules={ [{ required: true, message: 'You must enter your password!' }] }
                                >
                                    <Input.Password 
                                        type="password" 
                                        iconRender={ (visible) => (visible ? <span className="pw-visibility-toggle"><EyeInvisibleOutlined className="mr-1" />Hide</span> : <span className="pw-visibility-toggle"><EyeOutlined className="mr-1" />Show</span>) }
                                    />
                                </Form.Item>

                                <Form.Item className="text-right">
                                    <Button className="btn-purple" htmlType="submit">Login</Button>
                                </Form.Item>
                            </Form>
                        </div>
                    </div>
                </Col>

                <Col xs={ 0 } sm={ 0 } md={ 0 } lg={ 14 } xl={ 14 } style={{ height: '100vh' }}>
                    <div className="login-BG-wrapper aligner-xy" style={{ height: '100%' }}>
                        <div>
                            <img src={ loginBG } alt="Login" style={{ width: '100%' }} />
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default Login;
