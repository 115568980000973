import React, { useState, useCallback, useEffect } from 'react';
import { useImmer } from "use-immer";
import { Link, useHistory } from 'react-router-dom';
import { useSystemContext } from '../../../Context/SystemContext';

import ComandanteAPI from '../../../utils/ComandanteAPI';
import ComandanteEditor from '../../../utils/ComandanteEditor/ComandanteEditor';
import { convertToRaw } from 'draft-js';

import CustomIcon from '../../../UIElements/CustomIcon/CustomIcon';

import { Row, Col, Drawer, Form, Input, Select, DatePicker, Button, Spin, message } from 'antd';

import 'draft-js/dist/Draft.css';

const CreateTaskDrawer = ({ onClose, productId, projectId, stageId, refreshTasks }) => {
    const history = useHistory();

    const {
        currentUser,
        systemVariables
    } = useSystemContext();

    const roleOptions = (systemVariables && systemVariables.user && systemVariables.user.roleOptions ? systemVariables.user.roleOptions : null);
    const priorityLevelOptions = (systemVariables && systemVariables.task && systemVariables.task.priorityLevelOptions ? systemVariables.task.priorityLevelOptions : null);

    const [productDataset, updateProductDataset] = useImmer(null);
    const [projectDataset, updateProjectDataset] = useImmer(null);
    const [projectMembersDataset, updateProjectMembersDataset] = useImmer(null);

    const [createTaskForm] = Form.useForm();
    const [createTaskErrors, setCreateTaskErrors] = useState(null);

    const fetchProduct = useCallback(() => {
        if(productId) {
            ComandanteAPI.HttpGetRequest('products/' + productId + '/get', (err, res) => {
                updateProductDataset(productDataset => (res && res.data) || null);
            });
        }
    }, [productId, updateProductDataset]);
    
    const fetchProject = useCallback(() => {
        if(productId && projectId) {
            ComandanteAPI.HttpGetRequest('products/' + productId + '/projects/' + projectId + '/get', (err, res) => {
                updateProjectDataset(projectDataset => (res && res.data) || null);
            });
        }
    }, [productId, projectId, updateProjectDataset]);

    const fetchProjectMembers = useCallback(() => {
        if(productId && projectId) {
            ComandanteAPI.HttpGetRequest('products/' + productId + '/projects/' + projectId + '/members', (err, res) => {
                updateProjectMembersDataset(projectMembersDataset => (res && res.data) || []);
            });
        }
    }, [productId, projectId, updateProjectMembersDataset]);

    useEffect(() => {
        fetchProduct();
        fetchProject();
        fetchProjectMembers();
    }, [fetchProduct, fetchProject, fetchProjectMembers]);

    const [editorState, setEditorState] = useState(null)
    const _editorStateCallback = (editorState) => {
        setEditorState(editorState);
    }

    const onCreateTaskFinish = (values) => {
        let createTaskValues = {
            ...values,
            description: JSON.stringify(convertToRaw(editorState.getCurrentContent()))
        }

        ComandanteAPI.HttpPostRequest('products/' + productId + '/projects/' + projectId + '/tasks/add', createTaskValues, (err, res) => {
            if(err || !res) {    
                if(err && err.data && err.data.errors) {
                    let formErrors = [];
                    for(let formError of err.data.errors) {
                        if(formError.param && formError.msg) formErrors[formError.param] = formError.msg;
                    }

                    setCreateTaskErrors(formErrors);
                    return;
                }

                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.data && res.data.result === true) {
                history.push('/products/' + productId + '/projects/' + projectId + '/tasks/' + res.data.task._id);
                refreshTasks();
                onClose();

                /*
                if(res.data.task && res.data.task._id) window.location.href = '/products/' + productId + '/projects/' + projectId + '/tasks/' + res.data.task._id;
                else window.location.reload();
                */
            }
        });
    }

    return (
        (productDataset && productDataset.product && projectDataset && projectDataset.project) ? (
            <>
                <Drawer
                    width={ 720 }
                    visible={ true }
                    mask={ false }
                    maskClosable={ false }
                    closeIcon={ <CustomIcon iconKey="close" /> }
                    onClose={ onClose }
                >
                    <div className="task-view-H">
                        <Link to={ '/products/' + productId }>{ productDataset.product.name }</Link>
                        <span className="ml-2 mr-2">&gt;</span>
                        <Link to={ '/products/' + productId + '/projects/' + projectId }>{ projectDataset.project.name }</Link>
                    </div>

                    <div className="task-view-B">
                        <Form className="form--task-drawer" form={ createTaskForm } initialValues={{ stage: { value: stageId } }} name="createTask" onFinish={ onCreateTaskFinish } layout="vertical" hideRequiredMark={ true }>
                            <div className="form-R1" style={{ padding: '20px 30px 40px 30px' }}>
                                <Row gutter={ 8 }>
                                    <Col xs={ 12 } sm={ 12 } md={ 6 } lg={ 6 } xl={ 6 }>
                                        <Form.Item
                                            name="stage"
                                            label={ <><CustomIcon iconKey="piechart" color="#8C8C8C" className="mr-1" /> STAGE</> }
                                            validateStatus={ createTaskErrors && createTaskErrors.stage ? "error" : null }
                                            help={ createTaskErrors && createTaskErrors.stage ? createTaskErrors.stage : null }
                                        >
                                            <Select labelInValue>
                                                {
                                                    projectDataset.project.stages && Array.isArray(projectDataset.project.stages) && projectDataset.project.stages.length > 0 && projectDataset.project.stages.map((stage) => {
                                                        return (
                                                            <Select.Option
                                                                key={ stage._id }
                                                                value={ stage._id }
                                                                disabled={ (currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Observer', 'Contributor', 'Editor', 'Project Manager'].includes(roleOptions[currentUser.role+''])) && stage.locked ? true : false }
                                                            >
                                                                { stage.label }
                                                            </Select.Option>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col xs={ 12 } sm={ 12 } md={ 6 } lg={ 6 } xl={ 6 }>
                                        <Form.Item
                                            name="dueDate"
                                            label={ <><CustomIcon iconKey="date-time" color="#8C8C8C" className="mr-1"/> DUE DATE</> }
                                            validateStatus={ createTaskErrors && createTaskErrors.dueDate ? "error" : null }
                                        >
                                            <DatePicker style={{ width: '100%', height: '32px' }} />
                                        </Form.Item>
                                    </Col>

                                    <Col xs={ 12 } sm={ 12 } md={ 6 } lg={ 6 } xl={ 6 }>
                                        <Form.Item
                                            name="priority"
                                            label={ <><CustomIcon iconKey="flag" color="#8C8C8C" className="mr-1"/> PRIORITY</> }
                                            validateStatus={ createTaskErrors && createTaskErrors.priority ? "error" : null }
                                        >
                                            <Select labelInValue placeholder="Select priority">
                                                {
                                                    priorityLevelOptions && Object.keys(priorityLevelOptions).map((pKey) => {
                                                        return <Select.Option key={ pKey } value={ pKey }>{ priorityLevelOptions[pKey] }</Select.Option>
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col xs={ 12 } sm={ 12 } md={ 6 } lg={ 6 } xl={ 6 }>
                                        <Form.Item
                                            name="assignedTo"
                                            label={ <><CustomIcon iconKey="user" color="#8C8C8C" className="mr-1"/> ASSIGNEE</> }
                                            validateStatus={ createTaskErrors && createTaskErrors.assignedTo ? "error" : null }
                                        >
                                            <Select labelInValue placeholder="Select assignee">
                                                {
                                                    projectMembersDataset && projectMembersDataset.data && Object.values(projectMembersDataset.data).length > 0 && Object.values(projectMembersDataset.data).map(member => {
                                                        return <Select.Option key={ member._id } value={ member._id }>{ member.firstName + ' ' + member.lastName }</Select.Option>
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div>

                            <div className="form-R2" style={{ padding: '20px 30px 20px 30px' }}>
                                <Form.Item
                                    className={ 'task-drawer-custom-input' + (createTaskErrors && createTaskErrors.title ? ' error' : '' ) }
                                    name="title"
                                    label="Title"
                                    validateStatus={ createTaskErrors && createTaskErrors.title ? "error" : null }
                                    help={ createTaskErrors && createTaskErrors.title ? createTaskErrors.title : null }
                                >
                                    <Input className="title" />
                                </Form.Item>

                                <Form.Item
                                    className="task-drawer-custom-input comandante-rte-wrapper"
                                    name="description"
                                    label="Description"
                                    validateStatus={ createTaskErrors && createTaskErrors.description ? "error" : null }
                                    help={ createTaskErrors && createTaskErrors.description ? createTaskErrors.description : null }
                                >
                                    <ComandanteEditor className="comandante-editor" setEditorState={ _editorStateCallback } productId={ productId } projectId={ projectId } />
                                </Form.Item>

                                <Form.Item>
                                    <Button type="submit" className="btn-purple float-right mt-5" onClick={ () => { createTaskForm.submit() } }>Save Changes</Button>
                                </Form.Item>
                            </div>
                        </Form>
                    </div>
                </Drawer>
            </>
        ) : (
            <div className="aligner-xy" style={{ height: '100vh' }}><Spin /></div>
        )
    )
}

export default CreateTaskDrawer;