import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { useImmer } from "use-immer";

import { useSystemContext } from '../../../Context/SystemContext';

import ComandanteAPI from '../../../utils/ComandanteAPI';

import ProductIcon, { ProductIconsSet } from '../../../UIElements/ProductIcon/ProductIcon';
import { ColorSet } from '../../../UIElements/ColorPicker/ColorPicker';

import { Avatar, Button, Modal, Row, Col, Form, Input, Select, Table, message } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import modalBG from '../../../assets/images/modal-BG.png';
import avatarPlaceholder from '../../../assets/images/placeholder-avatar.png';
import removeIcon from '../../../assets/images/icon-remove.png';

const AddProductModal = ({ setVisible, ...restProps }) => {
    const history = useHistory();

    const { 
        currentUser, 
        systemVariables,
        fetchProducts
    } = useSystemContext();

    const stageOptions = systemVariables && systemVariables.product && systemVariables.product.stageOptions ? systemVariables.product.stageOptions : {}
    const roleOptions = (systemVariables && systemVariables.user && systemVariables.user.roleOptions ? systemVariables.user.roleOptions : null);
    
    const [teamsDataset, updateTeamsDataset] = useImmer(null);
    const [teamsToAdd, updateTeamsToAdd] = useImmer(null);
    const [membersDataset, updateMembersDataset] = useImmer(null);
    const [membersToAdd, updateMembersToAdd] = useImmer(null);
    const [combinedTeamsAndMembersData, updateCombinedTeamsAndMembersData] = useImmer(null);
    const [combinedTeamsAndMembersDataFilter, setCombinedTeamsAndMembersDataFilter] = useState(null);
    const [combinedTeamsAndMembersToAdd, updateCombinedTeamsAndMembersToAdd] = useImmer(null);

    const [addProductModalStep, setAddProductModalStep] = useState(1);
    const [addProductForm_step1] = Form.useForm();
    const [addProductForm_step3] = Form.useForm();
    const [addProductErrors_step1, setAddProductErrors_step1] = useState([]);
    const [addProductErrors_step3, setAddProductErrors_step3] = useState([]);
    const [addProductValues, setAddProductValues] = useState({});

    const [selectedProductIconKey, setSelectedProductIconKey] = useState(Object.keys(ProductIconsSet())[0]);
    const [selectedProductIconColor, setSelectedProductIconColor] = useState(ColorSet()[4]);

    const fetchTeams = useCallback(async () => {
        ComandanteAPI.HttpGetRequest('teams?paginated=false', (err, res) => {
            updateTeamsDataset(teamsDataset => (res && res.data) || null);
        });
    }, [updateTeamsDataset]);

    useEffect(() => {
        fetchTeams(null);
    }, [fetchTeams]);

    const fetchMembers = useCallback(async () => {
        ComandanteAPI.HttpGetRequest('members?paginated=false', (err, res) => {
            updateMembersDataset(membersDataset => (res && res.data) || null);
        });
    }, [updateMembersDataset]);

    useEffect(() => {
        fetchMembers(null);
    }, [fetchMembers]);

    useEffect(() => {
        if(currentUser) {
            if(membersDataset && membersDataset.data && membersDataset.data[currentUser._id+'']) {
                updateMembersToAdd(membersToAdd => {
                    if(!membersToAdd || !Array.isArray(membersToAdd)) return [currentUser._id+''];
                    if(!membersToAdd.includes(currentUser._id+'')) membersToAdd.push(currentUser._id+'');
                });
            }
        }
    }, [currentUser, membersDataset, updateMembersToAdd]);

    useEffect(() => {
        if(teamsDataset && membersDataset) {
            let xCombinedTeamsAndMembersData = {};
            
            if(teamsDataset.data && Object.keys(teamsDataset.data).length > 0) {
                for(let team of Object.values(teamsDataset.data)) {
                    xCombinedTeamsAndMembersData[team._id+''] = { ...team, isTeam: true };
                }
            }

            if(membersDataset.data && Object.keys(membersDataset.data).length > 0) {
                for(let member of Object.values(membersDataset.data)) {
                    xCombinedTeamsAndMembersData[member._id+''] = { ...member, isTeam: false };
                }
            }
            
            updateCombinedTeamsAndMembersData(combinedTeamsAndMembersData => xCombinedTeamsAndMembersData);
        }
    }, [teamsDataset, membersDataset, updateCombinedTeamsAndMembersData]);

    useEffect(() => {
        if(teamsToAdd || membersToAdd) {
            let xCombinedTeamsAndMembersToAdd = [];
            
            if(teamsToAdd && Array.isArray(teamsToAdd) && teamsToAdd.length > 0) {
                for(let teamId of teamsToAdd) {
                    xCombinedTeamsAndMembersToAdd.push(teamId);
                }
            }

            if(membersToAdd && Array.isArray(membersToAdd) && membersToAdd.length > 0) {
                for(let memberId of membersToAdd) {
                    xCombinedTeamsAndMembersToAdd.push(memberId);
                }
            }
            
            updateCombinedTeamsAndMembersToAdd(combinedTeamsAndMembersToAdd => xCombinedTeamsAndMembersToAdd);
        }
    }, [teamsToAdd, membersToAdd, updateCombinedTeamsAndMembersToAdd]);

    const handleAddProductModalCancel = () => { setVisible(false) };

    const handleAddProductModalNext = (values) => {
        if(addProductModalStep === 1) {
            addProductForm_step1.submit();
        } else if(addProductModalStep === 2) {
            setAddProductValues({ ...addProductValues, teamIds: teamsToAdd, memberIds: membersToAdd });
            setAddProductModalStep(3);
        } else if(addProductModalStep === 3) {
            addProductForm_step3.submit();
        }
    }
    const handleAddProductModalPrev = (values) => {
        if(addProductModalStep === 1) {
            
        } else if(addProductModalStep === 2) {
            setAddProductModalStep(1);
        } else if(addProductModalStep === 3) {
            setAddProductModalStep(2);
        }
    }
    
    const onAddProductFinish_step1 = values => {
        let addProductFormValues = { ...values, iconKey: { value: selectedProductIconKey }, iconColor: { value: selectedProductIconColor } };

        ComandanteAPI.HttpPostRequest('products/add?validationOnly=true', addProductFormValues, (err, res) => {
            if(err || !res) {    
                if(err && err.data && err.data.errors) {
                    let formErrors = [];
                    for(let formError of err.data.errors) {
                        if(formError.param && formError.msg) formErrors[formError.param] = formError.msg;
                    }

                    setAddProductErrors_step1(formErrors);
                    return;
                }

                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.data && res.data.result === true) {
                setAddProductValues({ ...addProductValues, ...addProductFormValues });
                setAddProductModalStep(2);
            }
        });
    }
    
    const onAddProductFinish_step3 = values => {
        ComandanteAPI.HttpPostRequest('products/add', { ...addProductValues, ...values }, (err, res) => {
            if(err || !res) {    
                if(err && err.data && err.data.errors) {
                    let formErrors = [];
                    for(let formError of err.data.errors) {
                        if(formError.param && formError.msg) formErrors[formError.param] = formError.msg;
                    }

                    setAddProductErrors_step3(formErrors);
                    return;
                }

                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.data && res.data.result === true) {
                fetchProducts();

                setVisible(false);
                addProductForm_step1.resetFields();
                addProductForm_step3.resetFields();

                if(res.data.product && res.data.product._id) history.push('/products/' + res.data.product._id);
            }
        });
    }

    const combinedTeamsAndMembersColumns = [
        {
            width: 32,
            render: (text, record) => {
                if(record.isTeam) {
                    return <Avatar shape="square" size={ 20 } style={{ backgroundColor: '#c41d7f' }}>{ record.name[0] }</Avatar>
                } else {
                    return <Avatar shape="square" size={ 20 } src={ (record.profilePhoto ? process.env.REACT_APP_COMANDANTE_API_ADDRESS + record.profilePhoto.path.substring(7) : avatarPlaceholder) } alt={ 'Avatar | ' + record.firstName + ' ' + record.lastName } height={ 20 } />
                }
            }
        },
        {
            title: 'Team / User',
            render: (text, record) => (record.isTeam ? record.name : record.firstName + ' ' + record.lastName)
        },
        {
            title: 'Role',
            width: 180,
            responsive: ['lg', 'xl'],
            render: (text, record) => {
                if(record.isTeam) {
                    return 'N/A';
                } else {
                    if(!roleOptions || !roleOptions[record.role]) return 'Unknown';
                    return roleOptions[record.role+''];
                }
            }
        },
        {
            width: 60,
            render: (text, record) => {
                return (
                    (!combinedTeamsAndMembersToAdd || !Array.isArray(combinedTeamsAndMembersToAdd) || !combinedTeamsAndMembersToAdd.includes(record._id+'')) && (
                        <Button 
                            className="btn-white mt-1" 
                            style={{ height: '25px', lineHeight: '25px' }}
                            onClick={ 
                                () => {
                                    if(record.isTeam) {
                                        updateTeamsToAdd(teamsToAdd => {
                                            if(!teamsToAdd || !Array.isArray(teamsToAdd)) return [record._id+''];
                                            if(!teamsToAdd.includes(record._id+'')) teamsToAdd.push(record._id+'');
                                        });
                                    } else {
                                        updateMembersToAdd(membersToAdd => {
                                            if(!membersToAdd || !Array.isArray(membersToAdd)) return [record._id+''];
                                            if(!membersToAdd.includes(record._id+'')) membersToAdd.push(record._id+'');
                                        });
                                    }
                                } 
                            }
                        >
                            Add
                        </Button>
                    )
                );
            }
        }
    ];

    const combinedTeamsAndMembersAddColumns = [
        {
            width: 32,
            render: (recordId) => {
                if(!combinedTeamsAndMembersData || !combinedTeamsAndMembersData[recordId+'']) return 'Unknown';

                if(combinedTeamsAndMembersData[recordId+''].isTeam) {
                    return <Avatar shape="square" size={ 20 } style={{ backgroundColor: '#c41d7f' }}>{ combinedTeamsAndMembersData[recordId+''].name[0] }</Avatar>
                } else {
                    return <Avatar shape="square" size={ 20 } src={ (combinedTeamsAndMembersData[recordId+''].profilePhoto ? process.env.REACT_APP_COMANDANTE_API_ADDRESS + combinedTeamsAndMembersData[recordId+''].profilePhoto.path.substring(7) : avatarPlaceholder) } alt={ 'Avatar | ' + combinedTeamsAndMembersData[recordId+''].firstName + ' ' + combinedTeamsAndMembersData[recordId+''].lastName } height={ 20 } />
                }
            }
        },
        {
            title: 'Team / User',
            render: (recordId) => {
                if(!combinedTeamsAndMembersData || !combinedTeamsAndMembersData[recordId+'']) return 'Unknown';
                if(combinedTeamsAndMembersData[recordId+''].isTeam) {
                    return combinedTeamsAndMembersData[recordId+''].name;
                } else {
                    return combinedTeamsAndMembersData[recordId+''].firstName + ' ' + combinedTeamsAndMembersData[recordId+''].lastName + (recordId+'' === currentUser._id+'' ? ' (You)' : '');
                }
            }
        },
        {
            title: 'Role',
            width: 180,
            responsive: ['lg', 'xl'],
            render: (recordId) => {
                if(!combinedTeamsAndMembersData || !combinedTeamsAndMembersData[recordId+'']) return 'Unknown';
                if(combinedTeamsAndMembersData[recordId+''].isTeam) {
                    return 'N/A';
                } else {
                    if(!roleOptions || !roleOptions[combinedTeamsAndMembersData[recordId+''].role]) return 'Unknown';
                    return roleOptions[combinedTeamsAndMembersData[recordId+''].role+''];
                }
            }
        },
        {
            width: 60,
            render: (recordId) => {
                return (
                    (recordId+'' !== currentUser._id+'') && (
                        <Button 
                            className="btn-nothing mt-1" 
                            style={{ height: '25px', lineHeight: '25px' }}
                            onClick={ 
                                () => {
                                    if(!combinedTeamsAndMembersData || !combinedTeamsAndMembersData[recordId+'']) return;

                                    if(combinedTeamsAndMembersData[recordId+''].isTeam) {
                                        updateTeamsToAdd(teamsToAdd => {
                                            if(teamsToAdd && Array.isArray(teamsToAdd) && teamsToAdd.includes(recordId+'')) {
                                                let i = teamsToAdd.indexOf(recordId+'');
                                                if(i > -1) teamsToAdd.splice(i, 1);
                                            }
                                        });
                                    } else {
                                        updateMembersToAdd(membersToAdd => {
                                            if(membersToAdd && Array.isArray(membersToAdd) && membersToAdd.includes(recordId+'')) {
                                                let i = membersToAdd.indexOf(recordId+'');
                                                if(i > -1) membersToAdd.splice(i, 1);
                                            }
                                        });
                                    }
                                }
                            }
                        >
                            <img className="icon" src={ removeIcon } alt="Icon" style={{ height: '16px' }} />
                        </Button>
                    )
                );
            }
        }
    ];

    return (
        <Modal className="add-product-modal-C" title={ null } footer={ null } visible={ true } onCancel={ handleAddProductModalCancel } width={ 640 }>
            { 
                (addProductModalStep === 1) ? (
                    <>
                        <div className="modal-header-W">
                            <img className="w-100 d-xs-none d-sm-none" src={ modalBG } alt="Edit Product Details" style={{ maxWidth: '180px', margin: '0 auto 0 auto' }} />
                            <div className="mt-5">Create Product</div>
                        </div>

                        <div className="modal-body-W add-product-step-1">
                            <Form form={ addProductForm_step1 } name="createProduct" onFinish={ onAddProductFinish_step1 } layout="vertical" hideRequiredMark={ true }>
                                <Form.Item
                                    name="name"
                                    label="PRODUCT NAME"
                                    rules={ [{ required: true, message: 'Please enter a valid product name.' }, { max: 24, message: 'Maximum of 24 characters are allowed.' }] }
                                    validateStatus={ addProductErrors_step1 && addProductErrors_step1.name ? "error" : null }
                                    help={ addProductErrors_step1 && addProductErrors_step1.name ? addProductErrors_step1.name : null }
                                >
                                    <Input />
                                </Form.Item>
                            
                                <Form.Item
                                    name="description"
                                    label="DESCRIPTION"
                                    rules={ [{ required: true, message: 'Please enter a valid product description.' }, { max: 256, message: 'Maximum of 256 characters are allowed.' }] }
                                    validateStatus={ addProductErrors_step1 && addProductErrors_step1.description ? "error" : null }
                                    help={ addProductErrors_step1 && addProductErrors_step1.description ? addProductErrors_step1.description : null }
                                >
                                    <Input.TextArea rows={ 3 } />
                                </Form.Item>

                                <Row gutter={ 24 }>
                                    <Col span={ 12 }>
                                        <Form.Item
                                            name="type"
                                            label="PRODUCT TYPE"
                                            validateStatus={ addProductErrors_step1 && addProductErrors_step1.type ? "error" : null }
                                            help={ addProductErrors_step1 && addProductErrors_step1.type ? addProductErrors_step1.type : null }
                                        >
                                            <Input />
                                        </Form.Item>
                                    </Col>

                                    <Col span={ 12 }>
                                        <Form.Item
                                            name="stage"
                                            label="STAGE"
                                            validateStatus={ addProductErrors_step1 && addProductErrors_step1.stage ? "error" : null }
                                            help={ addProductErrors_step1 && addProductErrors_step1.stage ? addProductErrors_step1.stage : null }
                                        >
                                            <Select labelInValue>
                                                {
                                                    Object.keys(stageOptions).map(pKey => {
                                                        return <Select.Option key={ pKey } value={ pKey }>{ stageOptions[pKey] }</Select.Option>;
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Row gutter={ 24 }>
                                    <Col span={ 6 }>
                                        <Form.Item
                                            label="ICON"
                                            name="iconKey"
                                            initialValue={{ value: (selectedProductIconKey ? selectedProductIconKey : null) }}
                                        >
                                            <Select 
                                                className="svg-select-box"
                                                labelInValue
                                                onSelect={ (selected) => { setSelectedProductIconKey(selected.value); } }
                                            >
                                                { 
                                                    ProductIconsSet() && Object.keys(ProductIconsSet()).map((iconKey) => {
                                                        return (
                                                            <Select.Option key={ iconKey } value={ iconKey }>
                                                                <ProductIcon iconKey={ iconKey } />
                                                            </Select.Option>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col span={ 6 }>
                                        <Form.Item
                                            label="COLOR"
                                            name="iconColor"
                                            initialValue={{ value: (selectedProductIconColor ? selectedProductIconColor : null) }}
                                        >
                                            <Select
                                                labelInValue
                                                onSelect={ (selected) => { setSelectedProductIconColor(selected.value); } }
                                            >
                                                { 
                                                    ColorSet() && Array.isArray(ColorSet()) && ColorSet().length > 0 && ColorSet().map((color) => {
                                                        return (
                                                            <Select.Option key={ color } value={ color }>
                                                                <span style={{ fontSize: '28px', lineHeight: 1, color: color }}>&#8226;</span>
                                                            </Select.Option>
                                                        );
                                                    })
                                                }
                                            </Select>
                                        </Form.Item>
                                    </Col>

                                    <Col span={ 12 }>
                                        <Form.Item>
                                            <div style={{ margin: '28px 0 0 0' }}>
                                                <ProductIcon iconKey={ selectedProductIconKey } color={ selectedProductIconColor } />
                                            </div>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                        
                        <div className="modal-footer-W" style={{ background: '#F8F8FB' }}>
                            <div className="text-right">
                                <Button className="btn-purple" onClick={ () => { handleAddProductModalNext() } }>Next Step</Button>
                            </div>
                        </div>
                    </>
                ) : (addProductModalStep === 2) ? (
                    <>
                        <div className="modal-header-W">
                            <div>Who is this product for?</div>
                        </div>

                        <div className="modal-body-W">
                            <div className="R1" style={{ padding: '15px 30px' }}>
                                <Form layout="vertical d-xs-none d-sm-none">
                                    <Form.Item label="Search by team / individual">
                                        <Input 
                                            style={{ maxWidth: '280px' }}
                                            onChange={ (event) => {
                                                event.persist(); // Signal to React not to nullify the event object

                                                setCombinedTeamsAndMembersDataFilter((event.target.value ? event.target.value.toLowerCase() : null));
                                            }}
                                        />
                                    </Form.Item>
                                </Form>

                                <div className="table-section-header mt-3">Teams / Individiuals Found</div>
                                <Table 
                                    rowKey="_id"
                                    rowClassName={ (record, index) => ((combinedTeamsAndMembersDataFilter && (record.isTeam ? !(record.name.toLowerCase().includes(combinedTeamsAndMembersDataFilter)) : !(record.firstName.toLowerCase().includes(combinedTeamsAndMembersDataFilter) || record.lastName.toLowerCase().includes(combinedTeamsAndMembersDataFilter) || record.username.toLowerCase().includes(combinedTeamsAndMembersDataFilter)))) || (combinedTeamsAndMembersToAdd && combinedTeamsAndMembersToAdd.includes(record._id+'')) ? 'd-none' : '') }
                                    className="members-to-add-T mt-2"
                                    columns={ combinedTeamsAndMembersColumns }
                                    dataSource={ combinedTeamsAndMembersData ? Object.values(combinedTeamsAndMembersData) : [] }
                                    pagination={ false }
                                />
                            </div>

                            <div className="R2" style={{ background: '#FFFFFF', padding: '15px 30px' }}>
                                <div className="table-section-header mt-3">Teams / Individiuals Added</div>
                                <Table
                                    rowKey={ (recordId) => recordId }
                                    rowClassName={ (recordId, index) => (combinedTeamsAndMembersDataFilter && combinedTeamsAndMembersData && combinedTeamsAndMembersData[recordId+''] && (combinedTeamsAndMembersData[recordId+''].isTeam ? !(combinedTeamsAndMembersData[recordId+''].name.toLowerCase().includes(combinedTeamsAndMembersDataFilter)) : !(combinedTeamsAndMembersData[recordId+''].firstName.toLowerCase().includes(combinedTeamsAndMembersDataFilter) || combinedTeamsAndMembersData[recordId+''].lastName.toLowerCase().includes(combinedTeamsAndMembersDataFilter) || combinedTeamsAndMembersData[recordId+''].username.toLowerCase().includes(combinedTeamsAndMembersDataFilter))) ? 'd-none' : '') }
                                    className="members-added-T mt-2"
                                    columns={ combinedTeamsAndMembersAddColumns }
                                    dataSource={ combinedTeamsAndMembersToAdd ? combinedTeamsAndMembersToAdd : [] }
                                    pagination={ false }
                                />
                            </div>
                        </div>

                        <div className="modal-footer-W" style={{ background: '#FFFFFF' }}>
                            <div>
                                <Button className="btn-purple float-right" onClick={ () => { handleAddProductModalNext() } }>Next Step</Button>
                                <Button className="btn-white" onClick={ () => { handleAddProductModalPrev() } }>Previous Step</Button>
                            </div>
                        </div>
                    </>
                ) : (addProductModalStep === 3) ? (
                    <>
                        <div className="modal-header-W">
                            <div>All good?</div>
                        </div>

                        <div className="modal-body-W add-product-step-3">
                            <Form form={ addProductForm_step3 } initialValues={{ ...addProductValues }} name="createProduct" onFinish={ onAddProductFinish_step3 } layout="horizontal" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} hideRequiredMark={ true } colon={ false }>
                                <Form.Item
                                    name="name"
                                    label="PRODUCT NAME"
                                    validateStatus={ addProductErrors_step3 && addProductErrors_step3.name ? "error" : null }
                                    help={ addProductErrors_step3 && addProductErrors_step3.name ? addProductErrors_step3.name : null }
                                    // rules={[ { required: true, message: 'You must enter a product name!' } ]}
                                >
                                    <Input suffix={ <EditOutlined style={{ fontSize: '18px' }} /> } />
                                </Form.Item>
                            
                                <Form.Item
                                    name="description"
                                    label="DESCRIPTION"
                                    validateStatus={ addProductErrors_step3 && addProductErrors_step3.description ? "error" : null }
                                    help={ addProductErrors_step3 && addProductErrors_step3.description ? addProductErrors_step3.description : null }
                                    // rules={[ { required: true, message: 'You must enter a product description!' } ]}
                                >
                                    <Input suffix={ <EditOutlined style={{ fontSize: '18px' }} /> } />
                                </Form.Item>

                                <Form.Item
                                    name="type"
                                    label="PRODUCT TYPE"
                                    validateStatus={ addProductErrors_step3 && addProductErrors_step3.type ? "error" : null }
                                    help={ addProductErrors_step3 && addProductErrors_step3.type ? addProductErrors_step3.type : null }
                                >
                                    <Input suffix={ <EditOutlined style={{ fontSize: '18px' }} /> } />
                                </Form.Item>
                                    
                                <Form.Item
                                    name="stage"
                                    label="STAGE"
                                    validateStatus={ addProductErrors_step3 && addProductErrors_step3.stage ? "error" : null }
                                    help={ addProductErrors_step3 && addProductErrors_step3.stage ? addProductErrors_step3.stage : null }
                                >
                                    <Select labelInValue>
                                        {
                                            Object.keys(stageOptions).map(pKey => {
                                                return <Select.Option key={ pKey } value={ pKey }>{ stageOptions[pKey] }</Select.Option>;
                                            })
                                        }
                                    </Select>
                                </Form.Item>
                            </Form>
                        </div>

                        <div className="modal-footer-W" style={{ background: '#F8F8FB' }}>
                            <div>
                                <Button className="btn-purple float-right" onClick={ () => { handleAddProductModalNext() } }>Create Product</Button>
                                <Button className="btn-white" onClick={ () => { handleAddProductModalPrev() } }>Previous Step</Button>
                            </div>
                        </div>
                    </>
                ) : (addProductModalStep === 4) ? (
                    <>
                        { /*
                        <div className="modal-header-W"></div>

                        <div className="modal-body-W" style={{ padding: '30px 60px' }}>
                            <div className="text-center pt-5 pb-5">
                                <img src={ productCreatedImg } alt="Icon" style={{ width: '100%', maxWidth: '320px' }} />

                                <div className="mt-5" style={{ fontWeight: 500, fontSize: '24px', lineHeight: '32px', color: '#6246EA' }}>{ addProductValues && addProductValues.name ? addProductValues.name : 'New Product' }</div>
                                <div className="mt-2" style={{ fontWeight: 500, fontSize: '16px', lineHeight: '24px', color: '#595959' }}>has now been created!</div>
                            </div>
                        </div>
                        */ }
                    </>
                ) : (
                    null
                )
            }
        </Modal>
    );
}

export default AddProductModal;