import React, { useState, useEffect } from 'react';
import { useSystemContext } from '../../Context/SystemContext';

import { Link, useHistory } from 'react-router-dom';

import AddProductModal from '../../Pages/Products/AddProductModal/AddProductModal';
import EditProjectModal from '../../Pages/Project/EditProjectModal/EditProjectModal';
import ManageProjectTeamsAndMembers from '../../Pages/Project/ManageProjectTeamsAndMembers/ManageProjectTeamsAndMembers';

import ProductIcon, { ProductIconsSet } from '../../UIElements/ProductIcon/ProductIcon';
import ProjectIcon from '../../UIElements/ProjectIcon/ProjectIcon';
import CustomIcon from '../../UIElements/CustomIcon/CustomIcon';
import { ColorSet } from '../../UIElements/ColorPicker/ColorPicker';

import { Layout, Menu, Button, Tag } from 'antd';
import { CaretRightOutlined } from '@ant-design/icons';

import './Sidebar.css';

import selectedIndicator from './assets/images/selected-indicator.png';

const Sidebar = ({ collapsed }) => {
    const history = useHistory();

    const { 
        currentUser, 
        systemVariables,
        productsDataset,
        projectsDataset,
        projectsGroupedByProductIdDataset,
        notificationsCount
    } = useSystemContext();

    const roleOptions = (systemVariables && systemVariables.user && systemVariables.user.roleOptions ? systemVariables.user.roleOptions : null);
    const statusOptions = (systemVariables && systemVariables.product && systemVariables.product.statusOptions ? systemVariables.product.statusOptions : null);

    const { sidebarCollapsed } = false;

    const [location, setLocation] = useState(window.location);

    const [addProductModalVisible, setAddProductModalVisible] = useState(false);

    const [editProjectModalVisible, setEditProjectModalVisible] = useState(false);
    const [manageProjectTeamsAndMembersModalVisible, setManageProjectTeamsAndMembersModalVisible] = useState(false);
    const [projectIdForEditProjectModal, setProjectIdForEditProjectModal] = useState(null);
    const [productIdForEditProjectModal, setProductIdForEditProjectModal] = useState(null);

    useEffect(() => {
		history.listen((location, action) => {
			setLocation(location);
        });
	});

    var URLParts = [];
    if(location) {
        var currentPathParts = location.pathname.split('/')
        for(let pathPart of currentPathParts) {
            if(!pathPart || pathPart === '/') continue;
            URLParts.push('/' + pathPart);
        }
        
        if(URLParts.length === 0) URLParts.push('/');
    }

    let menuItems = (
        <Menu mode="inline" selectedKeys={ URLParts } defaultOpenKeys={ URLParts } style={{ paddingBottom: '50px' }}>
            {
                (currentUser && currentUser.authorizedFrontEndResources && Array.isArray(currentUser.authorizedFrontEndResources) && currentUser.authorizedFrontEndResources.includes('front-end.home')) && (
                    <Menu.Item key="/home">
                        <Link to='/home'>
                            { URLParts.includes('/home') && URLParts.slice(-1)[0] === '/home' ? <img className="selected-indicator" src={ selectedIndicator } alt="Selected" /> : null }
                            <div className={ 'nav-item-C' + (URLParts.includes('/home') && URLParts.slice(-1)[0] === '/home' ? ' selected' : '') }><CustomIcon iconKey="home" className="mr-2" />Home</div>
                        </Link>
                    </Menu.Item>
                )
            }

            {
                (currentUser && currentUser.authorizedFrontEndResources && Array.isArray(currentUser.authorizedFrontEndResources) && currentUser.authorizedFrontEndResources.includes('front-end.my-tasks')) && (
                    <Menu.Item key="/my-tasks">
                        <Link to='/my-tasks'>
                            { URLParts.includes('/my-tasks') && URLParts.slice(-1)[0] === '/my-tasks' ? <img className="selected-indicator" src={ selectedIndicator } alt="Selected" /> : null }
                            <div className={ 'nav-item-C' + (URLParts.includes('/my-tasks') && URLParts.slice(-1)[0] === '/my-tasks' ? ' selected' : '') }><CustomIcon iconKey="my-tasks" className="mr-2" />My Tasks</div>
                        </Link>
                    </Menu.Item>
                )
            }

            {
                (currentUser && currentUser.authorizedFrontEndResources && Array.isArray(currentUser.authorizedFrontEndResources) && currentUser.authorizedFrontEndResources.includes('front-end.notifications')) && (
                    <Menu.Item key="/notifications">
                        <Link to='/notifications'>
                            { URLParts.includes('/notifications') && URLParts.slice(-1)[0] === '/notifications' ? <img className="selected-indicator" src={ selectedIndicator } alt="Selected" /> : null }
                            <div className={ 'nav-item-C' + (URLParts.includes('/notifications') && URLParts.slice(-1)[0] === '/notifications' ? ' selected' : '') }><CustomIcon iconKey="notifications" className="mr-2" />
                                {
                                    (notificationsCount && notificationsCount > 0) ? (
                                        <div className="float-right" style={{ color: '#6246EA' }}>
                                            <Tag color="purple" style={{ borderRadius: '100%', margin: '0 0 0 0' }}>{ notificationsCount ? notificationsCount : '0' }</Tag>
                                        </div>
                                    ) : null
                                }
                                
                                Notifications
                            </div> 
                        </Link>
                    </Menu.Item>
                )
            }

            {
                (currentUser && currentUser.authorizedFrontEndResources && Array.isArray(currentUser.authorizedFrontEndResources) && currentUser.authorizedFrontEndResources.includes('front-end.users.add')) && (
                    <Menu.Item key="/users">
                        <Link to='/users'>
                            { URLParts.includes('/users') && URLParts.slice(-1)[0] === '/users' ? <img className="selected-indicator" src={ selectedIndicator } alt="Selected" /> : null }
                            <div className={ 'nav-item-C' + (URLParts.includes('/users') && URLParts.slice(-1)[0] === '/users' ? ' selected' : '') }><CustomIcon iconKey="manage-users" className="mr-2" />Users</div>
                        </Link>
                    </Menu.Item>
                )
            }

            {
                (currentUser && currentUser.authorizedFrontEndResources && Array.isArray(currentUser.authorizedFrontEndResources) && currentUser.authorizedFrontEndResources.includes('front-end.team')) && (
                    <Menu.Item key="/team">
                        <Link to='/team'>
                            { URLParts.includes('/team') && URLParts.slice(-1)[0] === '/team' ? <img className="selected-indicator" src={ selectedIndicator } alt="Selected" /> : null }
                            <div className={ 'nav-item-C' + (URLParts.includes('/team') && URLParts.slice(-1)[0] === '/team' ? ' selected' : '') }><CustomIcon iconKey="team" className="mr-2" />Teams</div>
                        </Link>
                    </Menu.Item>
                )
            }

            {
                (currentUser && currentUser.authorizedFrontEndResources && Array.isArray(currentUser.authorizedFrontEndResources) && currentUser.authorizedFrontEndResources.includes('front-end.products.add')) && (
                    <Menu.Item key="/products">
                        <Link to='/products'>
                            { URLParts.includes('/products') && URLParts.slice(-1)[0] === '/products' ? <img className="selected-indicator" src={ selectedIndicator } alt="Selected" /> : null }
                            <div className={ 'nav-item-C' + (URLParts.includes('/products') && URLParts.slice(-1)[0] === '/products' ? ' selected' : '') }><CustomIcon iconKey="manage-products" className="mr-2" />Products</div>
                        </Link>
                    </Menu.Item>
                )
            }

            {
                (currentUser && currentUser.authorizedFrontEndResources && Array.isArray(currentUser.authorizedFrontEndResources) && currentUser.authorizedFrontEndResources.includes('front-end.roadmap')) && (
                    <Menu.Item key="/roadmap" disabled={ true }>
                        { /*
                        <Link to='/roadmap'>
                            { URLParts.includes('/roadmap') && URLParts.slice(-1)[0] === '/roadmap' ? <img className="selected-indicator" src={ selectedIndicator } alt="Selected" /> : null }
                            <div className={ 'nav-item-C' + (URLParts.includes('/roadmap') && URLParts.slice(-1)[0] === '/roadmap' ? ' selected' : '') }><CustomIcon iconKey="roadmap" className="mr-2" />Roadmap</div>
                        </Link>
                        */ }

                        <Link to="#">
                            { URLParts.includes('/roadmap') && URLParts.slice(-1)[0] === '/roadmap' ? <img className="selected-indicator" src={ selectedIndicator } alt="Selected" /> : null }
                            <div className={ 'nav-item-C' + (URLParts.includes('/roadmap') && URLParts.slice(-1)[0] === '/roadmap' ? ' selected' : '') }><CustomIcon iconKey="roadmap" className="mr-2" color={ '#00000033' } />
                                Roadmap
                                <Tag color="magenta" style={{ borderRadius: '12px', margin: '0 0 0 95px', fontSize: '9px' }}>Coming soon</Tag>
                            </div>
                        </Link>
                    </Menu.Item>
                )
            }

            {
                (currentUser && currentUser.starredProjectIds && Array.isArray(currentUser.starredProjectIds) && currentUser.starredProjectIds.length > 0) && (
                    <>
                        <Menu.Item disabled>&nbsp;</Menu.Item>

                        <Menu.Item disabled>
                            <Link to='/#' disabled>
                                <div className="nav-item-C">FAVOURITES</div>
                            </Link>
                        </Menu.Item>

                        {
                            currentUser && currentUser.starredProjectIds && Array.isArray(currentUser.starredProjectIds) && currentUser.starredProjectIds.length > 0 && currentUser.starredProjectIds.map(projectId => {
                                return (
                                    projectsDataset && projectsDataset.data && projectsDataset.data[projectId] && (
                                        productsDataset && productsDataset.data && productsDataset.data[projectsDataset.data[projectId].productId+''] && (
                                            <Menu.Item className="menuItem-favourites" key={ '/' + projectsDataset.data[projectId]._id }>
                                                <Link className="link-preventDefault" to={ '/products/' + projectsDataset.data[projectId].productId + '/projects/' + projectsDataset.data[projectId]._id }>
                                                    { URLParts.includes('/' + projectsDataset.data[projectId]._id) && URLParts.slice(-1)[0] === '/' + projectsDataset.data[projectId]._id ? <img className="selected-indicator" alt="Selected" src={ selectedIndicator } /> : null }

                                                    { URLParts.includes('/roadmap') && URLParts.slice(-1)[0] === '/roadmap' ? <img className="selected-indicator" src={ selectedIndicator } alt="Selected" /> : null }
                                                    <div className={ 'nav-item-C' + (URLParts.includes('/' + projectsDataset.data[projectId]._id) && URLParts.slice(-1)[0] === '/' + projectsDataset.data[projectId]._id ? '' : ' selected') }>
                                                        <ProjectIcon color={ projectsDataset.data[projectId].iconColor ? projectsDataset.data[projectId].iconColor : null } />
                                                        { productsDataset.data[projectsDataset.data[projectId].productId+''].name + ' > ' + projectsDataset.data[projectId].name }

                                                        <div className="project-action-buttons-C float-right d-xs-none">
                                                            {
                                                                !(currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Observer', 'Contributor', 'Editor', 'Project Manager'].includes(roleOptions[currentUser.role+''])) && (
                                                                    <>
                                                                        <Button
                                                                            className="btn-nothing mr-1 p-0"
                                                                            disabled={ projectsDataset.data[projectId].isPublic }
                                                                            onClick={
                                                                                () => {
                                                                                    setProjectIdForEditProjectModal(projectsDataset.data[projectId]._id);
                                                                                    setProductIdForEditProjectModal(projectsDataset.data[projectId].productId);
                                                                                    setManageProjectTeamsAndMembersModalVisible(true);
                                                                                }
                                                                            }
                                                                        >
                                                                            <CustomIcon iconKey="team" color={ projectsDataset.data[projectId].isPublic ? 'rgba(0, 0, 0, 0.25)' : '#595959' } style={{ margin: '0 0 -1px 0' }} />
                                                                        </Button>

                                                                        <Button
                                                                            className="btn-nothing mr-2 p-0"
                                                                            onClick={
                                                                                () => { 
                                                                                    setProjectIdForEditProjectModal(projectsDataset.data[projectId]._id);
                                                                                    setProductIdForEditProjectModal(projectsDataset.data[projectId].productId);
                                                                                    setEditProjectModalVisible(true);
                                                                                }
                                                                            }
                                                                        >
                                                                            <CustomIcon iconKey="settings" style={{ height: '14px', margin: '0 0 -2px 0' }} />
                                                                        </Button>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </Link>
                                            </Menu.Item>
                                        )
                                    )
                                )
                            })
                        }
                    </>
                )
            }

            <Menu.Item disabled>&nbsp;</Menu.Item>

            <Menu.Item className="mt-5" disabled>
                <Link to='/#' disabled>
                    <div className="nav-item-C">ALL PRODUCTS</div>
                </Link>
            </Menu.Item>
            
            {
                (productsDataset && productsDataset.data && Array.isArray(Object.values(productsDataset.data)) && Object.values(productsDataset.data).map(product => {
                    return (
                        statusOptions && product.status && statusOptions[product.status] === 'Active' && <Menu.SubMenu key={ '/' + product._id } title={ 
                            <Link to={ '/products/' + product._id }>
                                { (URLParts.includes('/' + product._id) && URLParts.slice(-1)[0] === '/' + product._id) && (<img className="selected-indicator" alt="Selected" src={ selectedIndicator } />) }
                                {                 
                                    <div className={ 'submenu-title-C' + (URLParts.includes('/' + product._id) ? ' selected' : '') }>
                                        <CaretRightOutlined style={{ margin: '0 4px 0 0', fontSize: '10px' }} />
                                        <ProductIcon className="pt-1" iconKey={ product.iconKey ? product.iconKey : Object.keys(ProductIconsSet())[0] } color={ product.iconColor ? product.iconColor : ColorSet()[4] } /> 
                                        <div className="ml-1" style={{ width: '170px' }}>{ product.name }</div>
                                    </div> 
                                }
                            </Link> 
                        }>
                            {
                                (projectsGroupedByProductIdDataset && projectsGroupedByProductIdDataset.data && projectsGroupedByProductIdDataset.data[product._id] && Array.isArray(projectsGroupedByProductIdDataset.data[product._id]) && projectsGroupedByProductIdDataset.data[product._id].length > 0 && projectsGroupedByProductIdDataset.data[product._id].map(project => {
                                    return (
                                        <Menu.Item
                                            className="menuItem-project" 
                                            key={ '/' + project._id }
                                        >
                                            <Link to={ '/products/' + project.productId + '/projects/' + project._id }>
                                                { URLParts.includes('/' + project._id) && URLParts.slice(-1)[0] === '/' + project._id ? <img className="selected-indicator" alt="Selected" src={ selectedIndicator } /> : null }

                                                <div className="project-action-buttons-C float-right d-xs-none">
                                                    {
                                                        !(currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Observer', 'Contributor', 'Editor', 'Project Manager'].includes(roleOptions[currentUser.role+''])) && (
                                                            <>
                                                                <Button
                                                                    className="btn-nothing mr-1 p-0"
                                                                    disabled={ project.isPublic }
                                                                    onClick={
                                                                        () => {
                                                                            setProjectIdForEditProjectModal(project._id);
                                                                            setProductIdForEditProjectModal(project.productId);
                                                                            setManageProjectTeamsAndMembersModalVisible(true);
                                                                        }
                                                                    }
                                                                >
                                                                    <CustomIcon iconKey="team" color={ project.isPublic ? 'rgba(0, 0, 0, 0.25)' : '#595959' } style={{ margin: '0 0 -1px 0' }} />
                                                                </Button>

                                                                <Button
                                                                    className="btn-nothing mr-2 p-0"
                                                                    onClick={
                                                                        () => { 
                                                                            setProjectIdForEditProjectModal(project._id);
                                                                            setProductIdForEditProjectModal(project.productId);
                                                                            setEditProjectModalVisible(true);
                                                                        }
                                                                    }
                                                                >
                                                                    <CustomIcon iconKey="settings" style={{ height: '14px', margin: '0 0 -2px 0' }} />
                                                                </Button>
                                                            </>
                                                        )
                                                    }
                                                </div>

                                                <div className={ 'submenu-item-C' + (URLParts.includes('/' + project._id) && URLParts.slice(-1)[0] === '/' + project._id ? '' : ' selected') }>
                                                    <ProjectIcon color={ project.iconColor ? project.iconColor : null } />{ project.name }
                                                </div>
                                            </Link>
                                        </Menu.Item>
                                    );
                                }))
                            }
                        </Menu.SubMenu>
                    );
                }))
            }
        </Menu>
    );

    return (
        <Layout.Sider 
            trigger={ null } 
            collapsible={ true }
            collapsed={ false }
            className={ collapsed ? 'd-xs-none' : '' }
        >
            { menuItems }

            <div className="ant-layout-sider-actions">
                {
                    (currentUser && currentUser.authorizedFrontEndResources && Array.isArray(currentUser.authorizedFrontEndResources) && currentUser.authorizedFrontEndResources.includes('front-end.products.add')) && (
                        <div className="pt-3 pb-3">
                            <Button className="btn-nothing" onClick={ () => { setAddProductModalVisible(true) } } style={{ display: 'block', width: '100%', margin: '0 auto 0 auto' }}>
                                <div className="sidebar-action text-purple">
                                    <CustomIcon iconKey="add" color="#6246EA" /> New Product
                                </div>
                            </Button>
                        </div>
                    )
                }

                { addProductModalVisible && <AddProductModal setVisible={ setAddProductModalVisible } /> }
                { editProjectModalVisible && <EditProjectModal setVisible={ setEditProjectModalVisible } productId={ productIdForEditProjectModal } projectId={ projectIdForEditProjectModal } /> }
                { manageProjectTeamsAndMembersModalVisible && <ManageProjectTeamsAndMembers setVisible={ setManageProjectTeamsAndMembersModalVisible } productId={ productIdForEditProjectModal } projectId={ projectIdForEditProjectModal } /> }
            </div>
        </Layout.Sider>
    );
}

export default Sidebar;