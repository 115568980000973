import React from 'react';

import ComandanteAPI from '../../utils/ComandanteAPI';

import { Modal, Button, message } from 'antd';

const DeleteTeamModal = ({ teamId, setTeamId, refreshTeams }) => {
    const handleDeleteTaskModalCancel = () => { setTeamId(null) }
    const handleDeleteTaskModalOk = () => {
        ComandanteAPI.HttpPostRequest('teams/' + teamId + '/delete', {}, (err, res) => {
            if(err || !res) {    
                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.data && res.data.result === true) {
                setTeamId(null);
                refreshTeams();
            }
        });
    }

    return (
        <Modal className="confirmation-modal" title={ null } footer={ null } visible={ true } onCancel={ handleDeleteTaskModalCancel } width={ 520 }>
            <div className="confirmation-modal-header-W">
                
            </div>

            <div className="confirmation-modal-W">
                <div className="header">
                    Delete Team
                </div>

                <div className="body">
                    { 'Are you sure you want to delete this team?' }

                    <div className="text-right mt-4">
                        <Button className="btn-white mr-2" onClick={ handleDeleteTaskModalCancel }>Cancel</Button>
                        <Button className="btn-red" onClick={ handleDeleteTaskModalOk }>Delete</Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default DeleteTeamModal;