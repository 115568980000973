import React, { useState, useEffect, useCallback } from 'react';
import { useImmer } from "use-immer";
import { useSystemContext } from '../../Context/SystemContext';

import ComandanteAPI from '../../utils/ComandanteAPI';

import Helmet from 'react-helmet';

import { Link } from 'react-router-dom';

import MainLayout from '../../Layouts/Main';

import CustomDateFormat from '../../utils/CustomDateFormat';
import CustomIcon from '../../UIElements/CustomIcon/CustomIcon';

import { Collapse, Spin } from 'antd';

import './MyTasks.css';
import avatarPlaceholder from '../../assets/images/placeholder-avatar.png';

import ViewTaskDrawer from '../Task/Drawers/ViewTaskDrawer';

const MyTasks = () => {
    const { 
        currentUser, 
        systemVariables,
        productsDataset,
        projectsDataset,
        membersDataset
    } = useSystemContext();

    const priorityLevelOptions = (systemVariables && systemVariables.task && systemVariables.task.priorityLevelOptions ? systemVariables.task.priorityLevelOptions : null);

    const [myTasksDueTodayDataset, updateMyTasksDueTodayDataset] = useImmer(null);
    const [myTasksDueTomorrowDataset, updateMyTasksDueTomorrowDataset] = useImmer(null);
    const [myTasksDueLaterThisWeekDataset, updateMyTasksDueLaterThisWeekDataset] = useImmer(null);
    const [myTasksDueLaterThisMonthDataset, updateMyTasksDueLaterThisMonthDataset] = useImmer(null);

    const [taskForViewTaskDrawer, setTaskForViewTaskDrawer] = useState(null);

    const getMyTasksDueToday = useCallback(async () => {
        ComandanteAPI.HttpGetRequest('my-tasks/today?paginated=false', (err, res) => {
            updateMyTasksDueTodayDataset(myTasksDueTodayDataset => (res && res.data) || null);
        });
    }, [updateMyTasksDueTodayDataset]);

    const getMyTasksDueTomorrow = useCallback(async () => {
        ComandanteAPI.HttpGetRequest('my-tasks/tomorrow?paginated=false', (err, res) => {
            updateMyTasksDueTomorrowDataset(myTasksDueTomorrowDataset => (res && res.data) || null);
        });
    }, [updateMyTasksDueTomorrowDataset]);

    const getMyTasksDueLaterThisWeek = useCallback(async () => {
        ComandanteAPI.HttpGetRequest('my-tasks/later-this-week?paginated=false', (err, res) => {
            updateMyTasksDueLaterThisWeekDataset(myTasksDueLaterThisWeekDataset => (res && res.data) || null);
        });
    }, [updateMyTasksDueLaterThisWeekDataset]);

    const getMyTasksDueLaterThisMonth = useCallback(async () => {
        ComandanteAPI.HttpGetRequest('my-tasks/later-this-month?paginated=false', (err, res) => {
            updateMyTasksDueLaterThisMonthDataset(myTasksDueLaterThisMonthDataset => (res && res.data) || null);
        });
    }, [updateMyTasksDueLaterThisMonthDataset]);

    useEffect(() => {
        getMyTasksDueToday();
        getMyTasksDueTomorrow();
        getMyTasksDueLaterThisWeek();
        getMyTasksDueLaterThisMonth();
	}, [getMyTasksDueToday, getMyTasksDueTomorrow, getMyTasksDueLaterThisWeek, getMyTasksDueLaterThisMonth]);

    return (
        (currentUser && systemVariables) ? (
            <>
                <Helmet>
                    <title>My Tasks | { process.env.REACT_APP_META_TITLE }</title>
                </Helmet>

                <MainLayout>
                    <div style={{ maxWidth: '612px' }}>
                        <Collapse className="my-tasks--collapsing-cards m-xl-5" defaultActiveKey={ ['today', 'tomorrow', 'later-this-week', 'later-this-month'] }>
                            <Collapse.Panel key="today" header={ 'Today' }>
                                <div className="content">
                                    {
                                        myTasksDueTodayDataset && myTasksDueTodayDataset.data && Object.keys(myTasksDueTodayDataset.data).length > 0 ? (
                                            Object.values(myTasksDueTodayDataset.data).map(task => {
                                                return (
                                                    <div key={ task._id } className="task hover-pointer" onClick={ () => { setTaskForViewTaskDrawer({ ...task }) } }>
                                                        <div className="title">{ task.title ? task.title : 'N/A' }</div>

                                                        <div className="info-C">
                                                            <div className="breadcrumbs">
                                                                { 
                                                                    productsDataset && productsDataset.data && productsDataset.data[task.productId+''] ? (
                                                                        <Link to={ '/products/' + productsDataset.data[task.productId+'']._id }>{ productsDataset.data[task.productId+''].name }</Link>
                                                                    ) : (
                                                                        'Product'
                                                                    )
                                                                }

                                                                <span className="ml-2 mr-2">&gt;</span>

                                                                {
                                                                    projectsDataset && projectsDataset.data && projectsDataset.data[task.projectId+''] ? (
                                                                        <Link to={ '/products/' + productsDataset.data[task.productId+'']._id + '/projects/' + projectsDataset.data[task.projectId+'']._id }>{ projectsDataset.data[task.projectId+''].name }</Link>
                                                                    ) : (
                                                                        'Project'
                                                                    )
                                                                }
                                                            </div>

                                                            <div className="R1">
                                                                <div className={ 'priority d-inline-block float-right' +  (task.priority && priorityLevelOptions && priorityLevelOptions[task.priority] ? ' ' + priorityLevelOptions[task.priority].toLowerCase() : '')}>
                                                                    {
                                                                        (task.priority) ? (
                                                                            (priorityLevelOptions && priorityLevelOptions[task.priority]) ? (
                                                                                priorityLevelOptions[task.priority]
                                                                            ) : (
                                                                                task.priority
                                                                            )
                                                                        ) : (
                                                                            'None'
                                                                        )
                                                                    }
                                                                </div>

                                                                <div className="vertical-y">
                                                                    <div className={ 'assignee' + (task.assignedTo ? '' : ' empty') }>
                                                                        {
                                                                            (task.assignedTo) ? (
                                                                                <img className="mr-1 mb-0" src={ avatarPlaceholder } alt="Avatar" />
                                                                            ) : (
                                                                                <CustomIcon iconKey="profile" color="#C0C0C3" style={{ margin: '0 4px -2px 0' }} /> 
                                                                            )
                                                                        }

                                                                        { task.assignedTo && membersDataset && membersDataset.data && membersDataset.data[task.assignedTo] ? membersDataset.data[task.assignedTo].firstName + ' ' + membersDataset.data[task.assignedTo].lastName : 'No assignee' }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="R2 clearfix mt-3">
                                                                {
                                                                    task.dueDate && (
                                                                        <div className="float-right">
                                                                            <CustomDateFormat date={ task.dueDate } displayCalendarIcon />
                                                                        </div>
                                                                    )   
                                                                }

                                                                {
                                                                    task.activities && task.activities.length > 0 &&  (
                                                                        <span className="ml-1 mr-1">
                                                                            <CustomIcon iconKey="comments" color="#6246EA" /> { task.activities.length }
                                                                        </span>
                                                                    ) 
                                                                }

                                                                {
                                                                    task.dependencies && task.dependencies.length > 0 && (
                                                                        <span className="ml-1 mr-1">
                                                                            <CustomIcon iconKey="dependencies" color="#6246EA" /> { task.dependencies.length }
                                                                        </span>
                                                                    ) 
                                                                }

                                                                {
                                                                    task.checklist && task.checklist.length > 0 && (
                                                                        <span className="ml-1 mr-1">
                                                                            <CustomIcon iconKey="checklist" color="#6246EA" style={{ height: '13px', margin: '0 0 -1px 0' }} /> { task.checklist.length }
                                                                        </span>
                                                                    ) 
                                                                }

                                                                {
                                                                    task.attachments && task.attachments.length > 0 && (
                                                                        <span className="ml-1 mr-1">
                                                                            <CustomIcon iconKey="attachment-horizontal" color="#6246EA" /> { task.attachments.length }
                                                                        </span>
                                                                    ) 
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>  
                                                );
                                            })
                                        ) : (
                                            <div className="text-center p-3" style={{ background: '#F8F8FB', border: '1px solid #DFE0E1', borderRadius: '3px', color: '#8C8C8C' }}>
                                                <div className="title">You have no tasks due today.</div>
                                            </div>                            
                                        )
                                    }
                                </div> 
                            </Collapse.Panel>

                            <Collapse.Panel key="tomorrow" header={ 'Tomorrow' }>
                                <div className="content">
                                    {
                                        myTasksDueTomorrowDataset && myTasksDueTomorrowDataset.data && Object.keys(myTasksDueTomorrowDataset.data).length > 0 ? (
                                            Object.values(myTasksDueTomorrowDataset.data).map(task => {
                                                return (
                                                    <div key={ task._id } className="task hover-pointer" onClick={ () => { setTaskForViewTaskDrawer({ ...task }) } }>
                                                        <div className="title">{ task.title ? task.title : 'N/A' }</div>

                                                        <div className="info-C">
                                                            <div className="breadcrumbs">
                                                                { 
                                                                    productsDataset && productsDataset.data && productsDataset.data[task.productId+''] ? (
                                                                        <Link to={ '/products/' + productsDataset.data[task.productId+'']._id }>{ productsDataset.data[task.productId+''].name }</Link>
                                                                    ) : (
                                                                        'Product'
                                                                    )
                                                                }

                                                                <span className="ml-2 mr-2">&gt;</span>

                                                                {
                                                                    projectsDataset && projectsDataset.data && projectsDataset.data[task.projectId+''] ? (
                                                                        <Link to={ '/products/' + productsDataset.data[task.productId+'']._id + '/projects/' + projectsDataset.data[task.projectId+'']._id }>{ projectsDataset.data[task.projectId+''].name }</Link>
                                                                    ) : (
                                                                        'Project'
                                                                    )
                                                                }
                                                            </div>

                                                            <div className="R1">
                                                                <div className={ 'priority d-inline-block float-right' +  (task.priority && priorityLevelOptions && priorityLevelOptions[task.priority] ? ' ' + priorityLevelOptions[task.priority].toLowerCase() : '')}>
                                                                    {
                                                                        (task.priority) ? (
                                                                            (priorityLevelOptions && priorityLevelOptions[task.priority]) ? (
                                                                                priorityLevelOptions[task.priority]
                                                                            ) : (
                                                                                task.priority
                                                                            )
                                                                        ) : (
                                                                            'None'
                                                                        )
                                                                    }
                                                                </div>

                                                                <div className="vertical-y">
                                                                    <div className={ 'assignee' + (task.assignedTo ? '' : ' empty') }>
                                                                        {
                                                                            (task.assignedTo) ? (
                                                                                <img className="mr-1 mb-0" src={ avatarPlaceholder }  alt="Avatar" />
                                                                            ) : (
                                                                                <CustomIcon iconKey="profile" color="#C0C0C3" style={{ margin: '0 4px -2px 0' }} /> 
                                                                            )
                                                                        }

                                                                        { task.assignedTo && membersDataset && membersDataset.data && membersDataset.data[task.assignedTo] ? membersDataset.data[task.assignedTo].firstName + ' ' + membersDataset.data[task.assignedTo].lastName : 'No assignee' }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="R2 clearfix mt-3">
                                                                {
                                                                    task.dueDate && (
                                                                        <div className="float-right">
                                                                            <CustomDateFormat date={ task.dueDate } displayCalendarIcon />
                                                                        </div>
                                                                    )   
                                                                }

                                                                {
                                                                    task.activities && task.activities.length > 0 &&  (
                                                                        <span className="ml-1 mr-1">
                                                                            <CustomIcon iconKey="comments" color="#6246EA" /> { task.activities.length }
                                                                        </span>
                                                                    ) 
                                                                }

                                                                {
                                                                    task.dependencies && task.dependencies.length > 0 && (
                                                                        <span className="ml-1 mr-1">
                                                                            <CustomIcon iconKey="dependencies" color="#6246EA" /> { task.dependencies.length }
                                                                        </span>
                                                                    ) 
                                                                }

                                                                {
                                                                    task.checklist && task.checklist.length > 0 && (
                                                                        <span className="ml-1 mr-1">
                                                                            <CustomIcon iconKey="checklist" color="#6246EA" style={{ height: '13px', margin: '0 0 -1px 0' }} /> { task.checklist.length }
                                                                        </span>
                                                                    ) 
                                                                }

                                                                {
                                                                    task.attachments && task.attachments.length > 0 && (
                                                                        <span className="ml-1 mr-1">
                                                                            <CustomIcon iconKey="attachment-horizontal" color="#6246EA" /> { task.attachments.length }
                                                                        </span>
                                                                    ) 
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>  
                                                );
                                            })
                                        ) : (
                                            <div className="text-center p-3" style={{ background: '#F8F8FB', border: '1px solid #DFE0E1', borderRadius: '3px', color: '#8C8C8C' }}>
                                                <div className="title">You have no tasks due today.</div>
                                            </div>                            
                                        )
                                    }
                                </div> 
                            </Collapse.Panel>

                            <Collapse.Panel key="later-this-week" header={ 'Later this week' }>
                                <div className="content">
                                    {
                                        myTasksDueLaterThisWeekDataset && myTasksDueLaterThisWeekDataset.data && Object.keys(myTasksDueLaterThisWeekDataset.data).length > 0 ? (
                                            Object.values(myTasksDueLaterThisWeekDataset.data).map(task => {
                                                return (
                                                    <div key={ task._id } className="task hover-pointer" onClick={ () => { setTaskForViewTaskDrawer({ ...task }) } }>
                                                        <div className="title">{ task.title ? task.title : 'N/A' }</div>

                                                        <div className="info-C">
                                                            <div className="breadcrumbs">
                                                                { 
                                                                    productsDataset && productsDataset.data && productsDataset.data[task.productId+''] ? (
                                                                        <Link to={ '/products/' + productsDataset.data[task.productId+'']._id }>{ productsDataset.data[task.productId+''].name }</Link>
                                                                    ) : (
                                                                        'Product'
                                                                    )
                                                                }

                                                                <span className="ml-2 mr-2">&gt;</span>

                                                                {
                                                                    projectsDataset && projectsDataset.data && projectsDataset.data[task.projectId+''] ? (
                                                                        <Link to={ '/products/' + productsDataset.data[task.productId+'']._id + '/projects/' + projectsDataset.data[task.projectId+'']._id }>{ projectsDataset.data[task.projectId+''].name }</Link>
                                                                    ) : (
                                                                        'Project'
                                                                    )
                                                                }
                                                            </div>

                                                            <div className="R1">
                                                                <div className={ 'priority d-inline-block float-right' +  (task.priority && priorityLevelOptions && priorityLevelOptions[task.priority] ? ' ' + priorityLevelOptions[task.priority].toLowerCase() : '')}>
                                                                    {
                                                                        (task.priority) ? (
                                                                            (priorityLevelOptions && priorityLevelOptions[task.priority]) ? (
                                                                                priorityLevelOptions[task.priority]
                                                                            ) : (
                                                                                task.priority
                                                                            )
                                                                        ) : (
                                                                            'None'
                                                                        )
                                                                    }
                                                                </div>

                                                                <div className="vertical-y">
                                                                    <div className={ 'assignee' + (task.assignedTo ? '' : ' empty') }>
                                                                        {
                                                                            (task.assignedTo) ? (
                                                                                <img className="mr-1 mb-0" src={ avatarPlaceholder }  alt="Avatar" />
                                                                            ) : (
                                                                                <CustomIcon iconKey="profile" color="#C0C0C3" style={{ margin: '0 4px -2px 0' }} /> 
                                                                            )
                                                                        }

                                                                        { task.assignedTo && membersDataset && membersDataset.data && membersDataset.data[task.assignedTo] ? membersDataset.data[task.assignedTo].firstName + ' ' + membersDataset.data[task.assignedTo].lastName : 'No assignee' }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="R2 clearfix mt-3">
                                                                {
                                                                    task.dueDate && (
                                                                        <div className="float-right">
                                                                            <CustomDateFormat date={ task.dueDate } displayCalendarIcon />
                                                                        </div>
                                                                    )   
                                                                }

                                                                {
                                                                    task.activities && task.activities.length > 0 &&  (
                                                                        <span className="ml-1 mr-1">
                                                                            <CustomIcon iconKey="comments" color="#6246EA" /> { task.activities.length }
                                                                        </span>
                                                                    ) 
                                                                }

                                                                {
                                                                    task.dependencies && task.dependencies.length > 0 && (
                                                                        <span className="ml-1 mr-1">
                                                                            <CustomIcon iconKey="dependencies" color="#6246EA" /> { task.dependencies.length }
                                                                        </span>
                                                                    ) 
                                                                }

                                                                {
                                                                    task.checklist && task.checklist.length > 0 && (
                                                                        <span className="ml-1 mr-1">
                                                                            <CustomIcon iconKey="checklist" color="#6246EA" style={{ height: '13px', margin: '0 0 -1px 0' }} /> { task.checklist.length }
                                                                        </span>
                                                                    ) 
                                                                }

                                                                {
                                                                    task.attachments && task.attachments.length > 0 && (
                                                                        <span className="ml-1 mr-1">
                                                                            <CustomIcon iconKey="attachment-horizontal" color="#6246EA" /> { task.attachments.length }
                                                                        </span>
                                                                    ) 
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>  
                                                );
                                            })
                                        ) : (
                                            <div className="text-center p-3" style={{ background: '#F8F8FB', border: '1px solid #DFE0E1', borderRadius: '3px', color: '#8C8C8C' }}>
                                                <div className="title">You have no tasks due today.</div>
                                            </div>                            
                                        )
                                    }
                                </div>      
                            </Collapse.Panel>

                            <Collapse.Panel key="later-this-month" header={ 'Later this month' }>
                                <div className="content">
                                    {
                                        myTasksDueLaterThisMonthDataset && myTasksDueLaterThisMonthDataset.data && Object.keys(myTasksDueLaterThisMonthDataset.data).length > 0 ? (
                                            Object.values(myTasksDueLaterThisMonthDataset.data).map(task => {
                                                return (
                                                    <div key={ task._id } className="task hover-pointer" onClick={ () => { setTaskForViewTaskDrawer({ ...task }) } }>
                                                        <div className="title">{ task.title ? task.title : 'N/A' }</div>

                                                        <div className="info-C">
                                                            <div className="breadcrumbs">
                                                                { 
                                                                    productsDataset && productsDataset.data && productsDataset.data[task.productId+''] ? (
                                                                        <Link to={ '/products/' + productsDataset.data[task.productId+'']._id }>{ productsDataset.data[task.productId+''].name }</Link>
                                                                    ) : (
                                                                        'Product'
                                                                    )
                                                                }

                                                                <span className="ml-2 mr-2">&gt;</span>

                                                                {
                                                                    projectsDataset && projectsDataset.data && projectsDataset.data[task.projectId+''] ? (
                                                                        <Link to={ '/products/' + productsDataset.data[task.productId+'']._id + '/projects/' + projectsDataset.data[task.projectId+'']._id }>{ projectsDataset.data[task.projectId+''].name }</Link>
                                                                    ) : (
                                                                        'Project'
                                                                    )
                                                                }
                                                            </div>

                                                            <div className="R1">
                                                                <div className={ 'priority d-inline-block float-right' +  (task.priority && priorityLevelOptions && priorityLevelOptions[task.priority] ? ' ' + priorityLevelOptions[task.priority].toLowerCase() : '')}>
                                                                    {
                                                                        (task.priority) ? (
                                                                            (priorityLevelOptions && priorityLevelOptions[task.priority]) ? (
                                                                                priorityLevelOptions[task.priority]
                                                                            ) : (
                                                                                task.priority
                                                                            )
                                                                        ) : (
                                                                            'None'
                                                                        )
                                                                    }
                                                                </div>

                                                                <div className="vertical-y">
                                                                    <div className={ 'assignee' + (task.assignedTo ? '' : ' empty') }>
                                                                        {
                                                                            (task.assignedTo) ? (
                                                                                <img className="mr-1 mb-0" src={ avatarPlaceholder }  alt="Avatar" />
                                                                            ) : (
                                                                                <CustomIcon iconKey="profile" color="#C0C0C3" style={{ margin: '0 4px -2px 0' }} /> 
                                                                            )
                                                                        }

                                                                        { task.assignedTo && membersDataset && membersDataset.data && membersDataset.data[task.assignedTo] ? membersDataset.data[task.assignedTo].firstName + ' ' + membersDataset.data[task.assignedTo].lastName : 'No assignee' }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="R2 clearfix mt-3">
                                                                {
                                                                    task.dueDate && (
                                                                        <div className="float-right">
                                                                            <CustomDateFormat date={ task.dueDate } displayCalendarIcon />
                                                                        </div>
                                                                    )   
                                                                }

                                                                {
                                                                    task.activities && task.activities.length > 0 &&  (
                                                                        <span className="ml-1 mr-1">
                                                                            <CustomIcon iconKey="comments" color="#6246EA" /> { task.activities.length }
                                                                        </span>
                                                                    ) 
                                                                }

                                                                {
                                                                    task.dependencies && task.dependencies.length > 0 && (
                                                                        <span className="ml-1 mr-1">
                                                                            <CustomIcon iconKey="dependencies" color="#6246EA" /> { task.dependencies.length }
                                                                        </span>
                                                                    ) 
                                                                }

                                                                {
                                                                    task.checklist && task.checklist.length > 0 && (
                                                                        <span className="ml-1 mr-1">
                                                                            <CustomIcon iconKey="checklist" color="#6246EA" style={{ height: '13px', margin: '0 0 -1px 0' }} /> { task.checklist.length }
                                                                        </span>
                                                                    ) 
                                                                }

                                                                {
                                                                    task.attachments && task.attachments.length > 0 && (
                                                                        <span className="ml-1 mr-1">
                                                                            <CustomIcon iconKey="attachment-horizontal" color="#6246EA" /> { task.attachments.length }
                                                                        </span>
                                                                    ) 
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>  
                                                );
                                            })
                                        ) : (
                                            <div className="text-center p-3" style={{ background: '#F8F8FB', border: '1px solid #DFE0E1', borderRadius: '3px', color: '#8C8C8C' }}>
                                                <div className="title">You have no tasks due today.</div>
                                            </div>                            
                                        )
                                    }
                                </div>                        
                            </Collapse.Panel>
                        </Collapse>
                    </div>

                    { 
                        (taskForViewTaskDrawer && taskForViewTaskDrawer._id && taskForViewTaskDrawer.productId && taskForViewTaskDrawer.projectId) && (<ViewTaskDrawer
                            key={ 'viewTaskDrawer--taskId-' + taskForViewTaskDrawer._id }
                            onClose={ () => { setTaskForViewTaskDrawer(null); } }
                            productId={ taskForViewTaskDrawer.productId }
                            projectId={ taskForViewTaskDrawer.projectId }
                            taskId={ taskForViewTaskDrawer._id }
                            refreshTasks={ () => {} }
                            stageValue={ null }
                        />)
                    }
                </MainLayout>
            </>
        ) : (
            <div className="aligner-xy" style={{ height: '100vh' }}><Spin /></div>
        )
    )
}

export default MyTasks;