import React, { useState, useEffect, useCallback } from 'react';
import { useImmer } from "use-immer";
import { useSystemContext } from '../../Context/SystemContext';

import debounce from 'lodash.debounce';
import moment from 'moment';

import Helmet from 'react-helmet';

import ComandanteAPI from '../../utils/ComandanteAPI';

import MainLayout from "../../Layouts/Main";
import AddUserModal from './AddUserModal/AddUserModal';
import EditUserModal from './EditUserModal/EditUserModal';
import ChangeUserPasswordModal from './ChangeUserPasswordModal/ChangeUserPasswordModal';
import DeleteUserModal from './DeleteUserModal/DeleteUserModal';

import CustomIcon from '../../UIElements/CustomIcon/CustomIcon';
import { Skeleton, Empty, Form, Input, Button, Table, Avatar } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import avatarPlaceholder from '../../assets/images/placeholder-avatar.png';

import './Users.css';

const Users = (props) => {   
    const { 
        currentUser, 
        systemVariables
    } = useSystemContext();

    const roleOptions = (systemVariables && systemVariables.user && systemVariables.user.roleOptions ? systemVariables.user.roleOptions : null);

    const [usersDataset, updateUsersDataset] = useImmer(null);
    const [usersParameters, updateUsersParameters] = useImmer(null);

    const [addUserModalVisible, setAddUserModalVisible] = useState(false);

    const [editUserModalUserId, setEditUserModalUserId] = useState(null);
    const [editUserModalVisible, setEditUserModalVisible] = useState(false);

    const [changeUserPasswordModalUserId, setChangeUserPasswordModalUserId] = useState(null);
    const [changeUserPasswordModalVisible, setChangeUserPasswordModalVisible] = useState(false);
    
    const [deleteUserModalUserId, setDeleteUserModalUserId] = useState(null);
    const [deleteUserModalVisible, setDeleteUserModalVisible] = useState(false);

    const [searchForm] = Form.useForm();

    const fetchUsers = useCallback(async (search = null, sort = null) => {
        updateUsersParameters(usersParameters => {
            return {
                search: search,
                sort: sort
            };
        });

        ComandanteAPI.HttpGetRequest('members?paginated=false' + (search ? '&search=' + search : '') + (sort ? '&sort='+sort : ''), (err, res) => {
            updateUsersDataset(usersDataset => ((res && res.data) || []));
        });
    }, [updateUsersParameters, updateUsersDataset]);

    const debounced_fetchUsers = debounce((search, sort) => {
        fetchUsers(search, sort);
    }, 300);

    useEffect(() => {
        if(currentUser) {
            fetchUsers(((usersParameters && usersParameters.search) || null), ((usersParameters && usersParameters.sort) || null));
        }
	}, [currentUser, fetchUsers]);

    const showChangeUserPasswordModal = (userId) => {
        if(!userId) return;

        setChangeUserPasswordModalUserId(userId);
        setChangeUserPasswordModalVisible(true);
    }

    const usersColumns = [
        {
            title: '',
            dataIndex: 'avatar',
            key: 'avatar',
            width: 32,
            responsive: ['lg', 'xl'],
            render: (text, record) => <Avatar shape="square" size={ 20 } src={ (record.profilePhoto ? process.env.REACT_APP_COMANDANTE_API_ADDRESS + record.profilePhoto.path.substring(7) : avatarPlaceholder) } alt={ 'Avatar | ' + record.firstName + ' ' + record.lastName } height={ 20 } />
        },
        {
            title: 'Member',
            dataIndex: 'member',
            key: 'member',
            sorter: true,
            render: (text, record) => <span>{ record.firstName + ' ' + record.lastName }</span>
        },
        {
            title: 'Username',
            dataIndex: 'username',
            key: 'username',
            responsive: ['lg', 'xl'],
            sorter: true,
            render: (text, record) => <span>{ record.username }</span>,
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
            responsive: ['xl'],
            sorter: true,
            render: (text) => <span>{ text }</span>,
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            responsive: ['md', 'lg', 'xl'],
            sorter: true,
            render: (roleId) => <span>{ roleOptions && roleOptions[roleId] ? roleOptions[roleId] : null }</span>,
        },
        {
            title: 'Member Since',
            dataIndex: 'createdAt',
            key: 'createdAt',
            responsive: ['xl'],
            sorter: true,
            width: '150px',
            render: (text, record) => <span>{ moment(record.createdAt).format('LL') }</span>,
        },
        {
            title: '',
            key: 'actions',
            responsive: ['xs', 'sm', 'md', 'lg', 'xl'],
            render: (text, record) => {
                return (
                    <>
                        <Button className="btn-nothing action pr-1 pl-1" onClick={ () => { setEditUserModalUserId(record._id); setEditUserModalVisible(true); } } style={{ color: '#6246EA' }}><CustomIcon style={{ margin: '6px 4px 0 0' }} iconKey="edit-2" color="#6246EA" /> <span className="d-xs-none">Edit</span></Button>
                        <Button className="btn-nothing action pl-1 pr-1" onClick={ () => { setDeleteUserModalUserId(record._id); setDeleteUserModalVisible(true); } } style={{ color: '#E45858' }}><CustomIcon style={{ margin: '6px 4px 0 0' }} iconKey="delete-2" color="#E45858" /> <span className="d-xs-none">Delete</span></Button>
                    </>
                );
            }
        }
    ];

    return (
        <>
            <Helmet>
                <title> Users | { process.env.REACT_APP_META_TITLE }</title>
            </Helmet>

            <MainLayout>
                <>
                    <div className="users--section-header">
                        <div className="float-right">
                            <Form form={ searchForm } layout="vertical" className="d-xs-none d-sm-none d-inline">
                                <Form.Item className="users--search-users-input d-inline-block" name="search">
                                    <Input 
                                        style={{ zIndex: 999, maxWidth: '292px' }}
                                        prefix={ <SearchOutlined /> }
                                        placeholder="Search Users"
                                        onChange={ (event) => {
                                            event.persist(); // Signal to React not to nullify the event object

                                            debounced_fetchUsers(event.target.value, ((usersParameters && usersParameters.sort) || null));
                                        }}
                                    />
                                </Form.Item>
                            </Form>

                            <Button
                                className="btn-purple d-inline ml-4"
                                onClick={ () => { setAddUserModalVisible(true) } }
                            >
                                <CustomIcon iconKey="add" color="#FFFFFF" style={{ margin: '0 4px -1px 0' }} /> Add <span className="d-xs-none d-sm-none">&nbsp; New User</span>
                            </Button>
                        </div>
                        
                        <h2>Manage Users</h2>
                    </div>

                    <div className="users--section-body">
                        {
                            (usersDataset) ? (
                                (usersDataset.data) ? (
                                    <Table
                                        rowKey="_id"
                                        className="users-table"
                                        columns={ usersColumns }
                                        dataSource={ Object.values(usersDataset.data).length > 0 ? Object.values(usersDataset.data) : [] }
                                        pagination={ usersDataset.pagination ? usersDataset.pagination : false }
                                        onChange={ 
                                            (pagination, filters, sorter, extra) => {
                                                if(sorter.field && sorter.order) fetchUsers(((usersParameters && usersParameters.search) || null), sorter.field + '+' + sorter.order);
                                                else fetchUsers(((usersParameters && usersParameters.search) || null), null);
                                            }
                                        }
                                    />
                                ) : (
                                    <div className="empty-wrapper">
                                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ margin: '0 auto 0 auto' }} />
                                    </div>
                                )
                            ) : (
                                <Skeleton active />
                            )
                        }
                    </div>

                    { addUserModalVisible && <AddUserModal setVisible={ setAddUserModalVisible } refreshUsers={ fetchUsers } /> }
                    { editUserModalVisible && editUserModalUserId && <EditUserModal setVisible={ setEditUserModalVisible } userId={ editUserModalUserId } refreshUsers={ fetchUsers } showChangeUserPasswordModal={ showChangeUserPasswordModal } /> }
                    { changeUserPasswordModalVisible && changeUserPasswordModalUserId && <ChangeUserPasswordModal setVisible={ setChangeUserPasswordModalVisible } userId={ changeUserPasswordModalUserId } /> }
                    { deleteUserModalVisible && deleteUserModalUserId && <DeleteUserModal setVisible={ setDeleteUserModalVisible } userId={ deleteUserModalUserId } refreshUsers={ fetchUsers } /> }
                </>
            </MainLayout>
        </>
    )
}

export default Users;