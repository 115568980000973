import React, { useState, useEffect, useCallback } from 'react';
import { useSystemContext } from '../../../Context/SystemContext';
import { useHistory } from 'react-router-dom';

import ComandanteAPI from '../../../utils/ComandanteAPI';

import ProductIcon, { ProductIconsSet } from '../../../UIElements/ProductIcon/ProductIcon';
import { ColorSet } from '../../../UIElements/ColorPicker/ColorPicker';

import { Spin, Input, Button, Row, Col, Modal, message, Form, Select } from 'antd';

import '../Product.css';

import productDetailsModalBG from '../../../assets/images/product-details-modal-BG.png';

import Error from '../../Error/Error';

const EditProductModal = ({ productId, setVisible, ...restProps }) => {
    const history = useHistory();
    const {
        systemVariables,
    } = useSystemContext();

    const stageOptions = (systemVariables && systemVariables.product && systemVariables.product.stageOptions ? systemVariables.product.stageOptions : null);

    const [productDataset, setProductDataset] = useState(null);

    const [editProductDetailsForm] = Form.useForm();
    const [editProductErrors, setEditProductErrors] = useState([]);

    const [selectedProductIconKey, setSelectedProductIconKey] = useState(null);
    const [selectedProductIconColor, setSelectedProductIconColor] = useState(null);

    const [pageError, setPageError] = useState(null);

    const fetchProduct = useCallback(() => {
        ComandanteAPI.HttpGetRequest('products/' + productId + '/get', (err, res) => {
            if(err) {
                setPageError(<Error status={ (err.status ? err.status : null) } />);
                return;
            }

            if(!res || !res.data) {
                setPageError(<Error status={ 404 } />);
                return;
            }

            setProductDataset((res && res.data) || null);
        });
    }, [productId]);

    useEffect(() => {
        fetchProduct();
    }, [fetchProduct]);

    useEffect(() => {
        if(productDataset && productDataset.product) {
            setSelectedProductIconKey(productDataset.product.iconKey ? productDataset.product.iconKey : null);
            setSelectedProductIconColor(productDataset.product.iconColor ? productDataset.product.iconColor : null);
        }
    }, [productDataset]);

    useEffect(() => {
        if(editProductDetailsForm && editProductDetailsForm.__INTERNAL__ && editProductDetailsForm.__INTERNAL__.name) {
            editProductDetailsForm.setFieldsValue({
                name: (productDataset && productDataset.product && productDataset.product.name) || null,
                description: (productDataset && productDataset.product && productDataset.product.description) || null,
                type: (productDataset && productDataset.product && productDataset.product.type) || null,
                stage: { value: (productDataset && productDataset.product && productDataset.product.stage && productDataset.product.stage+'') || null },
                iconKey: { value: (productDataset && productDataset.product && productDataset.product.iconKey && productDataset.product.iconKey) || null },
                iconColor: { value: (productDataset && productDataset.product && productDataset.product.iconColor && productDataset.product.iconColor) || null },
            });
        }
    }, [editProductDetailsForm, productDataset]);

    const onEditProductDetailsFinish = values => {
        let editDetailsFormValues = { ...values, iconKey: { value: selectedProductIconKey }, iconColor: { value: selectedProductIconColor } }

        ComandanteAPI.HttpPostRequest('products/' + productId + '/edit', editDetailsFormValues, (err, res) => {
            if(err || !res) {
                if(err && err.data && err.data.errors) {
                    let formErrors = [];
                    err.data.errors.forEach(formErr => {
                        if(formErr.param && formErr.msg) formErrors[formErr.param] = formErr.msg;
                    })
                    setEditProductErrors(formErrors);
                    return;
                }

                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res && res.status === 200) {
                history.go(0);
            }
        });
    };

    const handleEditProductDetailsModalOk = () => {
        editProductDetailsForm.submit();
        setVisible(false);
    };

    if(pageError) return pageError;

    return (
        <Modal className="edit-product-modal-C" title={ null } footer={ null } visible={ true } onCancel={ () => { setVisible(false); } } width={ 640 }>
            <div className="modal-header-W">
                <img className="d-xs-none w-100" src={ productDetailsModalBG } alt="Edit Product Details" style={{ maxWidth: '180px', margin: '0 auto 0 auto' }} />
                <div className="mt-5">Edit Product Details</div>
                <div className="d-sm-none d-md-none d-lg-none d-xl-none mt-4"></div>
            </div>

            <div className="modal-body-W edit-product-step-1">
                { 
                    (productDataset && productDataset.product) ? (
                        <Form form={ editProductDetailsForm } initialValues={{}} name="editProductDetails" onFinish={ onEditProductDetailsFinish } layout="vertical" hideRequiredMark={ true }>
                            <Form.Item
                                name="name"
                                label="PRODUCT NAME"
                                rules={ [{ required: true, message: 'Please enter a valid product name.' }, { max: 24, message: 'Maximum of 24 characters are allowed.' }] }
                                validateStatus={ editProductErrors && editProductErrors.name ? "error" : null }
                                help={ editProductErrors && editProductErrors.name ? editProductErrors.name : null }
                            >
                                <Input />
                            </Form.Item>
                        
                            <Form.Item
                                name="description"
                                label="DESCRIPTION"
                                rules={ [{ required: true, message: 'Please enter a valid product description.' }, { max: 256, message: 'Maximum of 256 characters are allowed.' }] }
                                validateStatus={ editProductErrors && editProductErrors.description ? "error" : null }
                                help={ editProductErrors && editProductErrors.description ? editProductErrors.description : null }
                            >
                                <Input.TextArea rows={ 4 } />
                            </Form.Item>

                            <Row gutter={ 24 }>
                                <Col span={ 12 }>
                                    <Form.Item
                                        name="type"
                                        label="PRODUCT TYPE"
                                        validateStatus={ editProductErrors && editProductErrors.type ? "error" : null }
                                        help={ editProductErrors && editProductErrors.type ? editProductErrors.type : null }
                                    >
                                        <Input />
                                    </Form.Item>
                                </Col>

                                <Col span={ 12 }>
                                    <Form.Item
                                        name="stage"
                                        label="STAGE"
                                        validateStatus={ editProductErrors && editProductErrors.stage ? "error" : null }
                                        help={ editProductErrors && editProductErrors.stage ? editProductErrors.stage : null }
                                    >
                                        <Select labelInValue>
                                            {
                                                stageOptions && Object.keys(stageOptions).map(pKey => {
                                                    return <Select.Option key={ pKey+'' } value={ pKey+'' }>{ stageOptions[pKey] }</Select.Option>;
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={ 24 }>
                                <Col span={ 6 }>
                                    <Form.Item
                                        label="ICON"
                                        name="iconKey"
                                    >
                                        <Select 
                                            className="svg-select-box"
                                            labelInValue
                                            onSelect={ (selected) => { setSelectedProductIconKey(selected.value); } }
                                        >
                                            { 
                                                ProductIconsSet() && Object.keys(ProductIconsSet()).map((iconKey) => {
                                                    return (
                                                        <Select.Option key={ iconKey } value={ iconKey }>
                                                            <ProductIcon iconKey={ iconKey } />
                                                        </Select.Option>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={ 6 }>
                                    <Form.Item
                                        label="COLOR"
                                        name="iconColor"
                                    >
                                        <Select 
                                            labelInValue
                                            onSelect={ (selected) => { setSelectedProductIconColor(selected.value); } }
                                        >
                                            { 
                                                ColorSet() && Array.isArray(ColorSet()) && ColorSet().length > 0 && ColorSet().map((color) => {
                                                    return (
                                                        <Select.Option key={ color } value={ color }>
                                                            <span style={{ fontSize: '28px', lineHeight: 1, color: color }}>&#8226;</span>
                                                        </Select.Option>
                                                    );
                                                })
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>

                                <Col span={ 12 }>
                                    <Form.Item>
                                        <div style={{ margin: '28px 0 0 0' }}>
                                            <ProductIcon iconKey={ selectedProductIconKey || (productDataset && productDataset.product && productDataset.product.iconKey) } color={ selectedProductIconColor || (productDataset && productDataset.product && productDataset.product.iconColor) } />
                                        </div>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    ) : (
                        <div className="aligner-xy" style={{ height: '100%' }}><Spin /></div>
                    )
                }
            </div>

            <div className="modal-footer-W text-right" style={{ background: '#F8F8FB' }}>
                <div>
                    <Button className="btn-white mr-2" onClick={ () => { setVisible(false) } }>Cancel</Button>
                    <Button className="btn-purple" onClick={ () => { handleEditProductDetailsModalOk() } }>Save Changes</Button>
                </div>
            </div>
        </Modal>
    )
}

export default EditProductModal;