import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import Helmet from 'react-helmet';

import { BrowserRouter } from 'react-router-dom';

import reportWebVitals from './reportWebVitals';

import './index.css';

ReactDOM.render(
    <BrowserRouter>
        <>
            <Helmet>
                <title>{ process.env.REACT_APP_META_TITLE }</title>
                <meta charSet="utf-8" />
            </Helmet>


            <App />
        </>
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
