import React, { useState, useRef } from 'react';
import { useSystemContext } from '../../Context/SystemContext';

import ComandanteAPI from '../../utils/ComandanteAPI';

import { Link } from 'react-router-dom';

import { Layout, Button, Dropdown, Menu, Avatar, Form, Modal, Input, message } from 'antd';
import { UserOutlined, MenuOutlined } from '@ant-design/icons';

import SearchTaskDrawer from '../../Pages/Task/Drawers/SearchTaskDrawer';

import CustomIcon from '../../UIElements/CustomIcon/CustomIcon';
import logo from '../../assets/images/logo-texture.png';

import './Header.css';

const Header = ({ toggleSidebar }) => {
    const { 
        currentUser
    } = useSystemContext();

    const [searchTaskDrawerVisible, setSearchTaskDrawerVisible] = useState(false);

    const [changeMyPasswordModalVisible, setChangeMyPasswordModalVisible] = useState(false);
    const [changeMyPasswordForm] = Form.useForm();
    const [changeMyPasswordFormErrors, setChangeMyPasswordFormErrors] = useState([]);

    const refPasswordRulesLowercaseCharacter = useRef(null);
    const refPasswordRulesUppercaseCharacter = useRef(null);
    const refPasswordRulesNumber = useRef(null);
    const refPasswordRules8CharactersMinimum = useRef(null);

    const handleChangeMyPasswordModalCancel = () => {
        setChangeMyPasswordModalVisible(false);
    };

    const onChangeMyPasswordFormPasswordChange = (event) => {
        let password = '';
        if(event && event.target && event.target.value) password = event.target.value;

        if(refPasswordRulesLowercaseCharacter.current) {
            if(/[a-z]/.test(password)) {
                refPasswordRulesLowercaseCharacter.current.className = 'rule success';
            } else {
                refPasswordRulesLowercaseCharacter.current.className = 'rule danger';
            }
        }

        if(refPasswordRulesUppercaseCharacter.current) {
            if(/[A-Z]/.test(password)) {
                refPasswordRulesUppercaseCharacter.current.className = 'rule success';
            } else {
                refPasswordRulesUppercaseCharacter.current.className = 'rule danger';
            }
        }

        if(refPasswordRulesNumber.current) {
            if(/\d/.test(password)) {
                refPasswordRulesNumber.current.className = 'rule success';
            } else {
                refPasswordRulesNumber.current.className = 'rule danger';
            }
        }

        if(refPasswordRules8CharactersMinimum.current) {
            if(password.length >= 8) {
                refPasswordRules8CharactersMinimum.current.className = 'rule success';
            } else {
                refPasswordRules8CharactersMinimum.current.className = 'rule danger';
            }
        }
    }

    const handleChangeMyPasswordFormSubmit = (values) => {
        setChangeMyPasswordFormErrors(null);

        ComandanteAPI.HttpPostRequest('auth/change-password', { ...values }, (err, res) => {
            if(err && err.data && err.data.errors) {
                let formErrors = {};
                for(let formError of err.data.errors) {
                    if(formError.param && formError.msg) formErrors[formError.param] = formError.msg;
                }

                if(formErrors && Object.keys(formErrors).length > 0) {
                    setChangeMyPasswordFormErrors(formErrors);
                    return;
                }
            }

            if(res && res.data && res.data.result === true) {
                setChangeMyPasswordModalVisible(false);
                changeMyPasswordForm.resetFields();
                message.success('Your password has successfully been changed.');
            }
        });
    }

    const logout = () => {
        localStorage.removeItem('token');
        window.location.href = '/login';
        return;
    }

    return (
        <Layout.Header className="header-C" style={{ position: 'fixed', zIndex: 1, width: '100%' }}>
            <div className="actions-W" style={{ position: 'absolute', right: '20px', zIndex: '999' }}>
                <Button className="btn-nothing mr-1 pl-0 pr-0" onClick={ () => { setSearchTaskDrawerVisible(!searchTaskDrawerVisible) } }>
                    <CustomIcon iconKey="search" color="#6246EA" style={{ height: '13px', margin: '0 0 -1px 0' }} />
                </Button>

                <Dropdown overlay={
                    (
                        <Menu>
                            <Menu.Item onClick={ () => { setChangeMyPasswordModalVisible(true) } }>
                                <CustomIcon style={{ width: '15px', margin: '0 0 0 0' }} iconKey="user" /> Change Password
                            </Menu.Item>
                            <Menu.Item onClick={ () => { logout() } } style={{ color: '#E45858' }}>
                                <CustomIcon style={{ width: '15px', margin: '0 0 0 0' }} iconKey="logout" color="#E45858" /> Logout
                            </Menu.Item>
                        </Menu>
                    )
                } placement="bottomRight" arrow>
                    {
                        (currentUser && currentUser.profilePhoto) ? (
                            <Button className="action"><Avatar shape="square" size={ 20 } src={ process.env.REACT_APP_COMANDANTE_API_ADDRESS + currentUser.profilePhoto.path.substring(7) } alt={ 'Avatar | ' + currentUser.firstName + ' ' + currentUser.lastName } height={ 20 } style={{ margin: '0 8px 0 8px' }} /></Button>
                        ) : (
                            <Button className="action"><span className="icon">{ <UserOutlined /> }</span></Button>
                        )
                    }
                </Dropdown>
            </div>

            <Button className="btn-nothing d-sm-none d-md-none d-lg-none d-xl-none float-left pl-0 pr-0 mt-3" onClick={ () => { toggleSidebar(); } }><MenuOutlined style={{ fontSize: '18px', color: '#6246EA' }} /></Button>

            <Link className="d-xs-none" to='/'>
                <img src={ logo } alt="Logo" />
            </Link>

            <div className="text-center d-sm-none d-md-none d-lg-none d-xl-none mr-4" style={{ margin: '0 auto 0 auto' }}>
                <Link to='/'>
                    <img src={ logo } alt="Logo" />
                </Link>
            </div>

            {
                (searchTaskDrawerVisible) && (
                    <SearchTaskDrawer onClose={ () => { setSearchTaskDrawerVisible(false) } } />
                )
            }

            {
                (changeMyPasswordModalVisible) && (
                    <Modal title={ null } footer={ null } visible={ true } onCancel={ () => handleChangeMyPasswordModalCancel } width={ 540 }>
                        <div key={ 'changeMyPasswordModal' } className={ 'changeMyPasswordModal' }>
                            <div className="changeMyPasswordModal-header">
                                <h2> Change Password </h2>
                            </div>

                            <div className="changeMyPasswordModal-body">
                                <Form form={ changeMyPasswordForm } name="changeMyPasswordForm" onFinish={ handleChangeMyPasswordFormSubmit } layout="vertical" hideRequiredMark={ true }>
                                    <Form.Item
                                        name="currentPassword"
                                        label="Current Password"
                                        validateStatus={ changeMyPasswordFormErrors && changeMyPasswordFormErrors.currentPassword ? "error" : null }
                                        help={ changeMyPasswordFormErrors && changeMyPasswordFormErrors.currentPassword ? changeMyPasswordFormErrors.currentPassword : null }
                                    >
                                        <Input.Password />
                                    </Form.Item>

                                    <Form.Item
                                        name="newPassword"
                                        label="New Password"
                                        onChange={ onChangeMyPasswordFormPasswordChange }
                                        validateStatus={ changeMyPasswordFormErrors && changeMyPasswordFormErrors.newPassword ? "error" : null }
                                        help={ changeMyPasswordFormErrors && changeMyPasswordFormErrors.newPassword ? changeMyPasswordFormErrors.newPassword : null }
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                    
                                    <div className="password-rules">
                                        <div className="rule" ref={ refPasswordRulesLowercaseCharacter }><div className="box"></div> Lowercase character</div>
                                        <div className="rule" ref={ refPasswordRulesUppercaseCharacter }><div className="box"></div> Uppercase character</div>
                                        <div className="rule" ref={ refPasswordRulesNumber }><div className="box"></div> Number</div>
                                        <div className="rule" ref={ refPasswordRules8CharactersMinimum }><div className="box"></div> 8 characters minimum</div>
                                    </div>
                                </Form>
                            </div>
                            
                            <div className="changeMyPasswordModal-footer">
                                <Button className="btn-white" onClick={ () => { handleChangeMyPasswordModalCancel() } }>Cancel</Button>
                                <Button className="btn-purple float-right" onClick={ () => { changeMyPasswordForm.submit(); } }>Save</Button>
                            </div>
                        </div>
                    </Modal>
                )
            }
        </Layout.Header>
    );
}

export default Header;
