import React, { useState, useEffect, useCallback } from 'react';
import { useImmer } from "use-immer";
import { useParams } from 'react-router-dom';
import { useSystemContext } from '../../../Context/SystemContext';

import ComandanteAPI from '../../../utils/ComandanteAPI';

import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import { ColorSet } from '../../../UIElements/ColorPicker/ColorPicker';
import CustomIcon from '../../../UIElements/CustomIcon/CustomIcon';

import { Avatar, Row, Col, Modal, Form, Input, Select, Button, Table, Dropdown, Menu, message } from 'antd';
import { GlobalOutlined, LockFilled, UnlockFilled, PlusOutlined, EllipsisOutlined, EditOutlined } from '@ant-design/icons';

import '../Product.css';

import avatarPlaceholder from '../../../assets/images/placeholder-avatar.png';
import createProjectModalBG from '../../../assets/images/create-project-modal-BG.png';
import privacySettingsModalBG from '../../../assets/images/privacy-settings-modal-BG.png';
import removeIcon from '../../../assets/images/icon-remove.png';

const DragHandle = sortableHandle(() => <div className="text-center drag-handle"><CustomIcon style={{ margin: '4px 0 0 0' }} iconKey="drag" color="#C0C0C3" /></div>);

const SortableItem = sortableElement(props => <Form.Item { ...props } />);
const SortableContainer = sortableContainer(props => <div { ...props } />);

const AddProjectModal = ({ setVisible }) => {
    const { productId } = useParams();

    const { 
        currentUser,
        systemVariables
    } = useSystemContext();

    const roleOptions = (systemVariables && systemVariables.user && systemVariables.user.roleOptions ? systemVariables.user.roleOptions : null);

    const [productTeamsDataset, updateProductTeamsDataset] = useImmer(null);
    const [teamsToAdd, updateTeamsToAdd] = useImmer(null);
    const [productMembersDataset, updateProductMembersDataset] = useImmer(null);
    const [membersToAdd, updateMembersToAdd] = useImmer(null);
    const [combinedTeamsAndMembersData, updateCombinedTeamsAndMembersData] = useImmer(null);
    const [combinedTeamsAndMembersDataFilter, setCombinedTeamsAndMembersDataFilter] = useState(null);
    const [combinedTeamsAndMembersToAdd, updateCombinedTeamsAndMembersToAdd] = useImmer(null);

    const [addProjectModalStep, setAddProjectModalStep] = useState(1);
    const [addProjectForm_step1] = Form.useForm();
    const [addProjectForm_step4] = Form.useForm();
    const [addProjectForm_step5] = Form.useForm();
    const [addProjectErrors_step1, setAddProjectErrors_step1] = useState([]);
    const [addProjectErrors_step5, setAddProjectErrors_step5] = useState([]);
    const [addProjectValues, updateAddProjectValues] = useImmer(null);

    const [selectedProjectIconColor, setSelectedProjectIconColor] = useState(ColorSet()[0]);
    const [projectIsAccessibleToAllProductTeamsAndMembers, setProjectIsAccessibleToAllProductTeamsAndMembers] = useState(true);
    
    const [stageColors, updateStageColors] = useImmer(null);
    const [stageLockedValues, updateStageLockedValues] = useImmer(null);

    let stageRefs = {};
    const setStageRefs = (index, ref) => {
        stageRefs[''+index] = ref;
    }

    const getProductTeams = useCallback(() => {
        ComandanteAPI.HttpGetRequest('products/' + productId + '/teams?paginated=false', (err, res) => {
            updateProductTeamsDataset(productTeamsDataset => (res && res.data) || []);
        });
    }, [productId, updateProductTeamsDataset]);

    const getProductMembers = useCallback(() => {
        ComandanteAPI.HttpGetRequest('products/' + productId + '/members?paginated=false&excludeTeamMembers=true', (err, res) => {
            updateProductMembersDataset(productMembersDataset => (res && res.data) || []);
        });
    }, [productId, updateProductMembersDataset]);

    useEffect(() => {
        getProductTeams();
        getProductMembers();
    }, [getProductTeams, getProductMembers]);

    useEffect(() => {
        if(currentUser) {
            if(productMembersDataset && productMembersDataset.data && productMembersDataset.data[currentUser._id+'']) {
                updateMembersToAdd(membersToAdd => {
                    if(!membersToAdd || !Array.isArray(membersToAdd)) return [currentUser._id+''];
                    if(!membersToAdd.includes(currentUser._id+'')) membersToAdd.push(currentUser._id+'');
                });
            }
        }
    }, [currentUser, productMembersDataset, updateMembersToAdd]);

    useEffect(() => {
        if(productTeamsDataset && productMembersDataset) {
            let xCombinedTeamsAndMembersData = {};
            
            if(productTeamsDataset.data && Object.keys(productTeamsDataset.data).length > 0) {
                for(let team of Object.values(productTeamsDataset.data)) {
                    xCombinedTeamsAndMembersData[team._id+''] = { ...team, isTeam: true };
                }
            }

            if(productMembersDataset.data && Object.keys(productMembersDataset.data).length > 0) {
                for(let member of Object.values(productMembersDataset.data)) {
                    xCombinedTeamsAndMembersData[member._id+''] = { ...member, isTeam: false };
                }
            }
            
            updateCombinedTeamsAndMembersData(combinedTeamsAndMembersData => xCombinedTeamsAndMembersData);
        }
    }, [productTeamsDataset, productMembersDataset, updateCombinedTeamsAndMembersData]);

    useEffect(() => {
        if(teamsToAdd || membersToAdd) {
            let xCombinedTeamsAndMembersToAdd = [];
            
            if(teamsToAdd && Array.isArray(teamsToAdd) && teamsToAdd.length > 0) {
                for(let teamId of teamsToAdd) {
                    xCombinedTeamsAndMembersToAdd.push(teamId);
                }
            }

            if(membersToAdd && Array.isArray(membersToAdd) && membersToAdd.length > 0) {
                for(let memberId of membersToAdd) {
                    xCombinedTeamsAndMembersToAdd.push(memberId);
                }
            }
            
            updateCombinedTeamsAndMembersToAdd(combinedTeamsAndMembersToAdd => xCombinedTeamsAndMembersToAdd);
        }
    }, [teamsToAdd, membersToAdd, updateCombinedTeamsAndMembersToAdd]);

    useEffect(() => {
        if(stageColors && Array.isArray(stageColors) && stageColors.length > 0) {
            for(let [index, stageColor] of stageColors.entries()) {
                if(stageRefs && stageRefs[''+index] && stageRefs[''+index].input) {
                    stageRefs[''+index].input.style.color = stageColor;
                }
            }
        }
    }, [stageColors, stageRefs]);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        addProjectForm_step4.setFieldsValue({
            stages: arrayMove([].concat(addProjectForm_step4.getFieldsValue(true)['stages']), oldIndex, newIndex)
        });

        updateStageColors(stageColors => {
            let stageColor = stageColors[oldIndex]+'';
            let nStageColors = [ ...stageColors ];

            nStageColors.splice(oldIndex, 1);
            nStageColors.splice(newIndex, 0, stageColor);

            return nStageColors;
        });
    };

    const handleAddProjectModalCancel = () => { setVisible(false) };

    const handleAddProjectModalNext = (values) => {
        if(addProjectModalStep === 1) {
            addProjectForm_step1.submit();
        } else if(addProjectModalStep === 2) {
            updateAddProjectValues(addProjectValues => {
                return { ...addProjectValues, isPublic: projectIsAccessibleToAllProductTeamsAndMembers }
            });
            
            if(!projectIsAccessibleToAllProductTeamsAndMembers) setAddProjectModalStep(3);
            else setAddProjectModalStep(4);
        } else if(addProjectModalStep === 3) {
            updateAddProjectValues(addProjectValues => {
                return { ...addProjectValues, teamIds: teamsToAdd, memberIds: membersToAdd };
            });
            setAddProjectModalStep(4);
        } else if(addProjectModalStep === 4) {
            updateAddProjectValues(addProjectValues => {
                return { ...addProjectValues, stages: addProjectForm_step4.getFieldValue('stages'), stageColors: stageColors, stageLockedValues: stageLockedValues }
            })

            setAddProjectModalStep(5);
        } else if(addProjectModalStep === 5) {
            addProjectForm_step5.submit();
        }
    }

    const handleAddProjectModalPrev = (values) => {
        if(addProjectModalStep === 2) {
            setAddProjectModalStep(1);
        } else if(addProjectModalStep === 3) {
            setAddProjectModalStep(2);
        } else if(addProjectModalStep === 4) {
            if(!projectIsAccessibleToAllProductTeamsAndMembers) setAddProjectModalStep(3);
            else setAddProjectModalStep(2);
        } else if(addProjectModalStep === 5) {
            setAddProjectModalStep(4);
        }
    }

    const onAddProjectFinish_step1 = values => {
        let addProjectFormValues = { ...values, iconColor: selectedProjectIconColor };

        ComandanteAPI.HttpPostRequest('products/' + productId + '/projects/add?validationOnly=true', addProjectFormValues, (err, res) => {
            if(err || !res) {    
                if(err && err.data && err.data.errors) {
                    let formErrors = [];
                    for(let formError of err.data.errors) {
                        if(formError.param && formError.msg) formErrors[formError.param] = formError.msg;
                    }

                    setAddProjectErrors_step1(formErrors);
                    return;
                }

                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.data && res.data.result === true) {
                updateAddProjectValues(addProjectValues => {
                    return { ...addProjectValues, ...addProjectFormValues };
                });
                setAddProjectModalStep(2);
            }
        });
    }
    
    const onAddProjectFinish_step5 = values => {
        ComandanteAPI.HttpPostRequest('products/' + productId + '/projects/add', { ...addProjectValues, ...values }, (err, res) => {
            if(err || !res) {    
                if(err && err.data && err.data.errors) {
                    let formErrors = [];
                    for(let formError of err.data.errors) {
                        if(formError.param && formError.msg) formErrors[formError.param] = formError.msg;
                    }

                    setAddProjectErrors_step5(formErrors);
                    return;
                }

                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.data && res.data.result === true) {
                window.location.href = '/products/' + productId + '/projects/' + res.data.project._id;
            }
        });
    }

    const combinedTeamsAndMembersColumns = [
        {
            width: 32,
            render: (text, record) => {
                if(record.isTeam) {
                    return <Avatar shape="square" size={ 20 } style={{ backgroundColor: '#c41d7f' }}>{ record.name[0] }</Avatar>
                } else {
                    return <Avatar shape="square" size={ 20 } src={ (record.profilePhoto ? process.env.REACT_APP_COMANDANTE_API_ADDRESS + record.profilePhoto.path.substring(7) : avatarPlaceholder) } alt={ 'Avatar | ' + record.firstName + ' ' + record.lastName } height={ 20 } />
                }
            }
        },
        {
            title: 'Team / User',
            render: (text, record) => (record.isTeam ? record.name : record.firstName + ' ' + record.lastName)
        },
        {
            title: 'Role',
            width: 180,
            render: (text, record) => {
                if(record.isTeam) {
                    return 'N/A';
                } else {
                    if(!roleOptions || !roleOptions[record.role]) return 'Unknown';
                    return roleOptions[record.role+''];
                }
            }
        },
        {
            width: 60,
            render: (text, record) => {
                return (
                    (!combinedTeamsAndMembersToAdd || !Array.isArray(combinedTeamsAndMembersToAdd) || !combinedTeamsAndMembersToAdd.includes(record._id+'')) && (
                        <Button 
                            className="btn-white mt-1" 
                            style={{ height: '25px', lineHeight: '25px' }}
                            onClick={ 
                                () => {
                                    if(record.isTeam) {
                                        updateTeamsToAdd(teamsToAdd => {
                                            if(!teamsToAdd || !Array.isArray(teamsToAdd)) return [record._id+''];
                                            if(!teamsToAdd.includes(record._id+'')) teamsToAdd.push(record._id+'');
                                        });
                                    } else {
                                        updateMembersToAdd(membersToAdd => {
                                            if(!membersToAdd || !Array.isArray(membersToAdd)) return [record._id+''];
                                            if(!membersToAdd.includes(record._id+'')) membersToAdd.push(record._id+'');
                                        });
                                    }
                                } 
                            }
                        >
                            Add
                        </Button>
                    )
                );
            }
        }
    ];

    const combinedTeamsAndMembersAddColumns = [
        {
            width: 32,
            render: (recordId) => {
                if(!combinedTeamsAndMembersData || !combinedTeamsAndMembersData[recordId+'']) return 'Unknown';

                if(combinedTeamsAndMembersData[recordId+''].isTeam) {
                    return <Avatar shape="square" size={ 20 } style={{ backgroundColor: '#c41d7f' }}>{ combinedTeamsAndMembersData[recordId+''].name[0] }</Avatar>
                } else {
                    return <Avatar shape="square" size={ 20 } src={ (combinedTeamsAndMembersData[recordId+''].profilePhoto ? process.env.REACT_APP_COMANDANTE_API_ADDRESS + combinedTeamsAndMembersData[recordId+''].profilePhoto.path.substring(7) : avatarPlaceholder) } alt={ 'Avatar | ' + combinedTeamsAndMembersData[recordId+''].firstName + ' ' + combinedTeamsAndMembersData[recordId+''].lastName } height={ 20 } />
                }
            }
        },
        {
            title: 'Team / User',
            render: (recordId) => {
                if(!combinedTeamsAndMembersData || !combinedTeamsAndMembersData[recordId+'']) return 'Unknown';
                if(combinedTeamsAndMembersData[recordId+''].isTeam) {
                    return combinedTeamsAndMembersData[recordId+''].name;
                } else {
                    return combinedTeamsAndMembersData[recordId+''].firstName + ' ' + combinedTeamsAndMembersData[recordId+''].lastName + (recordId+'' === currentUser._id+'' ? ' (You)' : '');
                }
            }
        },
        {
            title: 'Role',
            width: 180,
            render: (recordId) => {
                if(!combinedTeamsAndMembersData || !combinedTeamsAndMembersData[recordId+'']) return 'Unknown';
                if(combinedTeamsAndMembersData[recordId+''].isTeam) {
                    return 'N/A';
                } else {
                    if(!roleOptions || !roleOptions[combinedTeamsAndMembersData[recordId+''].role]) return 'Unknown';
                    return roleOptions[combinedTeamsAndMembersData[recordId+''].role+''];
                }
            }
        },
        {
            width: 60,
            render: (recordId) => {
                return (
                    (recordId+'' !== currentUser._id+'') && (
                        <Button 
                            className="btn-nothing mt-1" 
                            style={{ height: '25px', lineHeight: '25px' }}
                            onClick={ 
                                () => {
                                    if(!combinedTeamsAndMembersData || !combinedTeamsAndMembersData[recordId+'']) return;

                                    if(combinedTeamsAndMembersData[recordId+''].isTeam) {
                                        updateTeamsToAdd(teamsToAdd => {
                                            if(teamsToAdd && Array.isArray(teamsToAdd) && teamsToAdd.includes(recordId+'')) {
                                                let i = teamsToAdd.indexOf(recordId+'');
                                                if(i > -1) teamsToAdd.splice(i, 1);
                                            }
                                        });
                                    } else {
                                        updateMembersToAdd(membersToAdd => {
                                            if(membersToAdd && Array.isArray(membersToAdd) && membersToAdd.includes(recordId+'')) {
                                                let i = membersToAdd.indexOf(recordId+'');
                                                if(i > -1) membersToAdd.splice(i, 1);
                                            }
                                        });
                                    }
                                }
                            }
                        >
                            <img className="icon" src={ removeIcon } alt="Icon" style={{ height: '16px' }} />
                        </Button>
                    )
                );
            }
        }
    ];

    const DraggableContainer = props => (
        <SortableContainer
            useDragHandle
            disableAutoscroll
            helperClass="stageFormItem_dragging"
            onSortEnd={ onSortEnd }
            { ...props }
        />
    );
    
    const DraggableFormItem = ({ stageIndex, ...restProps }) => {
        return <SortableItem index={ stageIndex } { ...restProps } style={{ zIndex: 9999 }} />;
    };

    return (
        <Modal title={ null } footer={ null } visible={ true } onCancel={ handleAddProjectModalCancel } width={ 640 }>
            { 
                (addProjectModalStep === 1) ? (
                    <>
                        <div className="modal-header-W">
                            <img className="w-100" src={ createProjectModalBG } alt="Edit Project Details" style={{ maxWidth: '250px', margin: '0 auto 0 auto' }} />
                            <div style={{ margin: '36px 0 0 0' }}>Project Details</div>
                        </div>

                        <div className="modal-body-W" style={{ padding: '30px 120px' }}>
                            <Form form={ addProjectForm_step1 } initialValues={{}} name="createProject" onFinish={ onAddProjectFinish_step1 } layout="vertical" hideRequiredMark={ true }>
                                <Form.Item
                                    name="name"
                                    label="PROJECT NAME"
                                    rules={ [{ required: true, message: 'Please enter a valid project name.' }, { max: 24, message: 'Maximum of 24 characters are allowed.' }] }
                                    validateStatus={ addProjectErrors_step1 && addProjectErrors_step1.name ? "error" : null }
                                    help={ addProjectErrors_step1 && addProjectErrors_step1.name ? addProjectErrors_step1.name : null }
                                >
                                    <Input />
                                </Form.Item>
                            
                                <Form.Item
                                    name="description"
                                    label="DESCRIPTION"
                                    rules={ [{ required: true, message: 'Please enter a valid project description.' }, { max: 256, message: 'Maximum of 256 characters are allowed.' }] }
                                    validateStatus={ addProjectErrors_step1 && addProjectErrors_step1.description ? "error" : null }
                                    help={ addProjectErrors_step1 && addProjectErrors_step1.description ? addProjectErrors_step1.description : null }
                                >
                                    <Input.TextArea rows={ 3 } />
                                </Form.Item>

                                <Form.Item
                                    label="CHOOSE A COLOR"
                                >
                                    <div className="color-picker">
                                        <div>
                                            {
                                                ColorSet() && Array.isArray(ColorSet()) && ColorSet().length > 0 && ColorSet().map((color) => {
                                                    return (
                                                        <div key={ color } className={ 'option' + (selectedProjectIconColor && selectedProjectIconColor === color ? ' selected' : '') } onClick={ () => { setSelectedProjectIconColor(color) } }>
                                                            <div className="outer">
                                                                <div className="inner" style={{ background: color }}></div>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </Form.Item>
                            </Form>
                        </div>

                        <div className="modal-footer-W" style={{ background: '#F8F8FB' }}>
                            <div className="text-right">
                                <Button className="btn-purple" onClick={ () => { handleAddProjectModalNext() } }>Next Step</Button>
                            </div>
                        </div>
                    </>
                ) : (addProjectModalStep === 2) ? (
                    <>
                        <div className="modal-header-W">
                            <img className="w-100" src={ privacySettingsModalBG } alt="Privacy Settings" style={{ maxWidth: '320px', margin: '0 auto 0 auto' }} />
                            <div className="mt-5">Privacy Settings</div>
                        </div>

                        <div className="modal-body-W" style={{ padding: '30px 30px' }}>
                            <div className="text-primary text-center mt-5">Would you like to open this project to product members?</div>
                            <div className="text-secondary text-center mt-5">All the members of this product will be able to see this project.</div>
                            <div className="text-center mt-5">
                                <Select
                                    labelInValue
                                    defaultValue={ { value: projectIsAccessibleToAllProductTeamsAndMembers } }
                                    onSelect={
                                        (selected) => {
                                            setProjectIsAccessibleToAllProductTeamsAndMembers(selected.value);
                                        }
                                    }
                                    className="text-left"
                                    style={{ width: '280px' }}
                                >
                                    <Select.Option value={ true }>
                                        <GlobalOutlined className="mr-1" /> All the product members
                                    </Select.Option>

                                    <Select.Option value={ false }>
                                        <LockFilled className="mr-1" />Only people invited
                                    </Select.Option>
                                </Select>
                            </div>
                        </div>

                        <div className="modal-footer-W pt-5" style={{ background: '#F8F8FB' }}>
                            <div>
                                <Button className="btn-purple float-right" onClick={ () => { handleAddProjectModalNext() } }>Next Step</Button>
                                <Button className="btn-white" onClick={ () => { handleAddProjectModalPrev() } }>Previous Step</Button>
                            </div>
                        </div>
                    </>
                ) : (addProjectModalStep === 3) ? (
                    <>
                        <div className="modal-header-W">
                            <div>Who is this project for?</div>
                        </div>

                        <div className="modal-body-W">
                            <div className="R1" style={{ padding: '15px 30px' }}>
                                <Form layout="vertical">
                                    <Form.Item label="Search by team / individual">
                                        <Input 
                                            style={{ maxWidth: '280px' }}
                                            onChange={ (event) => {
                                                event.persist(); // Signal to React not to nullify the event object

                                                setCombinedTeamsAndMembersDataFilter((event.target.value ? event.target.value.toLowerCase() : null));
                                            }}
                                        />
                                    </Form.Item>
                                </Form>

                                <div className="table-section-header mt-3">Teams / Individiuals Found</div>
                                <Table 
                                    rowKey="_id"
                                    rowClassName={ (record, index) => ((combinedTeamsAndMembersDataFilter && (record.isTeam ? !(record.name.toLowerCase().includes(combinedTeamsAndMembersDataFilter)) : !(record.firstName.toLowerCase().includes(combinedTeamsAndMembersDataFilter) || record.lastName.toLowerCase().includes(combinedTeamsAndMembersDataFilter) || record.username.toLowerCase().includes(combinedTeamsAndMembersDataFilter)))) || (combinedTeamsAndMembersToAdd && combinedTeamsAndMembersToAdd.includes(record._id+'')) ? 'd-none' : '') }
                                    className="members-to-add-T mt-2"
                                    style={{ maxHeight: '229px' }}
                                    columns={ combinedTeamsAndMembersColumns }
                                    dataSource={ combinedTeamsAndMembersData ? Object.values(combinedTeamsAndMembersData) : [] }
                                    pagination={ false }
                                />
                            </div>

                            <div className="R2" style={{ background: '#FFFFFF', padding: '15px 30px' }}>
                                <div className="table-section-header mt-3">Teams / Individiuals Added</div>
                                <Table
                                    rowKey={ (recordId) => recordId }
                                    rowClassName={ (recordId, index) => (combinedTeamsAndMembersDataFilter && combinedTeamsAndMembersData && combinedTeamsAndMembersData[recordId+''] && (combinedTeamsAndMembersData[recordId+''].isTeam ? !(combinedTeamsAndMembersData[recordId+''].name.toLowerCase().includes(combinedTeamsAndMembersDataFilter)) : !(combinedTeamsAndMembersData[recordId+''].firstName.toLowerCase().includes(combinedTeamsAndMembersDataFilter) || combinedTeamsAndMembersData[recordId+''].lastName.toLowerCase().includes(combinedTeamsAndMembersDataFilter) || combinedTeamsAndMembersData[recordId+''].username.toLowerCase().includes(combinedTeamsAndMembersDataFilter))) ? 'd-none' : '') }
                                    className="members-added-T mt-2"
                                    style={{ maxHeight: '229px' }}
                                    columns={ combinedTeamsAndMembersAddColumns }
                                    dataSource={ combinedTeamsAndMembersToAdd ? combinedTeamsAndMembersToAdd : [] }
                                    pagination={ false }
                                />
                            </div>
                        </div>

                        <div className="modal-footer-W" style={{ background: '#FFFFFF' }}>
                            <div>
                                <Button className="btn-purple float-right" onClick={ () => { handleAddProjectModalNext() } }>Next Step</Button>
                                <Button className="btn-white" onClick={ () => { handleAddProjectModalPrev() } }>Previous Step</Button>
                            </div>
                        </div>
                    </>
                ) : (addProjectModalStep === 4) ? (
                    <>
                        <div className="modal-header-W">
                            <div>Project Stages Template</div>
                        </div>

                        <div className="modal-body-W" style={{ padding: '30px 120px' }}>
                            <Row gutter={ 16 }>
                                <Col span={ 12 }>
                                    <div className="col-header">Templates</div>
                                    <Button
                                        className="btn-nothing d-block mt-2"
                                        style={{ padding: '0 0 0 0', fontWeight: 500 }}
                                        onClick={
                                            () => {
                                                addProjectForm_step4.setFieldsValue({ 'stages': ['To do', 'Done'] });
                                                updateStageColors(stageColors => {
                                                    return [ColorSet()[0], ColorSet()[1]];
                                                });
                                            }
                                        }
                                    >
                                        Basic
                                    </Button>
                                    <Button
                                        className="btn-nothing d-block mt-2"
                                        style={{ padding: '0 0 0 0', fontWeight: 500 }}
                                        onClick={
                                            () => {
                                                addProjectForm_step4.setFieldsValue({ 'stages': ['Planning', 'Development', 'Testing', 'Demo', 'Done'] });
                                                updateStageColors(stageColors => {
                                                    return [ColorSet()[0], ColorSet()[1], ColorSet()[2], ColorSet()[3], ColorSet()[4]];
                                                });
                                            }
                                        }
                                    >
                                        Development
                                    </Button>
                                    <Button
                                        className="btn-nothing d-block mt-2"
                                        style={{ padding: '0 0 0 0', fontWeight: 500 }}
                                        onClick={
                                            () => {
                                                addProjectForm_step4.setFieldsValue({ 'stages': ['To do', 'In review', 'Done'] });
                                                updateStageColors(stageColors => {
                                                    return [ColorSet()[0], ColorSet()[1], ColorSet()[2]];
                                                });
                                            }
                                        }
                                    >
                                        Marketing
                                    </Button>
                                    <Button
                                        className="btn-nothing d-block mt-2"
                                        style={{ padding: '0 0 0 0', fontWeight: 500 }}
                                        onClick={
                                            () => {
                                                addProjectForm_step4.setFieldsValue({ 'stages': [] });
                                                updateStageColors(stageColors => {
                                                    return [];
                                                });
                                            }
                                        }
                                    >
                                        Custom
                                    </Button>
                                </Col>

                                <Col span={ 12 }>
                                    <div className="col-header">Stages</div>

                                    <Form id="form_manageStages" form={ addProjectForm_step4 } layout="vertical">
                                        <Form.List name="stages">
                                            { (fields, { add, remove }) => {
                                                return (
                                                    <DraggableContainer>
                                                        { fields.map((field, fieldIndex) => (
                                                            <DraggableFormItem key={ fieldIndex } stageIndex={ fieldIndex }>
                                                                <Form.Item { ...field }>
                                                                    <Input
                                                                        key={ fieldIndex }
                                                                        ref={ (ref) => { setStageRefs(fieldIndex, ref) } }
                                                                        className={ stageLockedValues && stageLockedValues[fieldIndex] ? 'stage locked' : '' }
                                                                        placeholder={ 'Enter a name...' }
                                                                        onPressEnter={ () => { if(stageRefs && stageRefs[''+fieldIndex]) stageRefs[''+fieldIndex].blur(); } }
                                                                        prefix={ <DragHandle /> }
                                                                        suffix={
                                                                            <Dropdown   
                                                                                placement="bottomCenter"
                                                                                trigger={ ['click'] }
                                                                                overlay={
                                                                                    <Menu style={{ width: '180px' }}>
                                                                                        <Menu.SubMenu title={ <><CustomIcon iconKey="colorpicker" style={{ width: '16px', height: '16px' }} /> Change color</> }>
                                                                                            {
                                                                                                ColorSet() && Array.isArray(ColorSet()) && ColorSet().length > 0 && ColorSet().map((color) => {
                                                                                                    return (
                                                                                                        <Menu.Item
                                                                                                            key={ color }
                                                                                                            className={ 'text-center d-inline-block' }
                                                                                                            style={{ width: '40px' }}
                                                                                                            onItemHover={ () => {} }
                                                                                                            onClick={
                                                                                                                () => {
                                                                                                                    updateStageColors(stageColors => {
                                                                                                                        if(stageColors && stageColors[fieldIndex]) {
                                                                                                                            console.log('----------- updated color', fieldIndex, color);
                                                                                                                            stageColors[fieldIndex] = color;
                                                                                                                        }
                                                                                                                    });
                                                                                                                    setTimeout(() => {

                                                                                                                    })
                                                                                                                }
                                                                                                            }
                                                                                                        >
                                                                                                            <div style={{ fontSize: '32px', color: color }}>&#8226;</div>
                                                                                                        </Menu.Item>
                                                                                                    );
                                                                                                })
                                                                                            }
                                                                                        </Menu.SubMenu>

                                                                                        {
                                                                                            stageLockedValues && stageLockedValues[fieldIndex] ? (
                                                                                                <Menu.Item
                                                                                                    onClick={
                                                                                                        () => {
                                                                                                            updateStageLockedValues(stageLockedValues => {
                                                                                                                if(!stageLockedValues || !Array.isArray(stageLockedValues) || stageLockedValues.length <= 0) return;
                                                                                                                if(stageLockedValues[fieldIndex]) stageLockedValues[fieldIndex] = false;

                                                                                                                return stageLockedValues;
                                                                                                            })
                                                                                                            setTimeout(() => {

                                                                                                            })
                                                                                                        }
                                                                                                    }
                                                                                                >
                                                                                                    <UnlockFilled className="mr-0" style={{ fontSize: '16px' }} /> Unlock stage
                                                                                                </Menu.Item>
                                                                                            ) : (
                                                                                                <Menu.Item
                                                                                                    onClick={
                                                                                                        () => {
                                                                                                            updateStageLockedValues(stageLockedValues => {
                                                                                                                if(!stageLockedValues || !Array.isArray(stageLockedValues) || stageLockedValues.length <= 0) stageLockedValues = [];
                                                                                                                stageLockedValues[fieldIndex] = true;

                                                                                                                return stageLockedValues;
                                                                                                            })
                                                                                                        }
                                                                                                    }
                                                                                                >
                                                                                                    <LockFilled className="mr-0" style={{ fontSize: '16px' }} /> Lock stage
                                                                                                </Menu.Item>
                                                                                            )
                                                                                        }

                                                                                        <Menu.Item onClick={ () => remove(field.name) }>
                                                                                            <CustomIcon iconKey="delete" color="#E45858" style={{ margin: '4px 0 0 0', height: '14px' }} /> Remove stage
                                                                                        </Menu.Item>
                                                                                    </Menu>
                                                                                }
                                                                            >
                                                                                <EllipsisOutlined style={{ fontSize: '24px', lineHeight: 0.5 }} />
                                                                            </Dropdown>
                                                                        }
                                                                    />
                                                                </Form.Item>

                                                                { /* fields.length > 1 && <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(field.name)} /> */ }
                                                            </DraggableFormItem>
                                                        ))}
                                                
                                                        <Form.Item>                                                
                                                            <Button 
                                                                className="btn-nothing"
                                                                style={{ fontWeight: 500, fontSize: '12px', color: '#6246EA' }}
                                                                icon={ <PlusOutlined /> }
                                                                onClick={
                                                                    () => {
                                                                        add();

                                                                        updateStageColors(stageColors => {
                                                                            if(!stageColors || !Array.isArray(stageColors)) {
                                                                                let nStageColors = [];
                                                                                nStageColors.push(ColorSet()[0]);
                                                                                return nStageColors;
                                                                            }


                                                                            stageColors.push(ColorSet()[stageColors.length % 8]);
                                                                        });

                                                                        updateStageLockedValues(stageLockedValues => {
                                                                            if(!stageLockedValues || !Array.isArray(stageLockedValues)) {
                                                                                let nStageLockedValues = [];
                                                                                nStageLockedValues.push(false);

                                                                                return nStageLockedValues;
                                                                            }

                                                                            stageLockedValues.push(false);
                                                                        });
                                                                    }
                                                                } 
                                                            >
                                                                ADD A PROJECT STAGE
                                                            </Button>
                                                        </Form.Item>
                                                    </DraggableContainer>
                                                )}
                                            }   
                                        </Form.List>
                                    </Form>
                                </Col>
                            </Row>
                        </div>

                        <div className="modal-footer-W" style={{ background: '#F8F8FB' }}>
                            <div>
                                <Button className="btn-purple float-right" onClick={ () => { handleAddProjectModalNext() } }>Next Step</Button>
                                <Button className="btn-white" onClick={ () => { handleAddProjectModalPrev() } }>Previous Step</Button>
                            </div>
                        </div>
                    </>
                ) : (addProjectModalStep === 5) ? (
                    <>
                        <div className="modal-header-W">
                            <div>All good?</div>
                        </div>

                        <div className="modal-body-W" style={{ padding: '30px 120px' }}>
                            <Form form={ addProjectForm_step5 } name="createProduct" onFinish={ onAddProjectFinish_step5 } layout="horizontal" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }} hideRequiredMark={ true } colon={ false }>
                                <Form.Item
                                    name="name"
                                    label="PRODUCT NAME"
                                    validateStatus={ addProjectErrors_step5 && addProjectErrors_step5.name ? "error" : null }
                                    help={ addProjectErrors_step5 && addProjectErrors_step5.name ? addProjectErrors_step5.name : null }
                                    initialValue={ addProjectValues && addProjectValues.name ? addProjectValues.name : null }
                                >
                                    <Input suffix={ <EditOutlined style={{ fontSize: '18px' }} /> } />
                                </Form.Item>
                            
                                <Form.Item
                                    name="description"
                                    label="DESCRIPTION"
                                    validateStatus={ addProjectErrors_step5 && addProjectErrors_step5.description ? "error" : null }
                                    help={ addProjectErrors_step5 && addProjectErrors_step5.description ? addProjectErrors_step5.description : null }
                                    initialValue={ addProjectValues && addProjectValues.description ? addProjectValues.description : null }
                                >
                                    <Input suffix={ <EditOutlined style={{ fontSize: '18px' }} /> } />
                                </Form.Item>

                                <Form.Item
                                    name="isPublic"
                                    label="ACCESSIBILITY"
                                    validateStatus={ addProjectErrors_step5 && addProjectErrors_step5.isPublic ? "error" : null }
                                    help={ addProjectErrors_step5 && addProjectErrors_step5.isPublic ? addProjectErrors_step5.isPublic : null }
                                    initialValue={{ value: projectIsAccessibleToAllProductTeamsAndMembers  }}
                                >
                                    <Select
                                        labelInValue
                                        onSelect={
                                            (selected) => {
                                                setProjectIsAccessibleToAllProductTeamsAndMembers(selected.value);
                                            }
                                        }
                                        className="text-left"
                                    >
                                        <Select.Option value={ true }>
                                            All the product members
                                        </Select.Option>

                                        <Select.Option value={ false }>
                                            Only people invited
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Form>
                        </div>

                        <div className="modal-footer-W" style={{ background: '#F8F8FB' }}>
                            <div>
                                <Button className="btn-purple float-right" onClick={ () => { handleAddProjectModalNext() } }>Create Project</Button>
                                <Button className="btn-white" onClick={ () => { handleAddProjectModalPrev() } }>Previous Step</Button>
                            </div>
                        </div>
                    </>
                ) : (
                    null
                )
            }
        </Modal>
    )
}

export default AddProjectModal;