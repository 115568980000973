import React, { useState, useEffect, useCallback } from 'react';

import { useImmer } from "use-immer";

import { useSystemContext } from '../../Context/SystemContext';

import ComandanteAPI from '../../utils/ComandanteAPI';

import debounce from 'lodash.debounce';

import { Modal, Form, Input, Button, Table, message } from 'antd';

import './Team.css';

import addTeamModalBG from '../../assets/images/add-team-modal-BG.png';
import avatarPlaceholder from '../../assets/images/placeholder-avatar.png'
import removeIcon from '../../assets/images/icon-remove.png';

const AddTeamModal = ({ setVisible, refreshTeams, ...restProps }) => {
    const { 
        currentUser
    } = useSystemContext();

    const [membersDataset, updateMembersDataset] = useImmer(null);
    const [membersToAdd, updateMembersToAdd] = useImmer(null);

    const [addTeamModalStep, setAddTeamModalStep] = useState(1);
    const [addTeamForm_step1] = Form.useForm();
    const [addTeamForm_step2] = Form.useForm();
    const [addTeamErrors_step1, setAddTeamErrors_step1] = useState([]);
    const [addTeamValues, setAddTeamValues] = useState({});

    const fetchMembers = useCallback((searchQuery) => {
        ComandanteAPI.HttpGetRequest('members?paginated=false' + (searchQuery ? '&search=' + searchQuery : ''), (err, res) => {
            updateMembersDataset(membersDataset => (res && res.data) || null);
        });
    }, [updateMembersDataset]);

    useEffect(() => {
        fetchMembers(null);
    }, [fetchMembers]);

    const debounced_fetchMembers = debounce(value => {
        fetchMembers(value);
    }, 300);

    const handleAddTeamModalCancel = () => { setVisible(false) };
    const handleAddTeamModalNext = (values) => {
        if(addTeamModalStep === 1) {
            addTeamForm_step1.submit();
        } else if(addTeamModalStep === 2) {
            let memberIds = [];
            if(membersToAdd && Object.values(membersToAdd).length > 0) {
                for(let member of Object.values(membersToAdd)) {
                    memberIds.push(member._id);
                }
            }
            
            ComandanteAPI.HttpPostRequest('teams/add', { ...addTeamValues, memberIds: memberIds, ...values }, (err, res) => {
                if(err || !res) {
                    message.error('System is currently unavailable. Please try again later.');
                    return;
                }

                if(res.data && res.data.result === true) {
                    refreshTeams();

                    setVisible(false);
                    addTeamForm_step1.resetFields();
                    addTeamForm_step2.resetFields();
                }
            });
        }
    }
    const handleAddTeamModalPrev = (values) => {
        if(addTeamModalStep === 1) {
            
        } else if(addTeamModalStep === 2) {
            setAddTeamModalStep(1);
        }
    }

    const onAddTeamFinish_step1 = values => {
        ComandanteAPI.HttpPostRequest('teams/add?validationOnly=true', values, (err, res) => {
            if(err || !res) {    
                if(err && err.data && err.data.errors) {
                    let formErrors = [];
                    for(let formError of err.data.errors) {
                        if(formError.param && formError.msg) formErrors[formError.param] = formError.msg;
                    }

                    setAddTeamErrors_step1(formErrors);
                    return;
                }

                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res && res.data && res.data.result === true) {
                setAddTeamValues({ ...addTeamValues, ...values });
                setAddTeamModalStep(2);

                console.log()
            }
        });
    }

    const membersQueryColumns = [
        {
            width: 32,
            render: (text, record) => <img src={ avatarPlaceholder } alt="Avatar" height={ 20 } />
        },
        {
            title: 'User',
            render: (text, record) => record.firstName + ' ' + record.lastName,
        },
        {
            title: 'Title',
            width: 180,
            render: (text, record) => record.jobTitle,
        },
        {
            width: 60,
            render: (text, record) => {
                return (
                    (!membersToAdd || !membersToAdd[record._id]) && (
                        <Button 
                            className="btn-white mt-1" 
                            style={{ height: '25px', lineHeight: '25px' }}
                            onClick={ 
                                () => {
                                    updateMembersToAdd(membersToAdd => {
                                        if(!membersToAdd) return { [record._id+'']: record };
                                        membersToAdd[record._id+''] = record;
                                    });
                                } 
                            }
                        >
                            Add
                        </Button>
                    )
                );
            }
        }
    ];

    const membersToAddColumns = [
        {
            width: 32,
            render: (text, record) => <img src={ avatarPlaceholder } alt="Avatar" height={ 20 } />
        },
        {
            title: 'User',
            render: (text, record) => record.firstName + ' ' + record.lastName + (record._id+'' === currentUser._id+'' ? ' (You)' : ''),
        },
        {
            title: 'Title',
            width: 180,
            render: (text, record) => record.jobTitle,
        },
        {
            width: 60,
            render: (text, record) => {
                return (
                    <Button 
                        className="btn-nothing mt-1" 
                        style={{ height: '25px', lineHeight: '25px' }}
                        onClick={ 
                            () => {
                                updateMembersToAdd(membersToAdd => {
                                    if(membersToAdd && membersToAdd[record._id+'']) delete membersToAdd[record._id+''];
                                });
                            }
                        }
                    >
                        <img className="icon" src={ removeIcon } alt="Icon" style={{ height: '16px' }} />
                    </Button>
                );
            }
        }
    ];

    return (
        <Modal title={ null } footer={ null } visible={ true } onCancel={ handleAddTeamModalCancel } width={ 640 }>
            { 
                (addTeamModalStep === 1) ? (
                    <>
                        <div className="modal-header-W" style={{ padding: '80px 0 80px 0' }}>
                            <img className="w-100" src={ addTeamModalBG } alt="Create a new team" style={{ maxWidth: '280px', margin: '0 auto 0 auto' }} />
                            <div className="mt-5">Create a new team</div>
                        </div>

                        <div className="modal-body-W" style={{ padding: '30px 120px' }}>
                            <Form form={ addTeamForm_step1 } name="createTeam" onFinish={ onAddTeamFinish_step1 } layout="vertical" hideRequiredMark={ true }>
                                <Form.Item
                                    name="name"
                                    label="TEAM NAME"
                                    rules={ [{ required: true, message: 'Please enter a valid team name.' }, { max: 24, message: 'Maximum of 24 characters are allowed.' }] }
                                    validateStatus={ addTeamErrors_step1 && addTeamErrors_step1.name ? "error" : null }
                                    help={ addTeamErrors_step1 && addTeamErrors_step1.name ? addTeamErrors_step1.name : null }
                                >
                                    <Input />
                                </Form.Item>
                            </Form>
                        </div>
                        
                        <div className="modal-footer-W" style={{ background: '#F8F8FB' }}>
                            <div className="text-right">
                                <Button className="btn-purple" onClick={ () => { handleAddTeamModalNext() } }>Next Step</Button>
                            </div>
                        </div>
                    </>
                ) : (
                    <>
                        <div className="modal-header-W">
                            <div>Who is this team formed by?</div>
                        </div>

                        <div className="modal-body-W">
                            <div className="R1" style={{ padding: '15px 30px' }}>
                                <Form layout="vertical">
                                    <Form.Item label="Search by individual">
                                        <Input 
                                            style={{ maxWidth: '280px' }}
                                            onChange={ (event) => {
                                                event.persist(); // Signal to React not to nullify the event object

                                                debounced_fetchMembers(event.target.value);
                                            }}
                                        />
                                    </Form.Item>
                                </Form>

                                <div className="table-section-header mt-3">Individiuals Found</div>
                                <Table 
                                    rowKey="_id"
                                    rowClassName={ (record, index) => ((membersToAdd && membersToAdd[record._id+'']) ? 'd-none' : '') }
                                    className="members-to-add-T mt-2"
                                    columns={ membersQueryColumns }
                                    dataSource={ membersDataset && membersDataset.data ? Object.values(membersDataset.data) : [] } pagination={ false }
                                />
                            </div>

                            <div className="R2" style={{ background: '#FFFFFF', padding: '15px 30px' }}>
                                <div className="table-section-header mt-3">Individiuals Added</div>
                                <Table
                                    rowKey="_id"
                                    className="members-added-T mt-2"
                                    columns={ membersToAddColumns }
                                    dataSource={ membersToAdd ? Object.values(membersToAdd) : [] }
                                    pagination={ false }
                                />
                            </div>
                        </div>

                        <div className="modal-footer-W" style={{ background: '#FFFFFF' }}>
                            <div>
                                <Button className="btn-purple float-right" onClick={ () => { handleAddTeamModalNext() } }>Create Team</Button>
                                <Button className="btn-white" onClick={ () => { handleAddTeamModalPrev() } }>Previous Step</Button>
                            </div>
                        </div>
                    </>
                )
            }
        </Modal>
    )
}

export default AddTeamModal;