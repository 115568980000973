import React from 'react';
import { useSystemContext } from '../../../Context/SystemContext';

import { Droppable } from "react-beautiful-dnd";

const Stage = ({ stageId, view, locked, ...restProps }) => {
    const { 
        currentUser,
        systemVariables
    } = useSystemContext();
    
    const roleOptions = (systemVariables && systemVariables.user && systemVariables.user.roleOptions ? systemVariables.user.roleOptions : null);

    return (
        <Droppable 
            key={ stageId }
            droppableId={ stageId }
            isDropDisabled={ (currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Observer', 'Contributor', 'Editor', 'Project Manager'].includes(roleOptions[currentUser.role+''])) && locked ? true : false }
        >
            { 
                (provided, snapshot) => {
                    return (
                        <div
                            { ...provided.droppableProps }
                            ref={ provided.innerRef }
                            style={{ 
                                // border: snapshot.isDraggingOver ? '1px dashed #CCC' : '0 none',
                                borderRadius: '3px',
                                padding: '4px 4px 4px 4px'
                            }}
                        >
                            { restProps.children }
                            { provided.placeholder }
                        </div>
                    )
                }
            }
        </Droppable>
    );
}

export default Stage;
