import React from 'react';

import { SystemContextProvider } from './Context/SystemContext';

import { Switch, Route, withRouter, Redirect } from 'react-router-dom';

import DndProviderHOC from './utils/DndProviderHOC';
import ProtectedRoute from './utils/ProtectedRoute';

import Login from './Pages/Login/Login';

import Home from './Pages/Home/Home';
import MyTasks from './Pages/MyTasks/MyTasks';
import Notifications from './Pages/Notifications/Notifications';
import Team from './Pages/Team/Team';
import Users from './Pages/Users/Users';

import Products from './Pages/Products/Products';
import Product from './Pages/Product/Product';
import Board from './Pages/Project/Board';
import List from './Pages/Project/List';
import Archived from './Pages/Project/Archived';
import ProjectReport from './Pages/Project/ProjectReport';

import Error from './Pages/Error/Error';

import './App.less';

const App = () => {
	return (
		<SystemContextProvider>
			<DndProviderHOC>
				<Switch>
					<Route exact path='/login'>
						{ localStorage.getItem('token') ? <Redirect to="/" /> : <Login /> }
					</Route>
					
					<ProtectedRoute exact path='/' render={ () => <Redirect to="/home" /> } />
					<ProtectedRoute exact path='/home' resourcePath="front-end.home" component={ Home } />
					<ProtectedRoute exact path='/my-tasks' resourcePath="front-end.my-tasks" component={ MyTasks } />
					<ProtectedRoute exact path='/notifications' resourcePath="front-end.notifications" component={ Notifications } />
					<ProtectedRoute exact path='/team' resourcePath="front-end.team" component={ Team } />
					{ /* <ProtectedRoute exact path='/roadmap' resourcePath="front-end.roadmap" component={ Home } /> */ }

					<ProtectedRoute exact path='/products' resourcePath="front-end.products" component={ Products } />
					<ProtectedRoute exact path='/products/:productId' resourcePath="front-end.products/:productId" component={ Product } />
					<ProtectedRoute exact path='/products/:productId/projects/:projectId' resourcePath="front-end.products/:productId/projects/:projectId" component={ Board } />
					<ProtectedRoute exact path='/products/:productId/projects/:projectId/board' resourcePath="front-end.products/:productId/projects/:projectId" component={ Board } />
					<ProtectedRoute exact path='/products/:productId/projects/:projectId/list' resourcePath="front-end.products/:productId/projects/:projectId" component={ List } />
					<ProtectedRoute exact path='/products/:productId/projects/:projectId/archived' resourcePath="front-end.products/:productId/projects/:projectId" component={ Archived } />
					<ProtectedRoute exact path='/products/:productId/projects/:projectId/report' resourcePath="front-end.products/:productId/projects/:projectId" component={ ProjectReport } />
					<ProtectedRoute exact path='/products/:productId/projects/:projectId/tasks/:taskId' resourcePath="front-end.products/:productId/projects/:projectId/tasks/:taskId" component={ Board } />

					<ProtectedRoute exact path='/users' resourcePath="front-end.users" component={ Users } />

					<Route render={ () => <Error status="404" /> } />
				</Switch>
			</DndProviderHOC>
		</SystemContextProvider>
	);
}

export default withRouter(App);