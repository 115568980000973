import React, { useState } from 'react';

import ComandanteAPI from '../../utils/ComandanteAPI';

import { Modal, Form, Input, Button, message } from 'antd';

const EditTeamModal = ({ teamId, setTeamId, initialValue, refreshTeams }) => {
    const [editTaskForm] = Form.useForm();
    const [editTeamErrors, setEditTeamErrors] = useState(null);

    const handleEditTaskModalCancel = () => { setTeamId(null) }
    const handleEditTaskModalOk = () => {
        ComandanteAPI.HttpPostRequest('teams/' + teamId + '/edit', editTaskForm.getFieldsValue(), (err, res) => {
            if(err || !res) {    
                if(err && err.data && err.data.errors) {
                    let formErrors = [];
                    for(let formError of err.data.errors) {
                        if(formError.param && formError.msg) formErrors[formError.param] = formError.msg;
                    }

                    setEditTeamErrors(formErrors);
                    return;
                }

                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res && res.data && res.data.result === true) {
                setTeamId(null);
                refreshTeams();
            }
        });
    }

    return (
        <Modal className="confirmation-modal" title={ null } footer={ null } visible={ true } onCancel={ handleEditTaskModalCancel } width={ 520 }>
            <div className="confirmation-modal-header-W">
                
            </div>

            <div className="confirmation-modal-W">
                <div className="header">
                    Edit Team
                </div>

                <div className="body">
                    { 'Are you sure you want to edit details for this team?' }

                    <Form form={ editTaskForm } layout="vertical" hideRequiredMark={ true }>
                        <Form.Item
                            name="name"
                            label="TEAM NAME"
                            initialValue={ initialValue }
                            rules={ [{ required: true, message: 'Please enter a valid team name.' }, { max: 24, message: 'Maximum of 24 characters are allowed.' }] }
                            validateStatus={ editTeamErrors && editTeamErrors.name ? "error" : null }
                            help={ editTeamErrors && editTeamErrors.name ? editTeamErrors.name : null }
                        >
                            <Input />
                        </Form.Item>
                    </Form>

                    <div className="text-right mt-5">
                        <Button className="btn-white mr-2" onClick={ handleEditTaskModalCancel }>Cancel</Button>
                        <Button className="btn-purple" onClick={ handleEditTaskModalOk }>Save</Button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default EditTeamModal;