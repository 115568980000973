import React from 'react';

import { Route, Redirect } from 'react-router-dom';

import { useSystemContext } from '../Context/SystemContext';

import Error from '../Pages/Error/Error';

const ProtectedRoute = (props) => {
    const { currentUser } = useSystemContext();

    if(!localStorage.getItem('token')) {
        return <Route { ...props } component={ () => <Redirect to={{ pathname: '/login' }} /> } />;
    }

    let routePath = (props.resourcePath ? props.resourcePath.replace(/^\/+|\/+$/g, '') : null);
    if(routePath) {
        if(currentUser && currentUser.authorizedFrontEndResources && !currentUser.authorizedFrontEndResources.includes(routePath)) {
            return <Route { ...props } component={ () => <Error status="403" /> } />;
        }
    }

    return <Route { ...props } />;
}

export default ProtectedRoute;