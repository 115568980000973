import React from 'react';
import ComandanteAPI from '../../../utils/ComandanteAPI';

import { Modal, Button, message } from 'antd';

import './DeleteUserModal.css';

const DeleteUserModal = ({ setVisible, userId, refreshUsers, ...restProps }) => {
    const handleDeleteUserModalCancel = () => {
        setVisible(false);
    }

    const handleDeleteUserModalDelete = () => {
        ComandanteAPI.HttpPostRequest('members/' + userId + '/delete', null, (err, res) => {
            if(err || !res) {
                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.status && res.status === 200) {
                handleDeleteUserModalCancel();
                refreshUsers();
                return;
            }
        });
    }

    return (
        <Modal className="confirmation-modal" title={ null } footer={ null } visible={ true } onCancel={ handleDeleteUserModalCancel } width={ 520 }>
            <div className="confirmation-modal-header-W"></div>

            <div className="confirmation-modal-W deletion-modal">
                <div className="header">
                    Delete User
                </div>

                <div className="body">
                    Are you sure you want to delete this user account?

                    <div className="text-right mt-4">
                        <Button className="btn-white mr-2" onClick={ handleDeleteUserModalCancel }>Cancel</Button>
                        <Button className="btn-red float-right" onClick={ handleDeleteUserModalDelete }>Permanently Delete</Button>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

export default DeleteUserModal;