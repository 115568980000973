import React, { useState, useEffect, useCallback } from 'react';
import { useImmer } from 'use-immer';
import { useSystemContext } from '../../Context/SystemContext';
import { useParams, useHistory } from 'react-router-dom';

import Helmet from 'react-helmet';

import ComandanteAPI from '../../utils/ComandanteAPI';

import debounce from 'lodash.debounce';

import { DragDropContext } from "react-beautiful-dnd";

import MainLayout from "../../Layouts/Main";

import { ColorSet } from '../../UIElements/ColorPicker/ColorPicker';
import CustomIcon from '../../UIElements/CustomIcon/CustomIcon';

import { Tabs, Card, Select, Button, Spin, Form, Input } from 'antd';
import { Bar, Pie } from '@ant-design/charts';
import { CaretDownOutlined, SearchOutlined } from '@ant-design/icons'

import Task from './Draggable/Task';
import ArchivedTask from './Draggable/ArchivedTask';
import Stage from './Droppable/Stage';
import CreateTaskDrawer from '../Task/Drawers/CreateTaskDrawer';
import ViewTaskDrawer from '../Task/Drawers/ViewTaskDrawer';

import './Project.css';

import Error from '../Error/Error';

const ProjectReport = () => {
    const { 
        currentUser,
        systemVariables
    } = useSystemContext();

    const roleOptions = (systemVariables && systemVariables.user && systemVariables.user.roleOptions ? systemVariables.user.roleOptions : null);

    const { productId, projectId } = useParams();
    const history = useHistory();

    const [productDataset, setProductDataset] = useState(null);
    const [projectDataset, setProjectDataset] = useState(null);
    const [projectReportData, setProjectReportData] = useState(null);
    const [tasksSortBy, setTasksSortBy] = useState('custom');

    const [pageError, setPageError] = useState(null);

    const fetchProduct = useCallback(() => {
        ComandanteAPI.HttpGetRequest('products/' + productId + '/get', (err, res) => {
            if(err) {
                setPageError(<Error status={ (err.status ? err.status : null) } />);
                return;
            }

            if(!res || !res.data) {
                setPageError(<Error status={ 404 } />);
                return;
            }

            setProductDataset((res && res.data) || null);
        });
    }, [productId]);

    const fetchProject = useCallback(() => {
        ComandanteAPI.HttpGetRequest('products/' + productId + '/projects/' + projectId + '/get', (err, res) => {
            if(err) {
                setPageError(<Error status={ (err.status ? err.status : null) } />);
                return;
            }

            if(!res || !res.data) {
                setPageError(<Error status={ 404 } />);
                return;
            }

            setProjectDataset((res && res.data) || null);
        });
    }, [productId, projectId]);

    const fetchProjectReport = useCallback(() => {
        ComandanteAPI.HttpGetRequest('products/' + productId + '/projects/' + projectId + '/report', (err, res) => {
            if(!res || !res.data) {
                setProjectReportData(null);
                return;
            }
            
            if(res.data.report) {
                if(res.data.report.activeTasksPerStatus && Array.isArray(res.data.report.activeTasksPerStatus) && res.data.report.activeTasksPerStatus.length > 0) {
                    res.data.report.activeTasksPerStatusColors = [];
                    for(let stage of res.data.report.activeTasksPerStatus) {
                        res.data.report.activeTasksPerStatusColors.push(stage.color);
                    }
                }
            }

            setProjectReportData(res.data);
        });
    }, [productId, projectId]);

    useEffect(() => {
        if(window.innerWidth <= 575) {
            history.push('/products/' + productId + '/projects/' + projectId + '/list');
        }
    })

    useEffect(() => {
        fetchProduct();
        fetchProject();
        fetchProjectReport();
    }, [fetchProduct, fetchProject, fetchProjectReport]);

    const onTabChange = (activeKey) => {
        history.push('/products/' + productId + '/projects/' + projectId + '/' + activeKey)
    }

    if(pageError) return pageError;

    return (
        (projectDataset && projectDataset.project) ? (
            <>
                <Helmet>
                    <title>{ (projectDataset.project.name ? projectDataset.project.name : 'Project') + ' | Projects | ' + (productDataset && productDataset.product.name ? productDataset.product.name : 'Product') + ' | Products | ' + process.env.REACT_APP_META_TITLE }</title>
                </Helmet>

                <MainLayout>
                    <>
                        <div className="project--section-header">
                            <div>
                                <h1 className="d-inline-block">{ (projectDataset.project.name ? projectDataset.project.name : 'Project') }</h1>

                                <Tabs className="project--header-tabs d-inline-block" defaultActiveKey="report" onChange={ onTabChange }>
                                    <Tabs.TabPane tab="Board" key="board"></Tabs.TabPane>
                                    <Tabs.TabPane tab="List" key="list"></Tabs.TabPane>
                                    <Tabs.TabPane tab="Archived" key="archived"></Tabs.TabPane>
                                    <Tabs.TabPane tab="Report" key="report"></Tabs.TabPane>
                                </Tabs>
                            </div>
                        </div>

                        <div className="project--section-body pt-4 pb-4" style={{ overflowX: 'scroll' }}>
                            {
                                !(currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Observer', 'Contributor', 'Editor'].includes(roleOptions[currentUser.role+''])) && (
                                    <div className="ml-4 mr-4" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'flex-start', alignItems: 'flex-start' }}>
                                        <div style={{ display: 'inline-block', width: 500 }}>
                                            {
                                                (projectReportData && projectReportData.report && projectReportData.report.activeTasksPerStatus) && (
                                                    <Card title="Active Tasks per Status" bordered={false} className="mt-4">
                                                        <Bar data={ (projectReportData.report.activeTasksPerStatus && Array.isArray(projectReportData.report.activeTasksPerStatus) ? [ ...projectReportData.report.activeTasksPerStatus ].reverse() : []) } xField="numberOfTasks" yField="yField" seriesField="label" colorField="color" color={ projectReportData.report.activeTasksPerStatusColors } isPercent={ true } isStack={ true } height={ 80 } />

                                                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'space-between', margin: '20px 0 0 0' }}>
                                                            {
                                                                projectReportData.report.activeTasksPerStatus.map((stage) => {
                                                                    return (
                                                                        <div key={ stage._id } style={{ width: '175px', margin: '10px 0 0 0', color: stage.color }}>
                                                                            <span className="label">{ stage.label }</span>
                                                                            <span className="numberOfTasks float-right" style={{ color: '#6C6D72' }}>{ stage.numberOfTasks } Tasks</span>
                                                                        </div>
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                    </Card>
                                                )
                                            }

                                            {
                                                (projectReportData && projectReportData.report && projectReportData.report.weeklyStatusChanges) && (
                                                    <Card title="Weekly Status Changes" bordered={false} className="mt-4">
                                                        {
                                                            projectReportData.report.weeklyStatusChanges.map((weeklyStatusChange) => {
                                                                return (
                                                                    <div key={ weeklyStatusChange.from._id+'-'+weeklyStatusChange.to._id }>
                                                                        { weeklyStatusChange.numberOfTasks } tasks from <span style={{ color: weeklyStatusChange.from.color }}>{ weeklyStatusChange.from.label }</span> to <span style={{ color: weeklyStatusChange.to.color }}>{ weeklyStatusChange.to.label }</span>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </Card>
                                                )
                                            }

                                            {
                                                (projectReportData && projectReportData.report && projectReportData.report.membersRoles) && (
                                                    <Card title="Members' Roles" bordered={false} className="mt-4">
                                                        <Pie data={ projectReportData.report.membersRoles } angleField="count" colorField="role" radius="0.6" label={{ type: 'outer', content: '{percentage}' }} interactions={ [{ type: 'pie-legend-active' }, { type: 'element-active' }] } width={ 300 } height={ 300 } />
                                                    </Card>
                                                )
                                            }
                                        </div>

                                        <div className="ml-4" style={{ display: 'inline-block', width: 300 }}>
                                            {
                                                (projectReportData && projectReportData.report && projectReportData.report.projectOverview) && (
                                                    <Card title="Project Overview" bordered={false} className="mt-4">
                                                        <div style={{ textAlign: 'right'}}>
                                                            <span className="float-left">Tasks Started</span>
                                                            <span>{ projectReportData.report.projectOverview.numberOfTasksStarted ? projectReportData.report.projectOverview.numberOfTasksStarted : 0 }</span>
                                                        </div>

                                                        <div style={{ textAlign: 'right'}}>
                                                            <span className="float-left">Tasks Not Started</span>
                                                            <span>{ projectReportData.report.projectOverview.numberOfTasksNotStarted ? projectReportData.report.projectOverview.numberOfTasksNotStarted : 0 }</span>
                                                        </div>

                                                        <div style={{ textAlign: 'right'}}>
                                                            <span className="float-left">Tasks Archived</span>
                                                            <span>{ projectReportData.report.projectOverview.numberOfTasksArchived ? projectReportData.report.projectOverview.numberOfTasksArchived : 0 }</span>
                                                        </div>
                                                    </Card>
                                                )
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </>
                </MainLayout>
            </>
        ) : (
            <div className="aligner-xy" style={{ height: '100vh' }}><Spin /></div>
        )
    )
}

export default ProjectReport;