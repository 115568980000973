import React, { useState, useRef } from 'react';
import { useImmer } from "use-immer";
import { useSystemContext } from '../../../Context/SystemContext';

import ComandanteAPI from '../../../utils/ComandanteAPI';

import { Modal, Form, Input, Select, Button, Upload } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import logo from '../../../assets/images/logo-texture.png';

import './AddUserModal.css';

const AddUserModal = ({ setVisible, refreshUsers, ...restProps }) => {
    const { 
        systemVariables,
    } = useSystemContext();

    const roleOptions = (systemVariables && systemVariables.user && systemVariables.user.roleOptions ? systemVariables.user.roleOptions : null);

    const [addUserFormStep, setAddUserFormStep] = useState(1);
    const [addUserFormStep1] = Form.useForm();
    const [addUserFormStep2] = Form.useForm();
    const [addUserFormErrorsStep1, setAddUserFormErrorsStep1] = useState([]);
    const [addUserFormErrorsStep2, setAddUserFormErrorsStep2] = useState([]);
    const [addUserFormValues, updateAddUserFormValues] = useImmer({});

    const refPasswordRulesLowercaseCharacter = useRef(null);
    const refPasswordRulesUppercaseCharacter = useRef(null);
    const refPasswordRulesNumber = useRef(null);
    const refPasswordRules8CharactersMinimum = useRef(null);

    const handleAddUserModalCancel = () => {
        setVisible(false);
    };

    const onAddUserFormStep1PasswordChange = (event) => {
        let password = '';
        if(event && event.target && event.target.value) password = event.target.value;

        if(refPasswordRulesLowercaseCharacter.current) {
            if(/[a-z]/.test(password)) {
                refPasswordRulesLowercaseCharacter.current.className = 'rule success';
            } else {
                refPasswordRulesLowercaseCharacter.current.className = 'rule danger';
            }
        }

        if(refPasswordRulesUppercaseCharacter.current) {
            if(/[A-Z]/.test(password)) {
                refPasswordRulesUppercaseCharacter.current.className = 'rule success';
            } else {
                refPasswordRulesUppercaseCharacter.current.className = 'rule danger';
            }
        }

        if(refPasswordRulesNumber.current) {
            if(/\d/.test(password)) {
                refPasswordRulesNumber.current.className = 'rule success';
            } else {
                refPasswordRulesNumber.current.className = 'rule danger';
            }
        }

        if(refPasswordRules8CharactersMinimum.current) {
            if(password.length >= 8) {
                refPasswordRules8CharactersMinimum.current.className = 'rule success';
            } else {
                refPasswordRules8CharactersMinimum.current.className = 'rule danger';
            }
        }
    }

    const handleAddUserFormStep1Submit = (values) => {
        ComandanteAPI.HttpPostRequest('members/add?validationOnly=true', values, (err, res) => {
            if(err && err.data && err.data.errors) {
                let formErrors = {};
                for(let formError of err.data.errors) {
                    if(!['email', 'username', 'password'].includes(formError.param)) continue;
                    if(formError.param && formError.msg) formErrors[formError.param] = formError.msg;
                }

                if(formErrors && Object.keys(formErrors).length > 0) {
                    setAddUserFormErrorsStep1(formErrors);
                    return;
                }
            }

            updateAddUserFormValues(addUserFormValues => {
                return {
                    ...addUserFormValues,
                    ...values
                };
            });

            setAddUserFormStep(2);
        });
    }

    const handleAddUserFormStep2Submit = (values) => {
        ComandanteAPI.HttpPostRequest('members/add', { ...addUserFormValues, ...values }, (err, res) => {
            if(err && err.data && err.data.errors) {
                let formErrors = {};
                for(let formError of err.data.errors) {
                    if(['email', 'username', 'password'].includes(formError.param)) {
                        handleAddUserModalPrev();
                        return;
                    }
                    
                    if(formError.param && formError.msg) formErrors[formError.param] = formError.msg;
                }

                if(formErrors && Object.keys(formErrors).length > 0) {
                    setAddUserFormErrorsStep2(formErrors);
                    return;
                }
            }

            if(res && res.data && res.data.result === true) {
                if(res.data.userId) {
                    updateAddUserFormValues(addUserFormValues => {
                        addUserFormValues.userId = res.data.userId;
                    });
                }

                refreshUsers();
                
                setAddUserFormStep(3);
            }
        });
    }

    const handleAddUserModalNext = () => {
        if(addUserFormStep === 1) {
            addUserFormStep1.submit();
        } else if(addUserFormStep === 2) {
            addUserFormStep2.submit();
        } else if(addUserFormStep === 3) {
            // nothing-to-do
        }
    }
    const handleAddUserModalPrev = () => {
        if(addUserFormStep === 1) {
            // nothing-to-do
        } else if(addUserFormStep === 2) {
            setAddUserFormStep(1);
        } else if(addUserFormStep === 3) {
            setAddUserFormStep(2);
        }
    }

    return (
        <Modal title={ null } footer={ null } visible={ true } onCancel={ handleAddUserModalCancel } width={ 540 }>
            <div key={ 'addUserModalStep' + addUserFormStep } className={ 'addUserModal' }>
                { 
                    (addUserFormStep === 1) ? (
                        <div className="addUserModal-step1">
                            <div className="addUserModal-header">
                                <img className="logo" style={{ maxWidth: '200px' }} src={ logo } alt="Logo" />
                                <h2> Admin - Create Account </h2>
                            </div>

                            <div className="addUserModal-body">
                                <Form form={ addUserFormStep1 } name="addUserFormStep1" onFinish={ handleAddUserFormStep1Submit } layout="vertical" hideRequiredMark={ true }>
                                    <Form.Item
                                        name="email"
                                        label="Email"
                                        validateStatus={ addUserFormErrorsStep1 && addUserFormErrorsStep1.email ? "error" : null }
                                        help={ addUserFormErrorsStep1 && addUserFormErrorsStep1.email ? addUserFormErrorsStep1.email : null }
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        name="username"
                                        label="Username"
                                        validateStatus={ addUserFormErrorsStep1 && addUserFormErrorsStep1.username ? "error" : null }
                                        help={ addUserFormErrorsStep1 && addUserFormErrorsStep1.username ? addUserFormErrorsStep1.username : null }
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        name="password"
                                        label="Password"
                                        onChange={ onAddUserFormStep1PasswordChange }
                                        validateStatus={ addUserFormErrorsStep1 && addUserFormErrorsStep1.password ? "error" : null }
                                        help={ addUserFormErrorsStep1 && addUserFormErrorsStep1.password ? addUserFormErrorsStep1.password : null }
                                    >
                                        <Input.Password />
                                    </Form.Item>
                                    
                                    <div className="password-rules">
                                        <div className="rule" ref={ refPasswordRulesLowercaseCharacter }><div className="box"></div> Lowercase character</div>
                                        <div className="rule" ref={ refPasswordRulesUppercaseCharacter }><div className="box"></div> Uppercase character</div>
                                        <div className="rule" ref={ refPasswordRulesNumber }><div className="box"></div> Number</div>
                                        <div className="rule" ref={ refPasswordRules8CharactersMinimum }><div className="box"></div> 8 characters minimum</div>
                                    </div>
                                </Form>
                            </div>
                            
                            <div className="addUserModal-footer">
                                <Button className="btn-purple float-right" onClick={ () => { handleAddUserModalNext() } }>Next Step</Button>
                            </div>
                        </div>
                    ) : (addUserFormStep === 2) ? (
                        <div className="addUserModal-step2">
                            <div className="addUserModal-header">
                                <img className="logo" style={{ maxWidth: '200px' }} src={ logo } alt="Logo" />
                                <h2> Admin - Create Account </h2>
                            </div>

                            <div className="addUserModal-body">
                                <Form form={ addUserFormStep2 } name="addUserFormStep2" onFinish={ handleAddUserFormStep2Submit } layout="vertical" hideRequiredMark={ true }>
                                    <Form.Item
                                        name="firstName"
                                        label="First Name"
                                        validateStatus={ addUserFormErrorsStep2 && addUserFormErrorsStep2.firstName ? "error" : null }
                                        help={ addUserFormErrorsStep2 && addUserFormErrorsStep2.firstName ? addUserFormErrorsStep2.firstName : null }
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        name="lastName"
                                        label="Last Name"
                                        validateStatus={ addUserFormErrorsStep2 && addUserFormErrorsStep2.lastName ? "error" : null }
                                        help={ addUserFormErrorsStep2 && addUserFormErrorsStep2.lastName ? addUserFormErrorsStep2.lastName : null }
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Form.Item
                                        name="role"
                                        label="Role"
                                        validateStatus={ addUserFormErrorsStep2 && addUserFormErrorsStep2.role ? "error" : null }
                                        help={ addUserFormErrorsStep2 && addUserFormErrorsStep2.role ? addUserFormErrorsStep2.role : null }
                                    >
                                        <Select labelInValue>
                                            {
                                                roleOptions && Object.keys(roleOptions).map(pKey => {
                                                    return <Select.Option key={ pKey+'' } value={ pKey+'' }>{ roleOptions[pKey] }</Select.Option>;
                                                })
                                            }
                                        </Select>
                                    </Form.Item>

                                    <div className="terms-of-use">
                                        By clicking the "Create account" button, you are creating a { process.env.REACT_APP_META_TITLE } account, and you agree to { process.env.REACT_APP_META_TITLE }'s Terms of Use and Privacy Policy. 
                                    </div>
                                </Form>
                            </div>
                            
                            <div className="addUserModal-footer">
                                <Button className="btn-purple float-right" onClick={ () => { handleAddUserModalNext() } }>Create Account</Button>
                                <Button className="btn-white" onClick={ () => { handleAddUserModalPrev() } }>Back</Button>
                            </div>
                        </div>
                    ) : (addUserFormStep === 3) ? (
                        <div className="addUserModal-step3">
                            <div className="addUserModal-header">
                                <img className="logo" style={{ maxWidth: '200px' }} src={ logo } alt="Logo" />
                                <h2> Admin - Create Account </h2>
                            </div>

                            <div className="addUserModal-body">
                                <div className="mb-2">Upload Profile Picture</div>

                                <Upload.Dragger 
                                    name="profilePhoto"
                                    action={ process.env.REACT_APP_COMANDANTE_API_ADDRESS + 'members/' + addUserFormValues.userId + '/upload-profile-picture' }
                                    headers={ (localStorage.getItem('token') ? { 'Authorization': localStorage.getItem('token') + '' } : {}) }
                                    accept = "image/*"
                                    multiple={ false }
                                    defaultFileList={ [] }
                                    progress={{ strokeWidth: 2, showInfo: false }}
                                    showUploadList={ false }
                                    onChange={ 
                                        ({ file, fileList, event }) => {
                                            if(fileList && Array.isArray(fileList) && fileList.length > 0) {
                                                let uploadCompleted = true;
                                                for(let fileListItem of fileList) {
                                                    if(!fileListItem.percent || fileListItem.percent < 100 || !fileListItem.status || fileListItem.status !== 'done') {
                                                        uploadCompleted = false;
                                                        break;
                                                    }
                                                }

                                                if(uploadCompleted) {
                                                    refreshUsers();

                                                    setVisible(false);
                                                    updateAddUserFormValues(addUserFormValues => {});
                                                    addUserFormStep1.resetFields();
                                                    addUserFormStep2.resetFields();
                                                }
                                            }
                                        }
                                    }
                                    style={{ background: '#FFFFF', border: '2px dashed #D1D1E9', padding: '15px 30px 15px 30px' }}
                                >
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined style={{ fontSize: '72px', color: '#D1D1E9' }} />
                                    </p>

                                    <p style={{ fontSize: '13px', color: '#8C8C8C' }}>Drag &amp; Drop your file here.</p>
                                    <p className="mt-2" style={{ fontSize: '12px', color: '#8C8C8C' }}>OR</p>

                                    <Button className="btn-white mt-4">Browse Files</Button>
                                </Upload.Dragger>
                            </div>
                            
                            <div className="addUserModal-footer">
                                <Button className="btn-white float-right" onClick={ () => { handleAddUserModalCancel() } }>Skip</Button>
                            </div>
                        </div>
                    ) : (
                        <>
                        </>
                    )
                }
            </div>
        </Modal>
    )
}

export default AddUserModal;
