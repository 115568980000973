import React, { useState, useEffect, useCallback } from 'react';
import { useImmer } from "use-immer";

import ComandanteAPI from '../../../utils/ComandanteAPI';

import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import { ColorSet } from '../../../UIElements/ColorPicker/ColorPicker';
import CustomIcon from '../../../UIElements/CustomIcon/CustomIcon';

import { Row, Col, Modal, Form, Input, Select, Button, Dropdown, Menu, message } from 'antd';
import { PlusOutlined, EllipsisOutlined, GlobalOutlined, LockFilled, UnlockFilled } from '@ant-design/icons';

import createProjectModalBG from '../../../assets/images/edit-project-modal-BG.png';

import Error from '../../../Pages/Error/Error';

const DragHandle = sortableHandle(() => <div className="text-center drag-handle"><CustomIcon style={{ margin: '4px 0 0 0' }} iconKey="drag" color="#C0C0C3" /></div>);

const SortableItem = sortableElement(props => <Form.Item { ...props } />);
const SortableContainer = sortableContainer(props => <div { ...props } />);

const EditProjectModal = ({ productId, projectId, setVisible, ...props }) => {
    const [projectDataset, setProjectDataset] = useState(null);

    const [editProjectModalStep, setEditProjectModalStep] = useState(1);
    const [editProjectForm_step1] = Form.useForm();
    const [editProjectForm_step2] = Form.useForm();
    const [editProjectErrors_step1, setEditProjectErrors_step1] = useState([]);
    const [editProjectValues, updateEditProjectValues] = useImmer(null);

    const [selectedProjectIconColor, setSelectedProjectIconColor] = useState(null);
    
    const [stageIds, updateStageIds] = useImmer(null);
    const [stageColors, updateStageColors] = useImmer(null);
    const [stageLockedValues, updateStageLockedValues] = useImmer(null);

    let stageRefs = {};
    const setStageRefs = (index, ref) => {
        stageRefs[''+index] = ref;
    }

    const [pageError, setPageError] = useState(null);

    const fetchProject = useCallback(() => {
        ComandanteAPI.HttpGetRequest('products/' + productId + '/projects/' + projectId + '/get', (err, res) => {
            if(err) {
                setPageError(<Error status={ (err.status ? err.status : null) } />);
                return;
            }

            if(!res || !res.data) {
                setPageError(<Error status={ 404 } />);
                return;
            }

            setProjectDataset((res && res.data) || null);
        });
    }, [productId, projectId]);

    useEffect(() => {
        fetchProject();
    }, [fetchProject]);

    useEffect(() => {
        if(editProjectForm_step1 && editProjectForm_step1.__INTERNAL__ && editProjectForm_step1.__INTERNAL__.name) {
            editProjectForm_step1.setFieldsValue({
                isPublic: (projectDataset && projectDataset.project && projectDataset.project.isPublic && { value: projectDataset.project.isPublic }) || { value: false },
                name: (projectDataset && projectDataset.project && projectDataset.project.name) || null,
                description: (projectDataset && projectDataset.project && projectDataset.project.description) || null,
            });
        }

        if(projectDataset && projectDataset.project && projectDataset.project.iconColor) {
            setSelectedProjectIconColor(projectDataset.project.iconColor)
        } else {
            setSelectedProjectIconColor(ColorSet()[0]);
        }

        if(projectDataset && projectDataset.project && projectDataset.project.stages) {
            let nInitialStages = [];
            let nStageIds = [];
            let nStageColors = [];
            let nStageLockedValues = [];

            if(Array.isArray(projectDataset.project.stages) && projectDataset.project.stages.length > 0) {
                for(let stage of projectDataset.project.stages) {
                    nInitialStages.push(stage.label+'');
                    nStageIds.push(stage._id+'');
                    nStageColors.push(stage.color+'');
                    nStageLockedValues.push(stage.locked ? true : false);
                }
            }

            updateStageIds(stageIds => nStageIds);
            updateStageColors(stageColors => nStageColors);
            updateStageLockedValues(stageLockedValues => nStageLockedValues);
            
            editProjectForm_step2.setFieldsValue({
                stages: nInitialStages
            });
        }
    }, [editProjectForm_step1, editProjectForm_step2, projectDataset, updateStageColors, updateStageIds, updateStageLockedValues]);

    useEffect(() => {
        if(stageColors && Array.isArray(stageColors) && stageColors.length > 0) {
            for(let [index, stageColor] of stageColors.entries()) {
                if(stageRefs && stageRefs[''+index] && stageRefs[''+index].input) {
                    stageRefs[''+index].input.style.color = stageColor;
                }
            }
        }
    }, [stageColors, stageRefs]);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if(oldIndex === newIndex) return;

        editProjectForm_step2.setFieldsValue({
            stages: arrayMove([].concat(editProjectForm_step2.getFieldsValue(true)['stages']), oldIndex, newIndex)
        });

        updateStageIds(stageIds => {
            let stageId = stageIds[oldIndex]+'';
            let nStageIds = [ ...stageIds ];
            
            nStageIds.splice(oldIndex, 1);
            nStageIds.splice(newIndex, 0, stageId);

            return nStageIds;
        });

        updateStageColors(stageColors => {
            let stageColor = stageColors[oldIndex]+'';
            let nStageColors = [ ...stageColors ];

            nStageColors.splice(oldIndex, 1);
            nStageColors.splice(newIndex, 0, stageColor);

            return nStageColors;
        });
    };

    const handleEditProjectModalCancel = () => { setVisible(false) };

    const handleEditProjectModalNext = (values) => {
        if(editProjectModalStep === 1) {
            editProjectForm_step1.submit();
        } else if(editProjectModalStep === 2) {
            updateEditProjectValues(editProjectValues => {
                return { ...editProjectValues, stages: editProjectForm_step2.getFieldValue('stages'), stageIds: stageIds, stageColors: stageColors, stageLockedValues: stageLockedValues }
            });

            editProjectForm_step2.submit();
        }
    }

    const handleEditProjectModalPrev = (values) => {
        if(editProjectModalStep === 2) {
            setEditProjectModalStep(1);
        }
    }

    const onEditProjectFinish_step1 = values => {
        let editProjectFormValues = {
            name: values.name,
            description: values.description,
            isPublic: { value: (values.isPublic && values.isPublic.value) || false },
            iconColor: selectedProjectIconColor
        };

        ComandanteAPI.HttpPostRequest('products/' + productId + '/projects/' + projectId + '/edit?validationOnly=true', editProjectFormValues, (err, res) => {
            if(err || !res) {    
                if(err && err.data && err.data.errors) {
                    let formErrors = [];
                    for(let formError of err.data.errors) {
                        if(formError.param && formError.msg) formErrors[formError.param] = formError.msg;
                    }

                    setEditProjectErrors_step1(formErrors);
                    return;
                }

                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.data && res.data.result === true) {
                updateEditProjectValues(editProjectValues => {
                    return { ...editProjectValues, ...editProjectFormValues };
                });
                setEditProjectModalStep(2);
            }
        });
    }

    const onEditProjectFinish_step2 = values => {
        ComandanteAPI.HttpPostRequest('products/' + productId + '/projects/' + projectId + '/edit', editProjectValues, (err, res) => {
            if(err || !res) {    
                if(err && err.data && err.data.errors) {
                    let formErrors = [];
                    for(let formError of err.data.errors) {
                        if(formError.param && formError.msg) formErrors[formError.param] = formError.msg;
                    }

                    setEditProjectErrors_step1(formErrors);
                    return;
                }

                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.data && res.data.result === true) {
                window.location.href = '/products/' + productId + '/projects/' + projectId;
            }
        });
    }

    const DraggableContainer = props => (
        <SortableContainer
            useDragHandle
            disableAutoscroll
            helperClass="stageFormItem_dragging"
            onSortEnd={ onSortEnd }
            { ...props }
        />
    );
    
    const DraggableFormItem = ({ stageIndex, ...restProps }) => {
        return <SortableItem index={ stageIndex } { ...restProps } style={{ zIndex: 9999 }} />;
    };

    if(pageError) return pageError;

    return (
        <Modal title={ null } footer={ null } visible={ true } onCancel={ handleEditProjectModalCancel } width={ 640 }>
            { 
                (editProjectModalStep === 1) ? (
                    <>
                        <div className="modal-header-W">
                            <img className="w-100" src={ createProjectModalBG } alt="Edit Project Details" style={{ maxWidth: '250px', margin: '0 auto 0 auto' }} />
                            <div style={{ margin: '36px 0 0 0' }}>Project Details</div>
                        </div>

                        <div className="modal-body-W" style={{ padding: '30px 120px' }}>
                            <Form form={ editProjectForm_step1 } initialValues={{}} name="createProject" onFinish={ onEditProjectFinish_step1 } layout="vertical" hideRequiredMark={ true }>
                                <Form.Item
                                    name="isPublic"
                                    label="PRIVACY"
                                    validateStatus={ editProjectErrors_step1 && editProjectErrors_step1.isPublic ? "error" : null }
                                    help={ editProjectErrors_step1 && editProjectErrors_step1.isPublic ? editProjectErrors_step1.isPublic : null }
                                >   
                                    <Select
                                        labelInValue
                                        className="text-left"
                                        style={{ width: '280px' }}
                                    >
                                        <Select.Option value={ true }>
                                            <GlobalOutlined className="mr-1" /> All the product members
                                        </Select.Option>

                                        <Select.Option value={ false }>
                                            <LockFilled className="mr-1" />Only people invited
                                        </Select.Option>
                                    </Select>
                                </Form.Item>

                                <Form.Item
                                    name="name"
                                    label="PROJECT NAME"
                                    rules={ [{ required: true, message: 'Please enter a valid project name.' }, { max: 24, message: 'Maximum of 24 characters are allowed.' }] }
                                    validateStatus={ editProjectErrors_step1 && editProjectErrors_step1.name ? "error" : null }
                                    help={ editProjectErrors_step1 && editProjectErrors_step1.name ? editProjectErrors_step1.name : null }
                                >
                                    <Input />
                                </Form.Item>
                            
                                <Form.Item
                                    name="description"
                                    label="DESCRIPTION"
                                    rules={ [{ required: true, message: 'Please enter a valid project description.' }, { max: 256, message: 'Maximum of 256 characters are allowed.' }] }
                                    validateStatus={ editProjectErrors_step1 && editProjectErrors_step1.description ? "error" : null }
                                    help={ editProjectErrors_step1 && editProjectErrors_step1.description ? editProjectErrors_step1.description : null }
                                >
                                    <Input.TextArea rows={ 3 } />
                                </Form.Item>

                                <Form.Item
                                    label="CHOOSE A COLOR"
                                >
                                    <div className="color-picker">
                                        <div>
                                            {
                                                ColorSet() && Array.isArray(ColorSet()) && ColorSet().length > 0 && ColorSet().map((color) => {
                                                    return (
                                                        <div key={ color } className={ 'option' + (selectedProjectIconColor && selectedProjectIconColor === color ? ' selected' : '') } onClick={ () => { setSelectedProjectIconColor(color) } }>
                                                            <div className="outer">
                                                                <div className="inner" style={{ background: color }}></div>
                                                            </div>
                                                        </div>
                                                    );
                                                })
                                            }
                                        </div>
                                    </div>
                                </Form.Item>
                            </Form>
                        </div>

                        <div className="modal-footer-W" style={{ background: '#F8F8FB' }}>
                            <div className="text-right">
                                <Button className="btn-purple" onClick={ () => { handleEditProjectModalNext() } }>Next Step</Button>
                            </div>
                        </div>
                    </>
                ) : (editProjectModalStep === 2) ? (
                    <>
                        <div className="modal-header-W">
                            <div>Project Stages Template</div>
                        </div>

                        <div className="modal-body-W" style={{ padding: '30px 120px' }}>
                            <Row gutter={ 16 }>
                                <Col span={ 12 }>
                                    <div className="col-header">Templates</div>
                                    <Button
                                        className="btn-nothing d-block mt-2"
                                        style={{ padding: '0 0 0 0', fontWeight: 500 }}
                                        onClick={
                                            () => {
                                                editProjectForm_step2.setFieldsValue({ 'stages': ['To do', 'Done'] });
                                                updateStageColors(stageColors => {
                                                    return [ColorSet()[0], ColorSet()[1]];
                                                });
                                            }
                                        }
                                    >
                                        Basic
                                    </Button>
                                    <Button
                                        className="btn-nothing d-block mt-2"
                                        style={{ padding: '0 0 0 0', fontWeight: 500 }}
                                        onClick={
                                            () => {
                                                editProjectForm_step2.setFieldsValue({ 'stages': ['Planning', 'Development', 'Testing', 'Demo', 'Done'] });
                                                updateStageColors(stageColors => {
                                                    return [ColorSet()[0], ColorSet()[1], ColorSet()[2], ColorSet()[3], ColorSet()[4]];
                                                });
                                            }
                                        }
                                    >
                                        Development
                                    </Button>
                                    <Button
                                        className="btn-nothing d-block mt-2"
                                        style={{ padding: '0 0 0 0', fontWeight: 500 }}
                                        onClick={
                                            () => {
                                                editProjectForm_step2.setFieldsValue({ 'stages': ['To do', 'In review', 'Done'] });
                                                updateStageColors(stageColors => {
                                                    return [ColorSet()[0], ColorSet()[1], ColorSet()[2]];
                                                });
                                            }
                                        }
                                    >
                                        Marketing
                                    </Button>
                                    <Button
                                        className="btn-nothing d-block mt-2"
                                        style={{ padding: '0 0 0 0', fontWeight: 500 }}
                                        onClick={
                                            () => {
                                                editProjectForm_step2.setFieldsValue({ 'stages': [] });
                                                updateStageColors(stageColors => {
                                                    return [];
                                                });
                                            }
                                        }
                                    >
                                        Custom
                                    </Button>
                                </Col>

                                <Col span={ 12 }>
                                    <div className="col-header">Stages</div>

                                    <Form id="form_manageStages" form={ editProjectForm_step2 } onFinish={ onEditProjectFinish_step2 } layout="vertical">
                                        <Form.List name="stages">
                                            { (fields, { add, remove }) => {
                                                return (
                                                    <DraggableContainer>
                                                        { fields.map((field, fieldIndex) => (
                                                            <DraggableFormItem key={ fieldIndex } stageIndex={ fieldIndex }>
                                                                <Form.Item { ...field }>
                                                                    <Input
                                                                        key={ fieldIndex }
                                                                        ref={ (ref) => { setStageRefs(fieldIndex, ref) } }
                                                                        className={ stageLockedValues && stageLockedValues[fieldIndex] ? 'stage locked' : '' }
                                                                        placeholder={ 'Enter a name...' }
                                                                        onPressEnter={ () => { if(stageRefs && stageRefs[''+fieldIndex]) stageRefs[''+fieldIndex].blur(); } }
                                                                        prefix={ <DragHandle /> }
                                                                        suffix={
                                                                            <Dropdown   
                                                                                placement="bottomCenter"
                                                                                trigger={ ['click'] }
                                                                                overlay={
                                                                                    <Menu style={{ width: '180px' }}>
                                                                                        <Menu.SubMenu title={ <><CustomIcon iconKey="colorpicker" style={{ width: '16px', height: '16px' }} /> Change color</> }>
                                                                                            {
                                                                                                ColorSet() && Array.isArray(ColorSet()) && ColorSet().length > 0 && ColorSet().map((color) => {
                                                                                                    return (
                                                                                                        <Menu.Item
                                                                                                            key={ color }
                                                                                                            className={ 'text-center d-inline-block' }
                                                                                                            style={{ width: '40px' }}
                                                                                                            onItemHover={ () => {} }
                                                                                                            onClick={
                                                                                                                () => {
                                                                                                                    updateStageColors(stageColors => {
                                                                                                                        if(stageColors && stageColors[fieldIndex]) {
                                                                                                                            stageColors[fieldIndex] = color;
                                                                                                                        }
                                                                                                                    });
                                                                                                                }
                                                                                                            }
                                                                                                        >
                                                                                                            <div style={{ fontSize: '32px', color: color }}>&#8226;</div>
                                                                                                        </Menu.Item>
                                                                                                    );
                                                                                                })
                                                                                            }
                                                                                        </Menu.SubMenu>

                                                                                        {
                                                                                            stageLockedValues && stageLockedValues[fieldIndex] ? (
                                                                                                <Menu.Item
                                                                                                    onClick={
                                                                                                        () => {
                                                                                                            updateStageLockedValues(stageLockedValues => {
                                                                                                                if(!stageLockedValues || !Array.isArray(stageLockedValues) || stageLockedValues.length <= 0) return;
                                                                                                                if(stageLockedValues[fieldIndex]) stageLockedValues[fieldIndex] = false;

                                                                                                                return stageLockedValues;
                                                                                                            })
                                                                                                        }
                                                                                                    }
                                                                                                >
                                                                                                    <UnlockFilled className="mr-0" style={{ fontSize: '16px' }} /> Unlock stage
                                                                                                </Menu.Item>
                                                                                            ) : (
                                                                                                <Menu.Item
                                                                                                    onClick={
                                                                                                        () => {
                                                                                                            updateStageLockedValues(stageLockedValues => {
                                                                                                                if(!stageLockedValues || !Array.isArray(stageLockedValues) || stageLockedValues.length <= 0) stageLockedValues = [];
                                                                                                                stageLockedValues[fieldIndex] = true;

                                                                                                                return stageLockedValues;
                                                                                                            })
                                                                                                        }
                                                                                                    }
                                                                                                >
                                                                                                    <LockFilled className="mr-0" style={{ fontSize: '16px' }} /> Lock stage
                                                                                                </Menu.Item>
                                                                                            )
                                                                                        }

                                                                                        <Menu.Item onClick={ () => remove(field.name) }>
                                                                                            <CustomIcon iconKey="delete" color="#E45858" style={{ margin: '4px 0 0 0', height: '14px' }} /> Remove stage
                                                                                        </Menu.Item>
                                                                                    </Menu>
                                                                                }
                                                                            >
                                                                                <EllipsisOutlined style={{ fontSize: '24px', lineHeight: 0.5 }} />
                                                                            </Dropdown>
                                                                        }
                                                                    />
                                                                </Form.Item>

                                                                { /* fields.length > 1 && <MinusCircleOutlined className="dynamic-delete-button" onClick={() => remove(field.name)} /> */ }
                                                            </DraggableFormItem>
                                                        ))}
                                                
                                                        <Form.Item>                                                
                                                            <Button 
                                                                className="btn-nothing"
                                                                style={{ fontWeight: 500, fontSize: '12px', color: '#6246EA' }}
                                                                icon={ <PlusOutlined /> }
                                                                onClick={
                                                                    () => {
                                                                        add();

                                                                        updateStageIds(stageIds => {
                                                                            if(!stageIds || !Array.isArray(stageIds)) {
                                                                                let nStageIds = [];
                                                                                nStageIds.push(null);
                                                                                return nStageIds;
                                                                            }

                                                                            stageIds.push(null);
                                                                        });

                                                                        updateStageColors(stageColors => {
                                                                            if(!stageColors || !Array.isArray(stageColors)) {
                                                                                let nStageColors = [];
                                                                                nStageColors.push(null);
                                                                                return nStageColors;
                                                                            }

                                                                            stageColors.push(null);
                                                                        });

                                                                        updateStageLockedValues(stageLockedValues => {
                                                                            if(!stageLockedValues || !Array.isArray(stageLockedValues)) {
                                                                                let nStageLockedValues = [];
                                                                                nStageLockedValues.push(false);

                                                                                return nStageLockedValues;
                                                                            }

                                                                            stageLockedValues.push(false);
                                                                        });
                                                                    }
                                                                } 
                                                            >
                                                                ADD A PROJECT STAGE
                                                            </Button>
                                                        </Form.Item>
                                                    </DraggableContainer>
                                                )}
                                            }   
                                        </Form.List>
                                    </Form>
                                </Col>
                            </Row>
                        </div>

                        <div className="modal-footer-W" style={{ background: '#F8F8FB' }}>
                            <div>
                                <Button className="btn-purple float-right" onClick={ () => { handleEditProjectModalNext() } }>Save Changes</Button>
                                <Button className="btn-white" onClick={ () => { handleEditProjectModalPrev() } }>Previous Step</Button>
                            </div>
                        </div>
                    </>
                ) : (
                    null
                )
            }
        </Modal>
    )
}

export default EditProjectModal;