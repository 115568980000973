import React, { useState, useEffect, useCallback } from 'react';
import { useImmer } from "use-immer";
import { useSystemContext } from '../../../Context/SystemContext';

import ComandanteAPI from '../../../utils/ComandanteAPI';

import { Avatar, Input, Button, Table, Modal, message, Form } from 'antd';

import avatarPlaceholder from '../../../assets/images/placeholder-avatar.png';
import removeIcon from '../../../assets/images/icon-remove.png';

const ManageProjectTeamsAndMembers = ({ productId, projectId, setVisible }) => {
    const { 
        currentUser,
        systemVariables
    } = useSystemContext();

    const roleOptions = (systemVariables && systemVariables.user && systemVariables.user.roleOptions ? systemVariables.user.roleOptions : null);

    const [projectDataset, setProjectDataset] = useState(null);

    const [productTeamsDataset, updateProductTeamsDataset] = useImmer(null);
    const [teamsToAdd, updateTeamsToAdd] = useImmer(null);
    const [productMembersDataset, updateProductMembersDataset] = useImmer(null);
    const [membersToAdd, updateMembersToAdd] = useImmer(null);
    const [combinedTeamsAndMembersData, updateCombinedTeamsAndMembersData] = useImmer(null);
    const [combinedTeamsAndMembersDataFilter, setCombinedTeamsAndMembersDataFilter] = useState(null);
    const [combinedTeamsAndMembersToAdd, updateCombinedTeamsAndMembersToAdd] = useImmer(null);

    const fetchProject = useCallback(() => {
        ComandanteAPI.HttpGetRequest('products/' + productId + '/projects/' + projectId + '/get', (err, res) => {
            setProjectDataset((res && res.data) || null);
        });
    }, [productId, projectId]);

    const fetchProductTeams = useCallback(() => {
        ComandanteAPI.HttpGetRequest('products/' + productId + '/teams?paginated=false', (err, res) => {
            updateProductTeamsDataset(productTeamsDataset => (res && res.data) || []);
        });
    }, [productId, updateProductTeamsDataset]);

    const fetchProductMembers = useCallback((sort = null) => {
        ComandanteAPI.HttpGetRequest('products/' + productId + '/members?paginated=false&excludeTeamMembers=true', (err, res) => {
            updateProductMembersDataset(productMembersDataset => (res && res.data) || []);
        });
    }, [productId, updateProductMembersDataset]);

    useEffect(() => {
        fetchProject();
        fetchProductTeams();
        fetchProductMembers();
    }, [fetchProject, fetchProductTeams, fetchProductMembers]);

    useEffect(() => {
        if(productTeamsDataset && productMembersDataset) {
            let xCombinedTeamsAndMembersData = {};
            
            if(productTeamsDataset.data && Object.keys(productTeamsDataset.data).length > 0) {
                for(let team of Object.values(productTeamsDataset.data)) {
                    xCombinedTeamsAndMembersData[team._id+''] = { ...team, isTeam: true };
                }
            }

            if(productMembersDataset.data && Object.keys(productMembersDataset.data).length > 0) {
                for(let member of Object.values(productMembersDataset.data)) {
                    xCombinedTeamsAndMembersData[member._id+''] = { ...member, isTeam: false };
                }
            }
            
            updateCombinedTeamsAndMembersData(combinedTeamsAndMembersData => xCombinedTeamsAndMembersData);
        }
    }, [productTeamsDataset, productMembersDataset, updateCombinedTeamsAndMembersData]);

    useEffect(() => {
        updateTeamsToAdd(teamsToAdd => {
            let nTeamsToAdd = [];

            if(projectDataset && projectDataset.project && projectDataset.project.teamIds && Array.isArray(projectDataset.project.teamIds) && projectDataset.project.teamIds.length > 0) {
                for(let teamId of projectDataset.project.teamIds) {
                    if(!nTeamsToAdd.includes(teamId+'')) nTeamsToAdd.push(teamId+'');
                }
            }

            return nTeamsToAdd;
        });

        updateMembersToAdd(membersToAdd => {
            let nMembersToAdd = [];

            if(projectDataset && projectDataset.project && projectDataset.project.memberIds && Array.isArray(projectDataset.project.memberIds) && projectDataset.project.memberIds.length > 0) {
                for(let memberId of projectDataset.project.memberIds) {
                    if(!nMembersToAdd.includes(memberId+'')) nMembersToAdd.push(memberId+'');
                }
            }

            return nMembersToAdd;
        });
    }, [projectDataset, updateTeamsToAdd, updateMembersToAdd]);

    useEffect(() => {
        if(teamsToAdd || membersToAdd) {
            let xCombinedTeamsAndMembersToAdd = [];
            
            if(teamsToAdd && Array.isArray(teamsToAdd) && teamsToAdd.length > 0) {
                for(let teamId of teamsToAdd) {
                    xCombinedTeamsAndMembersToAdd.push(teamId);
                }
            }

            if(membersToAdd && Array.isArray(membersToAdd) && membersToAdd.length > 0) {
                for(let memberId of membersToAdd) {
                    xCombinedTeamsAndMembersToAdd.push(memberId);
                }
            }
            
            updateCombinedTeamsAndMembersToAdd(combinedTeamsAndMembersToAdd => xCombinedTeamsAndMembersToAdd);
        }
    }, [teamsToAdd, membersToAdd, updateCombinedTeamsAndMembersToAdd]);

    const handleManageProjectMembersModalCancel = () => { setVisible(false) };

    const handleManageProjectMembersModalOk = () => {
        ComandanteAPI.HttpPostRequest('products/' + productId + '/projects/' + projectId + '/teams-and-members/manage', { teamIds: teamsToAdd, memberIds: membersToAdd }, (err, res) => {
            if(err || !res) {
                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.status === 200) {
                setVisible(false)
                fetchProject();
                return;
            }
        });
    }

    const handleManageProjectMembersModalUndo = () => {
        updateTeamsToAdd(teamsToAdd => {
            let nTeamsToAdd = [];

            if(projectDataset && projectDataset.project && projectDataset.project.teamIds && Array.isArray(projectDataset.project.teamIds) && projectDataset.project.teamIds.length > 0) {
                for(let teamId of projectDataset.project.teamIds) {
                    if(!nTeamsToAdd.includes(teamId+'')) nTeamsToAdd.push(teamId+'');
                }
            }

            return nTeamsToAdd;
        });

        updateMembersToAdd(membersToAdd => {
            let nMembersToAdd = [];

            if(projectDataset && projectDataset.project && projectDataset.project.memberIds && Array.isArray(projectDataset.project.memberIds) && projectDataset.project.memberIds.length > 0) {
                for(let memberId of projectDataset.project.memberIds) {
                    if(!nMembersToAdd.includes(memberId+'')) nMembersToAdd.push(memberId+'');
                }
            }

            return nMembersToAdd;
        });
    }

    const combinedTeamsAndMembersColumns = [
        {
            width: 32,
            render: (text, record) => {
                if(record.isTeam) {
                    return <Avatar shape="square" size={ 20 } style={{ backgroundColor: '#c41d7f' }}>{ record.name[0] }</Avatar>
                } else {
                    return <Avatar shape="square" size={ 20 } src={ (record.profilePhoto ? process.env.REACT_APP_COMANDANTE_API_ADDRESS + record.profilePhoto.path.substring(7) : avatarPlaceholder) } alt={ 'Avatar | ' + record.firstName + ' ' + record.lastName } height={ 20 } />
                }
            }
        },
        {
            title: 'Team / User',
            render: (text, record) => (record.isTeam ? record.name : record.firstName + ' ' + record.lastName)
        },
        {
            title: 'Role',
            width: 180,
            render: (text, record) => {
                if(record.isTeam) {
                    return 'N/A';
                } else {
                    if(!roleOptions || !roleOptions[record.role]) return 'Unknown';
                    return roleOptions[record.role+''];
                }
            }
        },
        {
            width: 60,
            render: (text, record) => {
                return (
                    (!combinedTeamsAndMembersToAdd || !Array.isArray(combinedTeamsAndMembersToAdd) || !combinedTeamsAndMembersToAdd.includes(record._id+'')) && (
                        <Button 
                            className="btn-white mt-1" 
                            style={{ height: '25px', lineHeight: '25px' }}
                            onClick={ 
                                () => {
                                    if(record.isTeam) {
                                        updateTeamsToAdd(teamsToAdd => {
                                            if(!teamsToAdd || !Array.isArray(teamsToAdd)) return [record._id+''];
                                            if(!teamsToAdd.includes(record._id+'')) teamsToAdd.push(record._id+'');
                                        });
                                    } else {
                                        updateMembersToAdd(membersToAdd => {
                                            if(!membersToAdd || !Array.isArray(membersToAdd)) return [record._id+''];
                                            if(!membersToAdd.includes(record._id+'')) membersToAdd.push(record._id+'');
                                        });
                                    }
                                } 
                            }
                        >
                            Add
                        </Button>
                    )
                );
            }
        }
    ];

    const combinedTeamsAndMembersAddColumns = [
        {
            width: 32,
            render: (recordId) => {
                if(!combinedTeamsAndMembersData || !combinedTeamsAndMembersData[recordId+'']) return 'Unknown';

                if(combinedTeamsAndMembersData[recordId+''].isTeam) {
                    return <Avatar shape="square" size={ 20 } style={{ backgroundColor: '#c41d7f' }}>{ combinedTeamsAndMembersData[recordId+''].name[0] }</Avatar>
                } else {
                    return <Avatar shape="square" size={ 20 } src={ (combinedTeamsAndMembersData[recordId+''].profilePhoto ? process.env.REACT_APP_COMANDANTE_API_ADDRESS + combinedTeamsAndMembersData[recordId+''].profilePhoto.path.substring(7) : avatarPlaceholder) } alt={ 'Avatar | ' + combinedTeamsAndMembersData[recordId+''].firstName + ' ' + combinedTeamsAndMembersData[recordId+''].lastName } height={ 20 } />
                }
            }
        },
        {
            title: 'Team / User',
            render: (recordId) => {
                if(!combinedTeamsAndMembersData || !combinedTeamsAndMembersData[recordId+'']) return 'Unknown';
                if(combinedTeamsAndMembersData[recordId+''].isTeam) {
                    return combinedTeamsAndMembersData[recordId+''].name;
                } else {
                    return combinedTeamsAndMembersData[recordId+''].firstName + ' ' + combinedTeamsAndMembersData[recordId+''].lastName + (recordId+'' === currentUser._id+'' ? ' (You)' : '');
                }
            }
        },
        {
            title: 'Role',
            width: 180,
            render: (recordId) => {
                if(!combinedTeamsAndMembersData || !combinedTeamsAndMembersData[recordId+'']) return 'Unknown';
                if(combinedTeamsAndMembersData[recordId+''].isTeam) {
                    return 'N/A';
                } else {
                    if(!roleOptions || !roleOptions[combinedTeamsAndMembersData[recordId+''].role]) return 'Unknown';
                    return roleOptions[combinedTeamsAndMembersData[recordId+''].role+''];
                }
            }
        },
        {
            width: 60,
            render: (recordId) => {
                return (
                    (recordId+'' !== currentUser._id+'') && (
                        <Button 
                            className="btn-nothing mt-1" 
                            style={{ height: '25px', lineHeight: '25px' }}
                            onClick={ 
                                () => {
                                    if(!combinedTeamsAndMembersData || !combinedTeamsAndMembersData[recordId+'']) return;

                                    if(combinedTeamsAndMembersData[recordId+''].isTeam) {
                                        updateTeamsToAdd(teamsToAdd => {
                                            if(teamsToAdd && Array.isArray(teamsToAdd) && teamsToAdd.includes(recordId+'')) {
                                                let i = teamsToAdd.indexOf(recordId+'');
                                                if(i > -1) teamsToAdd.splice(i, 1);
                                            }
                                        });
                                    } else {
                                        updateMembersToAdd(membersToAdd => {
                                            if(membersToAdd && Array.isArray(membersToAdd) && membersToAdd.includes(recordId+'')) {
                                                let i = membersToAdd.indexOf(recordId+'');
                                                if(i > -1) membersToAdd.splice(i, 1);
                                            }
                                        });
                                    }
                                }
                            }
                        >
                            <img className="icon" src={ removeIcon } alt="Icon" style={{ height: '16px' }} />
                        </Button>
                    )
                );
            }
        }
    ];

    return (
        <Modal title={ null } footer={ null } visible={ true } onCancel={ handleManageProjectMembersModalCancel } width={ 1199 }>
            <div className="modal-header-W" style={{ padding: '30px 30px' }}>
                <div className="text-left">Manage Project Members</div>
            </div>

            <div className="modal-body-W">
                <div className="R1" style={{ padding: '15px 30px' }}>
                    <div className="float-right" style={{ marginTop: '42px' }}>
                        <Button className="btn-white" onClick={ () => { handleManageProjectMembersModalUndo() } }>Undo All</Button>
                        <Button className="btn-purple ml-4" onClick={ () => { handleManageProjectMembersModalOk() } }>Save Changes</Button>
                    </div>

                    <Form layout="vertical">
                        <Form.Item label="Search by individual">
                            <Input 
                                style={{ maxWidth: '280px' }}
                                onChange={ (event) => {
                                    event.persist(); // Signal to React not to nullify the event object

                                    setCombinedTeamsAndMembersDataFilter((event.target.value ? event.target.value.toLowerCase() : null));
                                }}
                            />
                        </Form.Item>
                    </Form>

                    <div className="table-section-header mt-3">Teams / Individiuals Found</div>
                    <Table 
                        rowKey="_id"
                        rowClassName={ (record, index) => ((combinedTeamsAndMembersDataFilter && (record.isTeam ? !(record.name.toLowerCase().includes(combinedTeamsAndMembersDataFilter)) : !(record.firstName.toLowerCase().includes(combinedTeamsAndMembersDataFilter) || record.lastName.toLowerCase().includes(combinedTeamsAndMembersDataFilter) || record.username.toLowerCase().includes(combinedTeamsAndMembersDataFilter)))) || record._id+'' === currentUser._id+'' || (combinedTeamsAndMembersToAdd && combinedTeamsAndMembersToAdd.includes(record._id+'')) ? 'd-none' : '') }
                        className="members-to-add-T mt-2"
                        columns={ combinedTeamsAndMembersColumns }
                        dataSource={ combinedTeamsAndMembersData ? Object.values(combinedTeamsAndMembersData) : [] } pagination={ false }
                    />
                </div>

                <div className="R2" style={{ background: '#FFFFFF', padding: '15px 30px' }}>
                    <div className="table-section-header mt-3">Teams / Individiuals Added</div>
                    <Table
                        rowKey={ (recordId) => recordId }
                        rowClassName={ (recordId, index) => (combinedTeamsAndMembersDataFilter && combinedTeamsAndMembersData && combinedTeamsAndMembersData[recordId+''] && (combinedTeamsAndMembersData[recordId+''].isTeam ? !(combinedTeamsAndMembersData[recordId+''].name.toLowerCase().includes(combinedTeamsAndMembersDataFilter)) : !(combinedTeamsAndMembersData[recordId+''].firstName.toLowerCase().includes(combinedTeamsAndMembersDataFilter) || combinedTeamsAndMembersData[recordId+''].lastName.toLowerCase().includes(combinedTeamsAndMembersDataFilter) || combinedTeamsAndMembersData[recordId+''].username.toLowerCase().includes(combinedTeamsAndMembersDataFilter))) ? 'd-none' : '') }
                        className="members-added-T mt-2"
                        columns={ combinedTeamsAndMembersAddColumns }
                        dataSource={ combinedTeamsAndMembersToAdd ? combinedTeamsAndMembersToAdd : [] }
                        pagination={ false }
                    />
                </div>
            </div>
        </Modal>
    )
}

export default ManageProjectTeamsAndMembers;