export const ProductIconsSet = (color = '#595959', style = {}) => {
    return {
        "tri-hexagon": (
            <svg width="14" height="14" viewBox="0 0 14 14" fill={ color } xmlns="http://www.w3.org/2000/svg" style={{ ...style, maxHeight: '100%' }}>
                <path d="M4.83333 0.333374C4.96 0.333374 5.07333 0.406707 5.12667 0.506707L6.60667 3.14671L6.66667 3.33337L6.60667 3.52004L5.12667 6.16004C5.07333 6.26004 4.96 6.33337 4.83333 6.33337H1.83333C1.70667 6.33337 1.59333 6.26004 1.54 6.16004L0.0599999 3.52004L0 3.33337L0.0599999 3.14671L1.54 0.506707C1.59333 0.406707 1.70667 0.333374 1.83333 0.333374H4.83333ZM4.83333 7.66671C4.96 7.66671 5.07333 7.74004 5.12667 7.84004L6.60667 10.48L6.66667 10.6667L6.60667 10.8534L5.12667 13.4934C5.07333 13.5934 4.96 13.6667 4.83333 13.6667H1.83333C1.70667 13.6667 1.59333 13.5934 1.54 13.4934L0.0599999 10.8534L0 10.6667L0.0599999 10.48L1.54 7.84004C1.59333 7.74004 1.70667 7.66671 1.83333 7.66671H4.83333ZM11 4.00004C11.1267 4.00004 11.24 4.07337 11.2933 4.17337L12.7733 6.81337L12.8333 7.00004L12.7733 7.18671L11.2933 9.82671C11.24 9.92671 11.1267 10 11 10H8C7.87333 10 7.76 9.92671 7.70667 9.82671L6.22667 7.18671L6.16667 7.00004L6.22667 6.81337L7.70667 4.17337C7.76 4.07337 7.87333 4.00004 8 4.00004H11Z" />
            </svg>
        ),
        "quadrilateral": (
            <svg width="14" height="14" viewBox="0 0 14 14" fill={ color } xmlns="http://www.w3.org/2000/svg" style={{ ...style, maxHeight: '100%' }}>
                <path d="M7.00037 0.333374C6.66703 0.333374 6.3337 0.460041 6.06037 0.726707L0.727031 6.06004C0.200365 6.58004 0.200365 7.42004 0.727031 7.94004L6.06037 13.2734C6.58037 13.8 7.42036 13.8 7.94036 13.2734L13.2737 7.94004C13.8004 7.42004 13.8004 6.58004 13.2737 6.06004L7.94036 0.726707C7.66703 0.460041 7.3337 0.333374 7.00037 0.333374Z" />
            </svg>
        ),
        "pentagon": (
            <svg width="14" height="14" viewBox="0 0 14 14" fill={ color } xmlns="http://www.w3.org/2000/svg" style={{ ...style, maxHeight: '100%' }}>
                <path d="M6.99967 0.666626L0.333008 5.53329L2.86634 13.3333H11.133L13.6663 5.53329L6.99967 0.666626Z" />
            </svg>
        ),
        "enneagram": (
            <svg width="14" height="14" viewBox="0 0 14 14" fill={ color } xmlns="http://www.w3.org/2000/svg" style={{ ...style, maxHeight: '100%' }}>
                <path d="M0.466797 9.70667L1.5868 7.00001L0.466797 4.29334L3.17346 3.17334L4.29346 0.466675L7.00013 1.58667L9.7068 0.466675L10.8268 3.17334L13.5335 4.29334L12.4135 7.00001L13.5335 9.70667L10.8268 10.8267L9.7068 13.5333L7.00013 12.4133L4.29346 13.5333L3.17346 10.8267L0.466797 9.70667Z" />
            </svg>
        )
    }
}

const ProductIcon = (props) => {
    let iconSet = ProductIconsSet(props.color, props.style);
    
    return (
        <div className={ 'd-inline ' + props.className }>
            { iconSet && iconSet[props.iconKey] }
        </div>
    ) || null;
}

export default ProductIcon;