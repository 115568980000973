import React, { useState, useEffect, useCallback } from 'react';
import { useImmer } from "use-immer";
import { useSystemContext } from '../../Context/SystemContext';

import Helmet from 'react-helmet';

import { Link } from 'react-router-dom';

import moment from 'moment';

import ComandanteAPI from '../../utils/ComandanteAPI';

import debounce from 'lodash.debounce';

import MainLayout from '../../Layouts/Main';

import ProductIcon from '../../UIElements/ProductIcon/ProductIcon';
import CustomIcon from '../../UIElements/CustomIcon/CustomIcon';

import { sortableContainer, sortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';

import { Skeleton, Empty, Table, Tabs, Form, Input, Button, Modal, message } from 'antd';
import { SearchOutlined, RollbackOutlined } from '@ant-design/icons';

import AddProductModal from './AddProductModal/AddProductModal';

import './Products.css';

const DragHandle = sortableHandle(() => <div className="text-center drag-handle"><CustomIcon className="mt-3" style={{ margin: '12px 0 0 0' }} iconKey="drag" color="#C0C0C3" /></div>);

const SortableItem = sortableElement(props => <tr { ...props } />);
const SortableContainer = sortableContainer(props => <tbody { ...props } />);

const Products = (props) => {
    const { 
        systemVariables,
        membersDataset,
        fetchProducts,
    } = useSystemContext();

    const statusOptions = (systemVariables && systemVariables.product && systemVariables.product.statusOptions ? systemVariables.product.statusOptions : null);

    const [activeTab, setActiveTab] = useState('/active');

    const [productsDataset, updateProductsDataset] = useImmer(null);
    const [activeProducts, setActiveProducts] = useState(null);
    const [archivedProducts, setArchivedProducts] = useState(null);

    const [addProductModalVisible, setAddProductModalVisible] = useState(false);

    const [searchForm] = Form.useForm();

    const getProducts = useCallback(async (searchQuery) => {
        ComandanteAPI.HttpGetRequest('products?paginated=false' + (searchQuery ? '&search=' + searchQuery : ''), (err, res) => {
            updateProductsDataset(productsDataset => (res && res.data) || []);
        });
    }, [updateProductsDataset]);

    useEffect(() => {
        getProducts(null);
    }, [getProducts]);

    const debounced_getProducts = debounce(value => {
        getProducts(value);
    }, 300);

    useEffect(() => {
        if(productsDataset && productsDataset.data && Object.values(productsDataset.data).length > 0) {
            let iActiveProducts = [];
            let iArchivedProducts = [];

            for(let product of Object.values(productsDataset.data)) {
                if(statusOptions && statusOptions[product.status] && statusOptions[product.status+''] === 'Active') {
                    iActiveProducts.push(product);
                } else if(statusOptions && statusOptions[product.status] && statusOptions[product.status+''] === 'Inactive') {
                    iArchivedProducts.push(product);
                }
            }

            setActiveProducts(iActiveProducts);
            setArchivedProducts(iArchivedProducts);
        }
    }, [productsDataset, statusOptions]);

    const onSortEnd = ({ oldIndex, newIndex }) => {
        if(!productsDataset || !productsDataset.data) return;
        
        if(oldIndex !== newIndex) {
            let xProduct = (Object.values(productsDataset.data)[oldIndex] || null);
            if(xProduct === null) return;

            let newPositionIndex = ((Object.values(productsDataset.data)[newIndex] && Object.values(productsDataset.data)[newIndex].positionIndex) || null);
            if(newPositionIndex === null) return;

            updateProductsDataset(productsDataset => {
                productsDataset.data = arrayMove([].concat(Object.values(productsDataset.data)), oldIndex, newIndex).filter(el => !!el);
            });

            ComandanteAPI.HttpPostRequest('products/update-position-indices', { productId: xProduct._id, newPositionIndex: newPositionIndex }, (err, res) => {
                if(err || !res) {
                    message.error('Could not update the order!');
                    return;
                }

                if(res.status === 200) {
                    fetchProducts();
                    debounced_getProducts((searchForm && searchForm.getFieldValue('search') ? searchForm.getFieldValue('search') : ''));
                }
            });
        }
    };

    const DraggableContainer = props => (
        <SortableContainer
            useDragHandle
            disableAutoscroll
            helperClass="row-dragging"
            onSortEnd={ onSortEnd }
            { ...props }
        />
    );
    
    const DraggableBodyRow = ({ className, style, ...restProps }) => {
        const arrayIndex = (productsDataset && productsDataset.data && Object.keys(productsDataset.data).findIndex(productId => productId === restProps['data-row-key'])) || 0;
        return <SortableItem index={ arrayIndex } { ...restProps } />;
    };

    const columns = [
        {
            title: '',
            width: '40px',
            className: 'drag-visible',
	    responsive: ['md', 'lg', 'xl'],
            render: (text, record) => {
                return <DragHandle />
            }
        },
        {
            title: '',
            width: '40px',
            className: 'drag-visible',
            render: (text, record) => {
                return <div className="text-center"><ProductIcon style={{ margin: '11px 0 0 0' }} iconKey={ record.iconKey } color={ record.iconColor } /></div>
            }
        },
        {
            title: 'Product',
            dataIndex: 'name',
            key: 'name',
            className: 'productName drag-visible',
            render: (text, record) => {
                return record.name ? record.name : 'N/A';
            }
        },
        {
            title: 'Creation',
            width: '160px',
            responsive: ['xl'],
            render: (text, record) => {
                return record.createdAt ? moment(record.createdAt).format('LL') : 'N/A';
            }
        },
        {
            title: 'Project Manager',
	    responsive: ['md', 'lg', 'xl'],
            render: (text, record) => {
                return record.ownerId ? (membersDataset && membersDataset.data && membersDataset.data[record.ownerId] ? membersDataset.data[record.ownerId].firstName + ' ' + membersDataset.data[record.ownerId].lastName : record.ownerId) : 'N/A';
            }
        },
        {
            title: 'Members',
	    responsive: ['lg', 'xl'],
            render: (text, record) => {
                return record.members && Array.isArray(record.members) ? record.members.length : 'N/A';
            }
        },
        {
            title: 'Projects',
	    responsive: ['lg', 'xl'],
            render: (text, record) => {
                return 'N/A';
            }
        },
        {
            title: 'Last Activity',
	    responsive: ['xl'],
            render: (text, record) => {
                return 'N/A';
            }
        },
        {
            title: 'Actions',
            className: 'actions',
            render: (text, record) => {
                return (
                    <>
                        { 
                            activeTab === '/active' ? (
                                <>
                                    <Button className="btn-nothing action" onClick={ () => { setIdOfProductToBeArchived(record._id); setArchiveProductModalVisible(true) } }><CustomIcon className="ml-1 mr-1" iconKey="archive" /></Button>
                                    <Link to={ '/products/' + record._id }><Button className="btn-nothing action"><CustomIcon className="ml-1 mr-1" iconKey="view" /></Button></Link>
                                </>
                            ) : (
                                <>
                                    <Button className="btn-nothing action" onClick={ () => { setIdOfProductToBeUnarchived(record._id); setUnarchiveProductModalVisible(true) } }><RollbackOutlined className="ml-1 mr-1" style={{ fontSize: 21 }} /></Button>
                                    <Button className="btn-nothing action" onClick={ () => { setIdOfProductToBeDeleted(record._id); setDeleteProductModalVisible(true) } }><CustomIcon style={{ margin: '6px 0 0 0' }} iconKey="delete" color="#E45858" /></Button>
                                </>
                            )
                        }
                    </>
                );
            }
        },
    ];

    const [idOfProductToBeArchived, setIdOfProductToBeArchived] = useState(null);
    const [archiveProductModalVisible, setArchiveProductModalVisible] = useState(false);
    const handleArchiveProductModalOk = () => {
        ComandanteAPI.HttpPostRequest('products/' + idOfProductToBeArchived + '/archive', {}, (err, res) => {
            if(err || !res) {
                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.status === 200) {
                getProducts();
                fetchProducts();
                setArchiveProductModalVisible(false);
                
                return;
            }
        });
    };
    const handleArchiveProductModalCancel = () => { setArchiveProductModalVisible(false) };

    const [idOfProductToBeUnarchived, setIdOfProductToBeUnarchived] = useState(null);
    const [unarchiveProductModalVisible, setUnarchiveProductModalVisible] = useState(false);
    const handleUnarchiveProductModalOk = () => {
        ComandanteAPI.HttpPostRequest('products/' + idOfProductToBeUnarchived + '/unarchive', {}, (err, res) => {
            if(err || !res) {
                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.status === 200) {
                getProducts();
                fetchProducts();
                setUnarchiveProductModalVisible(false);
                
                return;
            }
        });
    };
    const handleUnarchiveProductModalCancel = () => { setUnarchiveProductModalVisible(false) };

    const [idOfProductToBeDeleted, setIdOfProductToBeDeleted] = useState(null);
    const [deleteProductModalVisible, setDeleteProductModalVisible] = useState(false);
    const handleDeleteProductModalOk = () => {
        ComandanteAPI.HttpPostRequest('products/' + idOfProductToBeDeleted + '/delete', {}, (err, res) => {
            if(err || !res) {
                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.status === 200) {
                setDeleteProductModalVisible(false);
                getProducts(null);
                
                return;
            }
        });
    };
    const handleDeleteProductModalCancel = () => { setDeleteProductModalVisible(false) };

    return (
        <>
            <Helmet>
                <title>Products | { process.env.REACT_APP_META_TITLE }</title>
            </Helmet>

            <MainLayout>
                <>
                    <div className="products--section-header">
                        <div className="float-right">
                            <Form form={ searchForm } layout="vertical" className="d-xs-none d-sm-none d-inline">
                                <Form.Item className="products--search-products-input d-inline-block" name="search">
                                    <Input 
                                        style={{ zIndex: 999, maxWidth: '280px' }}
                                        prefix={ <SearchOutlined /> }
                                        placeholder="Search Products"
                                        onChange={ (event) => {
                                            event.persist(); // Signal to React not to nullify the event object

                                            debounced_getProducts(event.target.value);
                                        }}
                                    />
                                </Form.Item>
                            </Form>

                            <Button
                                className="btn-purple d-inline ml-4"
                                onClick={ () => { setAddProductModalVisible(true) } }
                            >
                                <CustomIcon iconKey="add" color="#FFFFFF" style={{ margin: '0 4px -1px 0' }} /> Add New Product
                            </Button>
                        </div>
                        
                        <h2>Manage Products</h2>
                    </div>

                    <div className="products--section-body">
                        <Tabs defaultActiveKey="1" onChange={ (activeKey) => { setActiveTab(activeKey) } }>
                            <Tabs.TabPane tab="Active" key="/active">
                                {
                                    (activeProducts) ? (
                                        <Table
                                            rowKey={ (record) => record._id }
                                            className="products-table-C"
                                            columns={ columns }
                                            components={{
                                                body: {
                                                    wrapper: DraggableContainer,
                                                    row: DraggableBodyRow,
                                                }
                                            }}
                                            pagination={ false }
                                            dataSource={ activeProducts } 
                                        />
                                    ) : (productsDataset) ? (
                                        <div className="empty-wrapper">
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ margin: '0 auto 0 auto' }} />
                                        </div>
                                    ) : (
                                        <Skeleton active />
                                    )
                                }
                            </Tabs.TabPane>
                        
                            <Tabs.TabPane tab="Archived" key="/archived">
                                {
                                    (archivedProducts) ? (
                                        <Table
                                            rowKey={ (record) => record._id }
                                            className="products-table-C"
                                            columns={ columns }
                                            components={{
                                                body: {
                                                    wrapper: DraggableContainer,
                                                    row: DraggableBodyRow,
                                                }
                                            }}
                                            pagination={ false }
                                            dataSource={ archivedProducts } 
                                        />
                                    ) : (productsDataset) ? (
                                        <div className="empty-wrapper">
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ margin: '0 auto 0 auto' }} />
                                        </div>
                                    ) : (
                                        <Skeleton active />
                                    )
                                }
                            </Tabs.TabPane>
                        </Tabs>
                    </div>

                    <Modal className="confirmation-modal" title={ null } footer={ null } visible={ archiveProductModalVisible } onCancel={ handleArchiveProductModalCancel } width={ 520 }>
                        <div className="confirmation-modal-header-W">
                            
                        </div>

                        <div className="confirmation-modal-W">
                            <div className="header">
                                Archive Product
                            </div>

                            <div className="body">
                                { 'Are you sure you want to archive this product ?' }

                                <div className="text-right mt-4">
                                    <Button className="btn-white mr-2" onClick={ handleArchiveProductModalCancel }>Cancel</Button>
                                    <Button className="btn-purple" onClick={ handleArchiveProductModalOk }>Archive</Button>
                                </div>
                            </div>
                        </div>
                    </Modal>

                    <Modal className="confirmation-modal" title={ null } footer={ null } visible={ unarchiveProductModalVisible } onCancel={ handleUnarchiveProductModalCancel } width={ 520 }>
                        <div className="confirmation-modal-header-W">
                            
                        </div>

                        <div className="confirmation-modal-W">
                            <div className="header">
                                Unarchive Product
                            </div>

                            <div className="body">
                                { 'Are you sure you want to unarchive this product ?' }

                                <div className="text-right mt-4">
                                    <Button className="btn-white mr-2" onClick={ handleUnarchiveProductModalCancel }>Cancel</Button>
                                    <Button className="btn-purple" onClick={ handleUnarchiveProductModalOk }>Unarchive</Button>
                                </div>
                            </div>
                        </div>
                    </Modal>

                    <Modal className="confirmation-modal" title={ null } footer={ null } visible={ deleteProductModalVisible } onCancel={ handleDeleteProductModalCancel } width={ 520 }>
                        <div className="confirmation-modal-header-W"></div>

                        <div className="confirmation-modal-W deletion-modal">
                            <div className="header">
                                Delete Product
                            </div>

                            <div className="body">
                                Are you sure you want to delete this product ?

                                <div className="text-right mt-4">
                                    <Button className="btn-white mr-2" onClick={ handleDeleteProductModalCancel }>Cancel</Button>
                                    <Button className="btn-red" onClick={ handleDeleteProductModalOk }>Permanently Delete</Button>
                                </div>
                            </div>
                        </div>
                    </Modal>

                    { addProductModalVisible && <AddProductModal setVisible={ setAddProductModalVisible } /> }
                </>
            </MainLayout>
        </>
    )
}

export default Products;
