import React, { useState, useEffect, useCallback } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { useImmer } from "use-immer";
import { useSystemContext } from '../../Context/SystemContext';

import moment from 'moment';
import Helmet from 'react-helmet';

import ComandanteAPI from '../../utils/ComandanteAPI';
import Eligible from '../../utils/Eligible';

import MainLayout from "../../Layouts/Main";

import EditProductModal from './EditProductModal/EditProductModal';
import ManageProductTeamsAndMembersModal from './ManageProductTeamsAndMembersModal/ManageProductTeamsAndMembersModal';

import AddProjectModal from './AddProjectModal/AddProjectModal';

import ProductIcon, { ProductIconsSet } from '../../UIElements/ProductIcon/ProductIcon';
import CustomIcon from '../../UIElements/CustomIcon/CustomIcon';
import ProjectIcon from '../../UIElements/ProjectIcon/ProjectIcon';
import manageIcon from '../../assets/images/icon-manage.png';
import { ColorSet } from '../../UIElements/ColorPicker/ColorPicker';

import { Empty, Table, Avatar, Spin, Button, Row, Col, Modal, Tooltip, message } from 'antd';

import avatarPlaceholder from '../../assets/images/placeholder-avatar.png';

import './Product.css';

import Error from '../Error/Error';

const Product = (props) => {
    const { productId } = useParams();
    const history = useHistory();
    
    const { 
        currentUser, 
        fetchCurrentUser, 
        systemVariables,
        fetchProducts
    } = useSystemContext();

    const stageOptions = (systemVariables && systemVariables.product && systemVariables.product.stageOptions ? systemVariables.product.stageOptions : null);
    const roleOptions = (systemVariables && systemVariables.user && systemVariables.user.roleOptions ? systemVariables.user.roleOptions : null);

    const [productDataset, setProductDataset] = useState(null);

    const [productTeamsDataset, updateProductTeamsDataset] = useImmer(null);
    const [productMembersDataset, updateProductMembersDataset] = useImmer(null);
    const [productMembersIncludingTeamMembersDataset, updateProductMembersIncludingTeamMembersDataset] = useImmer(null);
    const [combinedProductTeamsAndMembersData, updateCombinedProductTeamsAndMembersData] = useImmer(null);

    const [editProductDetailsModalVisible, setEditProductDetailsModalVisible] = useState(false);
    const [archiveProductModalVisible, setArchiveProductModalVisible] = useState(false);

    const [projectsDataset, updateProjectsDataset] = useImmer(null);
    const [addProjectModalVisible, setAddProjectModalVisible] = useState(false);

    const [manageProductTeamsAndMembersModalVisible, setManageProductTeamsAndMembersModalVisible] = useState(false);

    const [projectToBeDeleted, setProjectToBeDeleted] = useState(null);
    const [deleteProjectModalVisible, setDeleteProjectModalVisible] = useState(false);

    const [pageError, setPageError] = useState(null);

    const fetchProduct = useCallback(() => {
        ComandanteAPI.HttpGetRequest('products/' + productId + '/get', (err, res) => {
            if(err) {
                setPageError(<Error status={ (err.status ? err.status : null) } />);
                return;
            }

            if(!res || !res.data) {
                setPageError(<Error status={ 404 } />);
                return;
            }

            setProductDataset((res && res.data) || null);
        });
    }, [productId]);

    useEffect(() => {
        fetchProduct();
    }, [fetchProduct]);

    const fetchProductTeams = useCallback(() => {
        ComandanteAPI.HttpGetRequest('products/' + productId + '/teams?paginated=false', (err, res) => {
            updateProductTeamsDataset(productTeamsDataset => (res && res.data) || []);
        });
    }, [productId, updateProductTeamsDataset]);

    const fetchProductMembers = useCallback(() => {
        ComandanteAPI.HttpGetRequest('products/' + productId + '/members?paginated=false&excludeTeamMembers=true', (err, res) => {
            updateProductMembersDataset(productMembersDataset => (res && res.data) || []);
        });
    }, [productId, updateProductMembersDataset]);

    const fetchProductMembersIncludingTeamMembers = useCallback(() => {
        ComandanteAPI.HttpGetRequest('products/' + productId + '/members?paginated=false&excludeTeamMembers=false', (err, res) => {
            updateProductMembersIncludingTeamMembersDataset(productMembersDataset => (res && res.data) || []);
        });
    }, [productId, updateProductMembersIncludingTeamMembersDataset]);

    useEffect(() => {
        fetchProductTeams();
        fetchProductMembers();
        fetchProductMembersIncludingTeamMembers();
    }, [fetchProductTeams, fetchProductMembers, fetchProductMembersIncludingTeamMembers]);

    useEffect(() => {
        if(productTeamsDataset && productMembersDataset) {
            let xCombinedProductTeamsAndMembersData = {};
            
            if(productTeamsDataset.data && Object.keys(productTeamsDataset.data).length > 0) {
                for(let team of Object.values(productTeamsDataset.data)) {
                    xCombinedProductTeamsAndMembersData[team._id+''] = { ...team, isTeam: true };
                }
            }

            if(productMembersDataset.data && Object.keys(productMembersDataset.data).length > 0) {
                for(let member of Object.values(productMembersDataset.data)) {
                    xCombinedProductTeamsAndMembersData[member._id+''] = { ...member, isTeam: false };
                }
            }
            
            updateCombinedProductTeamsAndMembersData(combinedProductTeamsAndMembersData => xCombinedProductTeamsAndMembersData);
        }
    }, [productTeamsDataset, productMembersDataset, updateCombinedProductTeamsAndMembersData]);

    const fetchProjects = useCallback(() => {
        ComandanteAPI.HttpGetRequest('products/' + productId + '/projects?withExtras=true&paginated=false', (err, res) => {
            updateProjectsDataset(projectsDataset => (res && res.data) || []);
        });
    }, [productId, updateProjectsDataset]);

    useEffect(() => {
        fetchProjects();
    }, [fetchProjects])

    useEffect(() => {
        updateProjectsDataset(projectsDataset => {
            if(projectsDataset && projectsDataset.data) {
                if(productMembersIncludingTeamMembersDataset && productMembersIncludingTeamMembersDataset.data && Object.keys(productMembersIncludingTeamMembersDataset.data).length > 0) {
                    let xProjects = {};

                    for(let project of Object.values(projectsDataset.data)) {
                        let memberNames = [];
                        if(productMembersIncludingTeamMembersDataset && productMembersIncludingTeamMembersDataset.data && Object.keys(productMembersIncludingTeamMembersDataset.data).length > 0) {
                            for(let member of Object.values(productMembersIncludingTeamMembersDataset.data)) {
                                if((project.memberIds && Array.isArray(project.memberIds) && project.memberIds.includes(''+member._id)) || (project.teamIds && Array.isArray(project.teamIds) && member.teamIds && Array.isArray(member.teamIds) && project.teamIds.some(teamId => member.teamIds.includes(teamId)))) {
                                    memberNames.push(member.firstName + ' ' + member.lastName);
                                }
                            }
                        }
    
                        xProjects[''+project._id] = { ...project, memberNames: memberNames }
                    }

                    projectsDataset.data = xProjects;
                }
            }
        });
    }, [productMembersIncludingTeamMembersDataset, updateProjectsDataset]);

    const handleArchiveProductModalOk = () => {
        ComandanteAPI.HttpPostRequest('products/' + productId + '/archive', {}, (err, res) => {
            if(err || !res) {
                message.error('System is currently unavailable. Please try again later.');
                return;
            }

            if(res.status === 200) {
                setArchiveProductModalVisible(false);
                fetchProducts();
                return;
            }
        });
    };
    const handleArchiveProductModalCancel = () => { setArchiveProductModalVisible(false) };

    const handleDeleteProjectModalCancel = () => { setDeleteProjectModalVisible(false) }
    const handleDeleteProjectModalOk = () => {
        if(projectToBeDeleted) {
            ComandanteAPI.HttpPostRequest('products/' + productId + '/projects/' + projectToBeDeleted + '/delete', {}, (err, res) => {
                if(err || !res) {    
                    message.error('System is currently unavailable. Please try again later.');
                    return;
                }

                if(res.data && res.data.result === true) {
                    fetchProjects();
                    setProjectToBeDeleted(null);
                    setDeleteProjectModalVisible(false);
                }
            });
        }
    }

    const onStarProject = (projectId) => {
        if(projectId) {
            ComandanteAPI.HttpPostRequest('products/' + productId + '/projects/' + projectId + '/star', {}, (err, res) => {
                if(err || !res) {    
                    message.error('System is currently unavailable. Please try again later.');
                    return;
                }

                if(res.data && res.data.result === true) {
                    fetchCurrentUser();
                }
            });
        }
    }

    const onUnstarProject = (projectId) => {
        if(projectId) {
            ComandanteAPI.HttpPostRequest('products/' + productId + '/projects/' + projectId + '/unstar', {}, (err, res) => {
                if(err || !res) {    
                    message.error('System is currently unavailable. Please try again later.');
                    return;
                }

                if(res.data && res.data.result === true) {
                    fetchCurrentUser();
                }
            });
        }
    }

    const productMembersColumns = [
        {
            title: '',
            dataIndex: 'avatar',
            key: 'avatar',
            width: 32,
            render: (text, record) => {
                if(record.isTeam) {
                    return <Avatar shape="square" size={ 20 } style={{ backgroundColor: '#c41d7f' }}>{ record.name[0] }</Avatar>
                } else {
                    return <Avatar shape="square" size={ 20 } src={ (record.profilePhoto ? process.env.REACT_APP_COMANDANTE_API_ADDRESS + record.profilePhoto.path.substring(7) : avatarPlaceholder) } alt={ 'Avatar | ' + record.firstName + ' ' + record.lastName } height={ 20 } />
                }
            }
        },
        {
            title: 'Team / User',
            dataIndex: 'fullName',
            key: 'user',
            render: (text, record) => <span>{ (record.isTeam ? record.name : record.firstName + ' ' + record.lastName) }</span>,
        },
        {
            title: 'Since',
            dataIndex: 'createdAt',
            key: 'createdAt',
            className: 'createdAt',
            responsive: ['lg', 'xl'],
            render: (text, record) => <span>{ moment(record.createdAt).format('LL') }</span>,
        },
        {
            title: 'Role',
            dataIndex: 'role',
            key: 'role',
            className: 'role',
            responsive: ['md', 'lg', 'xl'],
            render: (text, record) => {
                if(record.isTeam) {
                    return <span> N/A </span>;
                } else {
                    if(!roleOptions || !roleOptions[record.role]) return <span> Unknown </span>;
                    return <span> { roleOptions[record.role+''] } </span>
                }
            },
        }
    ];

    if(pageError) return pageError;

    return (
        (productDataset && productDataset.product) ? (
            <>
                <Helmet>
                    <title>{ (productDataset.product.name ? productDataset.product.name : 'Product') + ' | Products | ' + process.env.REACT_APP_META_TITLE }</title>
                </Helmet>

                <MainLayout>
                    <>
                        <Row className="details-R" type="flex">
                            <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ 12 } xl={ 10 } className="col-border-right" style={{ height: '100%' }}>
                                <div className="details-header p-5">
                                    {
                                        !(currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Observer', 'Contributor', 'Editor', 'Project Manager'].includes(roleOptions[currentUser.role+''])) && (
                                            <div className="float-right">
                                                <Button className="btn-white" onClick={ () => { setEditProductDetailsModalVisible(true) } }><CustomIcon className="mr-1" iconKey="edit" color="#6246EA" />Edit&nbsp;<span className="d-xs-none d-sm-none"> Details</span></Button>
                                                {
                                                    systemVariables && systemVariables.user && systemVariables.user.authorizedResourcesByAccessLevels && Eligible.isEligibleToPerformOperation(currentUser, productDataset.product._id, null, systemVariables.user.authorizedResourcesByAccessLevels, 'products/:productId/archive') ?
                                                        <Button className="btn-white ml-2" onClick={ () => { setArchiveProductModalVisible(true) } }><CustomIcon className="mr-1" style={{ width: '12px', height: '15px' }} iconKey="archive" color="#6246EA" /> Archive</Button>
                                                    : null
                                                }
                                            </div>       
                                        )
                                    }
                                    
                                    <h2>Product Details</h2>
                                </div>

                                <div className="product-details-C">
                                    <div className="details-row-flex pt-4 pb-4">
                                        <div className="label">Name</div>
                                        <div className="value">{ productDataset.product.name ? productDataset.product.name : 'N/A' }</div>
                                    </div>

                                    <div className="details-row-flex pt-4 pb-4">
                                        <div className="label">Type</div>
                                        <div className="value">{ productDataset.product.type ? productDataset.product.type : 'N/A' }</div>
                                    </div>

                                    <div className="details-row-flex pt-4 pb-4">
                                        <div className="label">Stage</div>
                                        <div className="value">{ productDataset.product.stage && productDataset.product.stage ? (stageOptions && stageOptions[productDataset.product.stage+'']) : 'N/A' }</div>
                                    </div>

                                    <div className="details-row-flex pt-4 pb-4">
                                        <div className="label">Icon</div>
                                        <div className="value">
                                            <ProductIcon iconKey={ (productDataset && productDataset.product && productDataset.product.iconKey) || Object.keys(ProductIconsSet())[0] } color={ (productDataset && productDataset.product && productDataset.product.iconColor) || ColorSet()[4] } />    
                                        </div>
                                    </div>

                                    <div className="details-row-flex pt-4 pb-4">
                                        <div className="label">Description</div>
                                        <div className="value">{ productDataset.product.description ? productDataset.product.description : 'N/A' }</div>
                                    </div>
                                </div>
                            </Col>

                            <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ 12 } xl={ 14 } style={{ height: '100%' }}>
                                <div className="details-header p-5">
                                    <h2>Projects</h2>
                                </div>

                                <div className="projects-C">
                                    {
                                        !(currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Observer', 'Contributor', 'Editor', 'Project Manager'].includes(roleOptions[currentUser.role+''])) && (
                                            <Button onClick={ () => { setAddProjectModalVisible(true) } } className="btn-dashed" block>
                                                <CustomIcon className="mr-1" iconKey="add" color="#6246EA" /> Create new project
                                            </Button>
                                        )
                                    }

                                    {
                                        projectsDataset && projectsDataset.data && Object.values(projectsDataset.data).length > 0 && Object.values(projectsDataset.data).map(project => {
                                            return (
                                                <div className="project hover-pointer mt-4 p-2" key={ project._id } onClick={ () => { history.push('/products/' + productId + '/projects/' + project._id+'') } }>
                                                    <div className="project-col-1">
                                                        <div className="project-col-1-inner">
                                                            <div className="img-W ml-2">
                                                                <ProjectIcon color={ project.iconColor ? project.iconColor : null } />
                                                            </div>
                                                            <div className="info">
                                                                <div className="name">{ project.name }</div>
                                                                <div className="extra">{ 'Created at ' + moment(project.createdAt).format('LL') }</div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div className="project-col-2">
                                                        <span className="d-xs-none d-sm-none d-md-none d-lg-none icon ml-2 mr-2"><CustomIcon className="mr-1" iconKey="tasks" style={{ height: '14px' }} />{ project.numberOfTasks ? project.numberOfTasks : '0' }</span>

                                                        <Tooltip
                                                            className="d-xs-none d-sm-none d-md-none d-lg-none"
                                                            placement="bottomLeft"
                                                            title={
                                                                <>
                                                                    {
                                                                        (project.memberNames && Array.isArray(project.memberNames) && project.memberNames.length > 0 && project.memberNames.map(memberName => <div key={ memberName }>{ memberName }</div>))
                                                                    }
                                                                </>
                                                            }
                                                        >
                                                            <span className="icon ml-2 mr-2"><CustomIcon className="mr-1" iconKey="members" />{ project.memberNames && Array.isArray(project.memberNames) && project.memberNames.length }</span>
                                                        </Tooltip>

                                                        <span className="d-xs-none d-sm-none d-md-none d-lg-none icon separated pt-1 pb-1 ml-2 mr-2">
                                                            <span className="pt-2 pb-1 pl-2 pr-2" style={{ background: '#F8F8FB', border: '1px solid #DFE0E1', borderRadius: '4px' }}>
                                                                {
                                                                    (project && project.isPublic) ? (
                                                                        <CustomIcon iconKey="public" color="#C0C0C3" />
                                                                    ) : (
                                                                        <CustomIcon iconKey="private" color="#C0C0C3" />
                                                                    )
                                                                }
                                                            </span>
                                                        </span>

                                                        <span className="d-xs-none d-sm-none d-md-none d-lg-none icon hover-pointer ml-2 mr-2">
                                                            <Button
                                                                className="btn-nothing d-inline-block pl-0 pr-0"
                                                                onClick={
                                                                    (e) => {
                                                                        e.stopPropagation();

                                                                        if(currentUser && currentUser.starredProjectIds && Array.isArray(currentUser.starredProjectIds) && currentUser.starredProjectIds.includes(project._id+'')) {
                                                                            onUnstarProject(project._id+'');
                                                                        } else {
                                                                            onStarProject(project._id+'');
                                                                        }
                                                                    }
                                                                }
                                                            >
                                                                {
                                                                    currentUser && currentUser.starredProjectIds && Array.isArray(currentUser.starredProjectIds) && currentUser.starredProjectIds.includes(project._id+'') ? (
                                                                        <CustomIcon iconKey="star-filled" style={{ padding: '1px 0 0 0' }} />
                                                                    ) : (
                                                                        <CustomIcon iconKey="star" style={{ padding: '1px 0 0 0' }} />
                                                                    )
                                                                }
                                                            </Button>
                                                        </span>

                                                        {
                                                            !(currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Observer', 'Contributor', 'Editor', 'Project Manager'].includes(roleOptions[currentUser.role+''])) && (
                                                                <span className="icon hover-pointer ml-2 mr-2">
                                                                    <Button
                                                                        className="btn-nothing d-inline-block pl-0 pr-0"
                                                                        onClick={
                                                                            (e) => {
                                                                                e.stopPropagation();

                                                                                setProjectToBeDeleted(project._id+'');
                                                                                setDeleteProjectModalVisible(true);
                                                                            }
                                                                        }
                                                                    >
                                                                        <CustomIcon iconKey="delete" color="#E45858" className="mr-1" style={{ margin: '0 0 -0px 0', height: '16px' }} />
                                                                    </Button>
                                                                </span>
                                                            )
                                                        }

                                                        { /* <span className="icon ml-2 mr-2"><CustomIcon iconKey="options-vertical" /></span> */ }
                                                    </div>
                                                </div>
                                            );
                                        })
                                    }
                                </div>
                            </Col>
                        </Row>

                        <Row type="flex">
                            <Col span={ 24 }>
                                <div className="p-5">
                                    {
                                        !(currentUser && roleOptions && roleOptions[currentUser.role+''] && ['Observer', 'Contributor', 'Editor', 'Project Manager'].includes(roleOptions[currentUser.role+''])) && (
                                            <div className="float-right">
                                                <Button className="btn-white" onClick={ () => { setManageProductTeamsAndMembersModalVisible(true) } }><img className="icon" style={{ margin: '0 4px 2px 0' }} src={ manageIcon } alt="Icon" /><span className="d-xs-none">Manage&nbsp;</span> Members</Button>
                                            </div>   
                                        )
                                    }
                                    
                                    <h2>Product Members</h2>
                                </div>

                                <div className="members-C">
                                    {
                                        (combinedProductTeamsAndMembersData) ? (   
                                            <Table
                                                rowKey="_id"
                                                className="product-members-T"
                                                columns={ productMembersColumns }
                                                dataSource={ combinedProductTeamsAndMembersData ? Object.values(combinedProductTeamsAndMembersData) : [] } pagination={ false }
                                            />
                                        ) : (
                                            <div className="empty-wrapper">
                                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ margin: '0 auto 0 auto' }} />
                                            </div>
                                        )
                                    }
                                </div>
                            </Col>
                        </Row>

                        { editProductDetailsModalVisible && <EditProductModal productId={ productId } setVisible={ setEditProductDetailsModalVisible } /> }
                        { manageProductTeamsAndMembersModalVisible && <ManageProductTeamsAndMembersModal productId={ productId } setVisible={ setManageProductTeamsAndMembersModalVisible } refreshProduct={ () => { fetchProduct() } } refreshProductTeamsAndMembers={ () => { fetchProductTeams(); fetchProductMembers(); } } /> }
                        { addProjectModalVisible && <AddProjectModal setVisible={ setAddProjectModalVisible } /> }

                        <Modal className="confirmation-modal" title={ null } footer={ null } visible={ archiveProductModalVisible } onCancel={ handleArchiveProductModalCancel } width={ 520 }>
                            <div className="confirmation-modal-header-W">
                                
                            </div>

                            <div className="confirmation-modal-W">
                                <div className="header">
                                    Archive Product
                                </div>

                                <div className="body">
                                    { 'Are you sure you want to archive ' + productDataset.product.name + ' ?' }

                                    <div className="text-right mt-4">
                                        <Button className="btn-white mr-2" onClick={ handleArchiveProductModalCancel }>Cancel</Button>
                                        <Button className="btn-purple" onClick={ handleArchiveProductModalOk }>Archive</Button>
                                    </div>
                                </div>
                            </div>
                        </Modal>

                        <Modal className="confirmation-modal" title={ null } footer={ null } visible={ deleteProjectModalVisible } onCancel={ handleDeleteProjectModalCancel } width={ 520 }>
                            <div className="confirmation-modal-header-W">
                                
                            </div>

                            <div className="confirmation-modal-W">
                                <div className="header">
                                    Delete Project
                                </div>

                                <div className="body">
                                    { 'Are you sure you want to delete this project ?' }

                                    <div className="text-right mt-4">
                                        <Button className="btn-white mr-2" onClick={ handleDeleteProjectModalCancel }>Cancel</Button>
                                        <Button className="btn-red" onClick={ handleDeleteProjectModalOk }>Delete</Button>
                                    </div>
                                </div>
                            </div>
                        </Modal>
                    </>
                </MainLayout>
            </>
        ) : (
            <div className="aligner-xy" style={{ height: '100vh' }}><Spin /></div>
        )
    )
}

export default Product;