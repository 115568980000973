import React, { useState, useRef } from 'react';

import ComandanteAPI from '../../../utils/ComandanteAPI';

import { Modal, Form, Input, Button } from 'antd';

import './ChangeUserPasswordModal.css';

const ChangeUserPasswordModal = ({ setVisible, userId, ...restProps }) => {
    const [changeUserPasswordForm] = Form.useForm();
    const [changeUserPasswordFormErrors, setChangeUserPasswordFormErrors] = useState([]);

    const refPasswordRulesLowercaseCharacter = useRef(null);
    const refPasswordRulesUppercaseCharacter = useRef(null);
    const refPasswordRulesNumber = useRef(null);
    const refPasswordRules8CharactersMinimum = useRef(null);

    const handleChangeUserPasswordModalCancel = () => {
        setVisible(false);
    };

    const onChangeUserPasswordFormPasswordChange = (event) => {
        let password = '';
        if(event && event.target && event.target.value) password = event.target.value;

        if(refPasswordRulesLowercaseCharacter.current) {
            if(/[a-z]/.test(password)) {
                refPasswordRulesLowercaseCharacter.current.className = 'rule success';
            } else {
                refPasswordRulesLowercaseCharacter.current.className = 'rule danger';
            }
        }

        if(refPasswordRulesUppercaseCharacter.current) {
            if(/[A-Z]/.test(password)) {
                refPasswordRulesUppercaseCharacter.current.className = 'rule success';
            } else {
                refPasswordRulesUppercaseCharacter.current.className = 'rule danger';
            }
        }

        if(refPasswordRulesNumber.current) {
            if(/\d/.test(password)) {
                refPasswordRulesNumber.current.className = 'rule success';
            } else {
                refPasswordRulesNumber.current.className = 'rule danger';
            }
        }

        if(refPasswordRules8CharactersMinimum.current) {
            if(password.length >= 8) {
                refPasswordRules8CharactersMinimum.current.className = 'rule success';
            } else {
                refPasswordRules8CharactersMinimum.current.className = 'rule danger';
            }
        }
    }

    const handleChangeUserPasswordFormSubmit = (values) => {
        ComandanteAPI.HttpPostRequest('members/' + userId + '/change-password', { ...values }, (err, res) => {
            if(err && err.data && err.data.errors) {
                let formErrors = {};
                for(let formError of err.data.errors) {
                    if(formError.param && formError.msg) formErrors[formError.param] = formError.msg;
                }

                if(formErrors && Object.keys(formErrors).length > 0) {
                    setChangeUserPasswordFormErrors(formErrors);
                    return;
                }
            }

            if(res && res.data && res.data.result === true) {
                setVisible(false);
                changeUserPasswordForm.resetFields();
            }
        });
    }

    return (
        <Modal title={ null } footer={ null } visible={ true } onCancel={ handleChangeUserPasswordModalCancel } width={ 540 }>
            <div key={ 'changeUserPasswordModal' } className={ 'changeUserPasswordModal' }>
                <div className="changeUserPasswordModal-header">
                    <h2> Change User Password </h2>
                </div>

                <div className="changeUserPasswordModal-body">
                    <Form form={ changeUserPasswordForm } name="changeUserPasswordForm" onFinish={ handleChangeUserPasswordFormSubmit } layout="vertical" hideRequiredMark={ true }>
                        <Form.Item
                            name="password"
                            label="New Password"
                            onChange={ onChangeUserPasswordFormPasswordChange }
                            validateStatus={ changeUserPasswordFormErrors && changeUserPasswordFormErrors.password ? "error" : null }
                            help={ changeUserPasswordFormErrors && changeUserPasswordFormErrors.password ? changeUserPasswordFormErrors.password : null }
                        >
                            <Input.Password />
                        </Form.Item>
                        
                        <div className="password-rules">
                            <div className="rule" ref={ refPasswordRulesLowercaseCharacter }><div className="box"></div> Lowercase character</div>
                            <div className="rule" ref={ refPasswordRulesUppercaseCharacter }><div className="box"></div> Uppercase character</div>
                            <div className="rule" ref={ refPasswordRulesNumber }><div className="box"></div> Number</div>
                            <div className="rule" ref={ refPasswordRules8CharactersMinimum }><div className="box"></div> 8 characters minimum</div>
                        </div>
                    </Form>
                </div>
                
                <div className="changeUserPasswordModal-footer">
                    <Button className="btn-white" onClick={ () => { handleChangeUserPasswordModalCancel() } }>Cancel</Button>
                    <Button className="btn-purple float-right" onClick={ () => { changeUserPasswordForm.submit(); } }>Save</Button>
                </div>
            </div>
        </Modal>
    )
}

export default ChangeUserPasswordModal;
