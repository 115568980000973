import React, { useState, useRef, useCallback, useEffect } from 'react';

import { useImmer } from "use-immer";

import debounce from 'lodash.debounce';

import ComandanteAPI from '../ComandanteAPI';

import { EditorState, ContentState, convertFromRaw, RichUtils, getDefaultKeyBinding } from 'draft-js';

import Editor from '@draft-js-plugins/editor';
import createMentionPlugin from '@draft-js-plugins/mention';
import createLinkifyPlugin from '@draft-js-plugins/linkify';

import CustomIcon from '../../UIElements/CustomIcon/CustomIcon';

import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/mention/lib/plugin.css';

import './ComandanteEditor.css';
import './DraftEditor.css';

import avatarPlaceholder from './avatar.png';

const Suggestion = ({ mention, theme, searchValue, isFocused, ...parentProps }) => {
    return (
        <div key={ mention._id } { ...parentProps }>
            <div className={ theme?.mentionSuggestionsEntryContainer + ' suggestion-W' }>
                <div className={ theme?.mentionSuggestionsEntryContainerLeft + ' suggestions-C1 float-left' }>
                    <img
                        src={ avatarPlaceholder }
                        className={ theme?.mentionSuggestionsEntryAvatar + ' avatar' }
                        alt="Avatar | Comandante"
                        role="presentation"
                    />
                </div>

                <div className={ theme?.mentionSuggestionsEntryContainerRight + ' suggestions-C2' }>
                    <div className="name">
                        { mention.firstName + ' ' + mention.lastName }
                    </div>

                    <div className="username">
                        { mention.username }
                    </div>
                </div>
            </div>
        </div>
    );
}

const ComandanteEditor = ({ productId, projectId, taskId, ...props }) => {
    const ref = useRef(null);

    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const [mentions_isOpen, setMentions_isOpen] = useState(false);
    const [mentions_suggestions, updateMentions_suggestions] = useImmer(null);

    useEffect(() => {
        if(props.setEditorState) {
            props.setEditorState(editorState);
        }
    }, [editorState]);

    /*
    useEffect(() => {
        if(ref && props.setRef && props.referenceKey) {
            props.setRef(props.referenceKey, ref);
        }
    }, [ref]);
    */

    useEffect(() => {
        if(props.initialValue) {
            setTimeout(() => {
                try {
                    let initialValueAsJSONObject = (typeof(props.initialValue) === 'string' ? JSON.parse(props.initialValue) : props.initialValue);
                    const nContentState = convertFromRaw(initialValueAsJSONObject);
                    const nEditorState = EditorState.push(editorState, nContentState);
                    setEditorState(nEditorState);
                } catch(err) {
                    console.error(err);
                }
            }, 1);
        }
    }, [props.initialValue]);

    useEffect(() => {
        if(props.clearEditor) {
            clearEditor();
        }
    }, [props.clearEditor]);

    const clearEditor = () => {
        setEditorState(EditorState.push(editorState, ContentState.createFromText('')));
    }

    const fetchMembers = useCallback((searchQuery) => {
        if(productId && projectId) {
            ComandanteAPI.HttpGetRequest('products/' + productId + '/projects/' + projectId + '/members?paginated=false' + (searchQuery ? '&search=' + searchQuery : ''), (err, res) => {
                let cSuggestions = {};

                let cSuggestionsDataset = (res && res.data) || null;
                if(cSuggestionsDataset && cSuggestionsDataset.result) {
                    if(cSuggestionsDataset.data) {
                        for(let cSuggestionData of Object.values(cSuggestionsDataset.data)) {
                            cSuggestions[''+cSuggestionData._id] = { 
                                _id: cSuggestionData._id, 
                                name: '@'+cSuggestionData.username, 
                                username: cSuggestionData.username, 
                                firstName: cSuggestionData.firstName, 
                                lastName: cSuggestionData.lastName, 
                                jobTitle: cSuggestionData.jobTitle
                            };
                        }
                    }
                }

                updateMentions_suggestions(mentions_suggestions => cSuggestions || null);
            });
        }
    }, [productId, projectId, updateMentions_suggestions])

    const debounced_fetchMembers = debounce(value => {
        fetchMembers(value);
    }, 300);

    const focus = () => {
        if(ref && ref.current) ref.current.focus();
    };

    const _handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            setEditorState(newState);

            return true;
        }

        return false;
    }

    const _mapKeyToEditorCommand = (e) => {
        if (e.keyCode === 9 /* TAB */) {
            const newEditorState = RichUtils.onTab(
                e,
                editorState,
                4, /* maxDepth */
            );
        
            if (newEditorState !== editorState) {
                setEditorState(newEditorState);
            }
        
            return;
        }

        return getDefaultKeyBinding(e);
    }

    const _toggleBlockType = (blockType) => {
        setEditorState(
            RichUtils.toggleBlockType(
                editorState,
                blockType
            )
        );
    }

    const _toggleInlineStyle = (inlineStyle) => {
        setEditorState(
            RichUtils.toggleInlineStyle(
                editorState,
                inlineStyle
            )
        );
    }

    const linkifyPlugin = createLinkifyPlugin({ target: '_blank' });

    const [{ plugins, MentionSuggestions }] = useState(() => {
        const mentionPlugin = createMentionPlugin({
            entityMutability: 'IMMUTABLE',
            supportWhitespace: false,
            popperOptions: {
                placement: 'left'
            },
            mentionComponent(mentionProps) {
                return (
                    <span className={ mentionProps.className + ' mentioned-user' }>
                        {
                            (mentionProps && mentionProps.mention) ? (
                                <span data-offset-key={ mentionProps.children[0].key }>
                                    { /* <span className="mentioned-user-id" style={{ display: 'none' }}>{ mentionProps.mention._id }</span> */ }
                                    <span id={ mentionProps.mention._id } data-text="true" className="mentioned-user-username">
										{ '@' + mentionProps.mention.username }
									</span>
                                </span>
                            )  : (
                                '@Member'
                            )
                        }
                    </span>
                );
            },
        });
        // eslint-disable-next-line no-shadow
        const { MentionSuggestions } = mentionPlugin;
        // eslint-disable-next-line no-shadow
        const plugins = [mentionPlugin];
        return { plugins, MentionSuggestions };
    });

    const mentions_onOpenChange = useCallback((_open) => {
        setMentions_isOpen(_open);
    }, []);

    const mentions_onSearchChange = useCallback(({ value }) => {
        debounced_fetchMembers(value);
    }, [debounced_fetchMembers]);

    // If the user changes block type before entering any text, we can either style the placeholder or hide it. Let's just hide it now.
    let className = 'RichEditor-editor';
    var contentState = editorState.getCurrentContent();
    if (!contentState.hasText()) {
        if (contentState.getBlockMap().first().getType() !== 'unstyled') {
            className += ' RichEditor-hidePlaceholder';
        }
    }

    return (
        <div key={ props.editorKey + '--editor-wrapper' } className={ 'RichEditor-root' + (props.className ? ' ' + props.className : '') + (props.readOnly ? ' read-only' : '') + (props.disabled ? ' ant-input-disabled' : '') }>
            <div className={ className } onClick={ focus }>
                <Editor
                    key={ props.editorKey + '--editor' }
                    editorKey={ props.editorKey ? props.editorKey : 'editorKey' }
                    blockStyleFn={ getBlockStyle }
                    keyBindingFn={ _mapKeyToEditorCommand }
                    customStyleMap={ styleMap }
                    editorState={ editorState }
                    handleKeyCommand={ _handleKeyCommand }
                    onChange={ setEditorState }
                    placeholder={ props.placeholder ? props.placeholder : '' }
                    spellCheck={ true }
                    ref={ ref }
                    plugins={ [...plugins, linkifyPlugin] }
                    onBlur={ props.onBlur }
                    readOnly={ props.readOnly || props.disabled ? true : false }
                />
                
                <MentionSuggestions
                    key={ props.editorKey + '--mentionSuggestions' }
                    mentionSuggestions="mentionSuggestions"
                    open={ mentions_isOpen }
                    onOpenChange={ mentions_onOpenChange }
                    suggestions={ mentions_suggestions ? Object.values(mentions_suggestions) : [] }
                    onSearchChange={ mentions_onSearchChange }
                    entryComponent={ Suggestion }
                    onAddMention={ (selected) => {
                        console.log(selected);
                    }}
                />
            </div>

            <div className={ (props.readOnly || props.disabled ? 'd-none' : 'd-block') + ' mt-2 pl-4 pr-4' }>
                <InlineStyleControls
                    editorState={ editorState }
                    onToggle={ _toggleInlineStyle }
                />
                <BlockStyleControls
                    editorState={ editorState }
                    onToggle={ _toggleBlockType }
                />
                <ColorControls
                    editorState={ editorState }
                    onToggle={ _toggleInlineStyle }
                />
            </div>
        </div>
    );
}

// Custom overrides for "code" style.
const styleMap = {
    CODE: {
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        fontFamily: '"Inter", sans-serif',
        fontSize: 16,
        padding: 2,
    },
    black: { color: '#595959' },
    red: { color: '#E45858' },
    yellow: { color: '#E4C558' },
    blue: { color: '#657ADD' },
    green: { color: '#00910E' }
};

const getBlockStyle = (block) => {
    switch (block.getType()) {
        case 'default': return 'RichEditor-default';
        case 'H1': return 'RichEditor-H1';
        case 'H2': return 'RichEditor-H2';
        case 'H3': return 'RichEditor-H3';
        case 'red': return 'RichEditor-red';
        case 'yellow': return 'RichEditor-yellow';
        case 'blue': return 'RichEditor-blue';
        case 'green': return 'RichEditor-green';
        case 'blockquote': return 'RichEditor-blockquote';
        default: return null;
    }
}

const StyleButton = (props) => {
    const onToggle = (e) => {
        e.preventDefault();
        props.onToggle(props.style);
    };

    let className = 'RichEditor-styleButton' + (['black', 'red', 'yellow', 'blue', 'green'].includes(props.style) ? ' color-picker' : '');  
    if(props.active) {
        className += ' RichEditor-activeButton';
    }

    return (
        <span className={ className } onMouseDown={ onToggle }>
            { props.label }
        </span>
    );
}

const BLOCK_TYPES = [
    { key: 'Unordered List', label: <CustomIcon className="mr-1" iconKey="unordered-list" color="#C0C0C3" style={{ marginBottom: '-2px' }} />, style: 'unordered-list-item' },
    { key: 'Ordered List', label: <CustomIcon className="mr-1" iconKey="ordered-list" color="#C0C0C3" style={{ marginBottom: '-2px' }} />, style: 'ordered-list-item' },
    { key: 'default', label: <b>T</b>, style: 'default' },
    { key: 'H1', label: <b>H1</b>, style: 'H1' },
    { key: 'H2', label: <b>H2</b>, style: 'H2' },
    { key: 'H3', label: <b>H3</b>, style: 'H3' },
    // { key: 'red', label: <span style={{ fontSize: '24px', color: '#E45858' }}>&#8226;</span>, style: 'red' },
    // { key: 'yellow', label: <span style={{ fontSize: '24px', color: '#E4C558' }}>&#8226;</span>, style: 'yellow' },
    // { key: 'blue', label: <span style={{ fontSize: '24px', color: '#657ADD' }}>&#8226;</span>, style: 'yellow' },
    // { key: 'green', label: <span style={{ fontSize: '24px', color: '#00910E' }}>&#8226;</span>, style: 'green' },
];

const BlockStyleControls = (props) => {
    const { editorState } = props;
    const selection = editorState.getSelection();
    const blockType = editorState.getCurrentContent().getBlockForKey(selection.getStartKey()).getType();

    return (
        <div className="RichEditor-controls d-inline-block d-xs-none">
            { BLOCK_TYPES.map((type) =>
                <StyleButton
                    key={ type.key }
                    active={ type.style === blockType }
                    label={ type.label }
                    onToggle={ props.onToggle }
                    style={ type.style }
                />
            )}
        </div>
    );
};

var INLINE_STYLES = [
    { key: 'Bold', label: <CustomIcon className="mr-1" iconKey="bold" color="#C0C0C3" />, style: 'BOLD' },
    { key: 'Italic', label: <CustomIcon className="mr-1" iconKey="italic" color="#C0C0C3" />, style: 'ITALIC' },
    { key: 'Underlined', label: <CustomIcon className="mr-1" iconKey="underlined" color="#C0C0C3" style={{ marginBottom: '-4px' }} />, style: 'UNDERLINE' },
    { key: 'Monospace', label: <CustomIcon className="mr-1" iconKey="code-block" color="#C0C0C3" style={{ marginBottom: '-3px' }} />, style: 'CODE' },
];

const InlineStyleControls = (props) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();

    return (
        <div className="RichEditor-controls d-inline-block d-xs-none">
            { INLINE_STYLES.map((type) =>
                <StyleButton
                    key={ type.key }
                    active={ currentStyle.has(type.style) }
                    label={ type.label }
                    onToggle={ props.onToggle }
                    style={ type.style }
                />
            )}
        </div>
    );
};

var COLOR_STYLES = [
    { key: 'black', label: <div style={{ fontSize: '28px', color: '#595959' }}>&#8226;</div>, style: 'black' },
    { key: 'red', label: <div style={{ fontSize: '28px', color: '#E45858' }}>&#8226;</div>, style: 'red' },
    { key: 'yellow', label: <div style={{ fontSize: '28px', color: '#E4C558' }}>&#8226;</div>, style: 'yellow' },
    { key: 'blue', label: <div style={{ fontSize: '28px', color: '#657ADD' }}>&#8226;</div>, style: 'blue' },
    { key: 'green', label: <div style={{ fontSize: '28px', color: '#00910E' }}>&#8226;</div>, style: 'green' },
];

const ColorControls = (props) => {
    const currentStyle = props.editorState.getCurrentInlineStyle();

    return (
        <div className="RichEditor-controls d-inline-block d-xs-none">
            { COLOR_STYLES.map((type) =>
                <StyleButton
                    key={ type.key }
                    active={ currentStyle.has(type.style) }
                    label={ type.label }
                    onToggle={ props.onToggle }
                    style={ type.style }
                />
            )}
        </div>
    );
};

export default ComandanteEditor;