class Eligible {
    static isEligibleToPerformOperation = (user, productId, projectId, resources, resource) => {
        if(user.role === 99) return true;

        if(!productId) return false;
        if(!Eligible.hasAccessToProduct(user, productId)) return false;
        if(projectId && !Eligible.hasAccessToProject(user, productId, projectId)) return false;
        let accessLevel = (!projectId ? Eligible.getProductAccessLevel(user, productId) : Eligible.getProjectAccessLevel(user, productId, projectId));

        if(accessLevel && resources[accessLevel] && Array.isArray(resources[accessLevel]) && resources[accessLevel].length > 0) {
            if(resources[accessLevel].includes(resource+'')) return true;
        }

        return false;
    }

    static hasAccessToProduct = (user, productId) => {
        let result = false;
        if(user.productsInvolved && Array.isArray(user.productsInvolved) && user.productsInvolved.length > 0) {
            user.productsInvolved.forEach(productInvolved => {
                if(productInvolved.productId && ''+productInvolved.productId === ''+productId) {
                    result = true;
                }
            })
        }

        return result;
    }

    static hasAccessToProject = (user, productId, projectId) => {
        if(!Eligible.hasAccessToProduct(user, productId)) return false;

        let result = false;
        if(user.projectsInvolved && Array.isArray(user.projectsInvolved) && user.projectsInvolved.length > 0) {
            user.projectsInvolved.forEach(projectInvolved => {
                if(projectInvolved.projectId && ''+projectInvolved.projectId === ''+projectId) {
                    result = true;
                }
            })
        }

        return result;
    }

    static getProductAccessLevel = (user, productId) => {
        if(!Eligible.hasAccessToProduct(user, productId)) return null;

        let accessLevel = null;
        if(user.productsInvolved && Array.isArray(user.productsInvolved) && user.productsInvolved.length > 0) {
            user.productsInvolved.forEach(productInvolved => {
                if(''+productInvolved.productId === ''+productId) {
                    accessLevel = productInvolved.accessLevel;
                }
            })
        }

        return accessLevel;
    }

    static getProjectAccessLevel = (user, productId, projectId) => {
        if(!Eligible.hasAccessToProject(user, productId, projectId)) return null;

        let accessLevel = null;
        if(user.projectsInvolved && Array.isArray(user.projectsInvolved) && user.projectsInvolved.length > 0) {
            user.projectsInvolved.forEach((projectInvolved) => {
                if(''+projectInvolved.projectId === ''+projectId) {
                    accessLevel = projectInvolved.accessLevel;
                }
            })
        }

        return accessLevel;
    }
}

export default Eligible;