import React from 'react';

import { useSystemContext } from '../../../Context/SystemContext';
import { useHistory } from 'react-router-dom';

import moment from 'moment';

import { Draggable } from "react-beautiful-dnd";

import CustomDateFormat from '../../../utils/CustomDateFormat';
import CustomIcon from '../../../UIElements/CustomIcon/CustomIcon';

import './Task.css';
import avatarPlaceholder from '../../../assets/images/placeholder-avatar.png';

const Task = ({ index, view, _id, isDragDisabled, organizationId, productId, projectId, title, assignee, comments, dependencies, checklist, attachments, priority, stage, dueDate, status, setTaskIdForViewTaskDrawer, ...restProps }) => {
    const { 
        systemVariables
    } = useSystemContext();

    const history = useHistory();

    const priorityLevelOptions = (systemVariables && systemVariables.task && systemVariables.task.priorityLevelOptions ? systemVariables.task.priorityLevelOptions : null);

    const getLastImageFile = attachments => {
        let imageFiles = [];
        if(attachments && Array.isArray(attachments) && attachments.length > 0) {
            attachments.forEach(attachment => {
                if(attachment.type.includes('image')) imageFiles.push(attachment);
            })
        }

        if(imageFiles && Array.isArray(imageFiles) && imageFiles.length > 0) {
            return imageFiles.slice(-1)[0];
        }

        return null;
    }

    let boardView = (
        <div
            className={ 'task-W board ' + (isDragDisabled && ' disabled') }
            onClick={ 
                () => {
                    setTaskIdForViewTaskDrawer(_id);
                    // history.push('/products/' + productId + '/projects/' + projectId + '/tasks/' + _id);
                } 
            }
        >
            {
                (attachments && Array.isArray(attachments) && attachments.length > 0 && getLastImageFile(attachments)) ? (
                    <img 
                        alt={ title }
                        src={ process.env.REACT_APP_COMANDANTE_API_ADDRESS + (getLastImageFile(attachments).filePath).substring(7) } 
                    />
                ) : (
                    null
                ) 
            }

            <div className="title">
                { title }
            </div>

            <div className="info-C">
                <div className="R1">
                    <div className={ 'priority d-inline-block float-right' +  (priority && priorityLevelOptions && priorityLevelOptions[priority+''] ? ' ' + priorityLevelOptions[priority+''].toLowerCase() : '')}>
                        {
                            (priority+'') ? (
                                (priorityLevelOptions && priorityLevelOptions[parseInt(priority)]) ? (
                                    priorityLevelOptions[priority]
                                ) : (
                                    priority
                                )
                            ) : (
                                'None'
                            )
                        }
                    </div>

                    <div className="vertical-y">
                        <div className={ 'assignee' + (assignee ? '' : ' empty') }>
                            {
                                (assignee) ? (
                                    <img className="mr-1 mb-0" src={ avatarPlaceholder } alt="Avatar" />
                                ) : (
                                    <CustomIcon iconKey="profile" color="#C0C0C3" style={{ margin: '0 4px -2px 0' }} /> 
                                )
                            }
                            { assignee ? assignee : 'No assignee'}
                        </div>
                    </div>
                </div>

                <div className="R2 clearfix mt-3">
                    {
                        dueDate && (
                            <div className="float-right">
                                <CustomDateFormat date={ dueDate } displayCalendarIcon />
                            </div>
                        )   
                    }

                    {
                        comments && comments.length > 0 &&  (
                            <>
                                <CustomIcon iconKey="comments" color="#6246EA" /> { comments.length }
                            </>
                        ) 
                    }

                    {
                        dependencies && dependencies.length > 0 && (
                            <>
                                <CustomIcon iconKey="dependencies" color="#6246EA" /> { dependencies.length }
                            </>
                        ) 
                    }

                    {
                        checklist && checklist.length > 0 && (
                            <>
                                <CustomIcon iconKey="checklist" color="#6246EA" /> { checklist.length }
                            </>
                        ) 
                    }

                    {
                        attachments && attachments.length > 0 && (
                            <>
                                <CustomIcon iconKey="attachment-horizontal" color="#6246EA" /> { attachments.length }
                            </>
                        ) 
                    }
                </div>
            </div>
        </div>
    );

    let listView = (
        <div
            className={ 'task-W list ' + (isDragDisabled && ' disabled') }
            onClick={   
                () => {
                    setTaskIdForViewTaskDrawer(_id);
                    // history.push('/products/' + productId + '/projects/' + projectId + '/tasks/' + _id);
                } 
            }
        >
            <div className="task">
                <div className="title">{ title ? title : 'N/A' }</div>

                <div className="info-C clearfix">
                    <div className="vertical-y d-inline-block">
                        <div className={ 'assignee mb-2' + (assignee ? '' : ' empty') }>
                            {
                                (assignee) ? (
                                    <img className="mr-1 mb-0" src={ avatarPlaceholder } alt="Avatar" />
                                ) : (
                                    <CustomIcon iconKey="profile" color="#C0C0C3" style={{ margin: '0 4px -2px 0' }} /> 
                                )
                            }

                            { assignee ? assignee : 'No assignee' }
                        </div>
                    </div>
                
                    <div className={ 'priority d-inline-block ml-4 mr-4 mb-2' +  (priority && priorityLevelOptions && priorityLevelOptions[priority] ? ' ' + priorityLevelOptions[priority].toLowerCase() : '')}>
                        {
                            (priority) ? (
                                (priorityLevelOptions && priorityLevelOptions[priority]) ? (
                                    priorityLevelOptions[priority]
                                ) : (
                                    priority
                                )
                            ) : (
                                'None'
                            )
                        }
                    </div>

                    {
                        dueDate && (
                            <div className="d-inline-block mb-2" style={{ fontSize: '12px', color: '#E45858' }}>
                                <CustomIcon iconKey="date-time" color='#E45858' style={{ margin: '0 2px -2px 0' }} /> { 'Due by ' + moment(dueDate).fromNow(true) }
                            </div>
                        )   
                    }
                </div>
            </div>
        </div>
    );

    return (
        <Draggable
            key={ _id }
            draggableId={ _id }
            index={ index }
            isDragDisabled={ isDragDisabled }
        >
            {
                (provided, snapshot) => {
                    return (
                        <div
                            ref={ provided.innerRef }
                            { ...provided.draggableProps }
                            { ...provided.dragHandleProps }
                        >
                            {
                                view && (
                                    (view === 'board') ? (
                                        boardView
                                    ) : (view === 'list') ? (
                                        listView
                                    ) : (
                                        null
                                    )
                                )
                            }
                        </div>
                    );
                }
            }
        </Draggable>
    );
}

export default Task;