import React, { useCallback } from 'react';
import { useImmer } from "use-immer";
import { useSystemContext } from '../../../Context/SystemContext';

import { useHistory } from 'react-router-dom';

import ComandanteAPI from '../../../utils/ComandanteAPI';

import debounce from 'lodash.debounce';

import CustomDateFormat from '../../../utils/CustomDateFormat';
import CustomIcon from '../../../UIElements/CustomIcon/CustomIcon';

import { Row, Col, Drawer, Input } from 'antd';

import './SearchTaskDrawer.css';

import searchTasksImg from './assets/images/search-tasks.png';
import avatarPlaceholder from '../../../assets/images/placeholder-avatar.png';

const SearchTaskDrawer = ({ onClose }) => {
    const history = useHistory();

    const {
        systemVariables,
        membersDataset
    } = useSystemContext();

    const priorityLevelOptions = (systemVariables && systemVariables.task && systemVariables.task.priorityLevelOptions ? systemVariables.task.priorityLevelOptions : null);

    const [tasksDataset, updateTasksDataset] = useImmer(null);

    const searchTasks = useCallback(async (searchQuery) => {
        if(!searchQuery) {
            updateTasksDataset(tasksDataset => null);
            return;
        }

        ComandanteAPI.HttpGetRequest('search/tasks?keyword='+searchQuery, (err, res) => {
            updateTasksDataset(tasksDataset => (res && res.data) || null);
        });
    }, [updateTasksDataset]);

    const debounced_searchTasks = debounce(value => {
        searchTasks(value);
    }, 300);

    const getLastImageFile = attachments => {
        let imageFiles = [];
        if(attachments && Array.isArray(attachments) && attachments.length > 0) {
            attachments.forEach(attachment => {
                if(attachment.type.includes('image')) imageFiles.push(attachment);
            })
        }

        if(imageFiles && Array.isArray(imageFiles) && imageFiles.length > 0) {
            return imageFiles.slice(-1)[0];
        }

        return null;
    }

    return (
        <Drawer
            width={ 360 }
            visible={ true }
            mask={ false }
            maskClosable={ false }
            closable={ false }
        >
            <div className="search-task-drawer-H">
                <div className="float-right">
                    <span className="hover-pointer" onClick={ () => { onClose() } }>
                        <CustomIcon iconKey="close" style={{ height: '16px', margin: '0 0 -1px 12px' }} />
                    </span>
                </div>

                <div style={{ fontSize: '18px' }}>Search tasks</div>
            </div>

            <div className="search-task-drawer-B">
                <Row gutter={ 16 }>
                    <Col span={ 24 }>
                        <Input 
                            className="input-box mb-5"
                            prefix={ <CustomIcon iconKey="search" color="#6246EA" style={{ height: '13px', margin: '0 0 -1px 0' }} /> }
                            placeholder="Search..."
                            onChange={ (event) => {
                                event.persist(); // Signal to React not to nullify the event object

                                debounced_searchTasks(event.target.value);
                            }}
                        />
                    </Col>
                </Row>

                {
                    (tasksDataset && tasksDataset.data && Object.keys(tasksDataset).length > 0) ? (
                        Object.values(tasksDataset.data).map(task => {
                            return (
                                <div
                                    key={ 'task_' + task._id }
                                    className="task-W board"
                                    onClick={ () => { history.push('/products/' + task.productId + '/projects/' + task.projectId + '/tasks/' + task._id) } }
                                >
                                    {
                                        (task.attachments && Array.isArray(task.attachments) && task.attachments.length > 0 && getLastImageFile(task.attachments)) && (
                                            <img 
                                                alt={ task.title }
                                                src={ process.env.REACT_APP_COMANDANTE_API_ADDRESS + (getLastImageFile(task.attachments).filePath).substring(7) } 
                                            />
                                        )
                                    }

                                    <div className="title">{ task.title }</div>

                                    <div className="info-C">
                                        <div className="R1">
                                            <div className={ 'priority d-inline-block float-right' +  (task.priority && priorityLevelOptions && priorityLevelOptions[task.priority] ? ' ' + priorityLevelOptions[task.priority].toLowerCase() : '')}>
                                                {
                                                    (task.priority) ? (
                                                        (priorityLevelOptions && priorityLevelOptions[task.priority]) ? (
                                                            priorityLevelOptions[task.priority]
                                                        ) : (
                                                            task.priority
                                                        )
                                                    ) : (
                                                        'None'
                                                    )
                                                }
                                            </div>

                                            <div className="vertical-y">
                                                <div className={ 'assignee' + (task.assignedTo ? '' : ' empty') }>
                                                    {
                                                        (task.assignedTo) ? (
                                                            <img className="mr-1 mb-0" src={ avatarPlaceholder } alt="Avatar" />
                                                        ) : (
                                                            <CustomIcon iconKey="profile" color="#C0C0C3" style={{ margin: '0 4px -2px 0' }} /> 
                                                        )
                                                    }
                                                    { task.assignedTo && membersDataset && membersDataset.data && membersDataset.data[task.assignedTo+''] ? membersDataset.data[task.assignedTo+''].firstName + ' ' + membersDataset.data[task.assignedTo+''].lastName : 'No assignee'}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="R2 clearfix mt-3">
                                            {
                                                task.dueDate && (
                                                    <div className="float-right">
                                                        <CustomDateFormat date={ task.dueDate } displayCalendarIcon />
                                                    </div>
                                                )   
                                            }

                                            {
                                                task.comments && task.comments.length > 0 &&  (
                                                    <span className="mr-2">
                                                        <CustomIcon iconKey="comments" color="#6246EA" /> { task.comments.length }
                                                    </span>
                                                ) 
                                            }

                                            {
                                                task.dependantTaskIds && task.dependantTaskIds.length > 0 && (
                                                    <span className="mr-2">
                                                        <CustomIcon iconKey="dependencies" color="#6246EA" style={{ margin: '0 0 2px 0' }} /> { task.dependantTaskIds.length }
                                                    </span>
                                                ) 
                                            }

                                            {
                                                task.checklist && task.checklist.length > 0 && (
                                                    <span className="mr-2">
                                                        <CustomIcon iconKey="checklist" color="#6246EA" style={{ margin: '0 0 -1px 0', height: '12px' }} /> { task.checklist.length }
                                                    </span>
                                                ) 
                                            }

                                            {
                                                task.attachments && task.attachments.length > 0 && (
                                                    <span className="mr-2">
                                                        <CustomIcon iconKey="attachment-horizontal" color="#6246EA" /> { task.attachments.length }
                                                    </span>
                                                ) 
                                            }
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    ) : (
                        <div className="text-center m-5">
                            <img className="w-75" src={ searchTasksImg } alt="Search" />

                            <div className="helper-1 text-center">Start by typing in the search field</div>
                            <div className="helper-2 text-center">You can search &amp; and find all the tasks that you have permission to acces to.</div>
                        </div>
                    )
                }
            </div>
        </Drawer>
    )
}

export default SearchTaskDrawer;