import React from 'react';

import { useSystemContext } from '../../../Context/SystemContext';

import moment from 'moment';

import CustomIcon from '../../../UIElements/CustomIcon/CustomIcon';

import './Task.css';
import avatarPlaceholder from '../../../assets/images/placeholder-avatar.png';

const ArchivedTask = ({ index, view, _id, organizationId, productId, projectId, title, assignee, comments, dependencies, checklist, attachments, priority, dueDate, status, setTaskIdForViewTaskDrawer, ...restProps }) => {
    const { 
        systemVariables
    } = useSystemContext();

    const priorityLevelOptions = (systemVariables && systemVariables.task && systemVariables.task.priorityLevelOptions ? systemVariables.task.priorityLevelOptions : null);

    return (
        <div
            className="task-W list" 
            onClick={   
                () => {
                    setTaskIdForViewTaskDrawer(_id);
                } 
            }
        >
            <div className="task hover-pointer">
                <div className="title">{ title ? title : 'N/A' }</div>

                <div className="info-C clearfix">
                    <div className="vertical-y d-inline-block">
                        <div className={ 'assignee' + (assignee ? '' : ' empty') }>
                            {
                                (assignee) ? (
                                    <img className="mr-1 mb-0" src={ avatarPlaceholder } alt="Avatar" />
                                ) : (
                                    <CustomIcon iconKey="profile" color="#C0C0C3" style={{ margin: '0 4px -2px 0' }} /> 
                                )
                            }

                            { assignee ? assignee : 'No assignee' }
                        </div>
                    </div>
                
                    <div className={ 'priority d-inline-block ml-4' +  (priority && priorityLevelOptions && priorityLevelOptions[priority] ? ' ' + priorityLevelOptions[priority].toLowerCase() : '')}>
                        {
                            (priority) ? (
                                (priorityLevelOptions && priorityLevelOptions[priority]) ? (
                                    priorityLevelOptions[priority]
                                ) : (
                                    priority
                                )
                            ) : (
                                'None'
                            )
                        }
                    </div>

                    {
                        dueDate && (
                            <div className="d-inline-block ml-4" style={{ fontSize: '12px', color: '#E45858' }}>
                                <CustomIcon iconKey="date-time" color='#E45858' style={{ margin: '0 2px -2px 0' }} /> { 'Due by ' + moment(dueDate).fromNow(true) }
                            </div>
                        )   
                    }
                </div>
            </div>
        </div>
    );
}

export default ArchivedTask;